import { isUser } from '~/utils/types';
import { createResource } from '~/core/creators';

createResource( {
  id               : 'GitHub.User',
  name             : 'GitHub User',
  icon             : 'fab:github',
  attachable       : [ 'SSP.User' ],
  service_id_field : 'github_id',
  fields           : {
    github_id : {
      type        : 'github-id',
      label       : 'Github User Id',
      summary     : true,
      index       : true,
      unique      : true,
      readonly    : true,
      optional    : true,
      immutable   : true,
    },
    login : {
      type        : 'github-login',
      label       : 'Github User Login',
      summary     : true,
      readonly    : true,
      index       : true,
      optional    : true,
      unique      : true,
      quicksearch : true,
    },
    name          : {
      type        : 'string',
      label       : 'Name',
      summary     : true,
      index       : true,
      optional    : true,
      quicksearch : true,
    },
  },

  behaviors : {
    hasEmail            : { useMultipleEmails : true },
    hasDisabled         : {},
    hasPrimaryAccount   : {},
    hasExternalLink     : {},
  },

  methods : class {

    static emailToLogin( email ) {
      const login = email
        .replace( /@.*/u, '' )
        .toLowerCase()
        .replace( /[^a-z0-9-]+/gu, '-' );
      const invalid = /^-/u.test( login )
        || /-$/u.test( login )
        || /--/u.test( login );
      if ( invalid ) {
        throw new Error(
          `Email ${email} reduces to invalid GitHub login "${login}"`,
        );
      }
      return login;
    }

    static createFrom( ssp_user ) {
      if ( ! isUser( ssp_user ) ) {
        throw new Error( `createFrom must be called with an SSP.User` );
      }
      return this.insert( {
        user_id   : ssp_user._id,
        name      : ssp_user.name,
        email     : ssp_user.email,
        login     : this.emailToLogin( ssp_user.email ),
      } );
    }
  },

  faces         : {
    'card.summary' : [
      {
        layout : 'Badges',
        fields : [ '@badge' ],
      },
      {
        layout : 'LabelsAndValues',
        fields : [ 'email', 'login', 'github_id' ],
      },
    ],
    'list.summary' : [
      {
        layout : {
          type       : 'ItemsBetweenTitleAndIcons',
          iconize    : [ {
            fieldName : 'login',
            icon      : 'fad:id-badge',
          } ],
        },
        fields : [ '-@all' ],
      },
      {
        layout : 'ItemsAndBadges',
        fields  : [ 'email', '@badge' ],
      },
    ],
  },
} );
