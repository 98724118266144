import { createResource } from '~/core/creators';

createResource( {
  id         : 'SSP.Team',
  name       : 'SSP Team',
  icon       : 'fal:users-class',
  attachable : [ 'SSP.Project' ],
  fields     : {
    name        : {
      type        : 'string',
      label       : 'Name',
      index       : true,
      quicksearch : true,
      help        : 'Name of the team',
      faker       : 'words.plural_noun',
      validators : {
        minlength : 1,
        maxlength : 40,
        regexp    : /^[^/]+$/u,
      },
    },
    description : {
      type        : 'text',
      label       : 'Description',
      summary     : true,
      optional    : true,
      help        : 'Description of the purpose for this team',
    },
    is_primary_admin   : {
      type        : 'boolean',
      label       : 'Primary Admin Team',
      optional    : true,
      summary     : true,
      form        : { hidden : true },
      readonly    : true,
      hideFalse   : true,
      default     : false,
      badge       : { label : 'Primary Admin Team', color : 'warning' },
    },
    acl_name        : {
      type        : 'string',
      label       : 'ACL Name',
      optional    : true,
      index       : true,
      unique      : true,
      sparse      : true,
      readonly    : true,
      identifier  : true,
    },
  },
  indexes     : {
    project_id_name_idx : {
      fields  : { project_id : 1, name : 1 },
      unique  : true,
    },
    project_id_is_primary_admin_idx : {
      fields                  : { project_id : 1, is_primary_admin : 1 },
      partialFilterExpression : { is_primary_admin : true },
      unique                  : true,
    },
  },
  behaviors   : {
    hasMembers  : {
      withCreator   : { default : false },
      withService   : false,
      withUpdateJob : false,
      userType      : 'SSP.User',
    },
  },
  actions       : {
    setPrimaryAdmin : {
      label            : 'Make Primary Admin Team',
      help             : 'Make this team the Primary Admin Team',
      icon             : 'far:user-crown',
      access           : 'support',
      visibilityMethod : '!is_primary_admin',
      handler          : 'makePrimaryAdmin',
    },
    delete : {
      label            : 'Delete',
      help             : 'Delete this Team resource from SSP',
      route            : 'delete',
      icon             : 'far:trash-alt',
      access           : 'admin',
      context          : 'self',
      disabledMethod() {
        if ( this.is_primary_admin ) {
          return 'Cannot delete primary admin team.';
        }
      },
    },
  },
  methods   : class {
    fullName() { return `${this.project_id}/${this.name}`; }
  },
  faces         : {
    'list.summary' : [
      {
        layout : {
          type       : 'ItemsBetweenTitleAndIcons',
        },
        fields : [
          {
            name : 'project_id',
            label : 'Project',
            face : { type : 'Owner' },
          },
        ],
      },
      {
        layout : 'ItemsAndBadges',
        fields  : [ 'description', '@badge' ],
      },
    ],
  },
} );
