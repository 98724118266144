import { hideProps } from '@ssp/utils';
import { DB } from '~';

import type { TResource } from '~/types';
import type { Resource } from '~/core/resource/Resource';

declare module './types' {
  export interface TraitConfigs {
    withTeamLinks: boolean;
  }
}

// TODO - Remove this when DB typings improve
export type TeamLink = TResource<'SSP.TeamLink'> & {
  schema: { id: string; };
  to?: Resource;
  from?: Resource;
  load(): Promise<Resource>;
};

interface TeamLinksMethods extends Resource {
  project_id: string;
}

class TeamLinksMethods {

  /**
   * Retrieve all of the SSP.TeamLink resources associated with this
   * resource.  If a direction is specified (either `to` or `from`)
   * then only team links where this resource is the `to` or `from`
   * resource will be returned.  If no direction is specified then
   * all the team links where this resource is at either end of the
   * link will be returned.
   *
   * @param dir - Direction to get links for.
   */
  async getTeamLinks( dir?: 'to' | 'from' ) {
    if ( ! this._team_links ) {
      const rs = DB.SSP.TeamLink.find( { $or : [
        { 'from.id' : this._id }, { 'to.id' : this._id },
      ] } ).minlength( false );
      const links = await rs.all( { links : true } );
      hideProps( this, { _team_links : links } );
    }
    return this._team_links.filter( link => {
      if ( dir === 'to' ) return link.to._id === this._id;
      if ( dir === 'from' ) return link.from._id === this._id;
      return true;
    } );
  }
  declare _team_links: TeamLink[];

  /**
   * Create an outgoing SSP.TeamLink connecting this resource to another.
   *
   * @param {Resource} other - The resource for the other end of
   * the link.
   * @param {object} [fields] - The field values for the team link.
   */
  async addTeamLinkTo( other, fields ) {
    return DB.SSP.TeamLink.insert( {
      ...fields,
      project_id  : this.project_id,
      to : other, from : this,
    } );
  }

  /**
   * Create an incoming SSP.TeamLink from another resource to this
   * one.
   *
   * @param {Resource} other - The resource for the other end of
   * the link.
   * @param {object} [fields] - The field values for the team link.
   */
  async addTeamLinkFrom( other, fields ) {
    return DB.SSP.TeamLink.insert( {
      ...fields,
      project_id  : this.project_id,
      to : other, from : this,
    } );
  }

}

export function buildModelConfig() {
  return [ {
    actions : {
      manageTeamLinks : {
        label           : 'Manage Team Links',
        icon            : 'far:link',
        route           : 'teamlinks',
        access          : 'admin',
      },
    },
    methods : TeamLinksMethods,
  }, BUILD.isServer && {
    events          : {
      async afterUpdate( ev ) {
        if ( this.project_id !== ev.origin.project_id ) {
          await this.removeTeamLinks();
        }
      },
      async afterRemove() {
        await this.removeTeamLinks();
      },
    },
    methods         : class extends TeamLinksMethods {

      async removeTeamLinks() {
        const links = await this.getTeamLinks();
        return Promise.all( links.map( link => link.remove() ) );
      }

    },
  } ];
}
