import _ from 'lodash';
import {
  Type, Validator, ValidatorConfig, ValidationContext,
} from '@ssp/types';
import { mkdebug, DatabaseFieldError } from '@ssp/utils';

import type { SelfOrArray } from '@ssp/ts';
import type { ValidatorRequest } from '@ssp/types';

import { runTransforms } from './transforms';
import { getDataMap, updateQuietly } from '../broker/data-utils';
import { isDBRecord } from '~/utils';
import { Schema } from '~/core/lib/Schema';

import type { MongoIndexOptions } from '../indexes/types';
import type { Face } from '../faces/Face';
import type { Resource } from '~/core/resource/Resource';
import type { ListOptions } from './List';

import { deferred } from './deferrals';

const debug = mkdebug( 'ssp:database:fields' );

export type FieldOptions = {
  name: string;
  type: string;
  label?: string;
  description?: string|string[];
  describe?: string|string[];
  help?: string|string[];
  optional?: boolean;
  required?: boolean;
  readonly?: boolean;
  createonly?: boolean;
  summary?: boolean;
  transient?: boolean;

  form?: $TSFixMe;
  face?: $TSFixMe;

  validator?: ValidatorRequest;
  validators?: ValidatorRequest;
  validate?: ValidatorRequest | false;

  transform?: SelfOrArray<string | ( ( arg: any ) => any )>;
  transforms?: SelfOrArray<string | ( ( arg: any ) => any )>;

  access?: 'admin' | 'member' | 'support' | 'none';

  default?: any;
  faker?: string | false;

  enumerable?: boolean;
  subdocObj?: boolean;
  array?: boolean;
  list?: boolean | ListOptions;
  metadata?: boolean;

  sortable?: boolean;
  minimal?: boolean;
  immutable?: boolean;
  hideTrue?: boolean;
  hideFalse?: boolean;
  quicksearch?: boolean;
  identifier?: boolean;
  unique?: boolean;
  index?: boolean | string | MongoIndexOptions;

  conditional?: {
    fieldName?: string;
    condition?: ( field, fieldState ) => boolean;
  };
  badge?: {
    label?: string;
    color?: string;
    value?: boolean;
  };
  coerce?: ( this: Field, value: any, rsrc?: Resource ) => any;
};

export interface Field {
  constructor: typeof Field;
}
export class Field {

  declare schema: Schema;

  /** Field name */
  name: string;

  get simple() { return true; }

  /** Short, human readable description of the field. */
  declare description?: string;

  /** Help text (longer, more verbose than the description) */
  declare help?: string;

  /**
   * Readonly field, no editing allowed on the client, all updates to
   * readonly fields must be initiated on the server-side.
   */
  readonly: boolean = false;

  /** Whether the field is required or not. */
  get required() { return this._required; }
  set required( value ) {
    if ( _.isBoolean( value ) ) {
      this._required = value;
    } else {
      throw new DatabaseFieldError( {
        message : `Invalid value for "required": ${value}`,
        tags    : {
          schema   : this.schema.id,
          field    : this.name,
          property : 'Field#required',
        },
        data    : { value },
      } );
    }
  }
  get optional() { return ! this.required; }
  set optional( value ) { this.required = ! value; }
  _required: boolean = true;

  /**
   * Immutable fields can only be set once.  After they have been set
   * they can never be changed.
   */
  immutable: boolean = false;

  /**
   * Minimal fields are the fields shown on "create resource" forms.
   * They are the minimum set of fields that you have to provide
   * values for in order to create a resource.
   */
  get minimal() { return this.required || this._minimal; }
  set minimal( m ) { this._minimal = !!m; }
  _minimal: boolean = false;

  /**
   * Sortable fields are fields that are shown as sortable options
   * from the 'Collection View Controls'.
   */
  sortable: boolean = false;

  /**
   * Whether or not this is a summary field.  Summary field just means
   * that it appears automatically on a default summary view.
   */
  summary: boolean = false;

  /**
   * Set to true if this is a metadata field.  Metadata fields are
   * things like created_at, created_by, updated_at, updated_by.
   * Generally fields that are about the record rather than about the
   * resource itself.
   */
  metadata: boolean = false;

  /**
   * The default value (or a function to generate a default)
   */
  declare default: any;

  /**
   * Set to true if this is an array field.  Note that an array field
   * is not the same as a list field.  If the field contains subdocs
   * then it must be a list field.  An array field can only be used
   * for simple arrays of strings (like the `tags` field, for
   * example).
   */
  array: boolean = false;

  /**
   * Set to true if field type is an object that should be treated
   * like a @see subdoc for form generation. <FieldElementSubDoc />
   */
  subdocObj: boolean = false;

  /**
   * Set to false if you want to make a field non-enumerable, so it
   * won't show up in the log output.  This should be used very
   * sparingly.
   */
  enumerable: boolean = true;

  /**
   * Access indicates the level of access required to edit this field
   * in the Simple ACL System.
   *
   * The access level can be:
   *  * admin   - For project-owned resources: Must be a member of the
   *              project's primary admin team.  For user-owned
   *              resources, you must be the owner.
   *  * member  - Must be a member of any of the owning project's teams.
   *  * support - This field is only editable by the SYSTEM/Support team.
   *  * none    - Nobody is allowed to edit this field directly.
   *
   * Note that people in the SYSTEM/Administrators group can edit any
   * field (including those with access "none"), and people in the
   * SYSTEM/Support group can edit admin or member fields for any
   * project.
   *
   * The default access is 'admin' for most fields.  For `metadata` or
   * `readonly` fields the default is `none`.
   */
  get access() {
    if ( this._access ) return this._access;
    if ( this.metadata || this.readonly ) return 'none';
    return 'admin';
  }
  set access( access ) { this._access = access; }
  _access: 'admin' | 'member' | 'support' | 'none';

  /**
   * The hideFalse field can be used with boolean type fields to
   * indicate the field should be hidden when the value is false.
   */
  declare hideFalse: boolean;

  /**
   * The hideTrue field can be used with boolean type fields to
   * indicate the field should be hidden when the value is true.
   */
  declare hideTrue: boolean;

  /**
   * Setting this property to true indicates that this field should be
   * searched by quicksearch.
   */
  quicksearch?: boolean = false;

  /**
   * Transforms can be used to ensure the value set for this field
   * conforms to specific rules.  When setting a value on a Model, the
   * value is first {@link #coerce} then transformed before being set.
   *
   * @type {Array<Function|string>}
   */
  declare transforms: ( string | ( ( arg: any ) => any ) )[];

  /**
   * Index can be set to true to automatically generate a single-field
   * index for that field, or you can configure it explicitly.
   */
  declare index: boolean | string | MongoIndexOptions;

  /**
   * This field is a unique identifier.  Setting this to true ensures
   * that there is a unique index for this field, and also makes this
   * a field that can be used by `rs.uniques()`.
   */
  declare unique: boolean; // true to automatically create unique indexes

  /**
   * Setting this to true indicates that this field is an identifier
   * field.
   *
   * For a `Resource`, this means that it can be used in place of the
   * primary id with methods like `fetch` and `resolve` when they are
   * called on the appropriate resource type.  This flag indicates
   * that only one resource of this type can have this value in
   * **any** of their identifier fields (not just this specific
   * field).
   *
   * For a `SubDocument`, this means that this field identifies the
   * subdoc when it's in an array of subdocuments, and every instance
   * of this class that exists in the same subdocument array must have
   * a unique set of values for their identifier fields. (To be
   * considered a duplicate *all* the identifier field values have to
   * match, not just some of them).
   */
  identifier: boolean = false;

  /**
   * Form configuration.  This can be either `false` in which case
   * this field will not be rendered in forms, or an object of form
   * field configuration options, which will be merged into the
   * configuration for this field when rendering a form.
   *
   * @example
   *  form    : false, // Do not include in a form
   *  form    : {
   *    type    : 'Text',
   *  },
   *
   * @type {boolean|object}
   */
  declare form: boolean | unknown;

  /**
   * Form Field configuration ( FieldElementConditional ).
   * Used to make this field conditional on the state of another field.
   *
   * @example
   *  conditional : {
   *    fieldName    : 'auto_add', // conditional field
   *    condition( field, fieldState ) {
   *      return fieldState.input.checked
   *    } // condition to be met
   *  },
   */
  declare conditional: {
    fieldName: string;
    condition: ( field, fieldState ) => boolean;
  };

  /**
   * Face configuration.  This can be a string indicating the type of
   * `DataView` component to use when rendering this field in a view,
   * or object of face configuration options (which must include
   * a `type` property), which will be merged into the configuration
   * for this field when rendering a face.
   *
   * @example
   *  face    : {
   *    type    : 'BooleanIcon',
   *  },
   *
   * @type {string|object}
   */
  declare face: string | Partial<Face>;

  declare type: Type;
  declare validators: ValidatorConfig[];

  get link() { return false; }
  get virtual() { return false; }

  constructor( options: FieldOptions ) {
    if ( ! _.isPlainObject( options ) ) {
      throw new DatabaseFieldError( {
        message : `Invalid Field configuration "${options}"`,
        tags    : { method : 'Field.constructor', field : 'UNKNOWN' },
        data    : { options },
      } );
    }
    _.assign( this, _.omit( options, [
      'type', 'form', 'face',
      'validator', 'validators', 'transform', 'transforms',
    ] ) );
    const {
      type,
      validator, validators, validate,
      transform, transforms,
      form, face,
      ...opts
    } = options;
    this.setType( type );
    _.merge( this, { form, face } );

    _.assign( this, opts );
    this.transforms = _.uniq( _.compact( _.flattenDeep( [
      this.transforms, transform, transforms,
    ] ) ) );
    this.init_validators( validate, validator, validators );
    const schema = Schema.get( this.type.name );
    if ( schema ) this.#subdocSchema = schema;
  }

  #subdocSchema?: Schema;

  get subdocModel() { return this.subdocSchema?.model; }
  get subdocSchema() {
    if ( this.#subdocSchema ) return this.#subdocSchema;
    return this.#subdocSchema = Schema.get( this.type.name );
  }
  get subdocClass() { return this.subdocSchema?.id; }

  init_validators( validate, ...more ) {
    if ( validate === false ) {
      this.validators = [];
      return;
    }
    this.validators = Validator.parse(
      {
        type        : 'required',
        config      : this.required,
        collection  : this.array || this.list,
      },
      this.array && { type : 'array', collection : true },
      this.subdoc && { type : 'dbmodel', config : this.subdocSchema.id },
      this.type.validators, this.validators, validate, ...more,
    );
  }

  setType( name ) {
    if ( ! _.isString( name ) ) {
      throw new DatabaseFieldError( {
        message : `"type" must be a string`,
        tags    : {
          schema : this.schema.id,
          method : 'Field#setType',
          field  : this.name,
        },
        data    : { type : name },
      } );
    }
    if ( this.type && this.type.name !== 'any' && this.type.name !== name ) {
      throw new DatabaseFieldError( {
        message : `Cannot replace Field type "${this.type}" with "${name}"`,
        tags    : {
          schema : this.schema.id,
          method : 'Field#setType',
          field  : this.name,
        },
        data    : { current : this.type.name, changed : name },
      } );
    }
    const type = Type.get( name );
    // If we don't find the type, then we set the type to 'any' and
    // stick an entry into the deferred array.  See the comments in
    // `index.js` in the `finish` method for more details about why
    // this is.
    if ( type ) {
      this.type = type;
    } else {
      this.type = Type.get( 'any' );
      deferred.push( { type : name, field : this } );
      return;
    }
  }

  /**
   * Returns true if the value of this field is a SubDocument.
   */
  get subdoc() { return Boolean( this.subdocSchema ); }

  /**
   * Returns true if the value of this field is a *typed* SubDocument
   * (meaning it defines a `type_field` which allows it to have
   * different child types, so it requires special handling because
   * you need to know which child type to use to create a new
   * instance).
   */
  get typed_subdoc() {
    return Boolean( this.subdoc && this.subdocSchema.type_field );
  }

  get list() { return false; }

  getDefault( doc, data ) {
    let value = this.default;
    if ( _.isFunction( value ) ) value = value.call( doc, data, this );
    if ( this.optional && _.isNil( value ) ) return;
    return this.transform( this.coerce( value, doc ), doc );
  }
  hasDefault() { return ! _.isNil( this.default ); }

  transformValue( value, context ) {
    return runTransforms( this.transforms, value, context, this );
  }
  transform( value, context ) {
    if ( this.array ) {
      return _.map(
        _.castArray( value ),
        v => this.transformValue( v, context ),
      );
    } else {
      return this.transformValue( value, context );
    }
  }

  declare _label: string;
  /**
   * The human-readable label for this field.  This is what will be
   * shown as the label in web forms.
   */
  get label() { return this._label || _.startCase( this.name ); }
  set label( label ) { this._label = label; }

  coerceValue( value: any, _rsrc?: Resource ) {
    if ( typeof this.type.coerce === 'function' ) {
      return this.type.coerce.call( this, value );
    }
    return value;
  }

  /**
   * Coerce a value using this fields type configuration.
   *
   * @param {*|*[]} value - The value to coerce (or an array of
   * values, if the field is marked as an array field).
   * @param {Resource} [rsrc] - The resource the value will be
   * associated with.
   * @returns {any} The coerced value.
   */
  coerce( value: any, rsrc?: Resource ) {
    if ( this.array ) {
      return _.map( _.castArray( value ), v => this.coerceValue( v, rsrc ) );
    } else {
      return this.coerceValue( value, rsrc );
    }
  }

  /**
   * Format a value for storage in the database or for transport over
   * the wire.  This is the opposite of the {@link #parse} method.
   *
   * @param {any} value - The value to format.
   */
  formatValue( value ) {
    // Types only have 'format' and not 'formatValue' methods, and
    // they always format the type itself, not the collection
    if ( _.isFunction( this.type.format ) ) {
      return this.type.format.call( this, value );
    }
    if ( _.isFunction( _.get( value, 'toJSON' ) ) ) return value.toJSON();
    return value;
  }
  format( value ) {
    if ( this.array ) {
      return _.map( value, val => this.formatValue( val ) );
    } else {
      return this.formatValue( value );
    }
  }

  /**
   * Parse a value from database storage or from transport over the
   * wire.  This is the opposite of the {@link #format} method.
   *
   * @param {any} value - The value to parse.
   */
  parseValue( value ) {
    if ( _.isFunction( this.type.parse ) ) {
      return this.type.parse.call( this, value );
    }
    // TODO - I don't think there is any way for this ever get called,
    // because if it's a subdoc then this.type will be the `dbmodel`
    // type for that schema, and that type has a `parse` method that
    // does the right thing.
    if ( this.subdoc ) return this.subdocModel.coerce( value );
    return value;
  }
  parse( value ) {
    if ( this.array ) {
      return _.map( value, val => this.parseValue( val ) );
    } else {
      return this.parseValue( value );
    }
  }

  equalsValue( one, two ) {
    // If they are both undefined then we consider them to be equal,
    // even if one is `undefined` and one is `null`.
    if ( _.isNil( one ) && _.isNil( two ) ) return true;
    // If only one or the other is undefined then they are not equal
    if ( _.isUndefined( one ) !== _.isUndefined( two ) ) return false;
    if ( _.isNull( one ) !== _.isNull( two ) ) return false;
    one = this.formatValue( one );
    two = this.formatValue( two );
    if ( _.isFunction( this.type.equals ) ) {
      const res = this.type.equals.call( this, one, two );
      if ( _.isBoolean( res ) ) return res;
    }
    return _.isEqual( one, two );
  }

  equals( one, two ) {
    if ( this.array ) {
      return _.isArray( one )
        && _.isArray( two )
        && _.isEqual( [ ...one ].sort(), [ ...two ].sort() );
    } else {
      return this.equalsValue( one, two );
    }
  }

  strictEquals( one, two ) { return this.equals( one, two ); }

  toJSON() {
    return _.mapKeys( this, ( _val, key ) => {
      if ( key.startsWith( '_' ) ) return key.substring( 1 );
      return key;
    } );
  }

  clone( add ) {
    const data = _.cloneDeep( this.toJSON() );
    if ( typeof add === 'function' ) {
      add( data );
    } else if ( _.isObject( add ) ) {
      _.merge( data, add );
    }
    const Ctor = this.constructor;
    const copy = new Ctor( { ...data, type : this.type.name } );
    [ 'schema' ].forEach( name => {
      const prop = Object.getOwnPropertyDescriptor( this, name );
      Object.defineProperty( copy, name, prop );
    } );
    return copy;
  }

  /**
   * Return a configuration value for a key, possibly looking through
   * the configuration tree to find it.
   *
   * @param {string} key - The key to lookup.
   * @param {string} [where] - The optional subconfig to look through
   * (form, face, etc).
   */
  get( key, where ) {
    const paths = [];
    if ( where ) {
      paths.push(
        [ where, key ], // field.form.foo
        [ 'type', where, key ], // field.type.form.foo
      );
    }
    paths.push(
      [ key ], // field.foo
      [ 'type', key ], // field.type.foo
    );
    for ( const path of paths ) {
      const val = _.get( this, path );
      if ( ! _.isNil( val ) ) return val;
    }
  }

  pick( keys, where ) {
    return _.fromPairs( _.map( keys, key => {
      return [ key, this.get( key, where ) ];
    } ) );
  }

  validate( context: ValidationContext = {} ) {
    const { safe, ...opts } = context;
    debug( `${this} validate`, context );
    return Validator.run( this.validators, {
      array   : this.array,
      ...opts,
      field   : this.name,
      param   : this.name,
    } ).catch( err => {
      if ( safe ) return err;
      throw err;
    } );
  }

  makeGetter() {
    const { name } = this;
    return function get() { return getDataMap( this ).get( name ); };
  }

  makeSetter() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const field = this;
    return function set( value ) {
      if ( value === null ) {
        getDataMap( this ).set( field.name, null );
      } else {
        value = field.transform( field.coerce( value, this ), this );
        getDataMap( this ).set( field.name, value );
      }
    };
  }

  updateQuietly( rsrc, value ) {
    const map = getDataMap( rsrc );
    if ( this.subdoc ) {
      if ( isDBRecord( rsrc[ this.name ] ) ) {
        return updateQuietly( rsrc[ this.name ], value );
      } else {
        value = this.transform( this.subdocModel.coerce( value ), rsrc );
        return map._set( this.name, value );
      }
    } else {
      value = this.transform( this.coerce( value, rsrc ), rsrc );
      return map._set( this.name, value );
    }
  }

  makeAccessors() {
    return {
      enumerable    : this.enumerable,
      configurable  : false,
      get           : this.makeGetter(),
      set           : this.makeSetter(),
    };
  }

  toString() { return `Field(${this.schema.id}#${this.name})`; }

}
