
declare module './types' {
  export interface TraitConfigs {
    withService: boolean;
  }
}

export function buildMemberInfoConfig( { userType } ) {
  return [ {
    fields          : {
      service_user_id : {
        type      : 'id',
        label     : 'Service User ID',
        readonly  : true,
        index     : true,
      },
      service_user  : {
        type        : userType,
        label       : 'Service User',
        computed    : {
          cached      : true,
          get() {
            if ( ! this.service_user_id ) return null;
            return this.getUserModel().fromId( this.service_user_id );
          },
          set( user ) {
            if ( user instanceof this.getUserModel() ) {
              this.service_user_id = user._id;
            }
          },
        },
      },
    },

    methods   : class {

      async getServiceUser() { return this.service_user.load(); }

    },
  }, BUILD.isServer && {
    methods   : class {
      async updateServiceInfo() {
        if ( this.service_user_id ) return;
        const userType = this.getUserType();
        const svcuser = await this.user.findOrCreateRelated( userType );
        this.service_user_id = svcuser._id;
      }

    },
    events    : {
      async beforeSave( ev ) {
        return this.updateServiceInfo();
      },
    },
  } ];
}
