import { SubDocument } from './SubDocument';

SubDocument.initialize( {
  id                  : 'SubDocument',
  is_abstract         : true,
  is_resource         : false,
  is_project_resource : false,
  is_user_resource    : false,

  faces         : {
    'card.summary' : [ { layout : 'PropTable', fields : [ '@summary' ] } ],
    'panel.summary' : { layout : 'PropTable', fields : [ '@summary' ] },
    'list.summary' : [ { layout : 'Values', fields : [ '@summary' ] } ],
  },

} );
