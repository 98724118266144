import { BadRequest, Forbidden } from '@ssp/utils';
import { getSchema } from '~/core/schemas';

import { Resource } from './Resource';
import { UserResource } from './UserResource';

UserResource.initialize( {
  id                  : 'UserResource',
  inherit             : 'Resource',
  is_abstract         : true,
  is_resource         : true,
  is_user_resource    : true,
  is_project_resource : false,
  fields      : {
    user_id       : {
      type      : 'id',
      label     : 'User ID',
      index     : true,
      access    : 'support',
      metadata  : true,
      faker     : 'user.id',
    },
    owner         : {
      type        : 'SSP.User',
      label       : 'Owner',
      face        : { type : 'Owner' },
      metadata    : true,
      readonly    : true,
      enumerable  : false,
      computed    : {
        cached  : true,
        compute() {
          // This one returns undefined instead of null because null
          // means "don't know yet, return undefined but don't cache
          // the return value so we can try again the next time this
          // is accessed".  Actually returning undefined means that
          // `undefined` will be put into the data map and this
          // property will return undefined for every access.
          if ( this._id === this.user_id ) return undefined;
          if ( ! this.user_id ) return null;
          return Resource.demand( 'SSP.User' ).fromId( this.user_id );
        },
      },
    },
    user          : {
      type        : 'SSP.User',
      label       : 'User',
      face        : { type : 'Owner' },
      metadata    : true,
      readonly    : true,
      enumerable  : false,
      computed    : {
        cached  : true,
        get() { return ( this._id === this.user_id ) ? this : this.owner; },
      },
    },
  },

  actions : {
    changeOwner   : {
      label   : 'Change Owner',
      help    : 'Move this resource to another SSP User',
      icon    : 'fas:theater-masks',
      access  : 'support',
      params  : {
        user_id : {
          label : 'New Owner',
          type  : 'any',
          parse : ref => ref.id || ref._id,
          form  : {
            type      : 'ResourceSelector',
            model     : 'SSP.User',
            query     : {},
            options   : {},
          },
        },
      },
      method        : 'update',
    },
  },

  behaviors     : {
    hasTimestamps     : {},
    hasResourceCounts : {},
  },

  faces         : {
    'list.summary' : [
      {
        layout : {
          type       : 'ItemsBetweenTitleAndIcons',
        },
        fields : [ '-@all' ],
      },
      {
        layout : 'ItemsAndBadges',
        fields  : [ 'email', '@badge' ],
      },
    ],
  },

}, BUILD.isClient && {
  queries       : {
    mine( mine=true ) { return [ {}, { flag : { mine } } ]; },
  },
}, BUILD.isServer && {
  queries : {
    async mine( mine=true ) {
      const user = ctx.user;
      const op = mine ? '$eq' : '$ne';
      return [ { user_id : { [ op ] : user._id } }, {} ];
    },
  },
  events  : {
    async beforeQuery( ev ) {
      const req = ev.request;
      if ( req.schema === 'SSP.User' ) return; // handled in SSP.User
      if ( req.schema === 'SSP.User.Settings' ) return; // handled directly
      await req.restrictions( {
        'view-any-user-resources' : null,
        'view-coworker-resources' : async () => {
          // If the query is for a specific user then we can
          // short-circuit the slow `getCoworkerIds`-based query and
          // just worry about whether or not that specific user is
          // a coworker.
          const query_user = req.query?.user_id;
          if ( typeof query_user === 'string' ) {
            // If they are searching for themselves then we can just ignore
            if ( query_user === req.subject._id ) return;
            // if they are a coworker then we can see them and we
            // don't have to worry about any additional restriction
            // queries because the base query is specific enough.
            if ( await req.subject.hasCoworker( query_user ) ) return;
            // If they aren't a coworker then we add an additional
            // restriction query that won't match anything, because they
            // aren't allowed to view this resource.
            return { _id : '' };
          }
          // If the query didn't include a specific user_id then we can
          // fallback to the slow query...
          const ids = await req.subject.getCoworkerIds();
          if ( ids.length ) return { user_id : { $in : ids } };
        },
        '*'                       : { user_id : req.subject._id },
      } );
    },
    async beforeRequest( ev ) {
      const req = ev.request;
      const { schema, method, owner, subject, resource } = req;
      if ( schema === 'SSP.User' ) return; // handled in SSP.User
      if ( method === 'insert' ) {
        if ( Resource.demand( 'SSP.User' ).is( owner ) ) {
          throw new BadRequest( 'owner is not a user' );
        }
        if ( ! getSchema( schema ).canAttachTo( owner ) ) {
          throw new BadRequest( `Can not attach ${schema} to user` );
        }
        if ( owner._id === subject._id ) {
          if ( ! req.can( 'create-own-user-resources' ) ) {
            throw new Forbidden( 'cannot create own resources' );
          }
        } else {
          if ( ! req.can( 'manage-any-user-resources' ) ) {
            throw new Forbidden( 'subject is not owner of resource' );
          }
        }
      }
      if ( method === 'remove' ) {
        if ( req.can( 'remove-any-user-resources' ) ) return;
        if ( resource.user_id === subject._id ) {
          if ( ! req.can( 'remove-own-user-resources' ) ) {
            throw new Forbidden( 'Cannot remove own user resources' );
          }
        }
        throw new Forbidden( 'cannot remove this resource' );
      }
    },
    async duringRequest( ev ) {
      const req = ev.request;
      const { schema, resource, subject } = req;
      if ( schema === 'SSP.User' ) return; // handled in SSP.User
      if ( resource ) {
        const [ res, reason ] = resource.canBeManagedBy( subject );
        if ( ! res ) throw new Forbidden( reason );
      }
    },
    afterUpdate( ev ) {
      const field = this.schema.service_id_field;
      if ( field && ev.updates.hasField( field ) ) {
        return this.owner.job( 'sync' );
      }
    },
  },
  methods : class {
    /**
     * Fields to use as the display name of the resource.
     * The first element will be taken as the primary field to use.
     * Any others will be used as fallback fields.
     */
    static get displayNameFields() { return [ 'name', 'email' ]; }

    async getDisplayName( this: UserResource ) {
      const ctor = Object.getPrototypeOf( this ).constructor;
      for ( const field of ctor.displayNameFields ) {
        if ( this[ field ] ) return this[ field ];
      }
      if ( this.owner ) {
        await this.owner.load();
        return this.owner.display_name;
      }
    }
  },
} );
