import _ from 'lodash';

import { Model } from '~/core/lib/Model';
import { isUser } from '~/utils/types';
import { MemberInfo } from './MemberInfo';
import { MembershipHelper } from './membership-helper';

import type { MemberSource, MemberMethodOptions } from './types';
import type {
  MembershipChanges, MembershipChange, MembershipHelperOptions,
} from './membership-helper';
import type { List } from '~/modules/fields/List';

const definitions: $TSFixMe[] = [];
export default definitions;

class MembersMethods extends Model {

  declare static MemberInfo: typeof MemberInfo;
  declare MemberInfo: typeof MemberInfo;

  declare members: List<MemberInfo>;

  /**
   * @param source - The member source to get
   */
  async getMemberInfoFor( source: MemberSource ) {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const id = await this.MemberInfo.getUserIdFor( source );
    const have = this.members.getKey( id );
    if ( have ) return have;
    const member = await this.MemberInfo.from( source );
    this.members.add( member );
    return member;
  }

  async getMembershipHelper( options: MembershipHelperOptions = {} ) {
    const helper = new MembershipHelper( this, options );
    await helper.prepare();
    return helper;
  }

  async updateMemberships(
    changes: MembershipChanges,
    options: MemberMethodOptions = {},
  ) {
    const { save = true, ...opts } = options;
    const helper = await this.getMembershipHelper( opts );
    await helper.processChanges( changes );
    await helper.commit( save );
    return helper;
  }

  async doMembershipChanges(
    sources: MemberSource[],
    change: Omit<MembershipChange, 'user_id'>,
    options: MemberMethodOptions = {},
  ) {
    const changes: MembershipChanges = await Promise.all(
      _.map( _.castArray( sources ), async src => {
        const user_id = await this.MemberInfo.getUserIdFor( src );
        return { user_id, ...change };
      } ),
    );
    log.trace( 'doMembershipChanges:', changes, options );
    return this.updateMemberships( changes, options );
  }

  async addMember(
    source: MemberSource,
    config: Record<string, any> = {},
    options: MemberMethodOptions = {},
  ) {
    return this.addMembers( source, config, options );
  }

  async addMembers(
    sources: MemberSource[],
    config: Record<string, any> = {},
    options: MemberMethodOptions = {},
  ) {
    return this.doMembershipChanges( sources, {
      change_type : 'add', config,
    }, options );
  }

  async removeMember(
    source: MemberSource,
    options: MemberMethodOptions = {},
  ) {
    return this.removeMembers( source, options );
  }

  async removeMembers(
    sources: MemberSource[],
    options: MemberMethodOptions = {},
  ) {
    return this.doMembershipChanges( sources, {
      change_type : 'remove',
    }, options );
  }

  async updateMember(
    source: MemberSource,
    config: Record<string, any> = {},
    options: MemberMethodOptions = {},
  ) {
    return this.updateMembers( source, config, options );
  }
  async updateMembers(
    sources: MemberSource[],
    config?: Record<string, any>,
    options: MemberMethodOptions = {},
  ) {
    return this.doMembershipChanges( sources, {
      change_type : 'update', config,
    }, options );
  }

  hasMember( user ) {
    if ( _.isString( user ) ) user = { user_id : user };
    return this.members.has( user );
  }

  hasPendingMembershipChanges() {
    for ( const member of this.members ) {
      if ( member.new_change ) return true;
    }
    return false;
  }

}

definitions.push( {
  methods   : MembersMethods,
  actions   : {
    manageMembers : {
      label           : 'Manage Members',
      icon            : 'far:users',
      route           : 'members',
      access          : 'admin',
    },
  },
  queries     : {
    user( user_id ) {
      if ( isUser( user_id ) && user_id._id ) user_id = user_id._id;
      if ( _.isString( user_id ) ) return { 'members.user_id' : user_id };
      throw new Error( `Invalid argument for query user(): ${user_id}` );
    },
  },
} );
