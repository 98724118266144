import { JobStepJob } from './Job';

import type { JobStepJobOptions } from './Job';

export type JobStepUpdateMembersPrepJobOptions = {
  type: 'UpdateMembersPrepJob';
} & JobStepJobOptions;

declare module '~/modules/jobs/types' {
  export interface JobStepTypes {
    UpdateMembersPrepJob: JobStepUpdateMembersPrepJobOptions;
  }
}

export class JobStepUpdateMembersPrepJob extends JobStepJob {

  get ignore_errors() { return true; }
  get ignore_status() { return true; }
  get label() { return 'Wait for preparation job'; }

}

JobStepUpdateMembersPrepJob.initialize( {
  id        : 'JobStep.UpdateMembersPrepJob',
  inherit   : 'JobStep.Job',
  fields    : {
  },
}, BUILD.isServer && {
  methods : class extends JobStepUpdateMembersPrepJob {

    async execute() {
      const job = await this.waiting_for_job.reload();

      // TODO - This is just serving as an invariant, this can't
      // actually happen, but the types on `.reload()` don't correctly
      // reflect that (you can only get an `Error` instance returned
      // by reload if you call it with `{ safe : true }`).
      if ( job instanceof Error ) throw job;

      // if the job was successful then we just need to make sure we
      // have a followup job
      if ( job.isSuccess() ) return this.followup();

      // if the job failed then we just ignore it, the membership
      // helper will make sure the error is attached to the
      // pending_change record
      if ( job.isFailure() ) return;

      // If we get here then the job we're waiting for hasn't finished
      // yet. In that case we make sure that it has the same or higher
      // priority as our own job, and then wait for it to finish.
      await job.bumpPriorityIfLower( this.job.priority );
      return this.wait( `Waiting for ${job}` );
    }

    async followup() {
      for ( const step of this.job.steps ) {
        if ( await this.isPendingFollowup( step ) ) return;
      }
      return this.subjob( 'update-members' );
    }

    async isPendingFollowup( step: JobStepJob ) {
      const job = step.waiting_for_job;
      // If we don't have a job then this step isn't the right type
      if ( ! job ) return false;
      // if the subjob step isn't runnable then it doesn't count
      if ( ! step.isRunnable() ) return false;
      // We wait as long as possible before loading the job to skip
      // as many as we can without having to load them first
      await job.load();
      // If it's not an update-members job then we can skip it too
      if ( job.job_name !== 'update-members' ) return false;
      // If it's marked do_not_coalesce then that job has already
      // started processing, so we need a new one for the followup
      if ( job.do_not_coalesce ) return false;
      // If we get here then this is a good followup job and we don't
      // need another one.
      return true;
    }

  },
} );
