import _ from 'lodash';
import { mkdebug, NotImplemented } from '@ssp/utils';

import type { Json, JsonObject } from '@ssp/utils';

import type { Schema } from '~/core/lib/Schema';
import type { Resource } from '~/core/resource/Resource';
import type {
  TransportResponse, TransportMethod,
} from '~/lib/TransportResponse';
import type { JobOptions, ActionOptions } from '~/modules';
import type { Updates } from '~/modules/updates';
import type { SchemaId } from '~/types';

export type FindResponse = JsonObject;
export type Query = JsonObject;
export type Update = JsonObject;
export type Pipeline = JsonObject[];

export interface Document {
  _id: string;
  [key: string]: Json;
}

export interface Options {
}
export interface FindOptions extends Options {
  /** Whether or not to include child types in the results. */
  children?: boolean;
  /** A comment to include in the mongo query */
  comment?: string;
  /**
   * Set to true if this should be an exact search (case-sensitive,
   * no substring)
   */
  exact?: boolean;
  /**
   * Specify data fields to add to the return ref_urls.
   */
  fields?: string[];
}
export interface FetchOptions extends Options {
}
export interface RetrieveOptions extends Options {
  /**
   * Only return a complete resource is the version is greater than
   * this, otherwise just return a ref_url confirming that the
   * version we said we had is the current version.
   */
  version?: number;
  /**
   * If resource is not found, insert a new one with default values
   * and values included here and return that.
   */
  insert?: boolean | Record<string, unknown>;
}
export interface ResolveOptions extends Options {
  /** Additional fields to include. */
  fields?: string[];
}
export interface InsertOptions extends Options {
}
export interface UpdateOptions extends Options {
}
export interface BatchOptions extends Options {
}

export type PerformPayload = {
  method: TransportMethod;
  schema: SchemaId;
  query?: any;
  options?: any;
  updates?: any;
  id?: string;
  job_name?: string;
  action_name?: string;
  data?: any;
};

const debug = mkdebug( 'ssp:database:transport' );

export abstract class Transport {

  constructor() { debug( 'Constructed Transport' ); }

  async perform(
    payload: PerformPayload,
  ): Promise<TransportResponse<any, any>> {
    void payload;
    throw new NotImplemented();
  }

  /**
   * Retrieve the content of a resource.
   *
   * @param schema - DB Schema
   * @param id - The database `_id` to retrieve.
   * @param options - Retrieve options.
   */
  retrieve<T extends Resource = Resource>(
    schema: Schema, id: string, options?: RetrieveOptions,
  ): Promise<TransportResponse<'retrieve', T>> {
    return this.perform( {
      method  : 'retrieve',
      schema  : schema.id,
      // Pass the id inside the query instead of directly, because we
      // don't want the `RequestContext` build process to load the
      // related resource in this case, since we would then just have
      // to run the same request again in order to get the related
      // transport response.
      query   : { id, ..._.pick( options, 'version' ) },
    } );
  }

  /**
   * Fetch the content of a resource.  This is very similar to
   * `retrieve`, except you can use this if you have an ident rather
   * than an id.
   *
   * @param schema - DB Schema
   * @param query - The query to fetch by.
   * @param options - Fetch options.
   */
  fetch<T extends Resource = Resource>(
    schema: Schema,
    query: Query,
    options?: FetchOptions,
  ): Promise<TransportResponse<'fetch', T>> {
    return this.perform( {
      method  : 'fetch',
      schema  : schema.id,
      query, options,
    } );
  }

  /**
   * Retrieve a batch of resources.
   *
   * @param schema - DB Schema
   * @param ids_and_versions - The database `_id`s to retrieve, mapped
   * to the version that the client already has.
   * @param options - Retrieve options.
   */
  batch<T extends Resource = Resource>(
    schema: Schema,
    ids_and_versions: Record<string, number>,
    options: BatchOptions,
  ): Promise<TransportResponse<'batch', T>> {
    return this.perform( {
      method  : 'batch',
      schema  : schema.id,
      query   : ids_and_versions,
      options,
    } );
  }

  /**
   * Run a query and return the results.  This is the backend method
   * called by {@see ResultSet}.  The `query` and `options` objects
   * are the exact `query` and `options` that get built up in
   * a `ResultSet` when assembling a query.
   *
   * @param schema - The DB schema to search against.
   * @param query - The query to execute.
   * @param options - Query options. {@see ResultSet} for more
   * details of what options are valid.
   */
  find<T extends Resource = Resource>(
    schema: Schema,
    query: Query,
    options?: FindOptions,
  ): Promise<TransportResponse<'find', T>> {
    return this.perform( {
      method  : 'find',
      schema  : schema.id,
      query, options,
    } );
  }

  /**
   * Resolve a query and return a current, versioned, and fully
   * resolved ReferenceURL for it.
   *
   * @param schema - The DB schema to resolve for.
   * @param query - The data available to resolve from.
   * @param options - Options object.
   */
  resolve<T extends Resource = Resource>(
    schema: Schema,
    query: Query,
    options?: ResolveOptions,
  ): Promise<TransportResponse<'resolve', T>> {
    return this.perform( {
      method  : 'resolve',
      schema  : schema.id,
      query, options,
    } );
  }

  insert<T extends Resource = Resource>(
    schema: Schema,
    updates: Updates,
    options?: InsertOptions,
  ): Promise<TransportResponse<'insert', T>> {
    return this.perform( {
      method  : 'insert',
      schema  : schema.id,
      updates, options,
    } );
  }

  update<T extends Resource = Resource>(
    schema: Schema,
    id: string,
    updates: Updates,
    options?: UpdateOptions,
  ): Promise<TransportResponse<'update', T>> {
    return this.perform( {
      method  : 'update',
      schema  : schema.id,
      id, updates, options,
    } );
  }

  remove<T extends Resource = Resource>(
    schema: Schema,
    id: string,
  ): Promise<TransportResponse<'remove', T>> {
    return this.perform( {
      method  : 'remove',
      schema  : schema.id,
      id,
    } );
  }

  job<T extends Resource | typeof Resource = Resource | typeof Resource>(
    resource: T,
    job_name: string,
    data: JsonObject,
    options?: JobOptions,
  ): Promise<TransportResponse<'job', T>> {
    return this.perform( {
      method    : 'job',
      schema    : resource.schema.id,
      id        : ( resource as any )._id,
      job_name, data, options,
    } );
  }

  action<
    T extends Resource | typeof Resource = Resource | typeof Resource,
    U = unknown,
>(
    resource: T,
    action_name: string,
    data: JsonObject,
    options?: ActionOptions,
  ): Promise<TransportResponse<'action', U>> {
    return this.perform( {
      method    : 'action',
      schema    : resource.schema.id,
      id        : ( resource as any )._id,
      action_name, data, options,
    } );
  }

}

let _transport: Transport;
export function setTransport( transport: Transport ) {
  _transport = transport;
  debug( 'setTransport', _transport );
}
export function getTransport(): Transport {
  if ( ! _transport ) throw new Error( `Transport has not been configured` );
  debug( 'getTransport', _transport );
  return _transport;
}
