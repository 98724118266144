import type { JobDefinition } from './JobDefinition';

/** Options you can provide when enqueuing a job. */
export type JobOptions = {
  /**
   * By default job coalescing only happens when another instance of
   * the same job is found that is still pending.  By passing a truthy
   * value here you can cause the job to coalesce with recently ended
   * jobs as well.  If you pass a string it will be parsed as
   * a timeout.  A number will be considered to be a value in
   * milliseconds, and a literal `true` is interpreted to mean the
   * default of 2 minutes.  With any of these values you enable
   * coalescing with jobs that ended up to that long ago.  If you want
   * to disable coalescing entirely (hopefully only for test purposes)
   * you can pass `false` and then coalescing will not happen even
   * with jobs that are still pending.
   */
  coalesce?: boolean | string;
  priority?: JobPriority;
};

export type SchemaConfigJobs = Record<string, JobDefinition>;

export const JOB_PRIORITIES = [ 'high', 'medium', 'normal', 'low' ] as const;
export type JobPriority = typeof JOB_PRIORITIES[number];
export function isJobPriority( value: any ): value is JobPriority {
  return JOB_PRIORITIES.includes( value );
}

export const JOB_STATUSES = [
  'queued',
  'deferred',
  'waiting',
  'complete',
  'failed',
  'cancelled',
  'interactive',
] as const;
export type JobStatus = typeof JOB_STATUSES[number];
export function isJobStatus( value: any ): value is JobStatus {
  return JOB_STATUSES.includes( value );
}

export type JobProgress = {
  /** The number of steps completed */
  stepsCompleted: number;
  /** The total number of steps (currently) */
  totalSteps: number;
  /** An integer that represents the completion percentage */
  completionPct: number;
};

export interface JobStepTypes {}
export type JobStepType = keyof JobStepTypes;

export type JobStepDefinition =
  | JobStepTypes[JobStepType] | { def: string; [key: string]: any; };

export const JOB_STEP_STATUSES = [
  'pending', // A step waiting to be run
  'waiting', // waiting for an external event
  'cancelled', // cancelled externally
  'interactive', // waiting for human input
  'complete', // finished successfully
  'failed', // finished unsuccessfully
  'deferred', // waiting for a retry
] as const;
export type JobStepStatus = typeof JOB_STEP_STATUSES[number];
export function isJobStepStatus( value: any ): value is JobStepStatus {
  return JOB_STEP_STATUSES.includes( value );
}

declare module '../declarations' {
  export interface SchemaConfigOptions {
    jobs: SchemaConfigJobs;
  }
}
