import _ from 'lodash';
import { hideProps } from '@ssp/utils';

import { Resource as CoreResource } from '~/core/resource/Resource';

import type { TResource } from '~/types';

import type { MembershipHelper, MembershipChange } from './MembershipHelper';
import type { TeamLink } from './TeamLink';
import type { User, UserSummary } from './User';

export type ResourceSummary = {
  id: string;
  name: string;
  members: UserSummary[];
};

export class Resource {

  readonly helper: MembershipHelper;

  has_changes: boolean = false;
  teamlinks: TeamLink[] = [];

  get is_primary_resource(): boolean {
    return this.helper.primary_resource === this;
  }

  get id(): string { return this.resource._id; }
  get name(): string { return this.resource.display_name; }

  constructor(
    public readonly resource: TResource<any>,
    helper: MembershipHelper,
  ) {
    if ( ! resource ) throw new Error( 'Require resource' );
    if ( ! ( resource instanceof CoreResource ) ) {
      throw new TypeError( `resource must be an instanceof of Resource` );
    }
    hideProps( this, { helper } );
  }

  async commit( save: boolean = true ) {

    const users = Array.from( Object.values( this.users ) );
    await Promise.all( users.map( u => u.commitChangeForResource( this ) ) );

    if ( ! save ) return;

    if ( this.is_primary_resource && this.helper.refresh_primary ) {
      return this.resource.refresh();
    } else {
      return this.resource.save();
    }
  }

  async prepare() {
    await this.resource.load();
    if ( this.helper.process_teamlinks ) {
      const links = await this.resource.getTeamLinks( 'from' );
      this.teamlinks = await Promise.all(
        links.map( l => this.helper.teamlink( l ) ),
      );
    }
  }

  getMemberInfoFor( id: string, add: boolean = false ) {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const MemberInfo = this.resource.getMemberInfo();
    const have = this.resource.members.getKey( id );
    if ( have ) return have;
    // We return a new MemberInfo here without actually adding them to
    // the resource yet (in most cases), because that would cause
    // other alternatives to not be considered because they are
    // already a member.
    const res = MemberInfo.create( { user_id : id } );
    // The only time that we *do* add it to the resource is at the end
    // when we're committing changes.
    if ( add ) this.resource.members.add( res );
    return res;
  }

  users: Record<string, User> = {};

  async user( user_id: string ) {
    if ( this.users[ user_id ] ) return this.users[ user_id ];
    return this.users[ user_id ] = await this.helper.user( user_id );
  }

  async processChange( change: MembershipChange, teamlink?: TeamLink ) {
    if ( this.resource.is_archived ) return;
    const { user_id } = change;
    const user = await this.user( user_id );

    const user_change = user.change( {
      resource : this, teamlink, change,
      userType : this.resource.getUserType(),
    } );
    if ( ! user_change ) return;

    if ( teamlink && ! user_change.acceptedBy( teamlink ) ) return user_change;
    if ( ! user_change.acceptedBy( this ) ) return user_change;

    const member = this.getMemberInfoFor( user.id );
    user_change.memberinfo = member;

    this.has_changes = true;

    // Note: this.teamlinks will be an empty array if
    // process_teamlinks is false
    const opts = _.omit( change, 'is_discovery' );
    await Promise.all( this.teamlinks.map( link => (
      link.processChange( opts )
    ) ) );
    return user_change;
  }

  acceptsChange( change: MembershipChange ): [ boolean, string ] {
    if ( ! change ) return [ false, 'No change' ];
    const { user_id, change_type } = change;
    const rsrc = this.resource;

    if ( change_type === 'add' ) {
      if ( rsrc.hasMember( user_id ) ) {
        return [ false, 'Already a member of resource' ];
      } else {
        return [ true, 'Not already a member' ];
      }
    }
    if ( change_type === 'remove' || change_type === 'update' ) {
      if ( rsrc.hasMember( user_id ) ) {
        return [ true, 'Member of resource' ];
      } else {
        return [ false, 'Not a member of resource' ];
      }
    }
    return [ false, `Unknown change_type "${change_type}"` ];
  }

  getSummaryInfo(): ResourceSummary {
    const rsrc = this.resource;
    const users = _.sortBy( this.users, 'name' );
    const members = users.map( u => u.getSummaryInfoForResource( this ) );
    return { id : rsrc._id, name : rsrc.name, members };
  }

  getMemberInfo() { return this.resource.getMemberInfo(); }
  getConfigFieldNames() : string[] {
    return this.getMemberInfo().getConfigFieldNames();
  }
  getResolvers() {
    const proto = this.getMemberInfo().prototype;
    const resolvers = _.pickBy( proto, ( value, key ) => {
      return _.isFunction( value ) && key.startsWith( 'resolve' );
    } );
    return _.mapKeys(
      resolvers, ( _v, key ) => key.replace( /^resolve/u, '' ).toLowerCase(),
    );
  }
}
