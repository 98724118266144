// Automatically generated, DO NOT EDIT!
// To update run "yarn do-updates"
/* eslint-disable */
import { Model } from '~/core/lib/Model';
Model.demand( 'Slack.Workspace' ).extend( {
  "actions": {
    "discover": {
      "access": "support",
      "icon": "far:search-plus",
      "id": "discover",
      "job_name": "discover",
      "label": "Discover",
      "name": "discover",
      "type": "class"
    },
    "discover-channels": {
      "access": "admin",
      "help": [
        "Search the remote service for new Channels in this Workspace"
      ],
      "icon": "far:file-import",
      "id": "discover-channels",
      "job_name": "discover-channels",
      "label": "Discover Channels",
      "name": "discover-channels",
      "type": "instance"
    },
    "discover-usergroup": {
      "access": "admin",
      "help": [
        "Search the remote service for new User Groups in this Workspace"
      ],
      "icon": "far:file-import",
      "id": "discover-usergroup",
      "job_name": "discover-usergroup",
      "label": "Discover User Groups",
      "name": "discover-usergroup",
      "type": "instance"
    },
    "import": {
      "access": "support",
      "icon": "far:file-import",
      "id": "import",
      "job_name": "import",
      "label": "Import",
      "name": "import",
      "params": {
        "domain": {
          "describe": "Slack Workspace Domain",
          "required": true,
          "type": "string"
        },
        "slack_id": {
          "describe": "Slack Workspace ID",
          "label": "Slack ID",
          "required": true,
          "type": "string"
        }
      },
      "type": "class"
    },
    "sync": {
      "access": "member",
      "icon": "fas:sync",
      "id": "sync",
      "job_name": "sync",
      "label": "Sync",
      "name": "sync",
      "type": "instance"
    }
  }
} );
