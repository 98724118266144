import { faker } from '../faker';

const orig_email = faker.internet.email;

export function domain() {
  return faker.helpers.arrayElement( [
    'example.com',
    'example.net',
    'example.org',
  ] );
}
export function email() {
  return orig_email() + '.example';
}
