import { DB } from '~';
import { createResource } from '~/core/creators';

createResource( {
  id          : 'Slack.UserGroup',
  name        : 'Slack User Group',
  icon        : 'fab:slack',
  attachable  : [ 'SSP.Project' ],
  hideZero    : true,
  fields      : {
    slack_id        : {
      type      : 'string',
      summary     : true,
      optional    : true,
      index       : true,
      unique      : true,
      readonly    : true,
      quicksearch : true,
      immutable   : true,
    },
    workspace : {
      type        : 'link',
      summary     : true,
      createonly  : true,
      label       : 'Workspace',
      help        : 'The workspace managing this user group',
      model       : 'Slack.Workspace',
      resultset( resource ) {
        return DB.Slack.Workspace.find(
          { project_id : resource.project_id },
          { minlength : false },
        );
      },
    },
    name            : {
      type      : 'string',
      label     : 'User Group Name',
      help      : [
        `The group's name.`,
        `This is the friendly name. May contain spaces. Try to keep`,
        `this fairly short, but spell out acronyms!`,
      ].join( ' ' ),
      summary     : true,
      index       : true,
      required    : true,
      quicksearch : true,
      validators  : { minlength : 1 },
    },
    handle            : {
      type      : 'string',
      label     : 'User Group Handle',
      help      : [
        `The group's @ handle.`,
        `Can only contain lowercase letters, numbers, hyphens,`,
        `and underscores.`,
      ].join( ' ' ),
      summary     : true,
      index       : true,
      quicksearch : true,
      required    : false,
      minimal     : true,
      validators  : {
        minlength   : 1,
        maxlength   : 100,
        characters  : [ 'lower', 'numbers', '-', '_' ],
      },
    },
    description   : {
      type        : 'text',
      summary     : true,
      help        : [
        `A detailed description can help members of your`,
        `organization understand this group's purpose.`,
      ].join( ' ' ),
      required    : false,
      minimal     : true,
      validators  : { maxlength : 255 },
    },
  },
  indexes   : {
    workspace_id_handle_idx : {
      fields  : { 'workspace.id' : 1, 'handle' : 1 },
      unique  : true,
    },
    workspace_id_name_idx : {
      fields  : { 'workspace.id' : 1, 'name' : 1 },
      unique  : true,
    },
  },
  behaviors : {
    hasExternalLink : {},
    hasArchived     : {},
    hasMembers      : {
      userType        : 'Slack.User',
      withService     : true,
      withCreator     : {},
      withFormMessage : {
        messages  : [
          {
            level   : 'info',
            message : 'Note: Slack guest users cannot be added to user groups.',
          },
        ],
      },
    },
  },
  methods : class {
    /**
     * Checks if a service user is restricted from being added
     * as a member to the resource.
     *
     * @param {UserResource} user - The service user to check
     */
    restrictedMembership( user ) {
      return user.is_restricted || user.is_ultra_restricted;
    }
  },
  faces         : {
    'list.summary' : [
      {
        layout : {
          type       : 'ItemsBetweenTitleAndIcons',
        },
        fields  : [ '-@all' ],
      },
      {
        layout : 'ItemsAndBadges',
        fields  : [ 'description', 'workspace', '@badge' ],
      },
    ],
  },
} );
