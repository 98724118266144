import { mkdebug } from '@ssp/utils';

import { getTransport } from '~/lib';

import type { TransportResponse } from '~/lib';
import type { ResultSet } from '../resultset';

const debug = mkdebug( 'ssp:database:origins:searches' );

export class Searches {
  cache: Record<string, Promise<TransportResponse<'find', any>>> = {};
  times: Record<string, number> = {};
  // For now only caching searches on the client
  enabled = BUILD.isClient;

  key( resultset: ResultSet ) { return resultset.getCacheKey(); }

  getCache( key, expires ) {
    debug( `Checking cache for "${key}"` );
    const found = this.cache[ key ];
    if ( ! found ) {
      debug( '  -> Found nothing in cache, bailing' );
      return;
    }
    const ts = this.times[ key ];
    if ( ! ts ) {
      debug( `  -> Had no timestamp, ignoring it` );
      delete this.cache[ key ];
      delete this.times[ key ];
      return;
    }
    const now = Date.now();
    const age = now - ts;
    const remain = expires - age;
    if ( remain < 0 ) {
      debug( 'Found expired search, removing it' );
      delete this.cache[ key ];
      delete this.times[ key ];
      return;
    }
    return found;
  }

  defaultExpires: number = BUILD.isTest ? 0 : 5000;
  async find(
    resultset: ResultSet, {
      expires = this.defaultExpires,
    } : {
      expires?: number;
    } = {},
  ) {
    if ( ! this.enabled ) return this.runSearch( resultset );
    const key = this.key( resultset );
    const found = this.getCache( key, expires );
    if ( found ) return found;
    this.times[ key ] = Date.now();
    return this.cache[ key ] = this.runSearch( resultset );
  }

  protected async runSearch( resultset: ResultSet ) {
    const schema = resultset.schema;
    const query = resultset.query ?? {};
    const options = resultset.options;

    const res = await getTransport().find( schema, query, options );
    await this.processResponse( res );
    return res;
  }

  protected async processResponse( res ) {
    debug( 'processResponse', res );
    return res;
  }

}
