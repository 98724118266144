import _ from 'lodash';
import { DB } from '~';
import { SubDocument } from '~/core/resource/SubDocument';
import { GeneralError, isEmail } from '@ssp/utils';
import { isUser } from '~/utils/types';
import { MemberInfoPendingChange } from './MemberInfoPendingChange';

import type { MemberSource, PendingChangeUpdate } from './types';
import type { TResource } from '~/types';

export type MemberInfoConfig = Record<string, unknown>;

export type MemberInfoLoadOptions = {
  safe?: boolean;
};

export class MemberInfo extends SubDocument {

  declare name: string;
  declare user_id: string;
  declare service_user_id?: string;
  declare user: TResource<'SSP.User'>;

  declare pending_change?: MemberInfoPendingChange;

  static async from( source: MemberSource ) {
    if ( source instanceof this ) {
      // If it's a MemberInfo instance then we return it directly if
      // it's the right kind of MemberInfo.  If it isn't the right
      // kind, then it will just get handled by the "Any object with
      // a `user_id` branch below
      if ( source.constructor === this ) return source;
    }
    const original = source;
    const member = this.create( {} );
    const SvcUser = this.getUserModel();
    let user, svcuser;

    if ( Array.isArray( source ) || isEmail( source ) ) {
      user = await DB.SSP.User.findOrCreateByEmail( source, { safe : true } );
      if ( user ) source = user;
    }
    if ( _.isString( source ) ) {
      user = await DB.SSP.User.retrieve( source, { safe : true } );
      if ( user ) source = user;
    }
    if ( isUser( source ) ) {
      user = source;
    } else if ( source instanceof SvcUser ) {
      svcuser = source;
    } else if ( _.isString( source.user_id ) ) {
      user = await DB.SSP.User.retrieve( source.user_id, { safe : true } );
      if ( user ) source = user;
    }
    if ( svcuser && ! user ) user = svcuser.user;
    if ( user ) {
      member.user_id = user._id;
    } else {
      throw new GeneralError( `Unable to identify user`, {
        source : original,
      } );
    }

    if ( this.hasTrait( 'service' ) ) {
      if ( ! svcuser ) {
        svcuser = await user.getRelated( SvcUser.schema.id, { safe : true } );
      }
      if ( svcuser ) member.service_user_id = svcuser._id;
    }

    return member;
  }

  // TODO - This should probably be a static method on SSP.User
  // instead of here...
  static async getUserIdFor( source: MemberSource ) {
    if ( isEmail( source ) ) {
      const user = await DB.SSP.User.getByEmail( source );
      return user._id;
    }
    if ( _.isString( source ) ) return source;
    if ( isUser( source ) ) return source._id;
    if ( _.isString( source.user_id ) ) return source.user_id;
    throw new Error( `Cannot get user_id from ${source}` );
  }

  static async createFrom( user: TResource<'SSP.User'>, config={} ) {
    if ( isUser( user ) && _.isString( user._id ) ) {
      const member = this.create( {
        name        : user.display_name,
        ...config,
        user_id     : user._id,
      } );
      if ( this.hasTrait( 'service' ) ) await member.updateServiceInfo();
      await member.updateUserInfo();
      return member;
    }
    throw new GeneralError(
      `Cannot create ${this.schema.id} from "${user}"`,
      { schema : this.schema.id, user },
    );
  }

  static getCreateRoute( parent ) {
    return parent.route( 'members/add' );
  }

  static coerce( value ) {
    if ( _.isString( value ) ) value = { user_id : value };
    return super.coerce( value );
  }

  static getServiceConfig() {
    if ( ! this.hasTrait( 'service' ) ) return;
    const user = this.getUserType();
    return {
      service   : user.getService(),
      userType  : user,
    };
  }

  static configFieldSelector = [
    '@all', '-@metadata', '-@virtual', '-@computed', '-@readonly',
    '-user_id', '-service_user_id', '-pending_change',
  ];
  static getConfigFieldNames() {
    return this.schema.getFieldNames( this.configFieldSelector );
  }
  static getConfigFields() {
    return this.schema.getFields( this.configFieldSelector );
  }
  getConfigFields() {
    return ( this.constructor as unknown as MemberInfo ).getConfigFields();
  }
  getConfigFieldNames() {
    return ( this.constructor as unknown as MemberInfo ).getConfigFieldNames();
  }

  getPendingChange() {
    if ( ! this.pending_change ) {
      this.pending_change = MemberInfoPendingChange.create( {} );
    }
    return this.pending_change;
  }

  get is_loading() {
    return this.user.is_loading || this.service_user?.is_loading;
  }

  /**
   * Update the pending_change record for this member.
   */
  updatePendingChange( opts: PendingChangeUpdate ) {
    const pend = MemberInfoPendingChange.create( {} );
    if ( opts.change_type === 'add' ) {
      pend.change_type = opts.change_type;
      pend.config = _.isPlainObject( opts.config ) ? opts.config : {};
      _.assign( this, opts.config );
    } else if ( opts.change_type === 'update' ) {
      pend.change_type = opts.change_type;
      pend.config = _.isPlainObject( opts.config ) ? opts.config : {};
    } else if ( opts.change_type === 'remove' ) {
      pend.change_type = 'remove';
      pend.config = null;
    } else if ( opts.change_type ) {
      throw new Error( `Invalid change_type "${opts.change_type}"` );
    }
    if ( _.isBoolean( opts.is_discovered ) ) {
      pend.is_discovered = opts.is_discovered;
    }
    if ( _.isBoolean( opts.is_discovery ) ) {
      pend.is_discovery = opts.is_discovery;
    }
    if ( opts.error || opts.error === null ) pend.setError( opts.error );
    return this.pending_change = pend;
  }
  /**
   * Similar to updatePendingChange, but we apply the change directly
   * to the member instead of as a pending change. This is used
   * primarily for discovered changes when refreshing, and for
   * resources like SSP.Team that don't have an update job and don't
   * need the pending_change record.
   */
  applyPendingChangeUpdate( opts: PendingChangeUpdate ) {
    if ( opts.change_type === 'add' || opts.change_type === 'update' ) {
      this.updateFromConfig( opts.config );
      if ( opts.service_user_id ) this.service_user_id = opts.service_user_id;
    } else if ( opts.change_type === 'remove' ) {
      this.delete();
    } else if ( opts.change_type ) {
      throw new Error( `Invalid change_type "${opts.change_type}"` );
    }
  }
  applyPendingChange( pend: MemberInfoPendingChange ) {
    const { change_type } = pend;
    const { service_user_id, ...config } = pend.config as any;
    return this.applyPendingChangeUpdate( {
      change_type, config, service_user_id,
    } );
  }

  async updateUserInfo() {
    if ( ! this.user_id ) {
      throw new Error( `Cannot updateUserInfo without user_id` );
    }
    if ( ! this.user ) return;
    await this.user.load();
    this.name = this.user.display_name;
  }

  async load( opts ) {
    try {
      return await Promise.all( [
        this.user.load( opts ),
        this.service_user?.load( opts ),
      ] );
    } catch ( error ) {
      this.setError( error );
    }
  }

  updateFromConfig( config: Record<string, unknown> ) {
    if ( ! config ) return false;
    if ( this.matchesConfig( config ) ) return false;
    if ( _.isString( config.service_user_id ) ) {
      this.service_user_id = config.service_user_id;
    }
    _.assign( this, _.pick( config, this.getConfigFieldNames() ) );
    return true;
  }

  matchesConfig( config: Record<string, unknown> ) {
    if ( config.service_user_id ) {
      if ( config.service_user_id !== this.service_user_id ) return false;
    }
    for ( const key of this.getConfigFieldNames() ) {
      if ( _.isUndefined( config[ key ] ) ) continue;
      if ( ! _.isEqual( config[ key ], this[ key ] ) ) return false;
    }
    return true;
  }

  getConfig( include_pending = true ): MemberInfoConfig {
    const res: MemberInfoConfig = {};
    _.assign( res, _.pick( this, this.getConfigFieldNames() ) );
    if ( include_pending && this.pending_change ) {
      _.assign( res, this.pending_change.config );
    }
    return res;
  }

  is_deleted: boolean = false;
  delete() {
    this.is_deleted = true;
    return this.broker?.resource?.members.delete( this );
  }

  /*
  get prev_change() {
    const origin = this.broker?.getVersionInstance();
    return origin.members.getByKey( this.user_id );
  }
  */

}

MemberInfo.initialize( {
  id              : 'MemberInfo',
  is_abstract     : true,
  is_subdocument  : true,
  inherit         : 'SubDocument',
  name            : 'Member',
  plural_name     : 'Members',
  fields          : {
    user_id         : {
      type        : 'id',
      label       : 'User ID',
      index       : true,
      identifier  : true,
      immutable   : true,
      form        : {
        type    : 'ResourceSelector',
        model   : 'SSP.User',
        query   : { is_disabled : false },
        options : {},
      },
    },
    name            : {
      type        : 'string',
      label       : 'Member Name',
      optional    : true,
      readonly    : true,
      sortable    : true,
    },
    user            : {
      type        : 'SSP.User',
      label       : 'SSP User',
      computed    : {
        cached      : true,
        compute() {
          if ( ! this.user_id ) return null;
          return DB.SSP.User.fromId( this.user_id );
        },
        set( user ) {
          if ( isUser( user ) ) { this.user_id = user._id; }
        },
      },
    },
    pending_change   : {
      type      : 'MemberInfoPendingChange',
      label     : 'Pending Change',
      optional  : true,
      form      : false,
    },
    error           : {
      type      : 'string',
      computed  : {
        get() {
          return this.error_info?.message || this.pending_change?.error;
        },
      },
    },
  },
  behaviors   : {
    hasErrorInfo  : {
      errorFieldName  : false,
    },
  },
  faces       : {
    'form.members'  : {
      layout    : 'Values',
      fields    : [
        'name', 'user_id', 'service_user_id', '@all', '-@computed',
      ],
    },
  },
}, BUILD.isServer && {
  events  : {
    async beforeSave( ev ) {
      const pend = this.pending_change;
      if ( pend ) {
        if (
          ( ev.refreshing && pend.is_discovery )
          || ! this.hasTrait( 'withUpdateJob' )
        ) {
          this.pending_change = null;
          this.applyPendingChange( pend );
        }
      }
      if ( this.is_deleted ) return;
      await this.updateUserInfo();
    },
  },
} );
