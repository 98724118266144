import { createBehavior } from '~/core/creators';

export type HasPrimaryAccountOptions = Record<string, never>;
declare module './declarations' {
  export interface BehaviorConfigs {
    hasPrimaryAccount?: HasPrimaryAccountOptions | boolean;
  }
  export interface BehaviorOptions {
    hasPrimaryAccount?: HasPrimaryAccountOptions;
  }
}

createBehavior<HasPrimaryAccountOptions>( {

  name  : 'hasPrimaryAccount',
  build() {
    return [
      {
        fields    : {
          is_primary_account : {
            type        : 'boolean',
            label       : 'is Primary Account',
            default     : false,
            summary     : true,
            readonly    : true,
            sortable    : true,
            badge       : { label : 'Primary Account', color : 'success' },
          },
        },
        indexes : {
          user_id_is_primary_account_index : {
            fields  : { user_id : 1, is_primary_account : 1 },
            options : {
              partialFilterExpression : { is_primary_account : true },
              unique                  : true,
            },
          },
        },
        actions : {
          makePrimaryAccount : {
            label            : 'Make Primary Account',
            icon             : 'far:user',
            access           : 'admin',
            visibilityMethod : '!is_primary_account',
            handler          : 'makePrimaryAccount',
            help             : 'Sets this resource to be used for SSP actions',
          },
        },
      },
      BUILD.isServer && {
        methods : class {
          async makePrimaryAccount() {
            await this.update( { is_primary_account : true } );
            return this;
          }
          async updatePrimaryAccount() {
            // find any record, R, that was the primary account
            const was = await this.constructor.fetch(
              { user_id : this.user_id, is_primary_account : true },
              { safe : true },
            );

            // no record was the primary account, so make this one primary
            if ( ! was ) {
              this.is_primary_account = true;
              return;
            }

            // if this record is primary and R is a different record,
            // then set R to be not primary
            if ( this.is_primary_account && was._id !== this._id ) {
              await was.update( { is_primary_account : false } );
            }
          }
        },
        events  : {
          async beforeSave( ev ) {
            await this.updatePrimaryAccount();
          },
        },
      },
    ];
  },

} );
