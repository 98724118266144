import _ from 'lodash';
import { resolveRoute } from '@ssp/utils';

import { Resource } from './Resource';

import type { TResource } from '~/types';

export interface ProjectResource extends Resource {
  project?: TResource<'SSP.Project'>;
  owner?: TResource<'SSP.Project'>;
  project_id?: string;
  _id?: string;
}

export class ProjectResource extends Resource {

  canBeManagedBy( subject ) {
    return this._canBeManagedBy( subject, () => {
      if ( subject.hasCapability( 'manage-any-project' ) ) {
        return [ true, 'subject can manage-any-project' ];
      }
      if ( subject.isAdminOfProject( this.project_id ) ) {
        return [ true, 'subject is project admin' ];
      }
    } );
  }

  get owner_id() { return this.project_id; }
  get owner_type() { return 'SSP.Project'; }
  getOwnerRoute( ...args ) {
    return _.compact( [ '/project', this.project_id, ...args ] ).join( '/' );
  }

  newResource( type, config={} ) {
    const model = Resource.demand( type );
    return model.create( { ...config, project_id : this._id } );
  }

  async addResource( type, config={} ) {
    const rsrc = this.newResource( type, config );
    await rsrc.save();
    return rsrc;
  }

  baseurl() {
    if ( ! this._id ) return;
    const base = this.project_id
      ? `/project/${this.project_id}` : 'resource';
    return resolveRoute( base, this.schema.id, this._id );
  }

  async updateResourceIds( msg, ids = {} ) {
    const is_my_update = _.isMatch( msg, {
      method  : 'update',
      type    : this.schema.id,
      id      : this._id,
    } );
    if ( is_my_update && this.schema.hasBehavior( 'hasMembers' ) ) {
      ids[ 'SSP.User' ] = Array.from( this.members.keys() );
    }
    return await super.updateResourceIds( msg, ids );
  }

  async computeResourceIds( ids = {} ) {
    if ( this.schema.hasBehavior( 'hasMembers' ) ) {
      ids[ 'SSP.User' ] = Array.from( this.members.keys() );
      const links = await Resource.demand( 'SSP.TeamLink' ).find( { $or : [
        { 'from.id' : this._id }, { 'to.id' : this._id },
      ] }, { filters : '*' } ).load();
      ids[ 'SSP.TeamLink' ] = links.getAllIds();
    }
    return await super.computeResourceIds( ids );
  }

  static async getRestrictQuery( subject: TResource<'SSP.User'>, _req, _ev ) {
    const ids = await subject.getProjectIds();
    return { project_id : { $in : ids } };
  }

}
