import { createBehavior } from '~/core/creators';
import { Forbidden } from '@ssp/utils';

export type HasUnmanageableOptions = Record<string, never>;
declare module './declarations' {
  export interface BehaviorConfigs {
    hasUnmanageable?: HasUnmanageableOptions | boolean;
  }
  export interface BehaviorOptions {
    hasUnmanageable?: HasUnmanageableOptions;
  }
}

createBehavior<HasUnmanageableOptions>( {

  name  : 'hasUnmanageable',
  build() {
    return {
      fields    : {
        // The intent behind having two fields was that we generally
        // want to store the flag to indicate that a resource *can't*
        // be managed, since that is the exceptional state, but when
        // using it (especially to show in a UI view) you proably want
        // to display it as "This resource can be managed" rather than
        // "This resource is not unmanageable".
        is_unmanageable : {
          type                    : 'boolean',
          label                   : 'Resource is not manageable?',
          summary                 : true,
          readonly                : true,
          metadata                : true,
          optional                : true,
          default                 : false,
          badge                   : { label : 'Unmanageable' },
          unmanageable_can_create : true,
        },
        is_manageable : {
          type     : 'boolean',
          label    : 'Resource is manageable?',
          hideTrue : true,
          computed : { get() { return ! this.is_unmanageable; } },
        },
      },
      events : {
        duringInsert( ev ) {
          const { updates, schema } = ev;

          if ( !this.is_unmanageable ) return;

          ctx.only( 'user', () => {
            const unmanageableFields = schema.getFieldNames( [
              '@all', '-@unmanageable_can_create', '-@readonly',
              '-@createonly', '-@metadata',
            ] );

            const intersection
              = unmanageableFields.filter( f => updates.hasField( f ) );

            if ( intersection.length > 0 ) {
              throw new Forbidden( [
                'An attempt was made to set field(s) that aren\'t manageable',
                'by SSP:',
                intersection.join( ', ' ),
              ].join( ' ' ) );
            }
          } );
        },
        duringUpdate( ev ) {
          const { updates, schema } = ev;

          if ( !this.is_unmanageable ) return;

          ctx.only( 'user', () => {
            const unmanageableFields = schema.getFieldNames( [
              '@all', '-@unmanageable_can_update', '-@readonly', '-@metadata',
            ] );

            const intersection
              = unmanageableFields.filter( f => updates.hasField( f ) );

            if ( intersection.length > 0 ) {
              throw new Forbidden( [
                'An attempt was made to update field(s) that aren\'t',
                'manageable by SSP:',
                intersection.join( ', ' ),
              ].join( ' ' ) );
            }
          } );
        },
      },
    };
  },

} );
