import _ from 'lodash';

import { Module } from '~/core/lib/Module';
import { MongoIndex } from './MongoIndex';

import type { Schema } from '~/core/lib/Schema';
import type { MongoIndexOptions } from './types';
import type { Field } from '../fields/Field';

const indexes_required = [
  'unique', 'quicksearch', 'sortable', 'identity',
];
const isIndexSpec = _.overSome( [ _.isString, _.isBoolean, _.isNumber ] );

Module.create( {
  name     : 'indexes',
  after    : [ 'fields' ],
  services : false,

  parse( data, origin ) {
    if ( _.isEmpty( data.indexes ) ) return;
    const indexes = _.mapValues( data.indexes, ( conf, name ) => ( {
      name,
      ..._.omit( conf, 'options' ),
      ...( conf.options || {} ),
      origin,
    } ) );
    if ( _.isEmpty( indexes ) ) return;
    return { indexes };
  },

  apply( data, config ) {
    if ( _.isEmpty( data.indexes ) ) return;
    const indexes =_.mapValues( data.indexes, ( conf, name ) => {
      return new MongoIndex( { name, ...conf, schema : config.id } );
    } );
    config.add( { indexes } );
  },

  finish( config ) {
    const indexes = _.keyBy( this.indexSchema( config.schema ), 'name' );
    if ( _.isEmpty( indexes ) ) return;
    config.add( { indexes } );
  },

  indexSchema( schema: Schema, prefix: string ) {
    return schema.getFields( '@all' )
      .flatMap( field => this.indexField( schema, field, prefix ) );
  },

  indexField( schema: Schema, field: Field, prefix: string ): MongoIndex[] {
    const name = _.compact( [ prefix, field.name ] ).join( '.' );

    const opts: MongoIndexOptions = {
      name, schema : schema.id, origin : `Field: ${field.name}`,
    };

    if ( field.virtual ) {
      if ( field.index ) {
        throw new Error( `${field} has index: true, but is virtual` );
      }
      return [];
    }

    // _id has special handling in MongoIndex, so all we need is it's name
    if ( name === '_id' ) return [ new MongoIndex( opts ) ];

    // we always build indexes for subdoc fields, if the subdoc schema
    // doesn't define any then it will just return an empty list.
    if ( field.subdoc ) {
      if ( field.index === false ) return [];
      return this.indexSchema( field.subdocSchema, name );
    }

    if ( field.link ) {
      // we always index link fields
      return [ 'type', 'id' ].map( x => new MongoIndex( {
        ...opts,
        name : name + '.' + x,
        fields : { [ name + '.' + x ] : 1 },
      } ) );
    }

    if ( field.index === false ) {
      for ( const k of indexes_required ) {
        if ( field[k] ) {
          throw new Error( `${field} has index: false, but ${k} is truthy` );
        }
      }
      return [];
    }

    if ( ! [ 'index', ...indexes_required ].some( x => field[ x ] ) ) {
      return [];
    }

    if ( _.isPlainObject( field.index ) ) {
      _.assign( opts, field.index );
    } else if ( isIndexSpec( field.index ) ) {
      _.defaultsDeep( opts, { fields : { [ name ] : field.index } } );
    }
    if ( _.isEmpty( opts.fields ) ) {
      _.merge( opts, { fields : { [ name ] : 1 } } );
    }
    if ( field.unique ) {
      opts.unique = true;
      if ( field.optional ) opts.sparse = true;
    }
    return [ new MongoIndex( opts ) ];
  },

} );
