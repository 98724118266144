import { init } from '@ssp/utils';
import { OriginCache } from './OriginCache';

let _cache: OriginCache;
export function getCache() {
  if ( _cache ) return _cache;
  _cache = new OriginCache();

  if ( BUILD.isTest && BUILD.isClient ) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { MemoryCache } = require( './MemoryCache' );
    _cache.addLayer( new MemoryCache() );
  } else if ( BUILD.isClient ) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { ForageCache } = require( './ForageCache' );
    // In the browser we use localforage to store data locally
    _cache.addLayer( new ForageCache() );
  } else {
    // On the server we don't cache for now..
  }

  init.register( {
    id      : 'db-cache',
    before  : [ 'database' ],
    after   : [ 'browser-meta' ],
    context : _cache,
  } );

  return _cache;
}
