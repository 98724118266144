import _ from 'lodash';
import { json, ReferenceURL, DatabaseFieldError } from '@ssp/utils';
import { Type } from '@ssp/types';
import type { Producable } from '@ssp/ts';

import { Field } from './Field';
import { getDataMap } from '../broker/data-utils';
import {
  isLinkData, isResource, isComponent, isService, isComponentName,
  isServiceName,
} from '~/utils';
import { getModel, getSchema } from '~/core/schemas';

import type { SchemaId } from '~/types';
import type { FieldOptions } from './Field';

const isRCS = _.overSome( [ isResource, isComponent, isService ] );

Type.create( { name : 'link' } );

export type LinkFieldOptions = FieldOptions & {
  type: 'link';

  /** Only this resource type can be assigned to this link field. */
  model: Producable<SchemaId>;
  /** Any of these resource type can be assigned to this link field. */
  models: Producable<SchemaId[]>;

  /** Apply this query when searching for resources for the link. */
  query: Producable<Record<string, unknown>>;

  /** Allow links that have only a type (such as for class jobs). */
  allow_type_only?: boolean;
  /** Allow links that have only a service type (such as for service jobs). */
  allow_service?: boolean;
};

export interface LinkField {
  constructor: typeof LinkField;
}
export class LinkField extends Field {

  /** Allow links that have only a type (such as for class jobs). */
  declare allow_type_only: boolean;
  /** Allow links that have only a service type (such as for service jobs). */
  declare allow_service: boolean;

  /** Only this resource type can be assigned to this link field. */
  declare model?: string;
  /** Any of these resource type can be assigned to this link field. */
  declare models?: string[];

  get link() { return true; }
  get simple() { return false; }

  constructor( opts: LinkFieldOptions ) {
    super( opts );
    _.defaultsDeep( this, {
      form : { type  : 'ResourceSelector' },
      face : { type  : 'Link' },
      // allow_type_only : false,
      // allow_service   : false,
    } );
  }

  formatValue( value ) {
    if ( _.isNil( value ) ) return value;
    if ( isResource( value ) ) {
      return {
        type  : value.schema.id,
        id    : value._id,
        name  : value.findDisplayName(),
      };
    }
    if ( isComponent( value ) && this.allow_type_only ) {
      return { type : value.schema.id, name : value.schema.name };
    }
    if ( isService( value ) && this.allow_service ) {
      return { type : value.schema.id, name : value.schema.name };
    }
    throw new DatabaseFieldError( {
      message : `Invalid argument to link format`,
      tags    : {
        schema : this.schema.id,
        field  : this.name,
        method : 'LinkField#formatValue',
      },
      data    : { value },
    } );
  }

  parse( value ) {
    if ( ! _.isPlainObject( value ) ) {
      throw new DatabaseFieldError( {
        message : `link parse requires plain object`,
        tags    : {
          schema : this.schema.id,
          field  : this.name,
          method : 'LinkField#parse',
        },
        data    : { value },
      } );
    }
    if ( ! value.type ) {
      throw new DatabaseFieldError( {
        message : `link requires type`,
        tags    : {
          schema : this.schema.id,
          field  : this.name,
          method : 'LinkField#parse',
        },
        data    : { value },
      } );
    }
    if ( value.id ) return this.hydrateLink( value );
    if ( this.allow_type_only || this.allow_service ) {
      return getModel( value.type );
    }
    throw new DatabaseFieldError( {
      message : `link requires id`,
      tags    : {
        schema : this.schema.id,
        field  : this.name,
        method : 'LinkField#parse',
      },
      data    : { value },
    } );
  }

  coerceValue( value ) {
    if ( _.isNil( value ) ) return;

    // Resource instance doesn't need coercing
    if ( isResource( value ) ) {
      return this.validateLinkResource( value );
    }

    value = this.coerceToLinkData( value );
    if ( _.isPlainObject( value ) ) return this.hydrateLink( value );

    throw new DatabaseFieldError( {
      message : `Cannot coerce invalid value "${value}"`,
      tags    : {
        schema : this.schema.id,
        field  : this.name,
        method : 'LinkField#coerceValue',
      },
      data    : { value },
    } );
  }

  equals( one, two ) {
    return _.isEqual(
      _.pick( this.coerceToLinkData( one ), 'type', 'id' ),
      _.pick( this.coerceToLinkData( two ), 'type', 'id' ),
    );
  }

  strictEquals( was, now ) {
    if ( isResource( now ) && ! isResource( was ) ) return false;
    return this.equals( was, now );
  }

  makeGetter() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const field = this;
    return function get() {
      const map = getDataMap( this );
      const value = map.get( field.name );
      if ( isRCS( value ) ) return value;
      if ( isLinkData( value ) ) {
        const rsrc = field.transform( field.hydrateLink( value ), this );
        map._set( field.name, rsrc, true );
        return rsrc;
      }
      return;
    };
  }

  makeSetter() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const field = this;
    const { name, allow_type_only, allow_service } = field;
    return function set( value ) {
      const map = getDataMap( this );
      if ( isResource( value ) ) {
        map.set( name, value );
      } else if ( isComponent( value ) ) {
        if ( ! allow_type_only ) {
          throw new DatabaseFieldError( {
            message : `Cannot link to component without allow_type_only`,
            tags    : {
              schema : this.schema.id,
              field  : this.name,
              method : `LinkField#set ${name}`,
            },
            data    : { value, allow_type_only },
          } );
        }
        map.set( name, value );
      } else if ( isService( value ) ) {
        if ( ! allow_service ) {
          throw new DatabaseFieldError( {
            message : `Cannot link to service without allow_service`,
            tags    : {
              schema : this.schema.id,
              field  : this.name,
              method : `LinkField#set ${name}`,
            },
            data    : { value, allow_service },
          } );
        }
        map.set( name, value );
      } else {
        value = field.coerceToLinkData( value );
        if ( isLinkData( value ) ) {
          map.set( name, value );
        }
      }
    };
  }

  updateQuietly( rsrc, value ) {
    const map = getDataMap( rsrc );
    if ( isRCS( value ) ) return map._set( this.name, value );
    value = this.coerceToLinkData( value );
    if ( isLinkData( value ) ) {
      const have = map.get( this.name );
      if ( have && this.equals( have, value ) ) return false;
      return map._set( this.name, value );
    }
    return false;
  }

  hydrateLink( value ) {
    const { type, id, _error, ...partial } = value;
    if ( _error ) return json.deserialize( _error );
    if ( ! _.isString( type ) ) return;
    if ( _.isString( id ) ) {
      return getModel( type ).construct( {
        id,
        partial,
        method    : 'hydrateLink',
        defaults  : false,
      } );
    } else {
      return getModel( type );
    }
  }

  validateLinkResource( value ) {
    const { model, models } = this;
    if ( models ) {
      if ( ! models.includes( value.schema.id ) ) {
        throw new DatabaseFieldError( {
          message : [
            `link value must be instance of`,
            _.initial( models ).concat( `or ${_.last( models )}` ).join( ', ' ),
          ].join( ' ' ),
          tags    : {
            schema : this.schema.id,
            field  : this.name,
            method : `LinkField#validateLinkResource`,
          },
          data    : { value },
        } );
      }
    } else if ( model ) {
      if ( value.schema.id !== model ) {
        throw new DatabaseFieldError( {
          message : `link value must be instance of ${model}`,
          tags    : {
            schema : this.schema.id,
            field  : this.name,
            method : `LinkField#validateLinkResource`,
          },
          data    : { value },
        } );
      }
    }
    return value;
  }

  /**
  * @typedef {Object} LinkData
  *
  * A plain object with a `type` property, and possibly `id` and `name`
  * properties.  It's the raw DB representation of a linked resource.
  */

  /**
  * Given something that might represent a resource to be linked,
  * coerce it into a `LinkData` object.  We don't coerce it to an
  * actual resource, because we want to hydrate it lazily, both for
  * performance and because not being lazy means we'll recurse forever
  * if there are circular links.
  *
  * @param {*} value - The value to coerce.
  * @returns {LinkData|undefined}
  */ // eslint-disable-next-line complexity
  coerceToLinkData( value ) {
    if ( ! value ) return;

    if ( isResource( value ) ) {
      return this.validateLinkData( {
        type  : value.schema.id,
        id    : value._id,
        name  : value.findDisplayName(),
      } );
    }
    // ReferenceURL instance
    if ( ( value instanceof ReferenceURL ) && value.valid ) {
      return this.validateLinkData( value.toLinkData() );
    }

    // Component or ServiceResource model
    if ( isComponent( value ) || isService( value ) ) {
      return this.validateLinkData( { type : value.schema.id } );
    }

    // string
    if ( _.isString( value ) ) {
      // If it's empty it's nothing
      if ( ! value.length ) return;
      // component name
      if ( isComponentName( value ) || isServiceName( value ) ) {
        return this.validateLinkData( { type : value } );
      }
      // ref url
      const ref = new ReferenceURL( value );
      if ( ref.valid ) return this.validateLinkData( ref.toLinkData() );
      // probably a bare id
      if ( this.model ) {
        return this.validateLinkData( { type : this.model, id : value } );
      }
    }

    // plain object
    if ( _.isPlainObject( value ) ) {
      // If it's empty it's nothing
      if ( _.isEmpty( value ) ) return;
      value = { ...value };
      if ( _.isString( value._id ) && ! _.isString( value.id ) ) {
        value.id = value._id;
        delete value._id;
      }
      if ( _.isString( value.display_name ) && ! value.name ) {
        value.name = value.display_name;
        delete value.display_name;
      }
      if ( isComponentName( this.model ) && ! value.type ) {
        value.type = this.model;
      }
      return this.validateLinkData( value );
    }
    throw new DatabaseFieldError( {
      message : `Unable to coerce "${value}" to linkdata`,
      tags    : {
        schema : this.schema.id,
        field  : this.name,
        method : `LinkField#coerceToLinkData`,
      },
      data    : { value },
    } );
  }
  validateLinkData( data ) {
    if ( ! _.isPlainObject( data ) ) {
      throw new TypeError( 'LinkData must be object' );
    }
    const { type, id, name } = data;
    if ( _.isString( id ) ) {
      if ( isComponentName( type ) ) {
        return { type, id, name };
      } else if ( isComponentName( this.model ) ) {
        return { type : this.model, id, name };
      }
    } else if ( id ) {
      throw new TypeError( 'LinkData must be string' );
    } else if ( isComponentName( type ) ) {
      if ( this.allow_type_only ) {
        return { type, name : getSchema( data.type ).name };
      } else {
        throw new DatabaseFieldError( {
          message : `Cannot link to component without allow_type_only`,
          tags    : {
            schema : this.schema.id,
            field  : this.name,
            method : `LinkField#validateLinkData`,
          },
          data    : { data },
        } );
      }
    } else if ( isServiceName( data.type ) ) {
      if ( this.allow_service ) {
        return { type, name : getSchema( data.type ).name };
      } else {
        throw new DatabaseFieldError( {
          message : `Cannot link to service without allow_service`,
          tags    : {
            schema : this.schema.id,
            field  : this.name,
            method : `LinkField#validateLinkData`,
          },
          data    : { data },
        } );
      }
    }
    throw new DatabaseFieldError( {
      message : `Unable to coerce "${data}" to linkdata`,
      tags    : {
        schema : this.schema.id,
        field  : this.name,
        method : `LinkField#validateLinkData`,
      },
      data    : { data },
    } );
  }

}
