import _ from 'lodash';
import { Type } from '@ssp/types';

import { throwError } from '~/core/errors';
import { Module } from '~/core/lib/Module';
import { deferred } from './deferrals';

Module.create( {
  name      : 'fields',
  services  : false,

  parse( data, origin ) {
    if ( _.isEmpty( data.fields ) ) return;
    const fields = _.omitBy( _.mapValues( data.fields, ( fd, name ) => {
      if ( ! _.isString( fd.type ) ) {
        throwError( 'Field.type must be a string (type name)', {
          property    : 'fields',
          field       : name,
          value       : fd.type,
        } );
      }

      for ( const x of [ 'description', 'help' ] ) {
        if ( _.isArray( fd[x] ) ) fd[x] = fd[x].join( ' ' );
      }
      return { name, ...fd, origin };
    } ), _.isNil );
    if ( _.isEmpty( fields ) ) return;
    return { fields };
  },

  apply( data, config ) {
    if ( _.isEmpty( data.fields ) ) return;
    config.add( { fields : data.fields } );
  },

  finish( config ) {
    // This is to work around an ordering problem with behaviors that
    // want to define fields that have a class type (like the
    // `hasMembers` behavior defining the base `MemberInfo` with
    // a `user` field of type `SSP.User`.  Those definitions don't
    // work normally, because behaviors have to be constructed before
    // components are, but the `SSP.User` class type won't be
    // constructed until the `SSP.User` component is.  So in
    // `Field#setType` if a field attempts to set a type that doesn't
    // exist we stick it in this deferred array and try that part
    // again here.
    for ( const { type, field } of deferred ) {
      if ( type ) {
        if ( ! Type.get( type ) ) {
          throwError( `Type "${type}" does not exist`, {
            method  : 'setType (deferred finish)',
            type, field : field.name, schema : config.id,
          } );
        }
        field.setType( type );
      }
    }
  },

} );
