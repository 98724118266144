import _ from 'lodash';
import { parseRefUrl, ReferenceURL } from '@ssp/utils';

import { getTransport } from '~/lib';
import { throwError } from '~/core/errors';
import { getCache } from './cache';
import { createCacheKey, extractIdents } from './utils';
import { isResourceTypeName } from '~/utils/types';
import { getSchema } from '~/core/schemas';

export class Resolutions {

  cache = getCache();

  key( type, ident ) {
    if ( ! isResourceTypeName( type ) ) {
      throwError( `type must be resource type name`, {
        method : 'key', type, ident,
      } );
    }
    return createCacheKey( 'R', type, ident );
  }

  async has( type, query ) {
    for ( const ident of extractIdents( type, query ) ) {
      if ( await this.cache.has( this.key( type, ident ) ) ) return true;
    }
    return false;
  }

  /**
   * Given a resource type and query, attempt to retrieve a cached
   * ref_url for that resource.
   *
   * @param {string} type - Resource Type
   * @param {string|object|string[]} query - Resource Query
   * @returns {ReferenceURL|undefined}
   */
  async get( type, query ) {
    for ( const ident of extractIdents( type, query ) ) {
      const id = await this.cache.get( this.key( type, ident ) );
      if ( id ) return new ReferenceURL( { type, id } );
    }
  }

  /**
   * Resolve a query and return an appropriate ReferenceURL.
   *
   * @param {string} type - Resource Type
   * @param {string|object|string[]} query - Resource Query
   * @returns {ReferenceURL|undefined}
   */
  async resolve( type, query ) {
    const res = await this._resolve( type, query );
    if ( ! res ) return;
    if ( res instanceof ReferenceURL ) return res;
    if ( res.status === 'not-found' ) return;
    if ( res.ok && res.ref_url ) return new ReferenceURL( res.ref_url );
    res.fatalize();
  }

  async _resolve( type, query ) {
    const url = await this.get( type, query );
    if ( url ) return url;
    const res = await getTransport().resolve( getSchema( type ), query, {
      fields : [ '@identifier' ],
      safe   : true,
    } );
    if ( res.status === 'not-found' ) return;
    await this.processResponse( res );
    return res;
  }

  async processResponse( res ) {
    if ( ! res.ok ) return;
    if ( res.status === 'not-found' ) return;
    const { method, ref_url } = res;
    const ref = parseRefUrl( ref_url );
    if ( ! ( ref.valid && ref.resolved ) ) return;
    if ( ! ref.id ) throwError( `Can't processResponse without id` );
    const idents = extractIdents( ref.type, ref );
    if ( method === 'remove' ) {
      await Promise.all( _.map( idents, ident => {
        return this.cache.remove( this.key( ref.type, ident ) );
      } ) );
    } else {
      await Promise.all( _.map( idents, ident => {
        return this.cache.set( this.key( ref.type, ident ), ref.id );
      } ) );
    }
    return res;
  }

}
