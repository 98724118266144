import { createBehavior } from '~/core/creators';

import type { Definitions } from '~';

export type HasServiceAccountsOptions = Record<string, never>;

declare module './declarations' {
  export interface BehaviorConfigs {
    hasServiceAccounts?: HasServiceAccountsOptions | boolean;
  }
  export interface BehaviorOptions {
    hasServiceAccounts?: HasServiceAccountsOptions;
  }
}

createBehavior<HasServiceAccountsOptions>( {

  name      : 'hasServiceAccounts',
  options   : {},

  build( config ) {
    const configs: Definitions = [ {
      fields    : {
        service_account_type  : {
          type        : 'string',
          optional    : true,
          index       : true,
          readonly    : true,
          quicksearch : false,
        },
      },
    } ];
    if ( BUILD.isServer ) {
      configs.push( {
        events  : {
          async beforeSave() {
            if ( ! this.service_account_type ) {
              const type = this.getServiceAccountType();
              if ( type ) this.service_account_type = type;
            }
            if ( this.service_account_type ) {
              if ( config.fields.hasField( 'do_not_auto_disable' ) ) {
                this.do_not_auto_disable = true;
              }
            }
          },
        },
        methods : class {
          getServiceAccountType() {
            const options = config.schema.getBehaviorOptions( 'hasEmails' );
            if ( ! options ) return;
            const {
              useSingleEmail, lowerEmailFieldName,
              useMultipleEmails, lowerEmailsFieldName,
            } = options;
            const emails = [
              ...( useMultipleEmails ?  this[ lowerEmailsFieldName ] : [] ),
              ...( useSingleEmail ?  this[ lowerEmailFieldName ] : [] ),
            ];
            for ( const email of emails ) {
              const type = emailServiceAccountType( email );
              if ( type ) return type;
            }
          }
        },
      } );
    }
    return configs;
  },
} );

function emailServiceAccountType( email ) {
  if ( email.includes( 'noreply' ) || email.includes( 'no-reply' ) ) {
    return 'no_reply_email';
  }
  if ( email.startsWith( 'svc_' ) ) return 'svc_prefix';
}
