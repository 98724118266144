import _ from 'lodash';
import {
  BoundedMap, background, Emitter, mkdebug, invariant, parseRefUrl,
  ReferenceURL, DatabaseOriginsError,
} from '@ssp/utils';
import { metrics } from '@ssp/metrics';

import { getSchema } from '~/core/schemas';
import { Updates } from '~/modules/updates/Updates';
import { isResource, isResourceTypeName } from '~/utils/types';
import { getTransport } from '~/lib';

import { Origin } from './Origin';
import { Resolutions } from './Resolutions';
import { Searches } from './Searches';
import { getOrigins } from './utils';

import type { Transport } from '~/lib';
import type { SchemaId, TResource } from '~/types';
import type { Resource } from '~/core/resource/Resource';
import type { ResultSet } from '~/modules/resultset';

import type { LiveUpdateMessage, LiveUpdateWatcher } from './types';

const debug = mkdebug( 'ssp:database:origins:origins' );

metrics.gauge( {
  name  : 'ssp_database_origins_memory_instances',
  help  : 'Count of the total number of Origin instances in memory',
  collect() { this.set( getOrigins().map.size ); },
} );
const origin_constructed = metrics.counter( {
  name  : 'ssp_database_origin_constructed_total',
  help  : 'Number of Origin instances created',
} );

export class Origins extends Emitter {

  map = new BoundedMap( {
    soft  : 500,
    hard  : 5000,
    evictable( origin: Origin ) { return origin.listenerCount() === 0; },
  } );

  transport: Transport = getTransport();

  readonly resolutions: Resolutions = new Resolutions();
  readonly searches: Searches = new Searches();

  /**
   * Returns true if we have a record that matches these parameters.
   *
   * @param {string|ReferenceURL} type - Type or ReferenceURL.
   * @param {string} id - Database ID.
   * @returns {boolean}
   */
  has( type, id ) { return this.map.has( this.key( type, id ) ); }

  /**
   * Get the Origin record for a type and id.
   *
   * @param type - Type or ReferenceURL.
   * @param id - Database ID.
   */
  get( type: string, id: string ): Origin {
    if ( ! ( _.isString( type ) && _.isString( id ) ) ) {
      throw new DatabaseOriginsError( {
        message : 'Cannot get origin without type and id',
        tags    : { schema : type, method : 'Origins#get' },
        data    : { type, id },
      } );
    }
    const had = this.map.get( this.key( type, id ) );
    if ( had ) return had as Origin;
    const origin = new Origin( type, id );
    origin_constructed.inc();
    this.map.set( this.key( type, id ), origin );
    return origin;
  }

  /**
   * Retrieves an Origin record, but without updating it's position in
   * the BoundedMap. This is used to get an Origin record for
   * background updating.
   *
   * @param {string|ReferenceURL} type - Type or ReferenceURL.
   * @param {string} id - Database ID.
   * @returns {Origin|undefined}
   */
  peek( type, id ) {
    const had = this.map.peek( this.key( type, id ) );
    if ( had ) return had;
    return new Origin( type, id );
  }

  /**
   * Resolve a resource query and return the appropriate Origin.  Note
   * that this only attempts to resolve from the cache and will not
   * forward a resolve request to the server if the resource is not
   * found in the cache.
   *
   * @param {string} type - Resource Type.
   * @param {string|object|string[]} query - Resource Query or ID.
   * @returns {Origin}
   */
  async resolve( type, query ) {
    const ref = await this.resolutions.get( type, query );
    if ( ref ) return this.get( ref.type, ref.id );
  }

  async find( resultset: ResultSet ) {
    const res = await this.searches.find( resultset );
    this.processResponse( res );
    return res;
  }

  /**
   * Fetch a resource from a query.
   *
   * @param {string} type - The resource type to fetch.
   * @param {object|string|string[]} query - The query to fetch by.
   * @param {object} [options] - Options object (See `Broker#load`).
   * @returns {TransportResponse|Origin} - This method may
   * return a `TransportResponse` if the fetch was sent to the server,
   * or an `Origin` or `Version` if it was found in the cache.
   */
  async fetch( type, query, options={} ) {
    const found = await this.resolve( type, query );
    if ( found ) return found.load( options );
    const schema = getSchema( type );
    const res = await this.transport.fetch( schema, query, options );
    return this.processResponse( res );
  }

  /**
   * Retrieve a resource by it's id.
   *
   * @param {string} type - The resource type to retrieve.
   * @param {string} id - The resource id to retrieve.
   * @param {object} [options] - Options object (See `Broker#load`).
   * @returns {TransportResponse|Origin} - This method may
   * return a `TransportResponse` if the fetch was sent to the server,
   * or an `Origin` or `Version` if it was found in the cache.
   */
  async retrieve( type, id, options={} ) {
    const found = await this.resolve( type, { _id : id } );
    if ( found ) return found.load( options );
    const schema = getSchema( type );
    const res = await this.transport.retrieve( schema, id, options );
    return this.processResponse( res );
  }

  /**
   * Create a resource.
   *
   * @param {Updates} updates - Updates instance.
   * @returns {TransportResponse}
   */
  async insert( updates ) {
    invariant( updates instanceof Updates );
    const res = await this.transport.insert( updates.getSchema(), updates );
    return this.processResponse( res );
  }

  /**
   * Update a resource.
   *
   * @param {Updates} updates - Updates instance.
   * @returns {TransportResponse}
   */
  async update( updates ) {
    invariant( updates instanceof Updates );
    const res = await this.transport.update(
      updates.getSchema(), updates.id, updates,
    );
    // This attaches the response to the updates object simply so that
    // the server-side transport can get access to it in order to send
    // it back the browser
    // eslint-disable-next-line require-atomic-updates
    updates.response = res;
    return this.processResponse( res );
  }

  /**
   * Remove a resource.
   *
   * @param {Schema} schema - Resource Schema.
   * @param {string} id - Resource ID.
   * @returns {TransportResponse}
   */
  async remove( schema, id ) {
    const res = await this.transport.remove( schema, id );
    return this.processResponse( res );
  }

  /**
   * Enqueue a job.
   *
   * @param {TResource<any>} resource - The resource to invoke the job
   * on.  This should be an instance of a resource for an instance
   * job, and a resource class constructor for a class job.
   * @param {string} job_name - The job name to enqueue.
   * @param {object} [data={}] - The job data.
   * @param {object} [options={}] - Job options.
   * @returns {TransportResponse}
   */
  async job( resource, job_name, data={}, options={} ) {
    const res = await this.transport.job( resource, job_name, data, options );
    return this.processResponse( res );
  }

  async action( resource, action_name, data={}, options={} ) {
    const res = await this.transport.action(
      resource, action_name, data, options,
    );
    return this.processResponse( res );
  }

  /**
   * Process a TransportResponse object.
   *
   * @param {TransportResponse} res - Response.
   * @returns {TransportResponse} - Returns the same transport
   * response object, but with a new `origin` property attached that
   * contains the Origin instance for the response resource.
   */
  async processResponse( res ) {
    debug( 'processResponse', res );
    if ( res.ref_url && ! res.error ) {
      const ref = parseRefUrl( res.ref_url );
      if ( ! ref.valid ) {
        res.error = `Cannot processResponse with invalid ref_url`;
      }
      const { type, id } = ref;
      await this.resolutions.processResponse( res );
      await this.get( type, id ).processResponse( res );
    }
    // Process the ref_urls last, so they don't trigger refreshes if
    // the response includes the data they are going to load.
    this.processRefUrls( res.ref_urls );
    return res;
  }

  processRefUrls( ref_urls ) {
    const refs = _.map( ref_urls, parseRefUrl )
      .filter( ref => {
        return ref.valid && ref.resolved && ref.type && ref.id && ref.version;
      } );
    background( this.processRefs( refs ), { label : `processRefUrls` } );
  }
  async processRefs( refs ) {
    for ( const ref of refs ) {
      // We use peek here because we want to update the in-memory copy
      // (if there is one) and the in-cache copy, but we don't want it
      // to get registered with our BoundedMap and blow out the cache
      // of all the things we are using just because we loaded a big
      // resultset that we aren't using yet.
      await this.peek( ref.type, ref.id ).processRef( ref );
    }
  }

  processRefUrl( ref_url ) {
    const ref = parseRefUrl( ref_url );
    if ( ! ( ref.valid && ref.resolved ) ) return;
    const { type, id, version } = ref;
    if ( ! ( type && id && version ) ) return;
    // We use peek here because we want to update the in-memory
    // copy (if there is one) and the in-cache copy, but we don't
    // want it to get registered with our BoundedMap and blow out
    // the cache of all the things we are using just because we
    // loaded a big resultset that we aren't using yet.
    background( this.peek( type, id ).processRef( ref ), {
      label       : `processRefUrl for ${type} ${id}`,
      logResolve  : true,
    } );
  }

  #live_update_emitters = {};
  #related_update_emitters = {};

  /**
   * Given a payload, find or create its Origin instance
   * then process its LiveUpdateMessage
   */
  processLiveUpdate( payload: LiveUpdateMessage ) {
    debug( 'LIVE UPDATE:', payload );
    // This is a stripped-down live-updates process that is much
    // faster than the normal one (but doesn't handle caching or
    // anything, all it does is let you get notified that something
    // changed).  We need this fast-path primarily to deal with
    // live-updates on the server, since it will have to process every
    // single one (which could mean dozens a second in production).
    this.#live_update_emitters[ payload.type ]?.emit( payload.id, payload );
    _.each( _.groupBy( payload.targets, 'type' ), ( targets, type ) => {
      const emitter = this.#related_update_emitters[ type ];
      if ( ! emitter ) return;
      _.each( targets, tgt => emitter.emit( tgt.id, payload ) );
    } );
    if ( BUILD.isClient ) {
      // We only do the slower cache-updating live update processing
      // in the browser.
      this.peek( payload.type, payload.id ).processLiveUpdate( payload );
    }
  }

  /** Watch for live-update events for a specific resource. */
  watchLiveUpdates(
    type: SchemaId, id: string, callback: LiveUpdateWatcher,
  ): () => void;
  /** Watch for live-update events for a specific resource. */
  watchLiveUpdates( rsrc: Resource, callback: LiveUpdateWatcher ): () => void;
  /** Watch for live-update events for all resources of a given type. */
  watchLiveUpdates( type: SchemaId, callback: LiveUpdateWatcher ): () => void;
  watchLiveUpdates(
    type: SchemaId | string | TResource<any>,
    id: string | LiveUpdateWatcher,
    callback?: LiveUpdateWatcher,
  ) {
    if ( typeof id === 'function' && ! callback ) {
      callback = id;
      id = undefined;
    }
    if ( isResource( type ) && ! id ) {
      id = type._id;
      type = type.type;
    }
    if ( ! isResourceTypeName( type ) ) {
      throw new DatabaseOriginsError( {
        message : `"${type}" is not a valid resource type`,
        tags    : { schema : type, method : 'Origins#watchLiveUpdates' },
        data    : { type },
      } );
    }
    if ( typeof callback !== 'function' ) {
      throw new TypeError( `watchLiveUpdates requires callback function` );
    }
    const emitter = this.#live_update_emitters[ type ]
      || ( this.#live_update_emitters[ type ] = new Emitter() );
    if ( _.isNil( id ) || id === '*' ) {
      return emitter.on( '*', ( _id, data ) => callback( data ) );
    } else if ( _.isString( id ) ) {
      return emitter.on( id, callback );
    } else {
      throw new Error( `Invalid id value for watchLiveUpdates` );
    }
  }

  /**
   * Watch for live-update events for resources related to a resource.
   *
   * @param type - The resource type to watch for.
   * @param id - The resource id.
   * @param callback - The callback function.
   */
  watchRelatedUpdates(
    type: SchemaId, id: string,
    callback: LiveUpdateWatcher,
  ): () => void;
  /**
   * Watch for live-update events for resources related to a resource.
   *
   * @param rsrc - The resource to watch for.
   * @param callback - The callback function.
   */
  watchRelatedUpdates(
    rsrc: TResource<any>,
    callback: LiveUpdateWatcher,
  ): () => void;
  watchRelatedUpdates(
    arg1: SchemaId | TResource<any>,
    arg2: string | LiveUpdateWatcher,
    arg3?: LiveUpdateWatcher,
  ): () => void {
    const type = isResource( arg1 ) ? arg1.schema.id : arg1;
    const id = isResource( arg1 ) ? arg1._id : arg2;
    const callback = arg3 || arg2;
    if ( ! isResourceTypeName( type ) ) {
      throw new TypeError( `"${type}" is not a valid resource type` );
    }
    if ( typeof callback !== 'function' ) {
      throw new TypeError( `watchRelatedUpdates requires callback function` );
    }
    if ( id === '*' || ! _.isString( id ) ) {
      throw new TypeError( `watchRelatedUpdates requires resource id` );
    }
    const emitter = this.#related_update_emitters[ type ]
      || ( this.#related_update_emitters[ type ] = new Emitter() );
    return emitter.on( id, callback );
  }

  /**
   * Return the cache key for a type/id pair.  If only passed
   * a ReferenceURL as an argument, the type and id will be extracted
   * from that.
   *
   * @param {string|ReferenceURL} type - Schema type, or ReferenceURL.
   * @param {string} [id] - Database ID.
   * @returns {string} - The cache key for that type/id pair.
   */
  key( type, id ) {
    const r = ReferenceURL.parse( type, { safe : true } );
    if ( r.valid ) return _.at( r, 'type', 'id' ).join( ':' );
    if ( _.isString( type ) && _.isString( id ) ) {
      return [ type, id ].join( ':' );
    }
    throw new DatabaseOriginsError( {
      message : `Cannot create key from ${type} / ${id}`,
      tags    : { schema : type, method : 'Origins#key' },
      data    : { type, id },
    } );
  }

}
