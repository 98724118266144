import _ from 'lodash';
import { Module } from '~/core/lib/Module';
import { Event } from './Event';
import { mkdebug } from '@ssp/utils';
import { CancellationError } from './errors';
import { isSubDoc } from '~/utils/types';
import { List } from '../fields/List';

const debug = mkdebug( 'ssp:database:events:dispatch' );

function isEvent( e ) { return e instanceof Event; }

export function dispatchEvents( events: Event[] ) {
  if ( ! ( Array.isArray( events ) && events.every( isEvent ) ) ) {
    Module.get( 'events' ).throw(
      'The argument to dispatchEvents must be an array of Event objects',
      {
        module    : 'events',
        method    : 'dispatchEvents',
        parameter : 'events',
        value     : events,
      },
    );
  }

  // if they are all sync then we can use a shortcut...
  if ( events.every( ev => ev.mode === 'sync' ) ) {
    return dispatchSync( events );
  } else {
    return dispatchAsync( events );
  }
}

function getHandlers( ev: Event ) {
  const handlers = ev.currentSchema.getEvents( ev.type );
  const skips = ctx.get( 'skip_event_handler_ids', null );
  if ( skips ) {
    return handlers.filter( handler => ! skips.includes( handler.id ) );
  } else {
    return handlers;
  }
}

async function dispatchAsync( event ) {
  if ( Array.isArray( event ) ) {
    for ( const ev of event ) await dispatchAsync( ev );
    return;
  }
  const handlers = getHandlers( event );
  if ( ! handlers.length ) return;
  debug( `+ DISPATCH ASYNC ${event.identity} (${handlers.length} handlers)` );
  for ( const handler of handlers ) {
    if ( event.immediatePropagationStopped ) break;
    await handler.execute( event );
  }
  if ( event.propagates && ! event.propagationStopped ) {
    for ( const { target, path } of getPropagatableSubdocs( event ) ) {
      event.currentTarget = target;
      event.currentTargetModel = target.constructor;
      event.currentTargetPath = path;
      await dispatchAsync( event );
    }
  }
  await finalize( event );
  debug( '- DISPATCH ASYNC', event.identity );
}

function dispatchSync( event ) {
  if ( Array.isArray( event ) ) {
    for ( const ev of event ) dispatchSync( ev );
    return;
  }
  if ( event.async ) event.throw( 'dispatchSync called for async event' );
  const handlers = getHandlers( event );
  if ( ! handlers.length ) return;
  debug( `+ DISPATCH SYNC ${event.identity} (${handlers.length} handlers)` );

  for ( const handler of handlers ) {
    if ( event.immediatePropagationStopped ) break;
    handler.execute( event );
  }
  if ( event.propagates && ! event.propagationStopped ) {
    for ( const { target, path } of getPropagatableSubdocs( event ) ) {
      event.currentTarget = target;
      event.currentTargetModel = target.constructor;
      event.currentTargetPath = path;
      dispatchSync( event );
    }
  }
  finalize( event );
  debug( '- DISPATCH SYNC', event.identity );
}

function getPropagatableSubdocs( event ) {
  const {
    currentTarget       : doc,
    currentTargetModel  : model,
    currentTargetPath   : path = [],
  } = event;
  if ( ! doc ) return [];
  const fields = model.schema.getFieldNames( '@subdoc', '@list', '-@computed' );
  const values = fields.flatMap( name => {
    let value = doc[ name ];
    if ( value instanceof List ) value = value.toArray();
    if ( _.isArray( value ) ) {
      return _.map( value, ( val, idx ) => ( {
        target : val, path : path.concat( name, idx ),
      } ) );
    } else {
      return { target : value, path : path.concat( name ) };
    }
  } );
  return values.filter( obj => isSubDoc( obj.target ) );
}

function finalize( event ) {
  event.currentTarget = event.primaryTarget;
  event.currentTargetModel = event.primaryTargetModel;
  if ( event.cancelable && event.defaultPrevented ) {
    throw new CancellationError( event );
  }
}
