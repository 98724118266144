import _ from 'lodash';
import { Resource } from './Resource';
import { resolveRoute } from '@ssp/utils';
import type { TResource, SchemaId } from '~/types';

export interface UserResource extends Resource {
  user?: TResource<'SSP.User'>;
  owner?: TResource<'SSP.User'>;
  user_id?: string;
  _id?: string;
}

export class UserResource extends Resource {

  get service_id() {
    return this.schema.service_id_field && this[ this.schema.service_id_field ];
  }

  canBeManagedBy( subject ) {
    return this._canBeManagedBy( subject, () => {
      if ( subject._id === this.user_id ) {
        return [ true, 'subject is resource owner' ];
      }
      if ( this.schema.getTopSchema().id === 'SSP.User' ) {
        if ( subject.hasCapability( 'manage-any-user' ) ) {
          return [ true, 'subject can manage any user' ];
        }
      } else {
        if ( subject.hasCapability( 'manage-any-user-resources' ) ) {
          return [ true, 'subject can manage any user resources' ];
        }
      }
    } );
  }

  get owner_id() { return this.user_id; }
  get owner_type() { return 'SSP.User'; }

  getOwnerRoute( ...args ) {
    return _.compact( [ '/user', this.user_id, ...args ] ).join( '/' );
  }

  newResource( type: SchemaId, config={} ) {
    const model = UserResource.demand( type );
    if ( ! model ) throw new Error( `No component found with id "${type}"` );
    if ( ! model.schema.is_user_resource ) {
      throw new Error( `${type} is not a UserResource` );
    }
    return model.create( { ...config, user_id : this._id } );
  }
  addResource( type, config={} ) {
    const rsrc = this.newResource( type, config );
    return rsrc.save();
  }
  baseurl() {
    if ( ! this._id ) return;
    const base = this.user_id ? `/user/${this.user_id}` : 'resource';
    return resolveRoute( base, this.schema.id, this._id );
  }

}
