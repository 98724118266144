import _ from 'lodash';
import { Module } from '~/core/lib/Module';
import { Action } from './Action';

Module.create( {
  name     : 'actions',
  services : true,

  parse( data, origin ) {
    if ( _.isEmpty( data.actions ) ) return;
    const actions = _.omitBy( _.mapValues( data.actions, ( config, name ) => {
      if ( config === false ) {
        return {
          name, origin, not_available : 'Excluded by schema definition',
        };
      } else {
        return { name, origin, ...config };
      }
    } ), _.isNil );
    if ( _.isEmpty( actions ) ) return;
    return { actions };
  },

  apply( data, config ) {
    if ( _.isEmpty( data.actions ) ) return;
    const actions = _.omitBy( _.mapValues( data.actions, conf => {
      return new Action( conf );
    } ), _.isNil );
    config.add( { actions } );
  },

} );
