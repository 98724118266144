import DB from '~';
import { createResource } from '~/core/creators';

createResource( {
  id          : 'BAH.User',
  name        : 'BAH User',
  icon        : 'far:address-book',
  attachable  : [ 'SSP.User' ],

  fields      : {
    dn              : {
      type        : 'string',
      index       : true,
      unique      : true,
      summary     : true,
      readonly    : true,
    },
    title           : {
      type        : 'string',
      index       : true,
      summary     : true,
      readonly    : true,
      optional    : true,
    },
    level           : {
      type        : 'string',
      index       : true,
      summary     : true,
      readonly    : true,
      optional    : true,
    },
    office          : {
      type        : 'string',
      index       : true,
      summary     : true,
      readonly    : true,
      optional    : true,
    },
    office_number   : {
      type        : 'string',
      index       : true,
      summary     : true,
      readonly    : true,
      optional    : true,
    },
    phone_number    : {
      type        : 'string',
      index       : true,
      summary     : true,
      readonly    : true,
      optional    : true,
    },
    certificates    : {
      type        : 'text',
      array       : true,
      optional    : true,
      readonly    : true,
    },
    citizenship     : {
      type        : 'string',
      index       : true,
      summary     : false,
      readonly    : true,
      optional    : true,
    },
    department      : {
      type        : 'string',
      index       : true,
      summary     : true,
      readonly    : true,
      optional    : true,
    },
    division        : {
      type        : 'string',
      index       : true,
      summary     : true,
      readonly    : true,
      optional    : true,
    },
    organization_id : {
      type        : 'string',
      index       : true,
      summary     : true,
      readonly    : true,
      optional    : true,
    },
    country         : {
      type        : 'string',
      index       : true,
      summary     : true,
      readonly    : true,
      optional    : true,
    },
    employee_id     : {
      type        : 'employee-id',
      index       : true,
      summary     : true,
      quicksearch : true,
      readonly    : true,
      optional    : true,
    },
    manager_dn        : {
      type        : 'string',
      index       : true,
      summary     : true,
      readonly    : true,
      optional    : true,
    },
    manager           : {
      type        : 'BAH.User',
      computed    : {
        cached  : true,
        compute() {
          const dn = this.manager_dn;
          if ( ! dn ) return;
          return DB.BAH.User.fromIdent( dn );
        },
      },
    },
    name              : {
      type        : 'string',
      index       : true,
      summary     : true,
      quicksearch : true,
      readonly    : true,
    },
    ldap_display_name     : {
      type        : 'string',
      index       : true,
      summary     : true,
      quicksearch : true,
      readonly    : true,
      optional    : true,
    },
    integrated_management_team : {
      type        : 'string',
      index       : true,
      readonly    : true,
      optional    : true,
    },
  },

  methods : class {
    static get displayNameFields() {
      return [ 'ldap_display_name', 'name', 'email' ];
    }
  },

  actions : {
    disable : false,
    enable  : false,
  },

  behaviors   : {
    hasEmail            : { useMultipleEmails : true },
    hasDisabled         : {},
    hasDeleted          : {},
    hasServiceAccounts  : {},
  },

  faces         : {
    'card.summary' : [
      {
        layout : 'Badges',
        fields : [ '@badge' ],
      },
      {
        layout : 'LabelsAndValues',
        fields : [
          'email', 'employee_id', 'title', 'division', 'department', 'dn',
        ],
      },
    ],
    'list.summary' : [
      {
        layout : {
          type       : 'ItemsBetweenTitleAndIcons',
          iconize    : [ {
            fieldName : 'employee_id',
            icon      : 'fad:id-badge',
          } ],
        },
        fields : [ '-@all' ],
      },
      {
        layout : 'ItemsAndBadges',
        fields  : [ 'dn', '@badge' ],
      },
    ],
  },

} );
