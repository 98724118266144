import { createResource } from '~/core/creators';

createResource( {
  id         : 'CSNAD.User',
  name       : 'CSNAD User',
  attachable : [ 'SSP.User' ],
  icon       : 'far:address-book',

  fields      : {
    dn              : {
      type        : 'string',
      index       : true,
      unique      : true,
      summary     : true,
      readonly    : true,
    },
    employee_id     : {
      type        : 'employee-id',
      index       : true,
      summary     : true,
      quicksearch : true,
      readonly    : true,
      optional    : true,
    },
    groups          : {
      type        : 'string',
      array       : true,
      index       : true,
      optional    : true,
      readonly    : true,
      default     : [],
    },
    username        : {
      type        : 'string',
      index       : true,
      unique      : true,
      optional    : true,
      summary     : true,
      quicksearch : true,
      readonly    : true,
    },
    name              : {
      type        : 'string',
      index       : true,
      summary     : true,
      quicksearch : true,
      readonly    : true,
    },
    ldap_display_name      : {
      type        : 'string',
      index       : true,
      summary     : true,
      quicksearch : true,
      readonly    : true,
      optional    : true,
    },
  },

  actions : {
    disable : false,
    enable  : false,
  },

  behaviors : {
    hasEmail            : { useMultipleEmails : true },
    hasDisabled         : {},
    hasDeleted          : {},
    hasServiceAccounts  : {},
  },

  faces         : {
    'card.summary' : [
      {
        layout : 'Badges',
        fields : [ '@badge' ],
      },
      {
        layout : 'LabelsAndValues',
        fields : [ 'email', 'username', 'dn' ],
      },
    ],
    'list.summary' : [
      {
        layout : {
          type       : 'ItemsBetweenTitleAndIcons',
          iconize    : [ {
            fieldName : 'employee_id',
            icon      : 'fad:id-badge',
          } ],
        },
        fields : [ '-@all' ],
      },
      {
        layout : 'ItemsAndBadges',
        fields  : [ 'dn', '@badge' ],
      },
    ],
  },

  methods : class {
    static get displayNameFields() {
      return [ 'ldap_display_name', 'name', 'email' ];
    }
  },

} );
