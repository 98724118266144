import { BadRequest, Forbidden } from '@ssp/utils';

import { getSchema } from '~/core/schemas';

import { Resource } from './Resource';
import { ProjectResource } from './ProjectResource';

ProjectResource.initialize( {
  id                  : 'ProjectResource',
  inherit             : 'Resource',
  is_abstract         : true,
  is_resource         : true,
  is_project_resource : true,
  is_user_resource    : false,
  fields        : {
    project_id  : {
      type      : 'id',
      label     : 'Project ID',
      index     : true,
      access    : 'support',
      metadata  : true,
      faker     : 'project.id',
    },
    owner         : {
      type        : 'SSP.Project',
      label       : 'Owner',
      face        : { type : 'Owner' },
      metadata    : true,
      readonly    : true,
      enumerable  : false,
      computed    : {
        cached  : true,
        compute() {
          // This one returns undefined instead of null because null
          // means "don't know yet, return undefined but don't cache
          // the return value so we can try again the next time this
          // is accessed".  Actually returning undefined means that
          // `undefined` will be put into the data map and this
          // property will return undefined for every access.
          if ( this._id === this.project_id ) return undefined;
          if ( ! this.project_id ) return null;
          return Resource.demand( 'SSP.Project' ).fromId( this.project_id );
        },
      },
    },
    project       : {
      type        : 'SSP.Project',
      label       : 'Project',
      face        : { type : 'Owner' },
      metadata    : true,
      readonly    : true,
      enumerable  : false,
      computed    : {
        cached  : true,
        get() { return ( this._id === this.project_id ) ? this : this.owner; },
      },
    },
  },

  actions : {
    changeOwner   : {
      label   : 'Change Owner',
      help    : 'Move this resource to another SSP Project',
      icon    : 'fas:theater-masks',
      access  : 'support',
      params  : {
        project_id  : {
          label : 'New Owner',
          type  : 'any',
          parse : ref => ref.id || ref._id,
          form  : {
            type      : 'ResourceSelector',
            model     : 'SSP.Project',
            query     : {},
            options   : {},
          },
        },
      },
      method        : 'update',
    },
    delete    : {
      label         : 'Delete Resource',
      help          : 'Delete this Resource',
      route         : 'delete',
      access        : 'admin',
      icon          : 'far:trash-alt',
      context       : 'self',
      disabledMethod() {
        if ( this[ this.schema?.service_id_field ] ) {
          return [
            `This ${this.schema.name} exists in the`,
            'external service, so deleting it is disabled.',
            'If you wish to archive this resource,',
            'do it directly within the service.',
          ].join( ' ' );
        }
      },
    },
  },

  behaviors     : {
    hasTimestamps     : {},
    hasResourceCounts : {},
  },

  faces         : {
    'list.summary' : [
      {
        layout : {
          type       : 'ItemsBetweenTitleAndIcons',
        },
        fields : [ '-@all' ],
      },
      {
        layout : 'ItemsAndBadges',
        fields  : [ '@badge' ],
      },
    ],
  },

}, BUILD.isClient && {
  queries       : {
    mine( mine=true ) { return [ {}, { flag : { mine } } ]; },
    user( user ) { return [ {}, { flag : { user } } ]; },
  },
}, BUILD.isServer && {
  queries : {
    async mine( mine=true ) {
      const user = ctx.user;
      const ids = user.project_ids;
      if ( ! Array.isArray( ids ) ) {
        throw new Error( `Can't query .mine() without user.project_ids` );
      }
      const op = mine ? '$in' : '$nin';
      return { project_id : { [ op ] : ids } };
    },
    async user( user_id ) {
      const user = await Resource.demand( 'SSP.User' ).retrieve( user_id );
      const ids = user.project_ids;
      if ( ! Array.isArray( ids ) ) {
        throw new Error( `Can't query .mine() without user.project_ids` );
      }
      return { project_id : { $in : ids } };
    },
  },
  events  : {
    async beforeQuery( ev ) {
      const req = ev.request;
      const { schema } = req;
      if ( isProjectSchema( schema ) ) return; // handled in SSP.Project
      // view-any-project-resources allows you to see really *ANY* project
      if ( req.can( 'view-any-project-resources' ) ) return;
      // If you can't view-any-project or view-unassociated-projects
      // then you can only see ones you are a member of, which is
      // a shorter query
      const query = await this.getRestrictQuery( req.subject, req, ev );
      if ( ! query ) return;
      return req.restrict( query );
    },
    async beforeRequest( ev ) {
      const req = ev.request;
      const { schema, method, owner } = req;
      if ( isProjectSchema( schema ) ) return; // handled in SSP.Project
      if ( method === 'insert' ) {
        if ( ! isProjectSchema( owner.schema.id ) ) {
          throw new BadRequest( 'owner is not a project' );
        }
        if ( ! getSchema( schema ).canAttachTo( owner ) ) {
          throw new BadRequest( `Can not attach ${schema} to project` );
        }
      }
    },
    async duringRequest( ev ) {
      const req = ev.request;
      const { resource, subject, schema } = req;
      if ( isProjectSchema( schema ) ) return; // handled in SSP.Project
      if ( resource ) {
        const [ res, reason ] = resource.canBeManagedBy( subject );
        if ( ! res ) throw new Forbidden( reason );
      }
    },
    async beforeRemove() {
      await this.findJobs( { running_on : null } ).remove();
      await this.findJobs( {} ).map( job => job.cancel( 'Resource deleted' ) );
    },
  },
} );

function isProjectSchema( id ) {
  return getSchema( id ).getTopSchema().id === 'SSP.Project';
}
