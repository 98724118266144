import _ from 'lodash';
import { mkdebug, DatabaseFieldError } from '@ssp/utils';

import type { ValidationContext } from '@ssp/types';

import { Field } from './Field';
import { List, ListOptions } from './List';
import { getDataMap, updateQuietly } from '../broker/data-utils';

import type { FieldOptions } from './Field';

const debug = mkdebug( 'ssp:database:fields:listfield' );

export type ListFieldOptions = FieldOptions & {
  list: true | ListOptions;
};

export interface ListField {
  constructor: typeof ListField;
}
export class ListField extends Field {

  coerce( value, context ) {
    if ( value instanceof List ) value = value.toArray();
    value = _.compact( _.flattenDeep( [ value ] ) );
    value = _.map( value, val => this.coerceValue( val, context ) );
    const list = this.makeList();
    list.replace( value );
    return list;
  }
  transform( value, context ) {
    if ( value instanceof List ) value = value.toArray();
    value = _.compact( _.flattenDeep( [ value ] ) );
    value = _.map( value, val => this.transformValue( val, context ) );
    const list = this.makeList();
    list.replace( value );
    return list;
  }
  prepare( value, context ) {
    return this.coerce( value, context );
  }

  list_options: ListOptions = {};

  get list() { return true; }

  get simple() { return false; }

  /**
   * Format a value for storage in the database or for transport over
   * the wire.  This is the opposite of the {@link #parse} method.
   */
  format( value: List ) {
    if ( ! ( value instanceof List ) ) {
      throw new DatabaseFieldError( {
        message : `Cannot format non-List value "${value}" (${typeof value})`,
        tags    : {
          schema : this.schema.id,
          method : 'ListField#format',
          field  : this.name,
        },
        data    : { value },
      } );
    }
    return value.toArray().map( val => this.formatValue( val ) );
  }

  /**
   * Parse a value from database storage or from transport over the
   * wire.  This is the opposite of the {@link #format} method.
   *
   * @param {any} value - The value to parse.
   */
  parse( value ) {
    if ( Array.isArray( value ) ) {
      const list = this.makeList();
      list.replace( value );
      return list;
    }
    throw new DatabaseFieldError( {
      message : `Cannot parse non-Array value "${value}" (${typeof value})`,
      tags    : {
        schema : this.schema.id,
        method : 'ListField#parse',
        field  : this.name,
      },
      data    : { value },
    } );
  }

  parseValue( value ) {
    if ( Array.isArray( value ) ) {
      const list = this.makeList();
      list.replace( value );
      return list;
    }
    throw new DatabaseFieldError( {
      message : `Cannot parse non-Array value "${value}" (${typeof value})`,
      tags    : {
        schema : this.schema.id,
        method : 'ListField#parseValue',
        field  : this.name,
      },
      data    : { value },
    } );
  }

  equals( one, two ) {
    // If they are both undefined then we consider them to be equal,
    // even if one is `undefined` and one is `null`.
    if ( _.isNil( one ) && _.isNil( two ) ) return true;
    // If only one of them is a List then they aren't equal
    if ( ( one instanceof List ) !== ( two instanceof List ) ) return false;

    one = getDataMap( one );
    two = getDataMap( two );

    // If they are different sizes then they can't be the same
    if ( one.size !== two.size ) return false;

    const ids = _.uniq( [ ...one.keys(), ...two.keys() ] );
    for ( const id of ids ) {
      if ( ! this.equalsValue( one.get( id ), two.get( id ) ) ) return false;
    }
    return true;
  }

  getListFor( doc ) {
    debug( 'getListFor / doc', doc._id, doc._version );
    const data = getDataMap( doc );
    const val = data.get( this.name );
    debug( 'getListFor / val', val );
    if ( val instanceof List ) {
      debug( 'getListFor found list, returning it' );
      return val;
    }
    if ( _.isNil( val ) ) {
      debug( 'getListFor did not find list, creating one' );
      const list = this.makeList();
      data.set( this.name, list );
      return list;
    }
    throw new DatabaseFieldError( {
      message : `ListField contains invalid data "${val}" (${typeof val})`,
      tags    : {
        schema : this.schema.id,
        method : 'ListField#getListFor',
        field  : this.name,
      },
      data    : { doc },
    } );
  }
  makeList() {
    if ( ! this.subdocSchema ) {
      throw new DatabaseFieldError( {
        message : `Can't makeList without subdocSchema`,
        tags    : {
          field   : this.name,
          method  : 'ListField#makeList',
          schema  : this.schema.id,
        },
      } );
    }
    return new List( this, { ...this.list_options } );
  }

  makeGetter() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const field = this;
    return function get() { return field.getListFor( this ); };
  }

  makeSetter() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const field = this;
    return function set( value ) {
      value = field.transform( field.coerce( value, this ), this );
      field.getListFor( this ).replace( value );
    };
  }

  updateQuietly( rsrc, values ) {
    if ( ! values ) return false;
    if ( values instanceof List ) values = this.format( values );
    if ( ! Array.isArray( values ) ) {
      log.warn( 'Ingored invalid ListField value - not an array', {
        values, class : 'ListField', method : 'updateQuietly',
      } );
      return false;
    }
    const list = this.getListFor( rsrc );
    if ( ! ( list instanceof List ) ) {
      throw new TypeError( `Cannot update list quietly without list` );
    }
    const had = new Set( list.keys() );
    const res = values.map( data => {
      data = this.coerceValue( data );
      const key = list.identity( data );
      if ( had.has( key ) ) {
        had.delete( key );
        const old = list.getKey( key );
        return updateQuietly( old, data );
      } else {
        list.add( data, true );
        return true;
      }
    } );
    for ( const removed of had ) {
      list.delete( list.get( removed ), true );
    }
    return Boolean( _.some( res ) || had.size );
  }

  constructor( options: ListFieldOptions ) {
    const { list, ...opts } = options;
    super( opts );
    if ( typeof list === 'object' ) {
      this.list_options = list;
    } else {
      this.list_options = {};
    }
  }

  async validate( options: ValidationContext = {} ) {
    debug( `${this} validate`, options );
    if ( ! this.validators.length ) return;
    return super.validate( {
      ...options,
      array   : false,
      object  : true,
      value   : ( options.value as $TSFixMe )?.toObject(),
    } );
  }

}
