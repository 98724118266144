import _ from 'lodash';
import { Validator } from '@ssp/types';
import { isInstanceOfModel } from '~/utils';
import { getSchema } from '~/core/schemas';

Validator.create( {
  name      : 'dbmodel',
  parse( { config } ) {
    if ( ! _.isString( config ) ) {
      throw new TypeError(
        'Parameter for the "dbmodel" validator must be a string',
      );
    }
    if ( ! getSchema( config ) ) {
      throw new TypeError( [
        'Parameter for the "dbmodel" validator must be the name of a Model',
        `(not ${config})`,
      ].join( ' ' ) );
    }
  },
  isValid( { value, config } ) { return isInstanceOfModel( config, value ); },
  getMessage( { name, config } ) {
    return `"${name}" must be an instance of ${config}`;
  },
} );
