import _ from 'lodash';
import {
  NotAcceptable, hideProps,
  isThenable, jsonSerialize, jsonDeserialize,
  createRefUrl, parseRefUrl, AnyError,
} from '@ssp/utils';

import { isResource } from '~/utils/types';

import type { Resource } from '~/core/resource/Resource';
import type { SchemaId } from '~/types';

export type TransportMethod =
  | 'find'
  | 'fetch'
  | 'retrieve'
  | 'batch'
  | 'insert'
  | 'update'
  | 'remove'
  | 'resolve'
  | 'job'
  | 'action';

export type TransportResponseOptions = {
  method: TransportMethod;
  schema: SchemaId;
  id?: string;
};

export class TransportResponse<
  M extends TransportMethod = TransportMethod, T=unknown
> {

  /** The method that was used to generate this response. */
  declare method: M;
  declare id: T extends Resource ? string : never;
  declare version?: T extends Resource ? number : never;
  response_created: Date = new Date();
  declare _version_instance?: $TSFixMe;

  declare batch?: TransportResponse<'batch'>;

  constructor( opts: TransportResponseOptions ) {
    const { ok : _ok, ...data } = jsonDeserialize( opts );
    Object.assign( this, data );
  }

  get origin() {
    if ( this.type && this.id ) {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      return require( '~/modules/origins' )
        .getOrigins()
        .get( this.type, this.id );
    }
  }

  /** Indicates whether the response is ok or contains an error. */
  get ok(): boolean { return ! this.error; }

  /**
   * The total number of responses that matched the query, not
   * including skip/limit considerations.
   */
  total?: number;

  /** The number of results in this response. */
  count?: number;

  // TODO - This is actually a JSON represenation of the resource, not
  // the resource itself.
  declare _resource: M extends 'retrieve'|'fetch'|'insert'|'update'|'job'
    ? T : never;

  /**
   * For an `action` response this will contain the return value from
   * the action method.
   */
  declare result?: M extends 'action' ? T : never;

  /**
   * The resource data for any call that returns a resource.
   *
   * Calls that return resources include:
   *  - `fetch` and `retrieve` (obviously)
   *  - `insert` - Returns the inserted record including it's new `_id`.
   *  - `update` - Returns the updated record including it's new `_version`.
   *  - `job` - Returns the created `SYSTEM.Job` record.
   */
  get resource(): this['_resource'] {
    if ( this._resource ) return this._resource;
  }
  set resource( rsrc ) {
    if ( ! rsrc ) return;
    if ( isThenable( rsrc ) ) {
      throw new NotAcceptable( `Cannot set resource to promise`, {
        resource : rsrc, response : this,
      } );
    }
    if ( _.isEmpty( rsrc ) ) {
      throw new NotAcceptable( `Cannot set resource to empty value`, {
        response : this,
      } );
    }
    if ( ! ( _.isPlainObject( rsrc ) || isResource( rsrc ) ) ) {
      throw new NotAcceptable( `Invalid resource`, {
        data : { resource : rsrc, response : this },
      } );
    }
    if ( rsrc.schema ) this.schema = rsrc.schema.id;
    this.id = rsrc._id;
    this.version = rsrc._version;
    this._resource = rsrc;
  }

  declare resources: M extends 'batch'|'find' ? T[] : never;
  declare ref_urls: M extends 'batch'|'find' ? string[] : never;

  declare error?: AnyError;
  declare _status?: string;

  /**
   * Status message if the response contains an error and the error had
   * a status property (or a status was set on a response).
   */
  get status() {
    if ( this._status ) return this._status;
    if ( this.error ) return this.error.status || 'unknown';
    return 'ok';
  }
  set status( status ) { this._status = status; }

  declare _schema?: SchemaId;
  /**
   * If the response contains a resource, this is the schema id for
   * that resource.
   */
  get schema(): SchemaId { return this._schema; }
  set schema( schema: SchemaId|Schema ) {
    if ( typeof schema === 'string' ) {
      this._schema = schema;
    } else {
      this._schema = schema.id;
    }
  }
  get type(): SchemaId { return this.schema; }
  set type( type: SchemaId ) { this.schema = type; }

  declare _ref_url?: string;
  /**
   * If the response contains a resource, this is the ref_url for that
   * resource.
   */
  get ref_url() {
    if ( this._ref_url ) return this._ref_url;
    if ( this.resource ) {
      const { resource } = this;
      const type = _.get( resource, 'schema.id', this.type );
      const version = resource._version;
      const id = resource._id;
      const name = resource.display_name;
      this.ref_url = createRefUrl( { type, id, version, name } );
      return this._ref_url;
    }
  }
  set ref_url( ref_url ) {
    const ref = parseRefUrl( ref_url );
    if ( ! ref ) return;
    this._ref_url = ref_url;
    _.assign( this, _.pick( ref, 'type', 'id', 'version', 'name' ) );
  }

  get ref() {
    const url = this.ref_url;
    if ( ! url ) return;
    return parseRefUrl( url );
  }

  /**
   * @property [ref_urls]
   *
   * If the response was to a search this will contain the ref_urls of
   * all of the results.  Under some conditions you may get this
   * property on other types of requests as well (such as if you
   * attempt a `fetch` and more than one resource matches the search).
   *
   * @type {string[]}
   */

  /**
   * @property [options]
   *
   * This will generally contain the options that were passed to the
   * method.
   *
   * @type {object}
   */

  fatalize() {
    if ( this.ok ) return;
    throw this.getError();
  }

  getError() {
    const err = this.error;
    hideProps( err, { response : this } );
    return err;
  }

  declare response_serialized?: Date;
  toJSON() {
    this.response_serialized = new Date();
    return jsonSerialize( {
      ok      : !! this.ok,
      status  : this.status,
      ..._.mapKeys( this, ( _val, key ) => key.replace( /^_/u, '' ) ),
    } );
  }

  hydrate( opts={} ) {
    if ( ! this.type ) return;
    this.fatalize();
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    return require( '~/core/lib/Schema' )
      .Schema
      .demand( this.type ).model.construct( {
        method    : 'TransportResponse.hydrate',
        ...opts,
        response  : this,
      } );
  }

}
