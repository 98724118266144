import _ from 'lodash';
import { hideProps, mkdebug } from '@ssp/utils';
import { parseCacheKey } from '../utils';

const debug = mkdebug( 'ssp:database:origins:cache' );

export interface CacheLayer<T=unknown> {
  get( key: string ): Promise<T|undefined>;
  set( key: string, value: T ): Promise<void>;
  has( key: string ): Promise<boolean>;
  remove( key: string ): Promise<void>;
  clear(): Promise<void>;
  keys?(): Promise<string[]>;
}

export class OriginCache {

  layers!: CacheLayer[];

  constructor( layers=[] ) {
    hideProps( this, { layers } );
  }

  async get( key ) {
    debug( 'get', key );
    if ( ! key ) return;
    for ( const layer of this.layers ) {
      const res = await layer.get( key );
      if ( res ) return res;
    }
  }
  async set( key, value ) {
    debug( 'set', key, value );
    if ( ! key ) return;
    for ( const layer of this.layers ) {
      await layer.set( key, value );
    }
  }
  async has( key ) {
    debug( 'has', key );
    if ( ! key ) return false;
    for ( const layer of this.layers ) {
      if ( await layer.has( key ) ) return true;
    }
    return false;
  }
  async remove( key ) {
    debug( 'remove', key );
    if ( ! key ) return;
    for ( const layer of this.layers ) {
      await layer.remove( key );
    }
  }
  async clear() {
    debug( 'clear' );
    for ( const layer of this.layers ) {
      await layer.clear();
    }
  }

  addLayer( layer ) { this.layers.push( layer ); }

  getMeta( key ) { return this.get( `X:${key}` ); }
  setMeta( key, value ) { return this.set( `X:${key}`, value ); }

  isValidKey( key ) {
    if ( ! key ) return false;
    try {
      return _.isPlainObject( parseCacheKey( key ) );
    } catch ( err ) {
      log.warn( `Error parsing cache key '${key}':`, err );

      return false;
    }
  }

  async cleanup() {
    for ( const layer of this.layers ) {
      if ( typeof layer.keys !== 'function' ) continue;
      const keys = await layer.keys();
      for ( const key of keys ) {
        if ( ! this.isValidKey( key ) ) await layer.remove( key );
      }
    }
  }

  async reset( dbid: string ) {
    await this.clear();
    if ( ! _.isString( dbid ) ) {
      throw new Error( `Cannot reset without dbid` );
    }
    await this.setMeta( 'dbid', dbid );
  }

  async getServerDBID() {
    if ( BUILD.isClient && SSP.meta?.server?.dbid ) return SSP.meta.server.dbid;
    if ( BUILD.isServer && SSP.meta?.dbid ) return SSP.meta.dbid;
    if ( BUILD.isTest ) return 'test-dbid';
  }

  async validate() {
    if ( BUILD.isClient ) {
      if ( window.location.pathname === '/reset-state' ) {
        await this.clear();
        // eslint-disable-next-line require-atomic-updates
        window.location.pathname = '/';
      }
    }
    const have = await this.getMeta( 'dbid' );
    const want = await this.getServerDBID();

    if ( ! ( have && want ) ) return this.clear();
    if ( have !== want ) return this.reset( want );
  }
  async start() {
    await this.validate();
    try {
      await this.cleanup();
    } catch ( err ) {
      log.error( `Cache cleanup threw:`, err );
    }
  }
  async stop() {
    // no-op, nothing to do for now
  }

}
