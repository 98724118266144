import _ from 'lodash';
import { hideProps } from '@ssp/utils';

import { DB } from '~';
import { getSchema } from '~/core/schemas';

import { Change } from './Change';

import type { TResource } from '~/types';

import type { MemberInfo } from '../MemberInfo';
import type { PendingChangeUpdate } from '../types';
import type { MembershipHelper, MembershipChange } from './MembershipHelper';
import type { ChangeSummary } from './Change';
import type { Resource } from './Resource';

export type UserSummary  = {
  id: string;
  name: string;
  change?: {
    user_id?: string;
    service_user_id?: string;
    change_type: 'add' | 'remove' | 'update';
    is_discovered?: boolean;
    is_discovery?: boolean;
    config?: Record<string, unknown>;
  };
  changes: ChangeSummary[];
};
export type UserFinalChangeInfo = {
  /** The user_id of the member to update. */
  user_id: string;
  /** The type of change */
  change_type: 'add' | 'remove' | 'update';
  /** Set to true for changes discovered by the update-members job */
  is_discovered?: boolean;
  /** Set to true for changes discovered by this update-members job */
  is_discovery?: boolean;
  /** Can be set to change which service user instance is a member. */
  service_user_id?: string;
  /**
   * Any configuration option the resource accepts can be included
   * here, such as "role" for resources using the withRole trait.
   */
  config: Record<string, any>;
  /** The changes that contributed to this final change. */
  included_changes: Change[];
  /** The changes that did not contribute to this final change. */
  excluded_changes: Change[];
};

export class User {

  user: TResource<'SSP.User'>;
  /** All of the changes that have been attempted. */
  changes: Change[] = [];

  readonly helper: MembershipHelper;

  constructor(
    public id: string,
    helper: MembershipHelper,
  ) {
    hideProps( this, { helper } );
    this.user = DB.SSP.User.fromId( id );
  }

  get name() { return this.user.display_name; }

  async prepare() {
    await this.user.load();
  }

  change( opts: Partial<Change> ) {
    const { resource, teamlink } = opts;
    for ( const change of this.changes ) {
      if ( ! change.teamlink ) continue;
      // Bail out early if we already have a change for this resource
      // and teamlink, because this indicates that we've circled
      // around and will start repeating ourselves.
      if ( change.resource === resource && change.teamlink === teamlink ) {
        return;
      }
    }
    const change = new Change( opts, this );
    this.changes.push( change );
    return change;
  }

  get will_provision_types(): string[] {
    const user_types = _.compact( _.uniq( this.changes
      .filter( change => change.change_type === 'add' )
      .map( change => change.userType ) ) );
    return _.difference( user_types, this.user.account_types );
  }

  /**
   * Return a list of services that will be provisioned for this user.
   */
  get will_provision_services(): string[] {
    return this.will_provision_types.map(
      id => getSchema( id ).service.name,
    ).sort();
  }

  getChanges() {
    // const changesets = _.groupBy( this.changes, 'resource.id' );
    // log.debug( 'CHANGESETS:', changesets );
  }

  getChangesForResource( rsrc: Resource ) {
    return this.changes.filter( change => change.resource === rsrc );
  }

  getFinalChangeForResource(
    rsrc: Resource,
  ): MembershipChange | undefined {
    const info = this.getFinalChangeInfoForResource( rsrc );
    if ( ! info ) return;
    const res: MembershipChange = {
      user_id         : this.id,
      change_type     : info.change_type,
      is_discovered   : info.is_discovered || false,
      is_discovery    : info.is_discovery || false,
      service_user_id : info.service_user_id,
      config          : info.config,
    };
    return res;
  }

  getFinalChangeInfoForResource(
    rsrc: Resource,
  ): UserFinalChangeInfo | undefined {
    const all_changes = this.getChangesForResource( rsrc );
    const included_changes = _.filter( all_changes, 'result' );
    const excluded_changes = _.reject( all_changes, 'result' );
    const change_objects = _.filter( included_changes, 'result' );
    if ( ! change_objects.length ) return;
    const changes = _.map( change_objects, 'change' );
    const collect = ( key: keyof MembershipChange ) => {
      const values = _.reject( _.uniq( _.map( changes, key ) ), _.isNil );
      if ( values.length === 1 ) return values[0];
      if ( values.length > 1 ) {
        throw new TypeError( `Mixed ${key} values: ${values}` );
      }
    };
    const configs = _.compact( _.map( changes, 'config' ) );
    const config: Record<string, any> = {};
    const MemberInfo = rsrc.getMemberInfo();
    const memberinfo = MemberInfo.create( { user_id : this.user._id } );
    for ( const field of rsrc.getConfigFieldNames() ) {
      const resolver = `resolve${_.upperFirst( field )}`;
      if ( typeof memberinfo[ resolver ] === 'function' ) {
        config[ field ] = memberinfo[ resolver ]( ..._.map( configs, field ) );
      } else {
        const values = _.reject( _.uniq( _.map( configs, field ) ), _.isNil );
        if ( values.length === 1 ) config[ field ] = values[0];
        if ( values.length > 1 ) {
          throw new TypeError( `Mixed config.${field} values: ${values}` );
        }
      }
    }

    const res: UserFinalChangeInfo = {
      user_id       : this.id,
      change_type   : collect( 'change_type' ),
      is_discovered : collect( 'is_discovered' ),
      is_discovery  : collect( 'is_discovery' ),
      config, included_changes, excluded_changes,
    };
    if ( collect( 'is_discovered' ) ) res.is_discovered = true;
    if ( collect( 'is_discovery' ) ) res.is_discovery = true;
    const service_user_id = collect( 'service_user_id' );
    if ( service_user_id ) res.service_user_id = service_user_id;
    return res;
  }

  getFinalChangeMessageForResource( rsrc: Resource ): string | undefined {
    const change = this.getFinalChangeInfoForResource( rsrc );
    if ( ! change ) return;
    log.debug( 'CHANGE:', change );
    /*
    return formatChangeMessage( {
      detailed, user,
      result      : true,
      change_type : change.change_type,
      config      : change.config,
    } );
    */
  }

  /**
   * Determines whether the change should be turned into a pending
   * change, or applied directly to the member.
   */
  shouldApplyChangeDirectlyTo(
    rsrc: Resource,
    change: PendingChangeUpdate,
    member: MemberInfo,
  ) {
    // If this was a discovered change and we're committing it to the
    // primary resource then we just apply it directly instead of
    // creating a pending change.
    if ( change.is_discovered && change.is_discovery ) return true;
    // If the resource has an update job then we update the pending
    // change to allow the job to handle it. There is one condition
    // where this happens when it probably shouldn't (when we're
    // updating the primary resource and it's a refresh), but at this
    // point we don't know that it's a refresh so we just assume it
    // won't be, and the `beforeSave` hook on `MemberInfo` will move
    // the changes over if it is.
    if ( ! member.hasTrait( 'withUpdateJob' ) ) return true;
    return false;
  }

  commitChangeForResource( rsrc: Resource ) {
    const change = this.getFinalChangeForResource( rsrc );
    if ( ! change ) return;
    const member = rsrc.getMemberInfoFor( this.id, true );
    if ( this.shouldApplyChangeDirectlyTo( rsrc, change, member ) ) {
      member.applyPendingChangeUpdate( change );
    } else {
      member.updatePendingChange( change );
    }
    return member;
  }

  getSummaryInfoForResource( rsrc: Resource ): UserSummary {
    const changes = this.getChangesForResource( rsrc )
      .map( c => c.getSummaryInfo() );
    const change = this.getFinalChangeForResource( rsrc );
    const res: UserSummary = {
      id   : this.id,
      name : this.user.display_name,
      changes,
    };
    if ( change ) res.change = change;
    return res;
  }

}
