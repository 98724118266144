import localforage from 'localforage';
import { mkdebug } from '@ssp/utils';
import { CacheLayer } from './OriginCache';

const debug = mkdebug( 'ssp:database:origins:cache:forage' );

declare global {
  interface Window {
    forage: typeof localforage;
  }
}

export class ForageCache implements CacheLayer {

  get forage() {
    if ( ! window.forage ) {
      window.forage = localforage.createInstance( {
        name        : 'ssp-storage',
        description : 'SSP Browser Storage',
      } );
    }
    return window.forage;
  }

  async get( key ) {
    debug( 'get', key );
    return this.forage.getItem( key );
  }
  async set( key, value ) {
    debug( 'set', key, value );
    return this.forage.setItem( key, value );
  }
  async has( key ) {
    debug( 'has', key );
    return !! this.forage.key( key );
  }
  async remove( key ) {
    debug( 'remove', key );
    return this.forage.removeItem( key );
  }
  async clear() {
    debug( 'clear' );
    return this.forage.clear();
  }
  async keys() {
    debug( 'keys' );
    return this.forage.keys();
  }

}
