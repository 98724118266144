import _ from 'lodash';
import { isConstructor, invariant } from '@ssp/utils';

import type { MethodDescriptor, SchemaConfigMethods } from './types';

export function isSchemaConfigMethods(
  value: any,
): value is SchemaConfigMethods {
  return _.isPlainObject( value ) && _.every( value, ( val, key ) => {
    return _.isString( key )
      && ( key.endsWith( ':static' ) || key.endsWith( ':proto' ) )
      && isMethodDescriptor( val );
  } );
}

export function isMethodDescriptor(
  value: any,
): value is MethodDescriptor {
  return _.isPlainObject( value )
    && _.isString( value.name )
    && _.isBoolean( value.is_static )
    && ( _.isString( value.origin ) || _.isNil( value.origin ) )
    && isPropertyDescriptor( value.desc )
    && ! _.without(
      _.keys( value ), 'name', 'is_static', 'origin', 'desc',
    ).length;
}

export function isPropertyDescriptor(
  value: any,
): value is PropertyDescriptor {
  if ( ! _.isPlainObject( value ) ) return false;
  if ( _.isEmpty( value ) ) return true;
  for ( const x of [ 'enumerable', 'configurable', 'writable' ] ) {
    const val = value[x];
    if ( ! ( _.isNil( val ) || _.isBoolean( val ) ) ) return false;
  }
  const extra = _.without(
    _.keys( value ),
    'enumerable', 'configurable', 'writable', 'value', 'get', 'set',
  );
  if ( extra.length ) return false;
  return true;
}

export type ExtractMethodDescriptorsSource =
  | ( new () => any )
  | SchemaConfigMethods
  | { [key: string]: ( ...args: any[] ) => any };

export function extractMethodDescriptors(
  source: ExtractMethodDescriptorsSource, origin: string,
): SchemaConfigMethods {
  if ( ! source ) return {};
  if ( isSchemaConfigMethods( source ) ) return source;
  if ( isConstructor( source ) ) {
    const res: SchemaConfigMethods = {};
    const ext = ( is_static, src, omit ) => _.each(
      _.omit( Object.getOwnPropertyDescriptors( src ), omit ),
      ( desc, name ) => {
        const id = name + ( is_static ? ':static' : ':proto' );
        res[ id ] = { name, is_static, desc, origin };
      },
    );
    ext( true, source, [ 'length', 'prototype', 'name' ] );
    ext( false, source.prototype, [ 'constructor' ] );
    return res;
  }
  if ( _.isPlainObject( source ) && _.every( source, _.isFunction ) ) {
    return _.mapKeys( _.mapValues( source, ( value, name ) => ( {
      name, origin, is_static : false, desc : { value },
    } ) ), ( _val, key ) => key + ':proto' );
  }
  if ( _.isArray( source ) ) {
    return _.assign( {}, ..._.map( source, src => (
      extractMethodDescriptors( src, origin )
    ) ) );
  }
  log.warn( 'Ignoring non-extractable method source:', source );
  return {};
}
export function applyMethodDescriptors(
  target, descriptors: SchemaConfigMethods,
) {
  const statics: PropertyDescriptorMap = {};
  const protos: PropertyDescriptorMap = {};
  for ( const desc of Object.values( descriptors ) ) {
    invariant( isMethodDescriptor( desc ) );
    // uniq by name and keep the last entry for each one
    ( desc.is_static ? statics : protos )[ desc.name ] = {
      ...desc.desc, configurable  : true,
    };
  }
  Object.defineProperties( target, statics );
  Object.defineProperties( target.prototype, protos );
}
