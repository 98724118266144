import _ from 'lodash';

import type { TResource } from '~/types';

export type HasMembersFormMessage = {
  message: string | string[];
  level?: 'info' | 'warning';
  condition?: ( resource: TResource<any> ) => boolean;
};

declare module './types' {
  export interface TraitConfigs {
    withFormMessage: HasMembersFormMessage[] | {
      messages?: HasMembersFormMessage[];
      message?: string|string[];
      warning?: string|string[];
    };
  }
  export interface TraitOptions {
    messages: HasMembersFormMessage[];
  }
}

export function buildOptions( { options } ) {
  const message = mkmsg( options.message );
  const warning = mkmsg( options.warning );
  const messages: HasMembersFormMessage[] = options.messages || [];
  if ( message ) messages.push( { message, level : 'info' } );
  if ( warning ) messages.push( { message, level : 'warning' } );
  return { messages };
}

export function buildModelConfig() {

  return {
    methods : class {
      getFormMessages() {
        const opts = this.schema.config.getBehaviorOptions( 'hasMembers' );
        return opts.messages.map( msg => {
          if ( msg.condition && ! msg.condition( this ) ) return null;
          return _.pick( msg, 'message', 'level' );
        } ).filter( x => x );
      }
    },
  };

}

function mkmsg( ...args: ( string | string[] )[] ) {
  const msg = _.filter( _.flattenDeep( args ), _.isString ).join( ' ' ).trim();
  if ( msg.length ) return msg;
}
