import _ from 'lodash';
import { Emitter, hideProps, mkdebug } from '@ssp/utils';

import { isBrokerable } from './utils';

import type { Schema } from '~/core/lib';
import type { Broker } from './Broker';

const debug = mkdebug( 'ssp:database:broker:datamap' );

let counter = 0;
export type DataMapOptions = {
  schema: Schema;
  is_list: boolean;
};

export interface DataMap extends Map<string, any>, Emitter {}
export class DataMap extends Map<string, any> {

  id = `datamap-${counter++}`;
  declare broker?: Broker;
  declare schema: Schema;
  declare is_list: boolean;

  constructor( options: DataMapOptions ) {
    super();
    Emitter.makeEmitter( this );
    hideProps( this, { schema : options.schema } );
    this.is_list = options.is_list;
  }

  set( key: string, value: any ) {
    debug( `${this} Setting`, key, 'to', value );
    if ( this._set( key, value ) ) this.emit( 'set', { key, value } );
    return this;
  }
  _set( key: string, value: any, skip_lock: boolean = false ) {
    debug( `${this} Setting`, key, 'to', value, '(quietly)' );
    if ( ! _.isString( key ) ) {
      throw new TypeError( `Cannot set non-string key "${key}"` );
    }
    const was = this.get( key );
    if ( this.equals( key, was, value ) ) return false;
    if ( this.is_list ) {
      this.lock( `${key} modified` );
    } else {
      const field = this.schema.getField( key );
      if ( field && ! skip_lock ) this.lock( `${key} modified` );
      if ( key === '_id' && this.broker && ! this.broker.id ) {
        this.broker.id = value;
      }
    }
    super.set( key, value );
    if ( this.broker && isBrokerable( value ) ) {
      value.attachBroker( this.broker );
    }
    return true;
  }

  delete( key: string ) {
    debug( `${this} Deleting`, key );
    const res = this._delete( key );
    if ( res ) {
      this.emit( 'delete', key );
    }
    return res;
  }
  _delete( key: string ) {
    debug( `${this} Deleting`, key, '(quietly)' );
    if ( ! this.has( key ) ) return false;
    const value = this.get( key );
    if ( this.broker && isBrokerable( value ) ) {
      value.detachBroker( this.broker );
    }
    this.lock( `${key} deleted` );
    super.delete( key );
    return true;
  }

  equals( key, val1, val2 ) {
    if ( _.isNil( val1 ) && _.isNil( val2 ) ) return false;
    if ( this.schema ) {
      const field = this.schema.getField( key );
      if ( field && field.strictEquals ) {
        return field.strictEquals( val1, val2 );
      }
    }
    return _.isEqual( val1, val2 );
  }

  get( key ) {
    const value = super.get( key );
    return value;
  }

  has( key ) {
    if ( ! super.has( key ) ) return false;
    const value = super.get( key );
    if ( _.result( value, 'isEmpty' ) ) return false;
    if ( _.isUndefined( value ) ) return true;
    if ( _.isNull( value ) ) return false;
    return true;
  }

  clear() {
    debug( `${this} Clearing` );
    if ( this._clear() ) {
      this.emit( 'cleared' );
    }
  }
  _clear() {
    debug( `${this} Clearing (quietly)` );
    if ( this.isEmpty() ) return false;
    if ( this.broker ) {
      for ( const value of this.values() ) {
        if ( isBrokerable( value ) ) value.detachBroker( this.broker );
      }
    }
    this.lock( `data cleared` );
    super.clear();
    return true;
  }

  isEmpty() {
    return Array.from( this.keys() ).length === 0;
  }

  [ Symbol.iterator ]() { return this.entries(); }
  toString() {
    let res = this.id;
    if ( this.broker ) res += `(${this.broker})`;
    return res;
  }

  getBrokerableValues() {
    return Array.from( this.values() ).filter( isBrokerable );
  }

  attachBroker( broker: Broker ) {
    /*
    if ( ! ( broker instanceof Broker ) ) {
      throw new TypeError( `"${broker}" is not a Broker` );
    }
    */
    debug( `${this} Attaching broker ${broker.broker_id}` );
    this.setBroker( broker );
    for ( const val of this.getBrokerableValues() ) val.attachBroker( broker );
  }
  detachBroker( broker: Broker ) {
    debug( `${this} Detaching broker ${this.broker?.broker_id}` );
    if ( this.broker === broker ) this.setBroker();
    for ( const val of this.getBrokerableValues() ) val.detachBroker( broker );
  }
  setBroker( broker?: Broker ) {
    Object.defineProperty( this, 'broker', {
      value         : broker,
      enumerable    : false,
      configurable  : true,
      writable      : false,
    } );
  }

  lock( reason ) {
    if ( this.broker ) {
      debug( `${this} Locking broker` );
      this.broker.lock( reason );
    } else {
      debug( `${this} No broker - cannot lock` );
    }
  }

}
