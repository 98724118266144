import { createBehavior } from '~/core/creators';
import { stringifyError } from '@ssp/utils';
import { getModel } from '~/core';

export type HasErrorInfoOptions = {
  errorFieldName?: string | false;
  errorInfoFieldName?: string | false;
};

declare module './declarations' {
  export interface BehaviorConfigs {
    hasErrorInfo?: HasErrorInfoOptions | boolean;
  }
  export interface BehaviorOptions {
    hasErrorInfo?: HasErrorInfoOptions;
  }
}
createBehavior<HasErrorInfoOptions>( {

  name      : 'hasErrorInfo',
  options   : {
    errorFieldName      : 'error',
    errorInfoFieldName  : 'error_info',
  },
  build() {
    const {
      errorFieldName,
      errorInfoFieldName,
    } = this.options;
    const fields: $TSFixMe = {};
    if ( errorFieldName ) {
      fields[ errorFieldName ] = {
        type     : 'text',
        label    : 'Error',
        optional : true,
        readonly : true,
        summary  : true,
        coerce   : stringifyError,
      };
    }
    if ( errorInfoFieldName ) {
      fields[ errorInfoFieldName ] = {
        type     : 'ErrorInfo',
        label    : 'Error Info',
        optional : true,
        readonly : true,
      };
    }
    return {
      fields,
      methods   : {
        setError( error: Error|null ) {
          if ( errorFieldName ) {
            this[ errorFieldName ] = coerceError( error );
          }
          if ( errorInfoFieldName ) {
            this[ errorInfoFieldName ] = coerceErrorInfo( error );
          }
        },
      },
    };
  },

} );

function coerceError( error: Error|null ) {
  if ( error === null ) return null;
  if ( error instanceof Error ) return stringifyError( error );
  return String( error );
}
function coerceErrorInfo( error: Error|null ) {
  if ( ! ( error instanceof Error ) ) return null;
  return getModel( 'ErrorInfo' ).fromError( error );
}
