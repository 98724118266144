import { Schema } from './lib/Schema';
import { Behavior } from './lib/Behavior';

type Source = Parameters<typeof Schema.get>[0];

export function getSchemas( ...filters: Parameters<typeof Schema['filter']> ) {
  return Schema.filter( ...filters  );
}

/** Retrieve a schema by ID. */
export function getSchema( id: Source ) { return Schema.demand( id ); }

/** Retrieve a model by ID. */
export function getModel( id: Source ) { return Schema.demand( id )?.model; }

/** Retrieve a subdoc by ID. */
export function getSubDocument( id: Source ) {
  const sch = getSchema( id );
  if ( sch.is_subdocument ) return sch.model;
}
export function getSubdoc( id: Source ) {
  return getSubDocument( id );
}
/** Retrieve a resource by ID. */
export function getResource( id: Source ) {
  const sch = getSchema( id );
  if ( sch.is_resource ) return sch.model;
}
export const getComponent = getResource;

/** Retrieve a service by ID. */
export function getService( id: Source ) {
  const sch = getSchema( id );
  if ( sch.is_service ) return sch.model;
}

export function getBehavior( name: string ) { return Behavior.get( name ); }

export function getProject( id: string ) {
  return getModel( 'SSP.Project' ).retrieve( id );
}
export function getUser( id: string ) {
  return getModel( 'SSP.User' ).retrieve( id );
}
