import _ from 'lodash';
import { createBehavior } from '~/core/creators';

export type HasDeletedOptions = Record<string, never>;
declare module './declarations' {
  export interface BehaviorConfigs {
    hasDeleted?: HasDeletedOptions | boolean;
  }
  export interface BehaviorOptions {
    hasDeleted?: HasDeletedOptions;
  }
}

createBehavior<HasDeletedOptions>( {

  name  : 'hasDeleted',
  build() {
    return [
      {
        fields    : {
          deleted_at : {
            type        : 'date',
            label       : 'Deleted At',
            optional    : true,
            metadata    : true,
          },
        },
      },
      BUILD.isServer && {
        events  : {
          async beforeRemove() {
            const { getMongo } = await import( '~/server/transport' );
            const name = `${this.schema.collection}.deleted`;
            // using raw mongo methods because we do not maintain
            // schemas for `.deleted` collections
            const coll = await getMongo().getNonSchemaCollection( name );
            const fields = this.schema.getFields(
              '@all', '-@virtual', '-@computed',
            );
            const data = _.fromPairs( _.map( fields, field => {
              return [ field.name, field.format( this[ field.name ] ) ];
            } ) );

            await coll.insertOne( { ...data, deleted_at : new Date() } );
          },
        },
      },
    ];
  },

} );
