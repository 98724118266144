
declare module './types' {
  export interface TraitConfigs {
    withCreator: {
      default?: boolean;
      /** Configuration to pass when calling `addMember`. */
      config?: any;
    };
  }
}

export function buildModelConfig( { options } ) {
  const configs = [];
  configs.push( {
    fields : {
      add_creator : {
        type       : 'boolean',
        label      : 'Add Yourself?',
        default    : options.default,
        summary    : false,
        metadata   : false,
        form       : {
          type : 'Switch',
        },
        transient  : true,
        createonly : true,
        optional   : true,
      },
    },
  } );
  if ( BUILD.isServer ) {
    const conf = options.config;
    configs.push( {
      events : {
        async beforeInsert() {
          if ( ! ( this.add_creator && ctx.is( 'user' ) ) ) return;
          log.trace( 'ADDING CREATOR:', { conf, user : `${ctx.user}` } );
          await this.addMember( ctx.user, conf, { save : false } );
          log.trace( 'MEMBERS AFTER ADDING CREATOR:', this.members );
        },
      },
    } );
  }
  return configs;
}
export const defaults = { default : true };
