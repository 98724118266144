import _ from 'lodash';
import { faker } from '../faker';

export function sample( ...data ) {
  return faker.helpers.arrayElement( _.flattenDeep( data ) );
}
export function samples( ...data ) {
  data = _.flattenDeep( data );
  const count = _.isInteger( _.last( data ) ) ? data.pop() : 3;
  const res = [];
  let tries = 0;
  while ( res.length < count && tries++ < 50 ) {
    const x = faker.helpers.arrayElement( data );
    if ( ! _.includes( res, x ) ) res.push( x );
  }
  return res;
}

export function chance( percent, yes=true, no=false ) {
  if ( _.isString( percent ) ) {
    percent = parseInt( percent.replace( /\D/gu, '' ) );
  }
  return faker.datatype.number( 100 ) <= percent ? yes : no;
}
