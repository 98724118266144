import { createResource } from '~/core/creators';

createResource( {
  id          : 'SYSTEM.Notification',
  name        : 'SYSTEM Notification',

  fields      : {
    user_id       : {
      type      : 'id',
      label     : 'User ID',
      optional  : true,
    },
    project_id    : {
      type      : 'id',
      label     : 'Project ID',
      optional  : true,
    },
    subject       : {
      type      : 'string',
      label     : 'Subject',
    },
    sent_at       : {
      type      : 'date',
      label     : 'Sent At',
      default() { return new Date(); },
    },
    sent_by       : {
      type      : 'string',
      label     : 'Sent By',
      default() { return ctx.get( 'user._id', 'SYSTEM' ); },
    },
    read_at       : {
      type      : 'date',
      label     : 'Read At',
      optional  : true,
    },
    read_by       : {
      type      : 'string',
      label     : 'Read By',
      optional  : true,
    },
    priority      : {
      type      : 'string',
      label     : 'Priority',
      optional  : true,
    },
    message       : {
      type      : 'string',
      label     : 'Content',
      optional  : true,
    },
    reactions     : {
      type      : 'object',
      label     : 'Reactions',
      default() { return {}; },
    },
  },

} );
