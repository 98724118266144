import _ from 'lodash';
import { hideProps, selectObjects } from '@ssp/utils';

import {
  Field, FieldSet, ListField, LinkField, VirtualField,
} from '~/modules/fields';


import type { LodashIteratee } from '@ssp/ts';

import type { SchemaConfig } from '~/core/lib/SchemaConfig';
import type { AnyField, FieldSelector, FieldOptions } from '~/modules/fields';

export class SchemaFields {

  declare fields: Record<string, AnyField>;
  declare config: SchemaConfig;

  get schema() { return this.config.schema; }

  constructor( config: SchemaConfig ) {
    hideProps( this, { fields : {}, config } );
  }

  addField( field: FieldOptions | Field ) {
    if ( field instanceof Field ) {
      this.fields[ field.name ] = field;
      hideProps( field, { schema : this.schema } );
      return field;
    }
    return this.addField( this.buildField( field ) );
  }

  addFields( fields: Record<string, FieldOptions> ) {
    return _.mapValues( fields, ( conf, name ) => {
      return this.addField( { name, ...conf } );
    } );
  }

  buildField( conf: FieldOptions ) {
    if ( conf.virtual || conf.computed ) return new VirtualField( conf );
    if ( conf.list ) return new ListField( conf );
    if ( conf.type === 'link' ) return new LinkField( conf );
    return new Field( conf );
  }

  getField( name: string | AnyField ) {
    if ( name instanceof Field ) return name;
    return this.fields[ name ];
  }
  hasField( name: string ) { return Boolean( this.fields[ name ] ); }

  hasActualField( name: string ) {
    const field = this.getField( name );
    if ( ! field ) return false;
    if ( field.virtual ) return false;
    return true;
  }
  hasLinkField( name: string ) {
    const field = this.getField( name );
    if ( ! field ) return false;
    return field.link;
  }

  /**
   * Build a {@link FieldSet} for the indicated fields.  If there is
   * only one argument and it is a plain object with a `fields`
   * property then that object will be passed directly to the FieldSet
   * constructor.  Otherwise all the arguments will be passed as the
   * field selectors.
   *
   * Here is a list of useful selectors for finding fields.
   *  *Booleans*
   *  - '@readonly'
   *  - '@optional'
   *  - '@required'
   *  - '@immutable'
   *  - '@minimal'
   *  - '@metadata'
   *  - '@array'
   *  - '@quicksearch'
   *  - '@index'
   *  - '@unique'
   *  - '@identifier'
   *  - '@simple'
   *  - '@list'
   *  *Strings:
   *  - 'name'
   * *Properties*
   *  - '@access:admin'
   *  - '@access:member'
   *  - '@access:support'
   *  - '@access:none'
   *  - '@access:anonymous'
   *  - '@subdocClass.id:Some.SubDocumentType'
   *
   * @returns The fields that matched the filter.
   */
  getFieldSet( ...args: FieldSelector<$TSFixMe>[] ) {
    args = _.compact( _.flattenDeep( args ) );
    // This allows getFields and getFieldNames to default to returning
    // all fields if called with no arguments.  If you really do want
    // to get an empty FieldSet, just use `.getFieldSet( '-@all' )`.
    if ( args.length === 0 ) args = [ '@all' ];
    if ( args.length === 1 && _.isPlainObject( args[ 0 ] ) ) {
      if ( _.isArray( args[ 0 ].fields ) ) {
        return this.getFieldSet( ...args[ 0 ].fields );
      }
    }
    return new FieldSet( { fields : args, schema : this.schema } );
  }

  /**
   * Get all the fields from all applicable schemas, filtering them by
   * some criteria.  This only returns the first field found for
   * a given name, so it won't return duplicates from schemas with
   * children.
   */
  getAllFields( filter?: LodashIteratee ): Field[] {
    const iteratee = _.iteratee( filter );
    const found = this.schema.getAllSchemas()
      .flatMap( schema => _.values( schema.fields ) )
      .filter( iteratee );
    return _.uniqBy( found, 'name' );
  }

  /**
   * Find all the fields that match a selector and return them.
   *
   * @example
   *  // Find all matching fields (can also use '*' instead of '@all'):
   *  schema.getFields( '@all' );
   *  // Find all the fields where the type is `computed`:
   *  schema.getFields( '@computed' );
   *  // Find all the fields where the type is `date`:
   *  schema.getFields( '@type:date' );
   *  // Find all the fields that have a `default` property:
   *  schema.getFields( '@has:default' );
   *  // Find all the fields that have a `default` of `false`:
   *  schema.getFields( { default : false } );
   *  // There is also a shortcut for finding fields with boolean
   *  flags where the flag is true:
   *  schema.getFields( '@readonly' );
   *  schema.getFields( '@metadata' );
   *  schema.getFields( '@quicksearch' );
   *  // Just find the `_id` field:
   *  schema.getFields( '_id' );
   */
  getFields( ...selectors: FieldSelector<$TSFixMe>[] ): Field[] {
    return selectObjects( {
      selectors,
      objects : Object.values( this.fields ),
      names   : [ 'name' ],
      transform( selector ) {
        if ( selector.startsWith( '@type:' ) ) {
          // You can specify fields by type, so to include all the date fields:
          // `fields : [ '@type:date' ]`
          selector = selector.slice( 6 );
          return ( f => _.get( f, 'type.name' ) === selector );
        }
      },
    } );
  }

  /**
   * Calls getFields to find all the fields that match the
   * provided selector, then returns just their names.
   */
  getFieldNames( ...selectors: FieldSelector<$TSFixMe>[] ): string[] {
    const fields = this.getFields( ...selectors );
    return _.uniq( _.map( fields, 'name' ) );
  }

  makeAccessors(): PropertyDescriptorMap {
    return _.mapValues( this.fields, field => field.makeAccessors() );
  }

}
