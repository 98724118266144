/* eslint-disable no-empty-function */
import _ from 'lodash';
// eslint-disable-line no-unused-vars
import { ResultSet } from './ResultSet';
import { mkdebug } from '@ssp/utils';

/** @module DB */
const debug = mkdebug( 'ssp:database:resultset:list' );

export class ListResultSet extends ResultSet {

  static get readonly_options() {
    return super.readonly_options.concat( 'list' );
  }
  static get required_options() {
    return _.without( super.required_options, 'resource' );
  }

  /**
   * The List instance that this resultset is associated with.
   *
   * @property list
   * @type {List}
   * @readonly
   */

  /**
   * The {@link Field} object that this list came from.
   *
   * @property field
   * @type {List}
   * @readonly
   */

  async execute( opts={} ) {
    const keys = Array.from( this.list.keys() );
    return {
      ref_urls  : keys,
      total     : keys.length,
      count     : keys.length,
    };
  }

  async count() { return this.total; }

  _hydrateObject( key ) { return this.list.get( key ); }
  getAllIds() { return this.ref_urls; }

  getIdsAndNames() {
    return Object.fromEntries( this.refs.map( x => ( [ x.id, x.name ] ) ) );
  }

  route( ...args ) { throw new TypeError( `Cannot route to subdoc` ); }

  remove( opts ) {
    return this.map( rsrc => rsrc.remove( opts ) );
  }

  getSortKey() { return this.getSortInfo()[ 1 ]; }
  getSortDirection() { return this.getSortInfo()[ 0 ]; }

}
