import _ from 'lodash';
import { invariant, createNamedClass } from '@ssp/utils';

import type { Squishy } from '@ssp/utils';

import { defineModel } from './lib/SchemaConfig';
import { JobDefinition } from '~/modules/jobs/JobDefinition';
import { Behavior } from './lib/Behavior';

import type { BehaviorDefinition } from './lib/Behavior';
import type { JobDefinitionOptions } from '~/modules/jobs/JobDefinition';
import type { SchemaId } from '~/types';
import type { ModelDefinition } from '~/modules/declarations';
import type { SchemaOptions } from './lib/Schema';

/** Create a new Job Definition */
export function createJob(
  schema: SchemaId,
  options: Omit<JobDefinitionOptions, 'component'|'origin'>,
): JobDefinition;
/*
export function createJob(
  schema: SchemaId[],
  options: JobDefinitionOptions,
): JobDefinition[];
*/
export function createJob(
  // schema: SchemaId | SchemaId[],
  schema: SchemaId,
  options: JobDefinitionOptions,
) {
  if ( Array.isArray( schema ) ) {
    return schema.map( s => createJob( s, options ) );
  }
  return JobDefinition.create( schema, options );
}

/** Create a new subdocument type. */
export function createSubDocument(
  config: ModelDefinition | SchemaOptions,
  ...definitions: Squishy<ModelDefinition>[]
) {
  return defineModel( [ {
    origin         : 'createSubDocument',
    inherit        : 'SubDocument',
    is_subdocument : true,
    ...config,
  }, ...definitions ] );
}

export function createUserResource(
  config: ModelDefinition & SchemaOptions,
  ...definitions: Squishy<ModelDefinition>[]
) {
  return defineModel( [ {
    origin              : 'createUserResource',
    inherit             : 'UserResource',
    is_resource         : true,
    is_user_resource    : true,
    is_project_resource : false,
    ...config,
  }, ...definitions ] );
}
export function createProjectResource(
  config: ModelDefinition & SchemaOptions,
  ...definitions: Squishy<ModelDefinition>[]
) {
  return defineModel( [ {
    origin              : 'createProjectResource',
    inherit             : 'ProjectResource',
    is_resource         : true,
    is_user_resource    : false,
    is_project_resource : true,
    ...config,
  }, ...definitions ] );
}

/** Create a new resource type. */
export function createResource(
  config: ModelDefinition & SchemaOptions,
  ...definitions: Squishy<ModelDefinition>[]
) {
  const is_user = config.is_user_resource
    || config.inherit === 'UserResource'
    || /^\w+\.User$/u.test( config.id );
  const is_project = config.is_project_resource
    || config.inherit === 'ProjectResource';
  invariant( ! ( is_user && is_project ) );
  if ( is_user ) {
    _.defaults( config, {
      inherit             : 'UserResource',
      is_resource         : true,
      is_user_resource    : true,
      is_project_resource : false,
    } );
  } else {
    _.defaults( config, {
      inherit             : 'ProjectResource',
      is_resource         : true,
      is_user_resource    : false,
      is_project_resource : true,
    } );
  }
  return defineModel( [ {
    origin  : 'createResource',
    ...config,
  }, ...definitions ] );
}

/** Create a new service type. */
export function createService(
  config: ModelDefinition & SchemaOptions,
  ...definitions: Squishy<ModelDefinition>[]
) {
  return defineModel( [ {
    origin              : 'createService',
    inherit             : 'ServiceResource',
    is_service          : true,
    is_resource         : false,
    is_user_resource    : false,
    is_project_resource : false,
    ...config,
  }, ...definitions ] );
}

export function createBehavior<
  TOptions extends Record<string, any> = Record<string, never>,
>( ...definitions: ( BehaviorDefinition<TOptions> | null | false )[] ) {
  const def = _.assign( {}, ...definitions );

  invariant( _.isPlainObject( def ), 'Behavior def must be an object' );

  const { name } = def;
  invariant( _.isString( name ), 'Behavior must have a name' );
  invariant( ! Behavior.has( name ), 'Behavior already exists' );

  const behavior = createNamedClass( name, Behavior, `Behavior<${name}>` );
  _.assign( behavior.prototype, def );
  Behavior.registry[ name ] = behavior;
  return behavior;
}
