import _ from 'lodash';
import { hideProps, DatabaseEventError } from '@ssp/utils';
import { isDBRecord, isModel } from '~/utils/types';
import type { Model } from '~/core/lib/Model';

const whens = [ 'before', 'during', 'after' ] as const;
export type When = typeof whens[number];
export function isWhen( val: any ): val is When {
  return whens.includes( val );
}

export interface EventOptions {
  /**
   * Set to true if this event can be canceled.  To cancel the event,
   * call `event.preventDefault()` or `event.cancel()`.
   */
  cancelable?: boolean;

  /**
   * Set to false if the event should not propagate into subdocuments.
   */
  propagates?: boolean;

  /**
   * Set this to `async` for events that need async handlers, the
   * default value is `sync`.
   */
  mode?: 'sync' | 'async';

  handled?: string[] | Record<string, any>;

  [key: string]: any;
}

export interface Event {
  [key: string]: any;
}
export class Event {

  static readonly whenOrder: When[] = [ 'before', 'during', 'after' ];
  static defaultOpts: Record<When, EventOptions> = {
    before  : { cancelable : true },
    during  : { cancelable : false },
    after   : { cancelable : false },
  } as const;

  /**
   * The type of event ("beforesave", "afterupdate", etc)
   */
  type: string;

  /** Just the "when" part of the name ("before", "during", "after") */
  when: When;

  /** Just the non-when part of the name ("save", "update", "delete") */
  name: string;

  /**
   * Set to true if this event can be canceled.  To cancel the event,
   * call `event.preventDefault()` or `event.cancel()`.
   */
  readonly cancelable: boolean;

  /**
   * Set to false if the event should not propagate into subdocuments.
   */
  readonly propagates: boolean = true;

  /**
   * Set this to `async` for events that need async handlers, the
   * default value is `sync`.
   */
  readonly mode: 'sync' | 'async' = 'sync';

  currentTarget: Model | typeof Model;
  primaryTarget: Model | typeof Model;

  get sync() { return this.mode === 'sync'; }
  get async() { return this.mode === 'async'; }

  get whenidx() { return _.findIndex( Event.whenOrder, this.when ); }

  /** Get the active target of the event. */
  get target() {
    if ( this.currentTarget !== null ) return this.currentTarget;
    return this.currentTargetModel;
  }

  /**
   * This will be set to true if the event was canceled.
   */
  defaultPrevented: boolean = false;

  /**
   * This will be set to true if propagation was stopped with the
   * {#stopPropagation} method.
   */
  propagationStopped: boolean = false;

  /**
   * This will be set to true if immediate propagation was stopped
   * with the {#stopImmediatePropagation} method.
   */
  immediatePropagationStopped: boolean = false;

  currentTargetModel: typeof Model;
  primaryTargetModel: typeof Model;

  get currentSchema() { return this.currentTargetModel?.schema; }
  get primarySchema() { return this.primaryTargetModel?.schema; }
  get schema() { return this.currentSchema; }

  get identity() {
    const ident = [ 'Event', this.type ];
    const schema = this.currentSchema?.id;
    const topschema = this.primarySchema?.id;
    if ( schema ) ident.push( 'from schema', schema );
    if ( schema !== topschema ) ident.push( `(child of ${topschema})` );
    return ident.join( ' ' );
  }

  readonly timestamp: number = Date.now();

  constructor( target, type, data ) {
    _.assign( this, data );

    this.type = type.toLowerCase();

    hideProps( this, {
      currentTargetModel : undefined,
      primaryTargetModel : undefined,
    } );
    if ( ! target ) {
      throw new DatabaseEventError( {
        message : 'Event must have target',
        tags    : { event : type },
        data    : { target, type, data, identity : this.identity },
      } );
    } else if ( isDBRecord( target ) ) {
      this.primaryTarget = this.currentTarget = target;
      this.primaryTargetModel = this.currentTargetModel = target.constructor;
    } else if ( isModel( target ) ) {
      this.primaryTargetModel = this.currentTargetModel = target;
      this.primaryTarget = this.currentTarget = null;
    } else {
      throw new DatabaseEventError( {
        message : `Event target must be DB Model or Record`,
        tags    : { event : type },
        data    : { target, type, data, identity : this.identity },
      } );
    }
    if ( ! this.currentTargetModel ) {
      throw new DatabaseEventError( {
        message : `Unable to determine targetModel for Event`,
        tags    : { event : type },
        data    : { target, type, data, identity : this.identity },
      } );
    }

    const x = /^(before|during|after)(\w+)$/u.exec( this.type );
    if ( ! x ) {
      throw new DatabaseEventError( {
        message : `Invalid event type ${this.type}`,
        tags    : { event : type },
        data    : { target, type, data, identity : this.identity },
      } );
    }

    const [ w, n ] = x.slice( 1 );
    if ( w === 'before' || w === 'during' || w === 'after' ) this.when = w;
    this.name = n;

    _.defaults( this, Event.defaultOpts[ this.when ] );
  }

  /**
   * Cancel this event, preventing the default action from occurring.
   */
  preventDefault() { this.defaultPrevented = true; }

  /**
   * Cancel this event, preventing the default action from occurring.
   */
  cancel() { this.preventDefault(); }

  /**
   * Stop propagation on this event, so that the event will not
   * propagate into subdocuments.
   */
  stopPropagation() { this.propagationStopped = true; }

  /**
   * Stop propagation on this event, so that the event will not
   * propagate into subdocuments, but also stop the event from
   * continuing to process events of the same type on the same level.
   */
  stopImmediatePropagation() {
    this.immediatePropagationStopped = true;
    this.stopPropagation();
  }

  get isPrimary() { return this.currentTarget === this.primaryTarget; }
  /**
   * Returns true if the event is being processed for a subdocument.
   * You really only need to use this when defining an event for
   * a module or somewhere else that the event will end up being
   * attached to subdocument classes as well as resource classes.
   */
  get isSubDocument() { return this.currentTarget !== this.primaryTarget; }
  get isSecondary() { return this.currentTarget !== this.primaryTarget; }

}
