import DB from '~';
import { createResource } from '~/core/creators';

createResource( {
  id               : 'Slack.Channel',
  name             : 'Slack Channel',
  attachable       : [ { type : 'SSP.Project' } ],
  service_id_field : 'slack_id',
  icon             : 'fab:slack',
  featured         : true,
  fields           : {
    slack_id        : {
      type        : 'slack-channel-id',
      summary     : true,
      optional    : true,
      index       : true,
      unique      : true,
      identifier  : true,
      readonly    : true,
      quicksearch : true,
      // intentionally left mutable, ids change if channel is moved
      immutable   : false,
    },
    workspace : {
      type        : 'link',
      summary     : true,
      createonly  : true,
      label       : 'Workspace',
      help        : 'The workspace managing this channel',
      model       : 'Slack.Workspace',
      resultset( resource, field ) {
        const { project_id } = resource;
        return DB.Slack.Workspace.find(
          { $or : [ { project_id }, { is_open_create : true } ] },
          { minlength : false },
        );
      },
    },
    name            : {
      type        : 'slack-channel-name',
      label       : 'Channel Name',
      index       : true,
      quicksearch : true,
    },
    purpose         : {
      type        : 'text',
      summary     : true,
      optional    : true,
      label       : 'Description',
      quicksearch : true,
    },
    is_private         : {
      type        : 'boolean',
      default     : true,
      summary     : true,
      createonly  : true,
      label       : 'Private',
      face : {
        type       : 'BooleanIcon',
        label      : 'Access',
        trueIcon   : 'far:lock',
        falseIcon  : 'far:lock-open',
        trueLabel  : 'Private',
        falseLabel : 'Public',
      },
    },
    is_shared        : {
      type        : 'boolean',
      label       : 'Shared?',
      optional    : true,
      readonly    : true,
      badge       : { label : 'Shared' },
    },
    is_org_shared    : {
      type        : 'boolean',
      label       : 'OrgShared?',
      optional    : true,
      readonly    : true,
    },
    is_deleted       : {
      type        : 'boolean',
      label       : 'Deleted?',
      optional    : true,
      readonly    : true,
      badge       : { label : 'Deleted', color : 'danger' },
    },
  },
  methods   : class {
    static async getRestrictQuery( subject, _req, _ev ) {
      const ids = await subject.getProjectIds();
      return { $or : [
        { project_id : { $in : ids } },
        { is_open_create : true },
      ] };
    }
  },
  behaviors : {
    hasUnmanageable : {},
    hasExternalLink : {},
    hasMembers      : {
      withCreator : {},
      userType    : 'Slack.User',
      withService : true,
    },
    hasArchived     : {},
  },
  indexes : {
    workspace_id_name_idx : {
      fields  : { 'workspace.id' : 1, 'name' : 1 },
      partialFilterExpression : { 'workspace.id' : { $exists : true } },
      unique  : true,
    },
  },
  faces         : {
    'list.summary' : [
      {
        layout : {
          type       : 'ItemsBetweenTitleAndIcons',
          iconize    : [ 'is_private' ],
        },
        fields  : [ 'workspace' ],
      },
      {
        layout : 'ItemsAndBadges',
        fields  : [ 'description', '@badge' ],
      },
    ],
  },
} );
