// Automatically generated, DO NOT EDIT!
// To update run "yarn do-updates"
/* eslint-disable */
import { Model } from '~/core/lib/Model';
Model.demand( 'Jira.User' ).extend( {
  "actions": {
    "disable": {
      "access": "support",
      "icon": "far:lock",
      "id": "disable",
      "job_name": "disable",
      "label": "Disable Account",
      "name": "disable",
      "params": {
        "ignore_already_disabled": {
          "demand": false,
          "describe": "Ignore accounts that are already disabled",
          "type": "boolean"
        }
      },
      "type": "instance",
      "visibilityMethod": "!is_disabled"
    },
    "discover": {
      "access": "support",
      "icon": "far:search-plus",
      "id": "discover",
      "job_name": "discover",
      "label": "Discover",
      "name": "discover",
      "type": "class"
    },
    "enable": {
      "access": "admin",
      "icon": "far:unlock",
      "id": "enable",
      "job_name": "enable",
      "label": "Enable Account",
      "name": "enable",
      "type": "instance",
      "visibilityMethod": "is_disabled"
    },
    "import": {
      "access": "support",
      "icon": "far:file-import",
      "id": "import",
      "job_name": "import",
      "label": "Import",
      "name": "import",
      "params": {
        "jira_id": {
          "describe": "Jira user key",
          "required": false,
          "type": "string"
        },
        "username": {
          "describe": "Username",
          "required": false,
          "type": "string"
        }
      },
      "type": "class"
    },
    "sync": {
      "access": "admin",
      "icon": "fas:sync",
      "id": "sync",
      "job_name": "sync",
      "label": "Sync",
      "name": "sync",
      "type": "instance"
    }
  }
} );
