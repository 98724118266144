import DB from '~';
import { createResource } from '~/core/creators';

createResource( {
  id               : 'GitHub.Repository',
  name             : 'GitHub Repository',
  attachable       : [ 'SSP.Project' ],
  service_id_field : 'github_id',
  icon             : 'fab:github',
  featured         : true,
  fields           : {
    github_id       : { // GitHub database ID
      type        : 'github-id',
      label       : 'GitHub Id',
      summary     : true,
      unique      : true,
      readonly    : true,
      optional    : true,
      immutable   : true,
    },
    name            : {
      type        : 'string',
      label       : 'Name',
      help        : `The repository's name.`,
      quicksearch : true,
      validators  : {
        minlength : 1,
        maxlength : 100,
        regexp    : /^[\w\-.]+$/iu,
      },
      faker       : 'gh-{{words.adjective}}-{{words.noun}}',
    },
    organization : {
      type        : 'link',
      label       : 'Organization',
      help        : 'The organization that will manage this repository.',
      summary     : true,
      quicksearch : true,
      createonly  : true,
      model       : 'GitHub.Organization',
      resultset( resource, field ) {
        return DB.GitHub.Organization.find(
          { project_id : resource.project_id },
          { minlength : false },
        );
      },
    },
    description      : {
      type        : 'string',
      label       : 'Description',
      summary     : true,
      optional    : true,
      quicksearch : true,
    },
    is_private         : {
      type        : 'boolean',
      default     : true,
      summary     : true,
      help        : [
        'Check to create a private repository,',
        'otherwise it will be public.',
      ],
      createonly  : true,
      label       : 'Private',
      face : {
        type           : 'BooleanIcon',
        label          : 'Access',
        trueIcon       : 'far:lock',
        falseIcon      : 'far:lock-open',
        trueLabel      : 'Private',
        falseLabel     : 'Public',
      },
    },
    homepage         : {
      type        : 'string',
      label       : 'Homepage',
      optional    : true,
      readonly    : true,
    },
    has_issues         : {
      type        : 'boolean',
      label       : 'Has Issues',
      optional    : true,
      readonly    : true,
    },
    has_wiki         : {
      type        : 'boolean',
      label       : 'Has Wiki',
      optional    : true,
      readonly    : true,
    },
    has_pages         : {
      type        : 'boolean',
      label       : 'Has Pages',
      optional    : true,
      readonly    : true,
    },
    is_fork           : {
      type        : 'boolean',
      label       : 'Forked Repo',
      optional    : true,
      readonly    : true,
    },
    is_fork_of   : {
      type        : 'string',
      label       : 'Forked from',
      optional    : true,
      readonly    : true,
    },
    default_branch    : {
      type        : 'string',
      label       : 'Default Branch',
      optional    : true,
      readonly    : true,
    },
  },
  behaviors     : {
    hasExternalLink     : {},
  },
  methods : class {
    getParent() { return this.organization; }
  },
  faces         : {
    'card.summary' : [
      {
        layout : 'Badges',
        fields : [ '@badge' ],
      },
      {
        layout : 'LabelsAndValues',
        fields : [
          'is_private', 'organization', 'external_link', 'description',
        ],
      },
    ],
    'list.summary' : [
      {
        layout : {
          type       : 'ItemsBetweenTitleAndIcons',
          iconize    : [ 'is_private' ],
        },
        fields : [ '-@all' ],
      },
      {
        layout : 'ItemsAndBadges',
        fields  : [ 'description', 'organization', '@badge' ],
      },
    ],
  },
} );
