import _ from 'lodash';
import { hideProps } from '@ssp/utils';
import { Field, FieldOptions } from './Field';
import { isSchema } from '~/utils';

import type { Schema } from '~/core/lib/Schema';
import type { LodashIteratee } from '@ssp/ts';
import type { SelectorsFor } from '@ssp/utils';

/**
 * Any options that can be provided to the `Field` constructor.  Note
 * that some options have more complex interaction with the field and
 * may result in unexpected behavior (in particular, you should not
 * attempt to change a fields `type` this way).
 */
export interface FieldConfig extends Partial<Omit<FieldOptions, 'name'>> {
  /** Field name. */
  name: string;
}

/**
 * A FieldSelector is used to indicate which fields you want to
 * include or exclude from a FieldSet.  The values that it accepts
 * are:
 *
 * - The name of any field in the Schema to add those specific fields.
 * - `*` or `@all` will add all fields from the schema
 * - `@` followed by the name of any property on `Field`, will add
 *   any fields where the value of that property is truthy.
 * - `@has:` followed by the name of any Field property will add any
 *   fields where the value of that property is not undefined or null.
 * - `@type:` followed by the name of a `Type` (from the `@ssp/types`
 *   package) will select all fields that have that type.
 * - `@<name>:<value>` will select fields where the stringified values
 *   of the `name` property is `value`.
 *
 * Any of the preceding items can be prefixed with `!` to invert the
 * meaning.  So `@readonly` means "select all the readonly fields"
 * while `!@readonly` means "select all the fields that are not marked
 * readonly".
 *
 * When calling a method that builds a FieldSet (such as {@see
 * Schema#getFieldsSet}, {@see Schema#getFields}, or {@see
 * Schema@getFieldNames}, you can prefix any of the preceding with
 * a `-` to indicate you want all the matching fields *removed* from
 * the resulting FieldSet. (Yes, you can combine them and use `-!` to
 * mean "remove all the fields that don't match this selector).
 *
 * When using string selectors also note that if the first selector
 * passed starts with a `-` then we implicitly add `@all` to the
 * beginning of your selector list (since you would otherwise have
 * nothing to remove from).
 *
 * Also, any string selectors that refer to property names can use
 * dot-separated names to select based on sub-object properties.
 *
 * In addition to the string selectors listed above, you can also
 * include:
 *
 * - Functions: Any functions will be called once for each `Field` in
 *   the `Schema` and passed that `Field` instance as an argument.  If
 *   the function returns a truthy value the field will be included in
 *   the FieldSet.
 * - A plain object containing a subset of Field properties, which
 *   will use the lodash `_.iteratee` method to create a filter function
 *   which will then be applied as just described for functions.
 *
 * Finally, you can include arrays (even nested arrays) of any
 * combination of any of the things listed.
 *
 * @example
 * // Note that some of these selectors are just for illustration
 * purposes and wouldn't actually make sense in real code.
 * DB.SSP.Project.getFieldSet(
 *   // By starting out with a `-` selector we implicitly start with `@all`
 *   '-@computed', // remove computed fields
 *   '@computed.set', // add back computed fields that have a defined setter
 *   '-!@has:type', // remove any fields that don't have a type property
 *   '-@boring', // exclude boring fields
 *   '-!@type.exciting', // exclude fields that don't have an exciting type
 * );
 */
export type FieldSelector<S extends Schema = Schema>
  = SelectorsFor<Field, keyof S['fields'], 'name'>;

export type FieldConfigs<S extends Schema> =
  | FieldConfig
  | FieldSelector<S>
  | FieldConfigs<S>[];

export function isFieldConfig( value: any ): value is FieldConfig {
  return _.isPlainObject( value ) && _.isString( value.name );
}
export type FieldConfigTransform<
  T extends Record<string, any> = Record<string, unknown>
> = ( data: T, field: Field ) => T;

export interface FieldSetOptions<S extends Schema> {
  /** Schema to get fields from. */
  schema: S;
  /** Initial field selectors to populate the FieldSet with. */
  fields?: FieldConfigs<S>;
}

/**
 * This class gives you a way to manage sets of schema fields.  This
 * is intended to be useful for things like selecting which of
 * a subset of fields to include in a result and report generation.
 *
 * There is a good example of how to use this in the `generator`
 * module, where you can specify what fields you want to include when
 * generating a random resource for development.
 *
 * This also allows you to add or override the configuration being
 * used for a field.  This is used heavily by the faces module, to
 * allow you to indicate not just which fields to include, but to
 * override their configuration on a per-face basis when you need to.
 */
export class FieldSet<S extends Schema> {

  schema: S;
  _fields: Record<string, FieldConfig> = {};
  selectors: FieldSelector<S>[] = [];

  /**
   * Create a new FieldSet instance.  You should not call this
   * directly, you should call `.getFieldSet()` on the appropriate
   * `schema` instance.
   */
  constructor( options: FieldSetOptions<S> ) {
    // eslint-disable-next-line prefer-const
    let { schema, fields, ...opts } = options;
    if ( isSchema( schema ) ) {
      this.schema = schema;
      hideProps( this, { schema } );
    } else {
      // If you are getting this error it means you need to use
      // `schema.getFieldSet()` instead of creating a FieldSet object
      // directly..
      throw new Error( `FieldSet requires schema` );
    }
    hideProps( this, { _fields : {} } );
    _.assign( this, opts );
    if ( fields ) {
      // If the first argument is a removal, then assume that we
      // wanted to start with '@all'
      if ( _.isString( fields[0] ) && fields[0].startsWith( '-' ) ) {
        this.fields( '@all', fields );
      } else {
        this.fields( fields );
      }
    }
  }

  /** Returns the number of fields referenced by this FieldSet. */
  get length() { return _.size( this._fields ); }
  get size() { return _.size( this._fields ); }
  /** Returns true if this FieldSet contains no fields. */
  get empty() { return this.isEmpty(); }
  /** Returns true if this FieldSet contains no fields. */
  isEmpty() { return this.length === 0; }

  /**
   * Get a field by name.  This only returns the override
   * configuration, not an actual Field instance.
   *
   * @param name - Field name to return.
   */
  get( name: string ) { return this._fields[ name ]; }

  /**
   * Get a named field from the fieldset.  If the fieldset includes
   * override configuration for this field, then a cloned field with
   * those overrides applied will be returned.
   *
   * @param name - Field name.
   */
  getField( name: string ) {
    const field = this.schema.getField( name );
    const conf = this.get( name );
    if ( _.size( conf ) === 1 ) return field;
    return field.clone( conf );
  }

  /**
   * Return a {@link Field} object for each field in this fieldset.
   * If there was override configuration provided for the field
   * that are part of this FieldSet, it will be applied.
   */
  getFields() {
    return _.compact( _.map( this._fields, f => this.getField( f.name ) ) );
  }

  /**
   * Returns all the fields, just like `getFields` does, but as an
   * object where the field names are the keys.
   */
  getFieldsObject() { return _.keyBy( this.getFields(), 'name' ); }

  /**
   * Return just the override configs for all fields.
   */
  getConfigs() { return _.values( this._fields ); }

  /**
   * Get a named field from the fieldset.  If the fieldset includes
   * override configuration for this field, then a cloned field with
   * those overrides applied will be returned.
   *
   * @param name - Field name.
   */
  field( name: string ) {
    return this.getField( name );
  }

  /**
   * This is the preferred way to change the fields that comprise this
   * FieldSet.  You can call this with multiple strings or objects
   * that are field selectors or field override configurations.  If
   * they are field selectors that are prefixed with a `-` then those
   * fields will be removed from the FieldSet, otherwise those fields
   * will be added.  If you provide a field config object for a field
   * already in the FieldSet, then the configuration for the field
   * will be updated.
   *
   * @param fields - The fields to add, remove or
   * configure.
   */
  fields( ...fields: ( FieldConfigs<S> | FieldSelector<S> )[] ) {
    const add = ( config: FieldConfig | Field ) => {
      if ( config instanceof Field ) config = { name : config.name };
      if ( ! config.name ) {
        throw new Error( 'Cannot add Field to FieldSet without name' );
      }
      if ( ! this._fields[ config.name ] ) {
        this._fields[ config.name ] = { name : config.name };
      }
      _.assign( this._fields[ config.name ], config );
    };
    const del = ( field: string | Field ) => {
      if ( field instanceof Field ) field = field.name;
      delete this._fields[ field ];
    };
    fields.flat( Infinity ).filter( Boolean ).forEach( field => {
      if ( field instanceof Field ) return add( field );
      if ( isFieldConfig( field ) ) return add( field );
      if ( _.isFunction( field ) || _.isPlainObject( field ) ) {
        return this.schema.getFields( field ).forEach( add );
      }
      ( this.selectors || ( this.selectors = [] ) ).push( field );
      if ( _.isString( field ) && field.startsWith( '-' ) ) {
        return this.schema.getFields( field.slice( 1 ) ).forEach( del );
      } else {
        return this.schema.getFields( field ).forEach( add );
      }
    } );
  }

  add( ...fields: FieldConfigs<S>[] ) {
    return this.fields( ...fields );
  }
  remove( ...fields: FieldSelector<S>[] ) {
    const names = this.schema.getFieldNames( ...fields )
      .map( name => name.replace( /^-?/u, '-' ) );
    return this.fields( ...names as FieldSelector<S>[] );
  }

  /**
   * Return an array containing the names of all the fields in this
   * FieldSet.
   */
  getNames() { return _.map( this._fields, 'name' ); }

  /**
   * Flatten the configuration for all the fields in this FieldSet
   * based on a subconfig key.  This is used by things like forms and
   * the faces module to take a Field configuration that has overrides
   * specific to that type, and "flatten" them down into a single
   * configuration that includes those overrides.
   *
   * @param {string} what - The subconfig field to flatten on.
   * @param {Function} [transform] - A transform function to run on
   * the final data before returning it.
   */
  flatten( what: string, transform?: FieldConfigTransform ) {
    return _.omitBy( this.mapValues( field => {
      const data = {};
      const add = ( obj ) => {
        if ( ! _.isObject( obj ) ) return;
        _.each( obj, ( val, key ) => {
          key = key.replace( /^_/u, '' );
          if ( _.isNil( val ) || ! _.isNil( data[ key ] ) ) return;
          data[ key ] = _.cloneDeep( val );
        } );
      };
      const copy = ( ...paths ) => {
        _.each( paths, path => add( _.get( field, path ) ) );
      };
      if ( what && field ) {
        // Copy 'form'/'face' from the field itself and it's type
        copy( what, `type.${what}` );
      }
      if ( field ) {
        // These are things we always copy from the type if they are
        // there (they can get overwritten by the field config later)
        copy( 'type.faker', 'faker' );
        // These are things we don't need to be included in the
        // flattened config (because we handle them separately as
        // needed below)
        add( _.omit( field.toJSON(), 'form', 'face', 'export' ) );
      }
      hideProps( data, { _field : field } );
      if ( transform ) transform( data, field );
      return data;
    } ), _.isNil );
  }

  /**
   * Exclude the given selectors, unless they were explicitly included.
   */
  defaultExclude( ...selectors: FieldSelector<S>[] ) {
    for ( const selector of selectors ) {
      if ( ! this.selectors?.includes( selector ) ) {
        this.fields( `-${selector}` );
      }
    }
  }

  map( iteratee: LodashIteratee ) {
    return _.map( this.getFields(), iteratee );
  }

  mapValues( iteratee: LodashIteratee ) {
    return _.mapValues( this.getFieldsObject(), iteratee );
  }

}
