import _ from 'lodash';
import { faker } from '../faker';
import { id as rsrc_id } from './resource';

export function id() { return rsrc_id( 'SSP.User' ); }

let i = 1;
export function employee_id() {
  // const num = faker.datatype.number( 9999 ).toString();
  // return `99${_.padStart( num, 4, '0' )}`;
  return `99${_.padStart( i++, 4, '0' )}`;
}

export function name() { return faker.name.fullName(); }
