import _ from 'lodash';

import { Schema } from '~/core/lib/Schema';

import type {
  MongoIndexOptions, IndexFieldsSpec, IndexFieldDirectionSpec,
  SimplifiedIndexInfo, IndexFieldString, PartialFilterExpression,
} from './types';

export class MongoIndex {

  static fromDB?( schema: Schema ): Promise<MongoIndex[]>;

  /**
   * These are keys that can be specified as options when creating
   * a MongoIndex instance.
   */
  static optkeys = [
    'name', 'schema', 'fields', 'key', 'unique', 'origin', 'v', 'ns',
    'version', 'sparse', 'partialFilterExpression', 'filter', 'options',
    'behavior',
  ];
  static diffkeys = [ 'fields', 'unique', 'filter', 'version', 'sparse' ];

  static getIndexes(
    { schema, name }: {
      schema?: string | string[];
      name?: string | string[];
    } = {},
  ) {
    const schemas = Schema.filter( 'is_any', { schema } );
    const indexes = schemas.flatMap( s => s.getIndexes() );
    if ( name?.length ) _.remove( indexes, i => ! name.includes( i.name ) );
    return indexes;
  }

  v = 2;
  get version() { return this.v; }
  set version( version ) { this.v = version; }

  fields = {};

  /** Create a unique index. */
  unique: boolean = false;

  /**
   * Create a sparse index (which means only indexing this values for
   * rows where it exists, which allows you to have a unique but
   * optional index, without it trying to limit you to only having one
   * record where the value is null).
   *
   * Although MongoDB has a `sparse` option for creating indexes, this
   * doesn't actually use that, we translate this into
   * a `partialFilterExpression` instead when applying it.
   */
  sparse: boolean = false;

  /** Index name. */
  name: string;

  /**
   * If specified, the index only references documents that match the
   * filter expression.
   *
   * @type {PartialFilterExpression}
   */
  get partialFilterExpression() { return this.filter; }
  set partialFilterExpression( filter ) { this.filter = filter; }
  /**
   * Shorter alias for `partialFilterExpression`.
   *
   * @type {PartialFilterExpression}
   */
  get filter() { return this.#filter; }
  set filter( filter ) {
    if ( filter && _.isEqual( filter, this.sparse_filter_expression ) ) {
      this.sparse = true;
    } else {
      this.#filter = filter;
    }
  }
  #filter;

  get sparse_filter_expression() {
    if ( _.size( this.fields ) === 1 ) {
      const [ key ] = _.keys( this.fields );
      return { [key] : { $exists : true } };
    }
  }

  /**
   * @property collation
   *
   * Specify collation options.
   *
   * @type {CollationDocument}
   */

  /**
   * Construct a new MongoIndex instance.  In many cases this accepts
   * two different names for the same option, because one is the name
   * used in a field configuration, and the other is the name used by
   * Mongo itself.
   *
   * @param {MongoIndexOptions} options - Options object.
   */
  constructor( options: MongoIndexOptions ) {
    if ( options.options ) {
      _.assign( options, options.options );
      delete options.options;
    }

    const extra = _.omit( options, MongoIndex.optkeys );
    if ( ! _.isEmpty( extra ) ) log.debug( 'UNUSED INDEX OPTIONS:', extra );

    const {
      name, fields, key, schema, ns, ...opts
    } = _.pick( options, MongoIndex.optkeys );
    if ( ! _.isString( name ) ) {
      log.debug( 'MongoIndex must have a name', options );
      throw new TypeError( `MongoIndex must have a name` );
    }
    if ( ! ( schema || ns ) ) {
      log.debug( 'NO SCHEMA:', options );
      throw new Error( `MongoIndex requires schema or ns` );
    }
    this.name = name;

    // _id fields have special handling because:
    //  * They are always named `_id_`.
    //  * They are always on only the `_id` field.
    //  * They are always unique.
    if ( name === '_id' || name === '_id_' ) {
      this.name = '_id_';
      this.unique = true;
      this.addFields( { _id : 1 } );
    }
    if ( fields ) {
      this.addFields( fields );
    } else if ( key ) {
      this.addFields( key );
    } else {
      this.addFields( { [ name ] : 1 } );
    }
    _.assign( this, opts );
  }

  /**
   * Add fields to the index.
   *
   * @param args - The fields to add to the index. This can be an
   * array of strings with optional leading `+` or `-` to indicate the
   * sort direction, or it can be an object where the keys are the
   * fields and the values indicate the sort direction.
   */
  addFields( ...args: ( string|string[]|IndexFieldsSpec )[] ) {
    _.each( args, arg => {
      if ( _.isNil( arg ) ) return;
      if ( _.isArray( arg ) ) {
        return _.map( arg, a => this.addFields( a ) );
      } else if ( _.isString( arg ) ) {
        return this.setField( arg );
      } else if ( _.isObject( arg ) ) {
        return _.map( arg, ( v, k ) => this.setField( k, v ) );
      } else {
        throw new TypeError( `Invalid argument "${arg}" to Index.addFields` );
      }
    } );
  }

  /**
   * Set the direction of a field in the index.
   *
   * @param field - The field to set.  This can be prefixed with `+`
   * or `-` to indicate direction, in which case you don't need to
   * specify the `direction` parameter.
   * @param direction - The direction to sort this field.
   */
  setField( field: string, direction?: IndexFieldDirectionSpec ) {
    if ( _.isNil( direction ) ) {
      const char1 = field[0];
      if ( char1 === '+' || char1 === '-' ) {
        direction = char1;
        field = field.slice( 1 );
      }
    }
    const plus = [ '+', 'asc', 1, true, undefined, null ];
    const minus = [ '-', 'desc', -1, false ];
    if ( minus.includes( direction ) ) {
      this.fields[ field ] = '-';
    } else if ( plus.includes( direction ) ) {
      this.fields[ field ] = '+';
    } else {
      throw new Error( `Invalid index direction "${direction}"` );
    }
  }

  /**
   * Simplify the index down to the bare minimum information.  Mostly
   * used when comparing indexes.
   */
  simplify(): SimplifiedIndexInfo {
    return _.omitBy( {
      name    : this.name,
      fields  : this.fieldsList.join( ', ' ),
      ..._.pick( this, 'unique', 'filter', 'version', 'sparse' ),
    }, _.isNil ) as SimplifiedIndexInfo;
  }

  /**
   * Get the fields of the index as an array of strings, with `+` and
   * `-` prefixes.
   */
  get fieldsList(): IndexFieldString[] {
    return _.map( this.fields, ( dir, name ) => {
      return dir + name as IndexFieldString;
    } );
  }

  /**
   * Compare this index with another `MongoIndex` instance, returning
   * an array of the key names of the properties where they differ.
   *
   * @param other - The other index instance to compare with.
   * @returns A list of the property names for the properties where
   * the values are different.
   */
  diff_keys( other: MongoIndex ): string[] {
    if ( ! ( other instanceof MongoIndex ) ) return [ ...MongoIndex.diffkeys ];
    return _.reject( MongoIndex.diffkeys, key => {
      return _.isEqual( this[key], other[key] );
    } );
  }

  /**
   * Compare this index with another `MongoIndex` instance and return
   * a boolean indicating if they are the same.
   *
   * @param other - The other index instance to compare with.
   */
  equals( other: MongoIndex ): boolean {
    const diffs = this.diff_keys( other );
    return diffs.length === 0;
  }

  /**
   * Translate this `MongoIndex` into a JSON document suitable for
   * passing to MongoDB's `createIndexes` method.
   */
  toMongo() {
    const idx: {
      name: string;
      key: Record<string, -1|1>;
      partialFilterExpression?: PartialFilterExpression;
      unique?: boolean;
    } = {
      name  : this.name,
      key   : _.mapValues( this.fields, dir => ( dir === '-' ? -1 : 1 ) ),
    };
    if ( this.filter ) {
      idx.partialFilterExpression = this.filter;
    } else if ( this.sparse ) {
      idx.partialFilterExpression = { [this.name] : { $exists : true } };
    }
    if ( this.unique ) idx.unique = true;
    return idx;
  }

  /**
   * Given two `MongoIndex` instances, return an array of `[ key,
   * value1, value2 ]` tuples that represent the properties where they
   * differ and the value of that property for each of the two
   * instances.
   *
   * @param one - The first `MongoIndex` to compare.
   * @param two - The other `MongoIndex` to compare.
   * @returns {[ string, any, any ][]}
   */
  static diff( one: MongoIndex, two: MongoIndex ) {
    const a = one ? one.simplify() : {};
    const b = two ? two.simplify() : {};
    return MongoIndex.diffkeys.map( key => ( [ key, a[key], b[key] ] ) );
  }
}

if ( BUILD.isServer ) {
  /**
   * Given a `Schema` (or anything that `Schema.get` can turn into
   * a `Schema`), return an array of `MongoIndex` instances for the
   * indexes that currently exist on the collection related to that
   * schema.
   *
   * @param {string|Schema} schema - The schema to get indexes for.
   * @returns {Promise<MongoIndex[]>} - The existing indexes on that
   * collection.
   */
  MongoIndex.fromDB = async function fromDB( source ) {
    const { getMongo } = await import( '~/server/transport' );
    const schema = Schema.demand( source );
    try {
      const coll = await getMongo().getCollection( schema.collection );
      const exist = await coll.indexes();
      return _.compact( _.map( exist, x => new MongoIndex( {
        schema, ...x,
      } ) ) );
    } catch ( err ) {
      log.error( `Error getting indexes from DB:`, err );
      return [];
    }
  };
}
