import _ from 'lodash';
import { jsonParse, jsonStringify, hideProps, mkdebug } from '@ssp/utils';

import { getOrigins } from '~/modules/origins';
import { Schema } from '~/core/lib/Schema';
import { isResourceTypeName, isResource } from '~/utils/types';
import { TransportResponse } from '~/lib/TransportResponse';

import { getCache } from './cache';
import { createCacheKey } from './utils';

import type { TransportResponseOptions } from '~/lib/TransportResponse';


const debug = mkdebug( 'ssp:database:origins:version' );

export class Version {

  /** Resource Type. */
  type: string;

  /** Resource ID. */
  id: string;

  /** Resource Version.  Always an integer. */
  version: number;

  /** The raw, stored data from the database. */
  data: string;

  get schema() { return Schema.demand( this.type ); }
  get model() { return this.schema.model; }
  get origin() { return getOrigins().get( this.type, this.id ); }

  constructor( type, id, version, options={} ) {
    if ( isResourceTypeName( type ) ) {
      this.type = type;
    } else {
      throw new TypeError(
        `Cannot create Version with invalid type "${type}"`,
      );
    }
    if ( _.isString( id ) ) {
      this.id = id;
    } else {
      throw new TypeError(
        `Cannot create Version with invalid id "${id}"`,
      );
    }
    if ( _.isInteger( version ) ) {
      this.version = version;
    } else {
      throw new TypeError(
        `Cannot create Version with invalid version "${version}"`,
      );
    }
    const { data, ...opts } = options;
    if ( data ) this.setData( data );
    if ( ! _.isEmpty( opts ) ) {
      throw new Error(
        `Unknown options to Version constructor: ${_.keys( opts )}`,
      );
    }
    this.cache_key = createCacheKey( 'D', this.type, this.id );
  }

  static async fromCache( type, id ) {
    if ( ! isResourceTypeName( type ) ) {
      throw new TypeError(
        `Cannot create Version with invalid type "${type}"`,
      );
    }
    if ( ! _.isString( id ) ) {
      throw new TypeError(
        `Cannot create Version with invalid id "${id}"`,
      );
    }

    const version = new Version( type, id, 0 );
    if ( await version.loadCache() ) return version;
  }

  static isValidData( data ) {
    return _.isPlainObject( data )
      && _.isString( data._id )
      && _.isInteger( data._version );
  }
  isValidData( data ) { return Version.isValidData( data ); }

  declare cache_key: string;

  async loadCache() {
    const cache = getCache();
    const data = await cache.get( this.cache_key );
    if ( ! data ) return;
    if ( ! this.isValidData( data ) ) {
      await cache.remove( this.cache_key );
      return false;
    }
    this.version = data._version;
    this.setData( data );
    return true;
  }

  async saveCache() {
    debug( `${this} caching version ${this.version}` );
    if ( ! this.version ) return;
    const data = this.getData();
    if ( ! data ) return;
    await getCache().set( this.cache_key, data );
  }

  get label() { return `${this.type} ${this.id} v${this.version}`; }

  declare data_length: number;

  setData( data ) {
    if ( this.data ) {
      throw new Error( `Already have data for ${this.label}` );
    }
    if ( isResource( data ) ) data = data.toJSON();
    // We want to keep the virtual fields here, but not the computed
    // ones
    const fields = this.schema.getFieldNames( '@all', '-@computed' );
    data = jsonStringify( _.pick( data, fields ) );
    hideProps( this, { data } );
    this.data_length = data.length;
  }

  getData() {
    if ( ! _.isString( this.data ) ) return;
    return jsonParse( this.data );
  }

  getInstance( opts={} ) {
    return this.model.construct( {
      ...opts,
      version : this,
      method  : 'Version.getInstance',
    } );
  }

  toTransportResponse( opts: Partial<TransportResponseOptions> = {} ) {
    const res = new TransportResponse( {
      schema    : this.schema.id,
      method    : 'retrieve',
      ...opts,
    } );
    res._version_instance = this;
    return res;
  }

  toString() { return `Origins.Version<${this.type}/${this.id}>`; }

}
