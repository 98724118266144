import { createSubDocument } from '~/core/creators';

createSubDocument( {
  id          : 'Test.Thing.SubThing',
  fields      : {
    name  : {
      type        : 'string',
      required    : true,
      faker       : 'words.noun',
      identifier  : true,
    },
  },
} );
