/* eslint-disable no-empty-function */
import _ from 'lodash';
import {
  jsonStringify, deepFreeze, mkdebug, parseRefUrl, NotFound,
  DatabaseResultSetError, uuid,
} from '@ssp/utils';
import metrics from '@ssp/metrics';

import { Watchable } from '~/core/lib/Watchable';
import { mongoMerge } from '~/utils';
import { getOrigins } from '~/modules/origins';

import * as option_helpers from './option-helpers';

import type { LodashIteratee } from '@ssp/ts';

import type { Schema } from '~/core/lib/Schema';
import type { Resource, ResourceLoadOptions } from '~/core/resource';
import type { TResource, SchemaId, TSchema, TModel } from '~/types';

const load_hist = metrics.histogram( {
  name   : 'ssp_database_resultset_load_duration_seconds',
  help   : 'ResultSet load time in seconds.',
  labels : [ 'schema', 'status', 'locus' ],
} );

const { validate, ...helpers } = option_helpers;

const debug = mkdebug( 'ssp:database:resultset' );

const extendGuard = Symbol( 'ResultSet-extendGuard' );

/**
  * Default options applied to {@link #options} when finalizing for
  * execution.
  *
  * @type {object}
  */
const defaultOptions = {
  children      : true,
  // readConcern   : 'majority',
};

export type ResultSetOptions = {
  batch?: number;
  children?: boolean;
  collation?: $TSFixMe; // MongoDB Collation Document
  comment?: string;
  exact?: boolean;
  fields?: string[];
  flag?: { [key: string]: any; };
  hint?: string;
  limit?: number;
  maxTimeMS?: number;
  matched?: boolean;
  minlength?: number | boolean;
  pageSize?: number;
  quicksearch?: string | boolean;
  regexp?: boolean | string | RegExp;
  readConcern?: 'local' | 'available' | 'majority' | 'linearizable';
  restrictions?: $TSFixMe;
  search?: string;
  sensitive?: string;
  skip?: number;
  filters?: string[];
  sort?: boolean | string | string[];
};

type ResultSetOptions_Internal = {
  id?: string;
  num?: number;
  resource?: TResource<any>;
  parent?: ResultSet;
  query?: any;
  description?: string;
  options?: ResultSetOptions;
} & ResultSetOptions;

export type ResultSetLoadOptions = ResourceLoadOptions & {
  // live, reload, cache, insert, safe, links, comment, unlock
};

export class ResultSet<T extends SchemaId = SchemaId> extends Watchable {

  static schema: Schema;
  static get model() { return this.schema.model; }

  // These properties are the ones that get frozen (via
  // `deepFreeze`) on creation to ensure they are immutable.
  static get frozen_options() { return [ 'query', 'options' ]; }
  // These properties are either strings (so they don't need to be
  // frozen) or are references to objects that shouldn't be
  // immutable.  The references themselves are immutable though, so
  // the ResultSet can't be changed to point to a different object,
  // but the object it points to can be modified.
  static get readonly_options() {
    return [ 'resource', 'parent', 'description', 'id', 'num' ];
  }
  // These properties are the only ones that hold actual data about
  // this ResultSet.  They are the properties that need updating
  // when cloning.
  static get all_options() {
    return [ ...this.frozen_options, ...this.readonly_options ];
  }
  static get required_options() { return this.all_options; }
  static direct_option_keys = _.keys( _.pickBy( helpers, 'direct' ) );

  /** The query associated with this ResultSet. */
  declare readonly query: Record<string, unknown>;

  /** The options associated with this ResultSet. */
  declare readonly options?: ResultSetOptions;

  /**
   * A text description of this ResultSet.  This is basically
   * a stringified version of the method call that resulted in
   * creating this ResultSet.
   */
  declare readonly description: string;

  /** The {@link Schema} for this ResultSet. */
  get schema(): TSchema<T> {
    return Object.getPrototypeOf( this ).constructor.schema;
  }
  get model(): TModel<T> { return this.schema.model; }

  /**
   * The {@link Resource} object that this ResultSet started with.
   * This allows queries to reference the resource.
   */
  declare readonly resource: Resource;

  /**
   * The ResultSet that is the parent of this one.  When you call
   * a method that modifies a ResultSet it returns a new ResultSet
   * with the one you called the method on as it's parent.
   */
  declare readonly parent?: ResultSet<T>;

  declare readonly id: string;
  declare readonly num: number;

  get _id() { return [ this.id, this.num ].join( '.' ); }

  /**
   * The reference URLs returned by the query API.
   *
   * @type {string[]}
   * @readonly
   */
  get ref_urls(): string[] { return _.get( this, 'results.ref_urls', [] ); }
  get urls() { return this.ref_urls; }

  get refs() { return _.map( this.ref_urls, parseRefUrl ); }
  get objects() {
    return this.urls.map( url => this.getObjectWithKey( url ) );
  }

  /**
   * The total number of matches for the query, as returned by the
   * API.  If you use `.limit()` to limit how many results are
   * returned, this property will tell you how many results there were
   * in total.
   *
   * @type {number}
   * @readonly
   */
  get total() { return _.get( this, 'results.total' ); }

  /**
   * If the query produces an error when submitted to the server, the
   * error message will be exposed here.
   */
  get error() { return this._error || _.get( this, 'results.error' ); }
  set error( err ) { this._error = err; }
  declare _error?: Error | string;

  loaded = false;
  get loading(): boolean { return !! this._loading; }
  get has_loaded_data() { return _.isObject( this.results ); }
  /**
   * The raw results object returned by the server ResultSet API.
   *
   * @type {object}
   * @readonly
   */
  declare results: $TSFixMe;

  /**
   * The number of documents in the ResultSet.
   *
   * @type {number}
   * @readonly
   */
  get length() { return _.get( this, 'results.count', 0 ); }

  /**
   * Options to pass when calling `Resource#load`.
   *
   * @property loadOptions
   * @type {object}
   */
  declare loadOptions?: ResultSetLoadOptions;

  static config: ResultSetOptions = {};

  get ctor() { return Object.getPrototypeOf( this ).constructor; }
  get config(): ResultSetOptions { return { ...this.ctor.config }; }

  protected readonly _objects: Record<string, TResource<T>> = {};

  constructor(
    options: ResultSetOptions_Internal = {}, guard?: typeof extendGuard,
  ) {
    if ( new.target === ResultSet ) {
      throw new Error( `Do not instantiate ResultSet directly.` );
    }
    super();

    const { all_options, required_options, frozen_options } = new.target;
    const non_required_options = [];

    if ( guard !== extendGuard ) {
      if ( options.parent ) {
        throw new Error( `Cannot set fake parent when creating ResultSet` );
      }
      non_required_options.push( 'parent' );

      _.defaults( options, { query : {}, options : {} } );

      // If any options are passed to a ResultSet that should have been
      // part of the options object, then just collect them there.
      _.each( _.keys( helpers ), key => {
        const aval = options[ key ];
        const oval = options.options[ key ];
        if ( ! _.isNil( aval ) && ! _.isNil( oval ) && oval !== aval ) {
          throw new Error( [
            `Cannot specify "${key}" and "options.${key}" with`,
            `different values (${key}="${aval}", options.${key}="${oval}")`,
          ].join( ' ' ) );
        }
        options.options[ key ] = aval;
        delete options[ key ];
      } );

      // Building from outside the class (probably from
      // x.schema.resultset()) rather than from a `this.extend`.  For
      // this case we accept some slightly relaxed configuration.
      if ( options.description ) {
        options.description = `new ResultSet(${options.description})`;
      } else {
        options.description = this._make_description(
          'new ResultSet', ..._.compact( [ options.query, options.options ] ),
        );
      }

      options.options = this._process_options( options.options );
      options.query = this._process_query( options.query );
      _.defaults( options.options, this.config );
      options.id = uuid();
      options.num = 0;
    }
    const extra = _.omit( options, ...all_options );
    if ( ! _.isEmpty( extra ) ) {
      const keys = _.keys( extra );
      throw new Error(
        `Attempted to create ResultSet with invalid properties: ${keys}`,
      );
    }

    const mkprop = ( name, value, opts={} ) => {
      if ( _.isBoolean( opts ) ) opts = { enumerable : opts };
      debug( `Defining option property ${name}` );
      Object.defineProperty( this, name, {
        get() { return value; },
        set( _x ) { throw new Error( `Cannot set "${name}" on ResultSet` ); },
        enumerable      : ! [ 'resource', 'parent' ].includes( name ),
        configurable    : false,
        ...opts,
      } );
    };
    _.each( all_options, name => {
      let value = options[ name ];
      const required = required_options.includes( name )
        && ! non_required_options.includes( name );
      if ( required && _.isNil( value ) ) {
        throw new Error( `ResultSet requires ${name}` );
      }
      if ( frozen_options.includes( name ) ) value = deepFreeze( value );
      mkprop( name, value );
    } );
    if ( this.parent && typeof this.parent.replaced === 'function' ) {
      this.parent.replaced( this );
    }
  }

  /**
   * Extend this ResultSet by creating a new ResultSet with
   * a combination of it's properties and the additional query/options
   * provided to this method.
   *
   * @param {object} [query] - Query to add to the new ResultSet (will
   * be merged with the query in the existing ResultSet).
   * @param {FindOptions} [options] - Options to add to the new ResultSet
   * (will be merged with the options in the existing ResultSet).
   * @param {string} method - The method name that created this
   * extension.  The combination of `method` and `args` is used to
   * create the `description` property of the new ResultSet.
   * @param {any[]} args - The arguments that created this extension.
   * The combination of `method` and `args` is used to create the
   * `description` property of the new ResultSet.
   */
  protected _extend( query, options, method, ...args ) {
    const RS = Object.getPrototypeOf( this ).constructor;

    const new_query = this._process_query( query );
    const new_options = this._process_options( options );

    if (
      _.isEqual( new_options, this.options ) &&
      _.isEqual( new_query, this.query )
    ) return this;

    return new RS( {
      ..._.pick( this, RS.all_options ),
      num         : this.num + 1,
      query       : new_query,
      options     : new_options,
      description : this._make_description( method, ...args ),
      parent      : this,
    }, extendGuard );
  }

  replaced( replacement ) {
    if ( this.equals( replacement ) ) return;
    return this.changed();
  }

  equals( other ) {
    return _.isEqual( this.query, other.query )
      || _.isEqual( this.options, other.options );
  }

  protected _make_description( method, ...args ) {
    let desc = `${method}(`;
    if ( args.length > 0 ) {
      desc += ' ';
      const json = x => jsonStringify( x, { spaces : 0 } );
      desc += _.map( args, json ).join( ', ' );
      desc += ' ';
    }
    desc += ')';
    return desc;
  }

  protected _process_query( query ) {
    if ( _.isNil( query ) ) return _.isNil( this.query ) ? {} : this.query;
    return mongoMerge( this.query, query );
  }

  protected _process_options( options ) {
    if ( _.isNil( options ) ) return this.options;
    if ( ! _.isPlainObject( options ) ) {
      throw new DatabaseResultSetError( {
        message : `Invalid options for ResultSet.extend(): "${options}"`,
        tags    : { method : 'extend', schema : this.schema.id },
        data    : { options },
      } );
    }
    const opts = { ...this.options };
    const proc = ( name: any, value?: any ) => {

      if ( _.isPlainObject( name ) ) {
        return _.map( name, ( val, key ) => proc( key, val ) );
      }

      const helper = helpers[ name ];
      if ( ! helper ) {
        throw new DatabaseResultSetError( {
          message : `Invalid option name "${name}"`,
          tags    : { method : 'extend', schema : this.schema.id },
          data    : { options, option_name : name },
        } );
      }

      if ( _.isNil( value ) ) return delete opts[ name ];
      if ( _.isEqual( value, opts[ name ] ) ) return;

      if ( helper.transform ) {
        if ( _.isArray( value ) ) {
          value = helper.transform.apply( this, value );
        } else {
          value = helper.transform.call( this, value );
        }
      } else if ( _.isArray( value ) ) {
        if ( value.length === 1 ) {
          value = value[0];
        } else {
          throw new DatabaseResultSetError( {
            message : `Too many arguments to ${name}`,
            tags    : { method : 'extend', schema : this.schema.id },
            data    : { value, resultset : this },
          } );
        }
      }
      if ( _.isPlainObject( value ) && ! helper.object ) return proc( value );
      if ( helper.validate ) helper.validate.call( this, value );
      if ( helper.type ) validate.call( this, name, value, helper.type );
      opts[ name ] = value;
    };
    proc( options );
    return opts;
  }

  /**
   * Add additional options into this ResultSet.  This returns the
   * same resultset if the options provided are all already included
   * in the current resultset.
   *
   * @param {FindOptions} options - The options to add.
   */
  addOptions( options ) {
    const opts = _.omitBy( _.pickBy( options, ( val, key ) => {
      return ! _.isEqual( this.options[ key ], val );
    } ), _.isNil );
    if ( _.isEmpty( opts ) ) return this;
    return this._extend( null, options, 'addOptions', opts );
  }

  /**
   * Add additional query elements into this ResultSet.  You can
   * also add additional options at the same time.
   *
   * @param {(object|string)} query - The query to add to the
   * ResultSet.  If this is a string then it's the name of
   * a pre-defined query to run.
   * @param {FindOptions} [options] - If the query parameter was
   * a string, then all the remaining arguments will be passed to the
   * pre-defined query.  If the query was an object, then the second
   * argument can be additional options to include in the ResultSet.
   */
  find( query, ...options ) {
    if ( _.isString( query ) ) {
      const [ new_query, new_options ] = this.runQuery( query, ...options );
      return this._extend( new_query, new_options, 'find', query, options );
    } else {
      options = options[ 0 ];
      return this._extend( query, options, 'find', query, options );
    }
  }

  /**
   * Run a named query.  This is an internal helper method that is
   * used by the query methods that are added to the ResultSet class.
   * You can use it if it you need to (i.e. if you are running a query
   * from a variable) but in general you should just use the query
   * methods themselves.
   *
   * @param {string} name - The name of the query to run.
   * @param {any[]} [args] - The arguments to the query.
   */
  runQuery( name, ...args ) {
    const { schema } = this;
    const method = schema.config.queries?.[ name ].handler;
    if ( ! _.isFunction( method ) ) {
      throw new DatabaseResultSetError( {
        message : `Invalid query "${name}" for ${schema.id}`,
        tags : { schema : this.schema.id, method : 'runQuery' },
        data : { query : name, arguments : args, resultset : this },
      } );
    }
    const res = method.call( this.model, ...args );
    const err = ( msg ) => {
      throw new DatabaseResultSetError( [
        `Query method ${name} was expected to return an object,`,
        `an array with a length of 2, or an empty array, but`,
        `instead it returned`,
        msg,
      ].join( ' ' ), {
        tags  : { schema : this.schema.id, method : 'runQuery' },
        data  : { query : name, arguments : args, resultset : this },
      } );
    };
    if ( _.isArray( res ) ) {
      if ( res.length === 0 ) return [ null, null ];
      if ( res.length === 2 ) return res;
      err( `an array with a length of ${res.length}` );
    }
    if ( _.isPlainObject( res ) ) return [ res, null ];
    err( `${res}` );
  }

  extendWithQuery( query_name, ...args ) {
    const [ new_query, new_options ] = this.runQuery( query_name, ...args );
    return this._extend( new_query, new_options, query_name, ...args );
  }

  declare _loading: Promise<ResultSet<T>>;

  /** Submit the query to the server. */
  load( { reload, ...opts }: ResultSetLoadOptions = {} ) {
    if ( this._loading ) return this._loading;
    if ( this.loaded && ! reload ) return this;
    log.debug( 'RESULTSET LOAD', this._id );
    const err = this.preload();
    if ( err ) {
      this.error = err;
      return this.changed().then( () => this );
    }
    return this._loading || ( this._loading = this._load( opts ) );
  }
  protected async _load( opts: ResultSetLoadOptions = {} ) {
    const timer = load_hist.timer( {
      schema  : this.schema.id,
      locus   : BUILD.isServer ? 'server' : 'client',
    } );
    const results = await this.execute( opts );
    _.assign( this, { results, loaded : true } );
    delete this._loading;
    await this.changed();
    timer( { status : this.error ? 'failure' : 'success' } );
    return this;
  }
  async loadBatch( size: number = this.options.batch, startFrom?: string ) {
    if ( ! this.loaded ) await this.load();
    const ref_urls = [ ...( this.ref_urls || [] ) ];
    if ( ! ref_urls.length ) return ref_urls;
    if ( startFrom ) {
      const idx = ref_urls.indexOf( startFrom );
      if ( idx > 0 ) ref_urls.splice( 0, idx );
    }
    const batch: TResource<T>[] = [];
    for ( const key of ref_urls ) {
      if ( this._objects[ key ] ) continue;
      batch.push( this.getObjectWithKey( key ) );
      if ( batch.length >= size ) break;
    }
    return Promise.all( batch.map( item => item.load( this.loadOptions ) ) );
  }

  getBatch( size: number = this.options.batch ) {
    const ref_urls = this.ref_urls;
    if ( ! Array.isArray( ref_urls ) ) return [];
    const res: TResource<T>[] = [];
    for ( const key of ref_urls ) {
      if ( this._objects[ key ] ) continue;
      res.push( this.getObjectWithKey( key ) );
      if ( res.length >= size ) return res;
    }
    return res;
  }

  getAll() {
    const ref_urls = this.ref_urls;
    if ( ! Array.isArray( ref_urls ) ) return [];
    return ref_urls.map( key => this.getObjectWithKey( key ) );
  }

  reload() { return this.load( { reload : true } ); }

  /**
   * This runs any preload checks defined by any of the option
   * helpers. If any preload check returns a string then that string
   * gets set as the resultset error message, which can then be
   * displayed to the user.  This is, for example, how we implemented
   * the `minlength` option.
   */
  preload() {
    const preloads = _.compact( [
      'preload',
      BUILD.isServer && 'preload_server',
      BUILD.isClient && 'preload_client',
    ] );
    for ( const [ name, helper ] of Object.entries( helpers ) ) {
      for ( const pre of preloads ) {
        const res = helper[ pre ]?.call( this, this.options[ name ] );
        if ( _.isString( res ) ) return res;
      }
    }
  }

  /**
   * Execute the ResultSet, returning a promise for the raw results.
   *
   * You should not call this method from outside of the ResultSet
   * class.  To get data from anywhere else you should be using
   * something like {@link #load}, {@link #single}, {@link #first},
   * {@link #count}, or {@link #all}.
   *
   * @param {Object} [opts] - Additional options to send to the
   * server.  These options do not get added to the ResultSet, they
   * are used internally to implement things like {@link #single} and
   * {@link #count}.
   *
   * @returns {Promise<Object>} - A promise for the results object.
   */
  async execute( opts={} ) {
    debug( `Executing ResultSet` );
    const query = this.finalizeQuery();
    const options = this.finalizeOptions( opts );
    const ev = ( this.resource || this.model ).eventbox( { query, options } );
    await ev.before( 'find' );
    const results = await getOrigins().find( this );
    if ( ! results ) throw new Error( `Origins.find didn't return results` );
    await ev.after( 'find', { results } );
    if ( results.error ) {
      throw new DatabaseResultSetError( results.error, {
        tags : { schema : this.schema.id, method : 'execute' },
        data : { results, resultset : this },
      } );
    }
    return results;
  }

  finalizeQuery() {
    return this.query;
  }

  finalizeOptions( extraOpts={} ) {
    const opts = _.assign( {}, this.options, extraOpts );
    _.defaults( opts, defaultOptions );
    return opts;
  }

  get ready() {
    return this.results && ! this.error;
  }

  /**
   * Return only the first match, without worrying about if there are
   * others.
   * If the ResultSet has not already been loaded then this method
   * will execute an isolated query to get only the first result,
   * without loading and populating the rest of the ResultSet until
   * it's needed.
   */
  async first( opts = this.loadOptions ) {
    if ( this.has_loaded_data ) return this.getObjectAtIndex( 0 ).load( opts );
    const res = await this.execute( { limit : 1 } );
    if ( res.ref_urls && res.ref_urls[ 0 ] ) {
      return this.model.fromRef( res.ref_urls[ 0 ] ).load( opts );
    }
    return;
  }

  /**
   * Return only a single match, throwing an exception if there are
   * others.
   * If the ResultSet has not already been loaded then this method
   * will execute an isolated query to get only the single result,
   * without loading and populating the rest of the ResultSet until
   * it's needed.
   *
   * @param {object} [opts] - Options object.  Will be passed through
   * to the load method if it needs to be called.
   */
  async single( opts = this.loadOptions ) {
    if ( ! this.has_loaded_data ) await this.load();
    if ( this.total === 0 ) return;
    if ( this.total === 1 ) {
      const rsrc = this.getObjectAtIndex( 0 );
      return rsrc.load( opts );
    }
    throw new NotFound( {
      message : `Expected a single result, but found ${this.total}`,
      status  : 'too-many-results',
      data    : { ref_urls : this.refs },
      tags    : { schema : this.schema.id, method : 'ResultSet#single' },
    } );
  }

  /**
   * Return just a count of the results.
   * If the ResultSet has not already been loaded then this method
   * will execute an isolated query to get the count, without loading
   * and populating the rest of the ResultSet until it's needed.
   */
  async count() {
    if ( this.has_loaded_data ) return this.total;
    const res = await this.execute( { returnTotalOnly : true } );
    return res.total;
  }

  getObjectAtIndex( idx ) {
    const ref = this.ref_urls[ idx ];
    if ( ! ref ) return;
    return this.getObjectWithKey( ref );
  }

  getObjectWithKey( key ) {
    if ( this._objects[ key ] ) return this._objects[ key ];
    return this._objects[ key ] = this._hydrateObject( key );
  }

  _hydrateObject( ref ) { return this.model.fromRef( ref ); }

  /* * * Array Methods * * */
  /** Iterate the results like `_.filter`. */
  async filter( callback: LodashIteratee ) {
    const items = await this.all();
    return _.filter( items, callback );
  }

  /** Iterate the results like `_.map`. */
  async map( callback: LodashIteratee ) {
    const iter = _.iteratee( callback );
    const objs = await this.all();
    return Promise.all( objs.map( ( obj, idx ) => {
      return iter( obj, idx, this );
    } ) );
  }
  mapSync( callback: LodashIteratee ) {
    const iter = _.iteratee( callback );
    return this.objects.map( ( obj, idx ) => {
      return iter( obj, idx, this );
    } );
  }

  /** Load and return all the result resources. */
  async all( options: ResourceLoadOptions = {} ) {
    if ( options.reload || ! this.loaded ) await this.load( options );
    return Promise.all( this.getAll().map( item => item.load( {
      ...this.loadOptions, ...options,
    } ) ) );
  }

  async ids() {
    if ( ! this.loaded ) await this.load();
    return this.getAllIds();
  }

  getAllIds() {
    return this.mapSync( '_id' );
  }

  getIdsAndNames() {
    const pairs = this.mapSync( x => ( [ x._id, x.name ] ) );
    return _.fromPairs( pairs );
  }

  * [ Symbol.iterator ]() {
    for ( const url of this.urls ) {
      yield this.getObjectWithKey( url );
    }
  }

  async * [ Symbol.asyncIterator ]() {
    for ( const url of this.urls ) {
      const obj = this.getObjectWithKey( url );
      if ( ! obj.loaded ) await this.loadBatch( this.options.batch, url );
      yield obj;
    }
  }

  get lineage() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let iter: ResultSet<T> = this;
    const lineage = [];
    while ( iter ) {
      lineage.unshift( iter );
      iter = iter.parent;
    }
    return lineage;
  }

  get history() { return _.map( this.lineage, 'description' ); }

  route( ...args ) { return ( this.resource || this.model ).route( ...args ); }
  job( ...args ) { return this.model.job( ...args ); }

  async remove( opts ) {
    return this.map( rsrc => rsrc.remove( opts ) );
  }

  getSortKey() { return this.getSortInfo()[ 1 ]; }
  getSortDirection() { return this.getSortInfo()[ 0 ]; }

  getSortField() {
    const key = this.getSortKey();
    if ( ! key ) return;
    return this.schema.getField( key );
  }

  getSortLabel() {
    const field = this.getSortField();
    if ( ! field ) return;
    return field.label;
  }

  getSortInfo() {
    const [ dir, key ] = this.parseSort( this.sort() );
    if ( key ) return [ dir || '+', key ];
    const sortable = this.getSortOptions()[ 0 ];
    if ( sortable ) return [ '+', sortable.label ];
    return [ '+', 'display_name' ];
  }

  getSortOptions() {
    const fields = this.schema.getFields( '@sortable' );
    return fields.map( field => _.pick( field, 'name', 'label' ) );
  }

  parseSort( sort ) {
    const x = /^([+-])(\w+)$/u.exec( sort );

    if ( ! x ) return [ '+', 'name' ];
    return [ x[ 1 ] || '+', x[ 2 ] || 'name' ];
  }

  getMinimizedResultSetOptions(): ResultSetOptions {
    return _.omitBy( this.options, ( value, key ) => {
      return _.isEqual( option_helpers[ key ].default, value );
    } );
  }

  #cacheKey?: string;
  getCacheKey() {
    if ( this.#cacheKey ) return this.#cacheKey;
    return this.#cacheKey = jsonStringify( {
      s : this.schema.id,
      q : this.query,
      o : this.getMinimizedResultSetOptions(),
    } );
  }

}

_.assign( ResultSet.prototype, _.mapValues( helpers, ( conf, name ) => {
  return function optionHelper( ...args ) {
    if ( ! helpers[ name ] ) throw new Error( `Invalid helper "${name}"` );
    if ( args.length === 0 ) return this.options[ name ];
    return this._extend( null, { [name] : args }, name, ...args );
  };
} ) );
