import _ from 'lodash';
import { hideProps } from '@ssp/utils';

import { Resource } from './Resource';
import { TeamLink } from './TeamLink';
import { User } from './User';
import { formatSummary } from './format-summary';

import type { TResource } from '~/types';

export type MembershipChange = {
  /** The user_id of the member to update. */
  user_id: string;
  /** The type of change */
  change_type: 'add' | 'remove' | 'update';
  /** Set to true for changes discovered by the update-members job */
  is_discovered?: boolean;
  /** Set to true for changes discovered by this update-members job */
  is_discovery?: boolean;
  /** Can be set to change which service user instance is a member. */
  service_user_id?: string;
  /**
   * Any configuration option the resource accepts can be included
   * here, such as "role" for resources using the withRole trait.
   */
  config?: Record<string, any>;
};
export type MembershipChanges = MembershipChange[];

export type MembershipHelperOptions = {

  /** Whether or not to process teamlinks. */
  process_teamlinks?: boolean;

  /**
   * Set to true when discovering changes, to avoid commiting pending
   * change records to the database.
   */
  is_discovery?: boolean;

  /**
   * Call `.refresh` on the primary resource instead of `.save`.
   */
  refresh_primary?: boolean;

};

export type ProcessFn<T=unknown> = (
  item: Resource | TeamLink,
) => T | null | undefined | Promise<T | null | undefined>;
export type ProcessSyncFn<T=unknown> = (
  item: Resource | TeamLink,
) => T | null | undefined;

export class MembershipHelper {

  #resources: Record<string, Resource> = {};
  #teamlinks: Record<string, TeamLink> = {};
  #users: Record<string, User> = {};

  process_teamlinks: boolean = true;
  is_discovery: boolean = false;

  readonly primary_resource: Resource;
  readonly _primary_resource: TResource<any>;

  refresh_primary: boolean = false;

  constructor(
    primary_resource: TResource<any>,
    options: MembershipHelperOptions = {},
  ) {
    _.assign( this, _.omitBy( options, _.isNil ) );
    hideProps( this, { _primary_resource : primary_resource } );
  }

  async formatSummary( detailed: boolean = false, by_user: boolean = false ) {
    return formatSummary( this, detailed, by_user );
  }

  getResources() {
    return _.sortBy( _.values( this.#resources ), 'name' );
  }

  getUsers() {
    return _.sortBy( _.values( this.#users ), 'name' );
  }

  async process<T=unknown>( fn: ProcessFn<T> ): Promise<T[]> {
    const queue: Resource[] = [ this.primary_resource ];
    const seen = new Set();
    const res: T[] = [];
    while ( queue.length ) {
      const rsrc = queue.shift();
      if ( seen.has( rsrc ) ) continue;
      seen.add( rsrc );
      const r = await fn( rsrc );
      if ( r ) res.push( r );
      for ( const link of rsrc.teamlinks ) {
        const l = await fn( link );
        if ( l ) res.push( l );
        queue.push( link.resource );
      }
    }
    return res;
  }
  async filter( fn: ProcessFn<boolean> ): Promise<( Resource | TeamLink )[]> {
    const res = await this.process( async item => {
      if ( await fn( item ) ) { return item; }
      return null;
    } );
    return res.filter( x => x );
  }

  async processChanges( changes: MembershipChanges ) {
    return Promise.all( changes.map( change => this.processChange( change ) ) );
  }

  async processChange( change: MembershipChange ) {
    log.debug( 'MembershipHelper#processChange:', change );
    return this.primary_resource.processChange( change );
  }

  async commit( save: boolean = true ) {
    await this.process( item => {
      if ( item instanceof Resource ) return item.commit( save );
    } );
  }

  async prepare() {
    const primary_resource = await this.resource( this._primary_resource );
    hideProps( this, { primary_resource } );
  }

  async resource( rsrc: TResource<any> ): Promise<Resource> {
    if ( this.#resources[ rsrc._id ] ) return this.#resources[ rsrc._id ];
    const helper = new Resource( rsrc, this );
    this.#resources[ rsrc._id ] = helper;
    await helper.prepare();
    return helper;
  }

  async teamlink( link: TResource<'SSP.TeamLink'> ): Promise<TeamLink> {
    if ( this.#teamlinks[ link._id ] ) return this.#teamlinks[ link._id ];
    const helper = new TeamLink( link, this );
    this.#teamlinks[ link._id ] = helper;
    await helper.prepare();
    return helper;
  }

  async teamlinks( links: TResource<'SSP.TeamLink'>[] ): Promise<TeamLink[]> {
    return Promise.all( links.map( l => this.teamlink( l ) ) );
  }

  async user( id: string ): Promise<User> {
    if ( this.#users[ id ] ) return this.#users[ id ];
    const helper = new User( id, this );
    this.#users[ id ] = helper;
    await helper.prepare();
    return helper;
  }

  async getImpactedUserTypes() {
    const rsrcs = await this.getImpactedResources();
    return _.uniq( _.compact( rsrcs.map( r => r.getUserType() ) ) );
  }

  async getImpactedResources() {
    return this.process( item => {
      if ( item instanceof Resource ) return item.resource;
    } );
  }

  async getImpactedTeamLinks() {
    return this.process( item => {
      if ( item instanceof TeamLink ) return item.teamlink;
    } );
  }

  getSummaryInfoByResource() {
    const resources = _.sortBy( this.#resources, 'name' );
    return _.compact( _.map( resources, rsrc => {
      if ( rsrc instanceof Resource ) return rsrc.getSummaryInfo();
    } ) );
  }
  async getSummaryInfoByUser() {
    throw new Error( 'TODO' );
  }

  getProvisionCounts() {
    const counts: Record<string, number> = {};
    for ( const user of Object.values( this.#users ) ) {
      for ( const id of user.will_provision_types ) {
        counts[ id ] = ( counts[ id ] || 0 ) + 1;
      }
    }
    return counts;
  }

}
