import _ from 'lodash';
import { ReferenceURL } from '@ssp/utils';

import { Schema } from '~/core/lib/Schema';
import { throwError } from '~/core/errors';
import { hasMongoOperators } from '~/utils';

export function buildIdentQuery( schema: Schema, ident ) {
  // If we're given a ref url, parse it and use it's values
  if ( _.isString( ident ) ) {
    const ref = new ReferenceURL( ident );
    if ( ref.valid ) {
      if ( ref.type && schema.id !== ref.type ) {
        // If we were called on the wrong type, relay to the right one
        return buildIdentQuery( Schema.demand( ref.type ), ident );
      }
      // If the ref had an ID then that's all we need.
      if ( ref.id ) return { _id : ref.id };
      if ( ref.ident ) ident = [ ref.ident ];
    } else {
      ident = [ ident ];
    }
  }

  if ( ! ( _.isArray( ident ) && _.every( ident, _.isString ) ) ) {
    throwError( `buildIdentQuery requires string or array of strings`, {
      method  : 'buildIdentQuery', ident,
    } );
  }

  // If it is an array of a single string then just turn it back
  // into a string, so we don't end up with `{ $and : [ x ] }`.
  if ( _.isArray( ident ) ) {
    ident = ident.length === 1 ? ident[ 0 ] : { $in : ident };
  }

  const fields = schema.getFieldNames( '@identifier' );
  const $or = _.map( fields, f => ( { [ f ] : ident } ) );
  return $or.length === 1 ? $or[ 0 ] : { $or };
}

export function buildUniqueQuery( schema: Schema, query ) {
  if ( _.isNil( query ) ) {
    throwError( `Cannot build unique query without query data`, {
      method : 'buildUniqueQuery', query,
    } );
  }
  if ( _.isString( query ) || _.isArray( query ) ) {
    return buildIdentQuery( schema, query );
    // Note that we've bailed out here, none of the following
    // applies if it was an identifier query..
  }
  if ( ! _.isPlainObject( query ) ) {
    throwError( `Invalid query for buildUniqueQuery`, {
      method : 'buildUniqueQuery', query,
    } );
  }
  // If the query has MongoDB operators then we can't transform it
  // into a uniques query, so we just return it as-is when is safe
  // mode, or throw an exception if not in safe mode.
  if ( hasMongoOperators( query ) ) {
    throwError( `Cannot build unique query with mongo operators`, {
      method : 'buildUniqueQuery', query,
    } );
  }
  const indexes = _.filter( schema.indexes, 'unique' );
  // Build queries for unique indexes
  const queries = _.compact( _.map( indexes, index => {
    // get values for all the fields covered by the index
    const q = _.mapValues( index.fields, ( _val, key ) => query[ key ] );
    // Don't include queries for indexes where we don't have
    // values for all the fields
    if ( _.some( q, _.isNil ) ) return;
    return q;
  } ) );
  // Build queries for fields that have a transformFetchQuery method.
  // Note that this does *not* require that the field that has the
  // transformFetchQuery method also have a unique index.  It's
  // assumed that the method is written in a way that will handle
  // that scenario.
  const transforms = _.filter( schema.fields, 'transformFetchQuery' );
  if ( transforms.length ) {
    queries.push( ..._.compact( _.flatMap( transforms, field => {
      const value = query[ field.name ];
      if ( _.isNil( value ) ) return;
      return field.transformFetchQuery( value, queries, schema );
    } ) ) );
  }
  if ( _.isEmpty( queries ) ) {
    throwError( `Unable to build uniques query -- no data`, {
      method : 'buildUniqueQuery', query,
    } );
  }
  return ( queries.length === 1 ) ? queries[ 0 ] : { $or : queries };
}
