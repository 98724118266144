import _ from 'lodash';
import { Schema as TypeSchema } from '@ssp/types';
import { invariant } from '@ssp/utils';
import type { Producable } from '@ssp/ts';

import { isUser, isProject } from '~/utils/types';

import defaults from './defaults';
import { verboseCanAction } from './utils';
import { ACTION_HANDLER_TYPES } from './types';

import type { Capability } from '~/components/SSP.User/data-maps';

import type {
  ActionAccess, ActionOptions, ActionHandlerType,
  GetRenderedContextOptions, RenderedContext,
} from './types';

export class Action {

  /**
   * A unique action id, mostly for something easy to use for a React
   * key.
   */
  id: string;

  /**
   * The short name of this action.  This is the identifier passed to
   * the server when requesting an action to run.
   */
  name: string;

  description?: string;

  /**
   * The type of action.  Will be either 'instance' or 'class' to
   * indicate whether the action is intended to be invoked on
   * instances or classes.
   */
  type: 'instance' | 'class' = 'instance';

  /**
   * The specific resource context the action should be available.
   * When left blank the action has not availability limitation.
   * Context types:
   *  * self - only available when acting on itself
   *  * user - only available when context is a User resource
   *  * project - only available when context is a Project resource
   */
  context?: 'self' | 'user' | 'project';

  /**
   * Set to `true` for a development-only action.  If this is true
   * then this action will only be shown when running in development
   * mode.
   */
  development: boolean = false;

  /**
   * The label for this action is the text that will be visible in the
   * actions menu in the UI.
   */
  label?: string;

  /**
   * The icon to display alongside the label in the UI.
   */
  icon?: string;

  /**
   * The name of a method that can be called on the resource to
   * determine whether this action should be visible in the menu or
   * not.  This method will be called with `this` set to the resource,
   * and it will also be passed the resource as it's first argument.
   */
  visibilityMethod?: string;

  /**
   * Method used to determine if the action should be disabled.
   */
  disabledMethod?: string;

  /**
   * Access indicates the level of access required to invoke this
   * action in the Simple ACL System.
   * The access level can be:
   *  * admin     - For project-owned resources: Must be a member of the
   *                project's primary admin team.  For user-owned
   *                resources, you must be the owner.
   *  * member    - Must be a member of any of the owning project's teams.
   *  * support   - This action can only be used by SYSTEM/Support team.
   *  * none      - Nobody is allowed to use this action.
   *  * anonymous - Anyone is allowed to use this action.
   *
   * Note that people in the SYSTEM/Administrators group can invoke
   * any action, and people in the SYSTEM/Support group can invoke
   * admin or member actions for any project, and can invoke admin
   * actions for any user.
   */
  access?: ActionAccess;

  /**
   * A capability that users must have to execute this action.
   * Important Note: To use an action the user must satisfy *both* the
   * capability and the access.  If you want access to be controlled
   * *only* by the capability, then specify 'anonymous' as the access.
   */
  capability?: Capability;

  /**
   * Help text to further describe the action to an end user.  If you
   * use an array in the definition, it will be joined with spaces
   * when constructed.
   */
  get help() { return this._help; }
  set help( help ) {
    this._help = _.filter( _.flattenDeep( [ help ] ), _.isString ).join( ' ' );
  }
  _help: string;

  /**
   * Data to pass to the handler.  For actions that don't have params,
   * this will just get passed straight through to the handler.  This
   * can make things like toggle actions easier to manage (just set
   * the `method` to `update` and set `data` to `{ field_to_turn_off
   * : false }`, for example).
   * If the action does have params then the objects will get merged.
   */
  data?: Record<string, any>;

  /** The type of handler this action has. */
  get handlerType(): ActionHandlerType { return Action.getHandlerType( this ); }

  static getHandlerType( action ): ActionHandlerType {
    for ( const h of ACTION_HANDLER_TYPES ) { if ( action[ h ] ) return h; }
  }

  typeSchema?: TypeSchema;

  message?: string;
  warning?: string;
  danger?: string;

  constructor( opts: ActionOptions ) {
    if ( ! opts.name ) throw new Error( `Action requires name` );

    _.assign( this, opts );
    _.defaults( this, defaults[ opts.name ], {
      id  : _.kebabCase( opts.name ),
    } );

    this.typeSchema = new TypeSchema( {
      name    : `Action: ${this.name}`,
      params  : this.params,
    } );

    // Don't check all the extra stuff for actions that are not available.
    if ( this.is_available ) this.check();
  }

  get is_available() {
    return this.handlerType && ! this.not_available;
  }

  check() {
    const warn = [];

    if ( ! ( this.capability || this.access ) ) {
      warn.push( `does not specify "capability" or "access"` );
    }

    const handlers = _.omitBy( _.pick( this, ACTION_HANDLER_TYPES ), _.isNil );

    if ( _.size( handlers ) === 0 ) warn.push( `has no handlers` );
    if ( _.size( handlers ) > 1 ) {
      warn.push( `has multiple handlers (${_.keys( handlers )})` );
    }

    if ( this.params && ! ( this.job_name || this.handler || this.method ) ) {
      warn.push( `has params, but no job_name, handler or method` );
    }
    if ( warn.length ) {
      const msg = [
        `Action "${this.name}" has warnings:`,
        ...warn.map( x => ` * ${x}` ),
      ].join( '\n' );
      log.warn( msg );
    }
  }

  isExecutable() {
    if ( _.isEmpty( this.handlerType ) ) return false;
    return true;
  }

  /**
   * A route fragment that will be used to navigate to a page when
   * this action is invoked.
   */
  route?: Producable<string>;

  /**
   * A URL to navigate to when this this action is invoked.  Note that
   * the difference between `href` and `route` is that `route`
   * navigates within the app, `href` is intended for actions that
   * navigate to another site or application.
   */
  href?: Producable<string>;

  /**
   * The name of a modal that will be opened when this action is
   * invoked. The modal will be provided with a `resource` prop for
   * the resource the action was invoked on.
   */
  modal?: Producable<string>;

  /**
   * The name of the job that will be enqueued when this action is
   * invoked.
   */
  job_name?: Producable<string>;

  /**
   * The name of the method that will be run on the server side when
   * this action is invoked.
   */
  handler?: Producable<string>;

  /**
   * Similar to handler, but names a method to be called on the client
   * side when this action is invoked.  This is used for actions that
   * are entirely handled on the client side, like "impersonate".
   */
  method?: Producable<string>;

  /** Parameters for the job. */
  params?: $TSFixMe;

  /**
   * Can be set to a true value on an action to indicate that the
   * action is not available. Prevents inherited actions from being
   * functional on this schema while still propagating to children.
   * Similar to is_disabled but prevents the action from appearing as
   * an ActionItem.
   */
  not_available?: string;

  /**
   * Disables the action but does not prevent it from appearing
   * as an ActionItem.
   */
  is_disabled?: string;


  get group() {
    if ( this._group ) return this._group;
    return this.access;
  }
  set group( group ) { this._group = group; }
  _group: string;

  /**
   * This will return `true` if this action is a "two-step" type that
   * first sends you to an `ActionView` and then finishes when you
   * submit the form on that view.
   */
  get needs_view() { return this.has_message || this.params; }

  /**
   * Returns `true` if any of the "message" properties (`message`,
   * `warning`, `danger`) are set.
   */
  get has_message() { return this.danger || this.warning || this.message; }

  checkRenderVisibility( action_context ): [ boolean, string ] {
    const { resource, subject, context } = action_context;

    if ( ! this.label ) return [ false, 'No label' ];
    if ( this.context === 'self' && context?._id !== resource._id ) {
      return [ false, 'Requires self context' ];
    }
    if ( this.context === 'user' && ! isUser( context ) ) {
      return [ false, 'Requires user context' ];
    }
    if ( this.context === 'project' && ! isProject( context ) ) {
      return [ false, 'Requires project context' ];
    }

    const extraChecks = ( subj, rsrc ) => {
      if ( this.visibilityMethod ) {
        const method = this.visibilityMethod;
        const invert = method.startsWith( '!' );
        const name = invert ? method.substring( 1 ) : method;
        let value = rsrc[ name ];

        if ( typeof value === 'function' ) {
          value = value.call( resource, resource, this, subj );
          if ( _.isString( value ) && value.length ) return value;
        }
        value = !!value;
        if ( invert ) value = !value;
        if ( ! value ) return `visibility method ${name} returned false`;
      }
    };
    return verboseCanAction( subject, resource, this, extraChecks );
  }

  checkRenderDisabled( context ) {
    const { resource } = context;
    if ( _.isString( this.is_disabled ) ) return [ true, this.is_disabled ];
    if ( this.is_disabled ) return [ true, 'Disabled by flag' ];
    if ( this.disabledMethod ) {
      if ( ! resource ) return [ true, 'Resource not available' ];
      const method = this.disabledMethod;
      if ( typeof method === 'function' ) {
        invariant( typeof method === 'function' );
        const value = method.call( resource, resource, this );
        if ( value ) return [ true, value ];
      }
      if ( _.isString( method ) ) {
        const invert = method.startsWith( '!' );
        const name = invert ? method.substring( 1 ) : method;
        let value = resource[ name ];
        if ( typeof value === 'function' ) {
          invariant( typeof value === 'function' );
          value = value.call( resource, resource, this );
          if ( value ) return [ true, value ];
          return [ false, 'Not disabled' ];
        }
        value = !!value;
        if ( invert ) value = ! value;
        if ( ! value ) return [ true, `Disbled, ${value}` ];
      }
    }
    return [ false, 'Not disabled' ];
  }

  getRenderedContext( options: GetRenderedContextOptions ): RenderedContext {
    // Doesn't even appear in "hidden: true" list when not_available
    // or internal
    if ( this.not_available || this.name?.startsWith( '_' ) ) return;

    const [ visible, visible_reason ] = this.checkRenderVisibility( options );
    const [ disabled, disabled_reason ] = this.checkRenderDisabled( options );

    // The other visible/disabled checks here
    const data: RenderedContext = {
      id     : this.id,
      name   : this.name,
      action : this,
      ..._.pick( this, [
        'description', 'help', 'group', 'label', 'icon',
      ] ),
    };
    if ( ! visible ) data.hidden = visible_reason;
    if ( disabled ) data.disabled = disabled_reason;

    const res = _.mapValues( data, val => (
      _.isFunction( val ) ? val.call( options.resource, options ) : val
    ) );

    // Attach whatever functions need to be included as functions here...
    return res;
  }
}
