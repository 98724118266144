
import { createBehavior } from '~/core/creators';

import type { SchemaId } from '~/types';

export type CountsIds = Record<SchemaId, string[]>;
export type HasResourceCountsOptions = Record<string, never>;

declare module './declarations' {
  export interface BehaviorConfigs {
    hasResourceCounts?: HasResourceCountsOptions | boolean;
  }
  export interface BehaviorOptions {
    hasResourceCounts?: HasResourceCountsOptions;
  }
}

createBehavior<HasResourceCountsOptions>( {

  name      : 'hasResourceCounts',

  options   : {
  },

  build() {
    return [
      {
        actions : {
          _getResourceIds : {
            access    : 'anonymous',
            method    : 'getResourceIds',
          },
        },
      },
      BUILD.isClient && {
        methods : class {
          async getResourceIds() {
            return await this.action( '_getResourceIds' );
          }
        },
      },
      BUILD.isServer && {
        methods : class {
          async getResourceIds() {
            return this.broker.origin.counts.getIds();
          }
        },
      },
    ];
  },

} );
