// Automatically generated, DO NOT EDIT!
// To update run "yarn do-updates"
/* eslint-disable */
import { Model } from '~/core/lib/Model';
Model.demand( 'Slack.UserGroup' ).extend( {
  "actions": {
    "archive": {
      "access": "admin",
      "help": "Archive a Slack User Group",
      "icon": "fas:archive",
      "id": "archive",
      "job_name": "archive",
      "label": "Archive",
      "name": "archive",
      "type": "instance",
      "visibilityMethod": "!is_archived"
    },
    "import": {
      "access": "support",
      "icon": "far:file-import",
      "id": "import",
      "job_name": "import",
      "label": "Import",
      "name": "import",
      "params": {
        "handle": {
          "demand": true,
          "describe": "User Group Handle",
          "type": "string"
        },
        "workspace": {
          "demand": true,
          "describe": "Slack Workspace ID (TXXXXXX)",
          "type": "string"
        }
      },
      "type": "class"
    },
    "sync": {
      "access": "member",
      "icon": "fas:sync",
      "id": "sync",
      "job_name": "sync",
      "label": "Sync",
      "name": "sync",
      "type": "instance"
    },
    "unarchive": {
      "access": "admin",
      "help": "Unarchive a Slack User Group",
      "icon": "far:inbox-out",
      "id": "unarchive",
      "job_name": "unarchive",
      "label": "Unarchive",
      "name": "unarchive",
      "type": "instance",
      "visibilityMethod": "is_archived"
    }
  }
} );
