import { createResource } from '~/core/creators';

createResource( {
  id          : 'Slack.Workspace',
  name        : 'Slack Workspace',
  attachable  : [ { type : 'SSP.Project', is_beta_project : true } ],
  icon        : 'fab:slack',
  hideZero    : true,
  fields      : {
    name              : {
      type        : 'string',
      index       : true,
      unique      : true,
      summary     : true,
      validators  : { maxlength : 255 },
      quicksearch : true,
    },
    description   : {
      type        : 'string',
      summary     : true,
      help        : [
        'A detailed description can help members of your',
        'organization decide whether they should join this workspace.',
      ],
      validators  : { maxlength : 255 },
      quicksearch : true,
    },
    domain            : {
      type        : 'string',
      unique      : true,
      identifier  : true,
      help        : 'letters, numbers and hyphens only. Max length = 21',
      validators  : {
        maxlength : 21,
        regexp    : /^[a-zA-Z0-9-]+$/u,
      },
    },
    access            : {
      type        : 'string',
      summary     : true,
      form        : {
        type        : 'Select',
        placeholder : 'Select Access type',
        help        : [
          'Open = Anyone in your organization will be',
          'able to join this workspace.',
          'By Request = Members can request to join this workspace.',
          'Invite Only = Members must be invited by a',
          'Workspace Owner or Admin.',
          'Hidden = This workspace will be hidden from non-members.',
        ],
        options() {
          return [
            { label : 'By Request', value : 'closed' },
            { label : 'Open', value : 'open' },
            { label : 'Invite Only', value : 'invite_only' },
            { label : 'Hidden', value : 'unlisted' },
          ];
        },
      },
    },
    slack_id           : {
      type        : 'slack-workspace-id',
      unique      : true,
      identifier  : true,
      readonly    : true,
      summary     : true,
      optional    : true,
      immutable   : true,
    },
    is_open_create     : {
      type        : 'boolean',
      readonly    : true,
      default     : false,
    },
  },
  behaviors : {
    hasExternalLink : {},
    hasMembers      : {
      withCreator : {},
      userType    : 'Slack.User',
      withService : true,
    },
  },
  faces         : {
    'list.summary' : [
      {
        layout : {
          type       : 'ItemsBetweenTitleAndIcons',
        },
        fields  : [ 'access' ],
      },
      {
        layout : 'ItemsAndBadges',
        fields  : [ 'description', 'organization', '@badge' ],
      },
    ],
  },
} );
