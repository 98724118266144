import { isUser } from '~/utils/types';
import { createResource } from '~/core/creators';

createResource( {
  id                : 'Slack.User',
  name              : 'Slack User',
  attachable        : [ 'SSP.User' ],
  icon              : 'fab:slack',
  service_id_field  : 'slack_id',
  fields            : {
    slack_id    : {
      type        : 'slack-user-id',
      label       : 'Slack User ID',
      summary     : true,
      index       : true,
      readonly    : true,
      unique      : true,
      optional    : true,
      immutable   : true,
    },
    username : {
      type      : 'string',
      label     : 'Username',
      summary   : true,
      unique    : true,
      readonly  : true,
      optional  : true,
    },
    name    : {
      type      : 'string',
      label     : 'Name',
      summary   : true,
      index     : true,
      unique    : false,
      optional  : true,
    },
    color   : {
      type      : 'string',
      label     : 'Color',
      summary   : true,
      readonly  : true,
      optional  : true,
    },
    is_admin            : {
      type      : 'boolean',
      readonly  : true,
      faker     : false,
      optional  : true,
      badge       : { label : 'Admin' },
    },
    is_owner            : {
      type      : 'boolean',
      readonly  : true,
      faker     : false,
      optional  : true,
      badge       : { label : 'Owner' },
    },
    is_primary_owner    : {
      type      : 'boolean',
      readonly  : true,
      faker     : false,
      optional  : true,
      badge       : { label : 'Primary Owner' },
    },
    is_restricted       : {
      type      : 'boolean',
      readonly  : true,
      faker     : false,
      optional  : true,
      badge       : { label : 'Restricted', color : 'danger' },
    },
    is_ultra_restricted : {
      type      : 'boolean',
      readonly  : true,
      faker     : false,
      optional  : true,
      badge       : { label : 'Ultra Restricted', color : 'danger' },
    },
    account_type        : {
      type      : 'string',
      label     : 'Account Type',
      readonly  : true,
      default   : 'Member',
    },
    teams         : {
      type      : 'string',
      array     : true,
      label     : `Array of Workspace ID's`,
      readonly  : true,
      optional  : true,
      default   : [],
    },
  },

  behaviors : {
    hasEmail            : {},
    hasDisabled         : {},
    hasPrimaryAccount   : {},
    hasExternalLink     : {},
  },
  methods : class {

    static createFrom( ssp_user ) {
      if ( ! isUser( ssp_user ) ) {
        throw new Error( `createFrom must be called with an SSP.User` );
      }
      return this.insert( {
        user_id       : ssp_user._id,
        name          : ssp_user.name,
        email         : ssp_user.email,
      } );
    }

  },
  faces         : {
    'card.summary' : [
      {
        layout : 'Badges',
        fields : [ '@badge' ],
      },
      {
        layout : 'LabelsAndValues',
        fields : [ 'email', 'username', 'slack_id' ],
      },
    ],
    'list.summary' : [
      {
        layout : {
          type       : 'ItemsBetweenTitleAndIcons',
          iconize    : [ {
            fieldName : 'slack_id',
            icon      : 'fad:id-badge',
          } ],
        },
        fields : [ '-@all' ],
      },
      {
        layout : 'ItemsAndBadges',
        fields  : [ 'email', '@badge' ],
      },
    ],
  },
} );
