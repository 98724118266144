import _ from 'lodash';
import { background, DatabaseModelError } from '@ssp/utils';
import { Resource } from './Resource';
import { isUser, isProject, isResource } from '~/utils/types';

Resource.initialize( {
  id                  : 'Resource',
  is_abstract         : true,
  is_resource         : true,
  is_project_resource : false,
  is_user_resource    : false,

  fields      : {
    _id : {
      type        : 'id',
      label       : 'ID',
      optional    : true,
      quicksearch : true,
      readonly    : true,
      index       : true,
      unique      : true,
      metadata    : true,
      identifier  : true,
      immutable   : true,
    },
    _version : {
      type                    : 'integer',
      label                   : 'Version',
      optional                : true,
      readonly                : true,
      metadata                : true,
      index                   : true,
      faker                   : 'none',
      default                 : 0,
    },
    tags      : {
      type                    : 'string',
      array                   : true,
      label                   : 'Tags',
      optional                : true,
      metadata                : true,
      faker                   : 'words.nouns(5)',
      default() { return []; },
    },
    display_name  : {
      type        : 'string',
      label       : 'Display Name',
      description : 'Name used by SSP to identify the resource',
      quicksearch : true,
      index       : true,
      optional    : true,
      readonly    : true,
      metadata    : true,
      sortable    : true,
    },
  },

  actions     : {
    edit  : {
      label          : 'Edit',
      icon           : 'fad:pencil-alt',
      route          : 'edit',
      access         : 'admin',
      disabledMethod() {
        if ( this.is_archived ) { return 'Cannot Edit Archived Resources'; }
      },
    },
    search_all  : {
      label   : 'Search All',
      icon    : 'fad:search',
      route   : ( { resource } ) => '/admin/search/' + resource.schema.id,
      type    : 'class',
      access  : 'support',
    },
    actions_help  : {
      label   : 'Help',
      icon    : 'fal:question',
      modal   : 'actions',
      group   : 'meta',
      access  : 'anonymous',
    },
  },

  resultset   : {
    sort  : '+display_name',
  },

  queries     : {
    ident( ident ) { return this.buildIdentQuery( ident ); },
    user( user_id ) {
      if ( _.isString( user_id ) ) return { user_id };
      if ( isUser( user_id ) && user_id._id ) {
        return { user_id : user_id._id };
      }
      throw new DatabaseModelError( {
        message : `Invalid argument for query user(): ${user_id}`,
        tags    : { schema : this.schema.id, query : 'user' },
        data    : { user_id },
      } );
    },
    project( project_id ) {
      if ( _.isString( project_id ) ) return { project_id };
      if ( isProject( project_id ) && project_id._id ) {
        return { project_id : project_id._id };
      }
      if ( isResource( project_id ) && project_id.project_id ) {
        return { project_id : project_id.project_id };
      }
      throw new DatabaseModelError( {
        message : `Invalid argument for query project(): ${project_id}`,
        tags    : { schema : this.schema.id, query : 'project' },
        data    : { project_id },
      } );
    },
    uniques( data ) { return this.schema.buildUniqueQuery( data ); },
  },

  faces         : {
    'card.summary' : [
      {
        layout  : 'Badges',
        fields  : [ '@badge' ],
      },
      // {
      //   layout      : 'Values',
      //   fields      :  [
      //     {
      //       name : 'project_id',
      //       label : 'Project',
      //       face : { type : 'Owner' },
      //     },
      //     {
      //       name : 'user_id',
      //       label : 'User',
      //       face : { type : 'Owner' },
      //     },
      //     '-@badge',
      //   ],
      // },
      {
        layout     : 'LabelsAndValues',
        fields     : [ '@summary', '-@badge' ],
        labelOnTop : [ 'description' ],
      },
    ],
    'panel.summary' : [
      {
        layout  : 'Badges',
        fields  : [ '@badge' ],
      },
      {
        layout  : 'PropTable',
        fields  : [
          '@summary',
          '-@badge',
        ],
      },
    ],
    'list.summary' : [
      {
        layout : {
          type       : 'ItemsBetweenTitleAndIcons',
        },
        fields : [ '-@computed' ],
      },
      {
        layout : 'ItemsAndBadges',
        fields  : [ 'description', '@badge' ],
      },
    ],
  },

  events  : {
    async afterGenerate() {
      await this.updateDisplayName();
    },
  },
  // NOTE: Cannot define behaviors in Resource, because it's loaded
  // before behaviors are, you have to define them in
  // UserResource/ProjectResource/SubDocument or later
}, BUILD.isServer && {
  events : {
    async beforeSave() {
      await this.updateDisplayName();
    },
    async afterInsert( ev ) {
      if ( ev.isSecondary || ! ev.schema.hasJob( 'sync' ) ) return;
      background( await this.job( 'sync' ), {
        label   : 'Background Sync Job',
        tags    : {
          schema    : ev.schema.id,
          job_name  : 'sync',
        },
      } );
    },
    ...require( '~/modules/resultset/events.server' ),
    ...require( '~/modules/triggers/events.server' ),
  },
} );
