import _ from 'lodash';
import { isConstructor } from '@ssp/utils';

import { getSchema } from '~/core/schemas';
import { isResource, isModel } from '~/utils/types';
import { getOrigins } from '~/modules/origins';
import { isResultSet } from '../resultset';

import type { ResultSet } from '../resultset';
import type { RenderedContext } from './types';
import type { Resource } from '~/core/resource/Resource';
import type { TransportResponse } from '~/lib/TransportResponse';
import type { TResource, TModel } from '~/types';

export type PerformActionOptions = {
  [key: string]: unknown;
};

export async function performAction<T=unknown>(
  resource: Resource | typeof Resource,
  action_name: string,
  data: Record<string, unknown> = {},
  opts: PerformActionOptions = {},
): Promise<T> {
  const res = await _performAction( resource, action_name, data, opts );
  res.fatalize();
  return res.result as T;
}
export async function _performAction<T=unknown>(
  resource: Resource | typeof Resource,
  action_name: string,
  data: Record<string, unknown> = {},
  opts: PerformActionOptions = {},
): Promise<TransportResponse<'action', T>> {
  return await getOrigins().action( resource, action_name, data, opts );
}

export function verboseCanAction(
  subject, resource, action, extraChecks,
): [ boolean, string ] {
  if ( ! subject ) return [ false, 'no subject provided' ];
  if ( ! resource ) return [ false, 'no resource provided' ];
  if ( ! action ) return [ false, 'no action provided' ];

  if ( ! ( action.access || action.capability ) ) {
    return [ false, 'no access and no capability' ];
  }
  if ( ! action.name.startsWith( '_' ) ) {
    if ( ! action.label ) return [ false, 'action has no label' ];
  }

  if ( BUILD.isProd && action.development ) {
    return [ false, 'development action in production environment' ];
  }

  if ( action.type === 'instance' && ! isResource( resource ) ) {
    return [ false, 'action has type instance, but resource is not instance' ];
  }
  if ( action.type === 'class' && ! (
    isModel( resource ) || isResultSet( resource )
  ) ) {
    return [
      false, 'action has type class, but resource is not Model or ResultSet',
    ];
  }

  // All the checks above here must only be negative checks (returning
  // false).  Checks that come after this point can return positive
  // (true) results.  The checks after this point should be only for
  // access/capability checking.  Also note that extraChecks can only
  // return strings (to indicate false) but can't return a value that
  // would short-circuit to true, which would bypass the
  // access/capability checks.
  if ( _.isFunction( extraChecks ) ) {
    const res = extraChecks( subject, resource, action );
    if ( _.isString( res ) ) return [ false, res ];
    if ( res ) {
      throw new Error(
        `Invalid return from verboseCanAction extraChecks method: ${res}`,
      );
    }
  }

  const { access, capability } = action;
  return verboseCheckControls( subject, resource, access, capability );
}

export function verboseCheckControls(
  subject, resource, access, capability,
): [ boolean, string ] {
  const checks = [
    verboseCheckAccess( subject, resource, access ),
    verboseCheckCapability( subject, capability ),
  ];
  return [
    _.every( checks, _.first ),
    _.map( checks, c => `[${c[ 0 ]}]${c[ 1 ]}` ).join( ', ' ),
  ];
}

export function verboseCheckCapability( subject, capability ) {
  if ( ! capability ) return [ true, 'no capability restrictions' ];
  if ( ! subject ) return [ false, 'no subject provided' ];

  if ( subject.hasCapability( capability ) ) {
    return [ true, `subject has capability ${capability}` ];
  } else {
    return [ false, `subject lacks capability ${capability}` ];
  }
}

export function verboseCheckAccess( subject, resource, access ) {
  if ( ! access ) return [ true, 'no access restrictions' ];
  if ( ! subject ) return [ false, 'no subject provided' ];
  if ( ! resource ) return [ false, 'no resource provided' ];

  if ( access === 'none' ) return [ false, 'no access for anyone' ];
  if ( access === 'anonymous' ) return [ true, 'anonymous access allowed' ];

  if ( access === 'support' ) {
    if ( subject.isSupport() ) {
      return [ true, 'support access, subject is support' ];
    } else {
      return [ false, 'support access, subject is not support' ];
    }
  }

  if ( isConstructor( resource ) ) {
    return [ false, `${access} access, but resource is not instance` ];
  }

  if ( subject.isSupport() ) {
    return [ true, `${access} access, subject is support` ];
  }

  if ( access === 'admin' ) return resource.canBeManagedBy( subject );

  if ( access === 'member' ) {
    if ( ! resource.schema.is_project_resource ) {
      return [ false, `${access} access, but not a project resource` ];
    }
    const owner = resource.owner || resource;
    if ( subject.isMemberOfProject( owner ) ) {
      return [ true, `${access} access, subject is ${access}` ];
    } else {
      return [ false, `${access} access, subject is not ${access}` ];
    }
  }

  return [ false, `Unknown access requirement ${access}` ];
}

/**
 * Create a "toggle action" that toggles a boolean property.  Can take
 * any options that a regular option can take, and the `enable` and
 * `disable` properties take the same options and override that value
 * for the individual states.
 *
 * @param {object} options - Options object.
 * @param {string} options.field - Field name to toggle.
 * @param {object} [options.enable] - Configuration for the enable action.
 * @param {object} [options.disable] - Configuration for the disable action.
 * @param {string} [options.icon] - Icon for the menu item.  Will be
 * used for either state if it doesn't have a specific icon specified.
 * @param {string} [options.label] - Default label.  This defaults to
 * the `field.label` value, it will have the appropriate `prefix`
 * prepended to it.
 */
export function createToggleAction( options ) {
  const { field, enable={}, disable={}, label, ...opts } = options;

  return {
    [ `enable_${field}` ] : {
      label             : `Enable ${label||field}`,
      visibilityMethod  : `!${field}`,
      method            : 'update',
      data              : { [ field ] : true },
      icon              : 'fas:toggle-on',
      ...opts,
      ...enable,
    },
    [ `disable_${field}` ] : {
      label             : `Disable ${label||field}`,
      visibilityMethod  : `${field}`,
      method            : 'update',
      data              : { [ field ] : false },
      icon              : 'fas:toggle-off',
      ...opts,
      ...disable,
    },
  };
}

export function createToggleActions( ...configs ) {
  return _.assign( {}, ..._.map( configs, createToggleAction ) );
}

export type GetActionsForOptions = {
  resource: TResource<any> | TModel<any> | ResultSet;
  subject: TResource<'SSP.User'>;
  context?: TResource<any>;
  /** Include  actions that would normally be hidden. */
  hidden?: boolean;
};
export function getActionsFor( opts: GetActionsForOptions ): RenderedContext[] {
  const schema = getSchema( opts.resource );

  const type = ( isModel( opts.resource ) || isResultSet( opts.resource ) )
    ? 'class' : 'instance';

  const actions = schema.getActions( { type } )
    .map( action => action.getRenderedContext( opts ) )
    .filter( x => x );

  if ( opts.hidden ) return actions;
  return _.reject( actions, 'hidden' );
}
