export const CAPABILITIES = [
  'bypass-restrictions',
  'create-any-user-resources',
  'create-own-user-resources',
  'create-projects',
  'impersonate-users',
  'manage-any-job',
  'manage-any-project',
  'manage-any-user',
  'manage-any-user-resources',
  'use-developer-tools',
  'use-support-tools',
  'view-any-project',
  'view-any-project-resources',
  'view-any-user',
  'view-any-user-resources',
  'view-any-job',
  'view-coworker-resources',
  'view-coworkers',
  'view-hidden-projects',
  'view-sensitive-projects',
  'view-unassociated-projects',
] as const;
export const SYSTEM_TEAMS = [
  'Everyone',
  'Employees',
  'Administrators',
  'Developers',
  'Support',
] as const;

export type SystemTeam = typeof SYSTEM_TEAMS[number];
export type Capability = typeof CAPABILITIES[number];

/**
 * This map translates from system_teams to capabilities
 */
export const CAPABILITIES_MAP: Record<SystemTeam, Readonly<Capability[]>> = {
  Everyone        : [
    'view-coworkers',
    'view-coworker-resources',
    'create-own-user-resources',
  ],
  Employees       : [
    'create-projects',
    'view-any-user',
  ],
  Administrators  : [
    'bypass-restrictions',
  ],
  Developers      : [
    'use-developer-tools',
  ],
  Support         : [
    'create-any-user-resources',
    'impersonate-users',
    'manage-any-job',
    'manage-any-project',
    'manage-any-user',
    'manage-any-user-resources',
    'use-support-tools',
    'view-any-project',
    'view-any-project-resources',
    'view-any-user',
    'view-any-user-resources',
    'view-any-job',
    'view-hidden-projects',
    'view-sensitive-projects',
    'view-unassociated-projects',
  ],
} as const;

/**
 * This map translates from acl_teams to system_teams
 */
export const SYSTEM_TEAMS_MAP: Record<string, Readonly<SystemTeam[]>> = {
  'SSP/Administrators'  : [ 'Administrators', 'Developers', 'Support' ],
  'SSP/Developers'      : [ 'Developers' ],
  'SSP/Support'         : [ 'Support' ],
  'NOC/Analysts'        : [ 'Support' ],
} as const;
