
// Default values for actions. These should be fallback values to support
// actions that are more fully defined on schema.

export default {
  discover : {
    help    : 'Search the remote service for new resources not in SSP',
  },
  import : {
    help    : 'Creates an SSP record for the specified remote object',
  },
  sync : {
    help    : 'Synchronizes SSP with the remote service',
  },
};
