import _ from 'lodash';
import { promise } from '@ssp/utils';
import { Validator, ValidationErrors } from '@ssp/types';

import type { ValidationContext, ValidatorName } from '@ssp/types';

import type { Model } from '~/core/lib/Model';
import type { FieldSelector } from '~/modules/fields/FieldSet';

export interface ValidationOptions {
  fields?: FieldSelector<any>[];
  skip?: ValidatorName[];
  safe?: boolean;
}

export async function validateModel(
  model: Model,
  options: ValidationOptions = {},
) {
  options = _.assign( {}, options, { schema : model.schema.id } );
  const ev = model.eventbox( {
    options : _.omit( {
      ...options,
      schema  : model.schema.id,
    }, 'safe' ),
    safe    : options.safe,
  } );

  await ev.before( 'validate' );

  const results = await getValidationResults( model, ev.options );

  await ev.during( 'validate', { results } );

  const errors = _.pickBy( results, _.isError );
  if ( ! _.isEmpty( errors ) ) {
    ev.error = new ValidationErrors( {
      errors,
      data : { options },
      tags : { schema : model.schema.id },
    } );
  }
  await ev.after( 'validate' );
  if ( ! ev.error ) return;
  if ( ev.safe ) return ev.error;
  throw ev.error;
}

export async function getValidationResults(
  model: Model,
  options: ValidationOptions = {},
) {
  const context: ValidationContext = {
    skip            : options.skip,
    doc             : model,
    schema          : model.schema.id,
    coerce          : false,
    strict          : false,
    keep            : true,
    defaultValues   : false,
  };

  // determine the fields that will be validated
  const fs = model.schema.getFieldSet( options.fields );
  if ( fs.empty ) fs.add( '@all' );
  fs.remove( '@virtual', '@computed' );
  if ( BUILD.isClient ) fs.remove( '@readonly' );

  // we'll keep track of the results for each field here.
  const promises = fs.mapValues( ( field, name ) => {
    return field.validate( {
      ...context,
      value : model[ name ],
      param : name,
      field : name,
      safe  : true,
    } );
  } );

  // This handles any validators that are defined for the resource
  // itself.
  promises[ '*' ] = await Validator.run( model.validators, context )
    .catch( _.identity );
  return promise.props( promises );
}
