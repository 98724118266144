import _ from 'lodash';
import { jsonParse, jsonStringify, ReferenceURL } from '@ssp/utils';
import { Schema } from '~/core/lib/Schema';
import { hasMongoOperators, isResourceTypeName } from '~/utils';
import { Origins } from './Origins';

import type { TResource } from '~/types';

/**
 * Given a string, check whether it's a stringified version number and
 * if it is turn it back into a number.  This also ensures that the
 * value passed is either a number or a stringified number and throws
 * if it's anything else.
 *
 * @param {string|number} val - Value to coerce.
 */
export function ver( val ) {
  const t = typeof val;
  if ( t === 'number' ) return val;
  if ( t !== 'string' ) {
    throw new TypeError( `Invalid version "${val}" with type "${t}"` );
  }
  if ( /\D/u.test( val ) ) {
    throw new TypeError( `Invalid version "${val}" - not a number` );
  }
  return parseInt( val );
}

const cache_keys = {
  D : {
    name   : 'Resource Data',
    labels : [ 'schema', 'id' ],
    data   : false,
  },
  M : {
    name   : 'Resource Metadata',
    labels : [ 'schema', 'id' ],
    data   : false,
  },
  R : {
    name   : 'Resolution Record',
    labels : [ 'schema' ],
    data   : true,
  },
  S : {
    name   : 'Search Record',
    labels : [ 'schema' ],
    data   : true,
  },
  X : {
    name   : 'Application Metadata',
    labels : [ 'label' ],
    data   : false,
  },
};

export function parseCacheKey( key ) {
  if ( key[1] !== ':' ) throw new Error( `Invalid cache key: ${key}` );
  // Key structure is:
  //  - single-character cache type + ':'
  //  - one or more simple string labels (separated by ':') + ':'
  //  - optional JSON encoded additional data (object or array)
  const x = /^([A-Z]):([^{]+)(?::(\{.+\}))?$/u.exec( key );
  const [ , type, raw_labels, raw_data ] = x;
  const conf = cache_keys[ type ];
  if ( ! conf ) throw new Error( `Unknown cache key type "${type}" (${key})` );
  const labels = _.compact( raw_labels.trim().split( ':' ) );
  if ( conf.labels.length !== labels.length ) {
    const e = conf.labels.length;
    const f = labels.length;
    throw new Error( `Expected ${e} labels, found ${f} (${key})` );
  }
  const res = _.zipObject( conf.labels, raw_labels );
  if ( conf.data && raw_data ) {
    res.data = jsonParse( raw_data );
  } else if ( ! conf.data && ! raw_data ) {
    // no-op
  } else if ( conf.data ) {
    throw new Error( `Expected JSON data in key (${key})` );
  } else if ( raw_data ) {
    throw new Error( `Found unexpected JSON data in key (${key})` );
  }
  return res;
}

export function createCacheKey( type, ...labels ) {
  const conf = cache_keys[ type ];
  if ( ! conf ) throw new Error( `Unknown cache key type "${type}"` );
  const parts = [ type ];
  for ( const label of conf.labels ) {
    const value = labels.shift();
    if ( _.isNil( value ) ) return;
    if ( label === 'schema' ) {
      if ( isResourceTypeName( value ) ) {
        parts.push( value );
      } else {
        return;
      }
    } else if ( label === 'id' ) {
      if ( _.isString( value ) ) {
        parts.push( value );
      } else {
        return;
      }
    } else {
      parts.push( value );
    }
  }
  if ( conf.data ) {
    const value = labels.shift();
    if ( _.isPlainObject( value ) ) {
      parts.push( jsonStringify( value, { space : 0 } ) );
    } else {
      return;
    }
  }
  const label = parts.join( ':' );
  if ( labels.length ) return label;
}

/**
 * Extract the identifiers for a resource or a query.  If given
 * a resource as an argument, extracts the identifiers that should
 * be cached for resolving that resource.  If given a query object,
 * extracts the identifiers that could be used to resolve that query
 * from the cache.  If given a string or an array of strings,
 * computes all the possible identifiers that could resolve for
 * those idents.
 *
 * @param schema - The schema to use to determine idents.
 * @param data - The resource or query to extract from.
 * @returns  - Returns objects representing the field values
 * for each possible unique identifier for the resource.
 */
export function extractIdents(
  schema: Schema,
  data: TResource<any> | ReferenceURL | Record<string, any> | string | string[],
): Record<string, string>[] {
  if ( _.isNil( data ) ) return [];
  if ( _.isString( schema ) ) schema = Schema.demand( schema );

  if ( _.isString( data ) ) {
    const ref = new ReferenceURL( data );
    if ( ref.valid ) {
      data = ref;
    } else {
      data = [ data ];
    }
  }

  if ( data instanceof ReferenceURL ) {
    if ( data.id ) {
      data = data.partials;
    } else if ( data.ident ) {
      data = [ data.ident ];
    } else {
      return [];
    }
  }

  if ( data instanceof Schema.demand( 'Resource' ).model ) data = data.toJSON();
  if ( hasMongoOperators( data ) ) return [];

  if ( _.isArray( data ) ) {
    data = _.filter( data, _.isString );
    if ( ! data.length ) return [];
    const ids = schema.getFieldNames( '@identifier' );
    return _.flatMap( data, val => _.map( ids, key => ( { [key] : val } ) ) );
  }

  if ( ! _.isObject( data ) ) return [];

  const indexes = schema.getIndexes();
  return _.flatMap( _.filter( indexes, 'unique' ), index => {
    const names = _.keys( index.fields );
    // get values for all the fields covered by the index
    const obj = _.pick( data, names );
    // Don't include if we don't have values for all the fields
    for ( const name of names ) {
      if ( _.isNil( obj[ name ] ) ) return [];
    }
    if ( _.size( obj ) === 1 ) {
      const [ key ] = _.keys( obj );
      const val = obj[ key ];
      if ( _.isArray( val ) ) return _.map( val, v => ( { [key] : v } ) );
    }
    return obj;
  } );
}

let _origins: Origins;
export function getOrigins() {
  return _origins || ( _origins = new Origins() );
}
