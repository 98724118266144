import { SubDocument } from '~/core/resource/SubDocument';

export class MemberInfoPendingChange extends SubDocument {
  declare change_type: 'add' | 'remove' | 'update';
  declare is_discovered: boolean;
  declare is_discovery: boolean;
  declare config: Record<string, unknown>;
  declare error: string;
  declare requested_by: string;
  declare requested_at: Date;

  get is_remove() { return this.change_type === 'remove'; }
  get is_add() { return this.change_type === 'add'; }
  get is_update() { return this.change_type === 'update'; }
}

MemberInfoPendingChange.initialize( {
  id              : 'MemberInfoPendingChange',
  is_subdocument  : true,
  name            : 'Membership Pending Change',
  inherit         : 'SubDocument',
  updates         : { mode : 'replace' },
  fields          : {
    change_type : {
      type      : 'string',
      describe  : 'Type of change',
      default   : false,
      options   : [ 'add', 'remove', 'update' ],
    },
    is_discovered  : {
      type      : 'boolean',
      describe  : 'Change was discovered rather than requested',
      default   : false,
    },
    is_discovery   : {
      type      : 'boolean',
      describe  : 'Change was discovered through "this" resource',
      default   : false,
    },
    config      : {
      type      : 'object',
      describe  : 'Configuration fields',
      default   : {},
    },
    requested_at  : {
      type        : 'date',
      optional    : false,
      index       : true,
      metadata    : true,
      readonly    : true,
      faker       : 'date.recent',
      sortable    : true,
    },
    requested_by  : {
      type        : 'id',
      optional    : false,
      index       : true,
      metadata    : true,
      readonly    : true,
      faker       : 'user.id',
      sortable    : true,
    },
    preparation_job : {
      type      : 'link',
      label     : 'Preparation Job',
      form      : false,
      summary   : true,
      model     : 'SYSTEM.Job',
      optional  : true,
      readonly  : true,
    },
  },
  behaviors : {
    hasErrorInfo    : {},
  },
}, BUILD.isServer && {
  events    : {
    beforeSave() {
      this.defaults( {
        requested_at : new Date(),
        requested_by : ctx.get( 'user._id', 'SYSTEM' ),
      } );
    },
  },
} );
