
export const permissionsMap = [
  {
    role        : 'Admin',
    permissions : [
      'SETPAGEPERMISSIONS', // Restrictions Add/Delete
      'REMOVEPAGE', // Pages Delete
      'EDITBLOG', // Blog Add
      'EDITSPACE', // Pages Add
      'SETSPACEPERMISSIONS', // Space Admin
      'VIEWSPACE', // All View
      'REMOVEBLOG', // Blog Delete
      'COMMENT', // Comment Add
      'CREATEATTACHMENT', // Attachment Add
      'REMOVEATTACHMENT', // Attachment Add
    ],
  },
  {
    role        : 'Member',
    permissions : [
      'SETPAGEPERMISSIONS', // Restrictions Add/Delete
      'REMOVEPAGE', // Pages Delete
      'EDITBLOG', // Blog Add
      'EDITSPACE', // Pages Add
      'VIEWSPACE', // All View
      'REMOVEBLOG', // Blog Delete
      'COMMENT', // Comment Add
      'CREATEATTACHMENT', // Attachment Add
      'REMOVEATTACHMENT', // Attachment Delete
    ],
  },
  {
    role        : 'Read-Only',
    permissions : [
      'VIEWSPACE', // All View
    ],
  },
];

// Permissions that are not initially included by "role".
// These can be applied in Confluence.
export const unusedPermissions = [
  'EXPORTPAGE', // Space Export
  'EXPORTSPACE', // Space Export
  'REMOVEOWNCONTENT', // All Delete Own
  'REMOVECOMMENT', // Comments Delete
  'REMOVEMAIL', // Email Delete
];
