import _ from 'lodash';
import {
  Emitter, background, hideProps, mkdebug,
  createRefUrl, parseRefUrl,
} from '@ssp/utils';

import { getSchema } from '~/core/schemas';
import { getTransport } from '~/lib';

import { getCache } from './cache';
import { Version } from './Version';
import { createCacheKey } from './utils';
import { Counts } from './Counts';
import { getWithBatchLoader } from './batches';

import type { SchemaId } from '~/types';
import type { TransportResponse } from '~/lib/TransportResponse';

import type { LiveUpdateMessage } from './types';
import type { BrokerLoadOptions } from '../broker/Broker';

export type OriginLoadOptions = BrokerLoadOptions;

const debug = mkdebug( 'ssp:database:origins:origin' );

// These keys represent the Origin properties that get stored in the
// cache as an object under `M:<type>:<id>`.
const metaKeys = [
  'type', 'id', 'name', 'deleted',
  'highestSeenVersion', 'highestSeenTime',
];

/** This class represents a single instance of an origin record. */
export class Origin extends Emitter {

  /**
   * Stored resource name.  This will be used to provide a name field
   * when one was found in a ref_url but the resource hasn't been
   * loaded yet.
   */
  name: string = '';

  declare type: SchemaId;
  declare id: string;
  declare version: number;

  /**
   * The highest version number that we have seen and therefore know
   * exists.  This may be higher than the version number of the data
   * we have cached, if we've received a ref_url from from the server
   * with a higher version but haven't yet fetched that resource.
   */
  highestSeenVersion: number = 0;

  /**
   * A timestamp indicating when we saw the version that is indicated
   * by highestSeenVersion.
   */
  highestSeenTime: number = 0;

  /**
   * Gets set to true if this object's meta has been loaded from the
   * cache.
   */
  #loaded: boolean = false;
  get loaded() { return this.#loaded; }

  /** The highest version that we know about. */
  get latest() { return this.highestSeenVersion || 0; }

  declare cache_key: string;
  declare main_channel: string;

  /**
   * @param type - Resource Type.
   * @param id - Resource ID.
   */
  constructor( type: SchemaId, id: string ) {
    super();
    if ( ! _.isString( type ) ) {
      throw new Error( `Cannot create Origin without type` );
    }
    if ( ! _.isString( id ) ) {
      throw new Error( `Cannot create Origin without id` );
    }
    _.assign( this, { type, id } );
    this.cache_key = createCacheKey( 'M', this.type, this.id );

    if ( type === 'SSP.User' ) {
      this.main_channel = `users/${id}`;
    } else if ( type === 'SSP.Project' ) {
      this.main_channel =  `projects/${id}`;
    } else {
      this.main_channel = `resources/${type}/${id}`;
    }
  }

  debug( ...args ) { debug( this.toString(), ...args ); }

  equals( field, value1, value2 ) {
    return this.schema.getField( field ).equals( value1, value2 );
  }

  get ref_url() {
    return createRefUrl( {
      type    : this.type,
      id      : this.id,
      name    : this.name,
      version : this.latest,
    } );
  }

  declare _version_instance?: Version;
  async getCachedVersion(): Promise<Version> {
    return this._version_instance || (
      this._version_instance = await Version.fromCache( this.type, this.id )
    );
  }

  async loadMeta() {
    if ( this.loaded ) return;
    const meta = await getCache().get( this.cache_key );
    _.assign( this, meta );
    this.#loaded = true;
  }

  async updateMeta( meta={} ) {
    await this.loadMeta();
    _.assign( this, meta );
    await getCache().set( this.cache_key, _.pick( this, metaKeys ) );
    // If we got notified of a newer version while loading then we
    // discard the pending load promise so that a new attempt will
    // request the new version.
    this.#loading = undefined;
  }

  get schema() { return getSchema( this.type ); }
  get model() { return this.schema.model; }

  #loading?: Promise<TransportResponse>;

  /** Load this resource. */
  load( opts: OriginLoadOptions = {} ): Promise<TransportResponse>  {
    this.debug( 'Origin.load:', opts, this.#loading );
    if ( this.#loading ) return this.#loading;
    const promise = this._load( opts );
    this.#loading = promise;
    promise.then( ( res ) => { this.#loading = undefined; return res; } );
    return promise;
  }

  _load(
    options: OriginLoadOptions = {},
  ): Promise<TransportResponse> {
    const { cache = false, ...opts } = options;
    // eslint-disable-next-line no-async-promise-executor
    return new Promise( async ( resolve, reject ) => {
      const have = await this.getCachedVersion();
      if ( cache && have ) {
        this.debug( 'RETURNING FROM CACHE WITH VERSION:', have );
        this.refresh();
        return resolve( have.toTransportResponse() );
      }
      const version = have?.version || 0;
      const { schema, id } = this;
      try {
        const res = opts.insert
          ? await getTransport().retrieve( schema, id, { ...opts, version } )
          : await getWithBatchLoader( schema, id, version );
        await this.processResponse( res );
        return resolve( res );
      } catch ( error ) {
        reject( error );
      }
    } );
  }

  /** Reload this resource, ignoring any existing cache. */
  async reload() {
    const res = await getTransport().retrieve( this.schema, this.id );
    await this.processResponse( res );
    return res;
  }

  declare _last_refresh?: number;
  /**
   * Attempt to fetch an updated version of this resource in the backgound.
   */
  refresh = _.throttle( () => {
    this.debug( `Refresh requested` );
    this._last_refresh = Date.now();

    background( this.load( { cache : false } ), {
      label       : `Refresh ${this}`,
      logResolve  : 'debug',
    } );
  }, 5_000, { leading : false, trailing : true } );

  /**
   * See https://pages.github.boozallencsn.com/SelfServicePortal/docs/deep-dives/live-updates#dboriginprocessref
   */
  async processRef( ref ) {
    this.debug( 'Processing Ref', ref );
    if ( _.isString( ref ) ) ref = parseRefUrl( ref );
    if ( ! ( ref.valid && ref.resolved ) ) return;
    const { name, version } = ref;
    return this.updateMeta( {
      name,
      highestSeenVersion : version,
      highestSeenTime    : Date.now(),
    } );
  }

  /**
   * Process a live update payload.
   * See https://pages.github.boozallencsn.com/SelfServicePortal/docs/deep-dives/live-updates#dboriginsprocessliveupdate
   */
  processLiveUpdate( payload: LiveUpdateMessage ) {
    return background( this._processLiveUpdate( payload ), {
      label       : `own live update ${this}`,
      // logResolve  : 'debug',
    } );
  }

  /**
   * See https://pages.github.boozallencsn.com/SelfServicePortal/docs/deep-dives/live-updates#dboriginsprocessliveupdate
   */
  async _processLiveUpdate( payload: LiveUpdateMessage ) {
    this.debug( '_processLiveUpdate/payload', payload );
    const ref = parseRefUrl( payload.ref_url );
    this.debug( '_processLiveUpdate/ref', ref );
    if ( ! ref.valid ) {
      this.debug( `_processLiveUpdate/ref invalid, skipping` );
      return;
    }
    if ( ! ref.resolved ) {
      this.debug( `_processLiveUpdate/ref not resolved? skipping` );
      return;
    }
    this.debug( '_processLiveUpdate -> processRef' );
    payload.ref = ref;
    await this.processRef( ref );
    await this.emitLiveUpdate( payload );
    await this.#counts?.processLiveUpdate( payload );
  }

  #watchers: Set<( update: LiveUpdateMessage ) => void> = new Set();
  watch( callback: ( update: LiveUpdateMessage ) => void ) {
    if ( ! this.#watchers.size ) this.#startSubscription();
    this.#watchers.add( callback );
    this.on( 'live-update', callback );
    return () => this.unwatch( callback );
  }
  unwatch( callback: ( update: LiveUpdateMessage ) => void ) {
    this.#watchers.add( callback );
    this.off( 'live-update', callback );
    if ( ! this.#watchers.size ) this.#stopSubscription();
  }
  #startSubscription() {
    const { socket } = getTransport();
    if ( socket ) socket.leaveChannel( this.main_channel );
  }
  #stopSubscription() {
    const { socket } = getTransport();
    if ( socket ) socket.leaveChannel( this.main_channel );
  }

  async emitLiveUpdate( payload: LiveUpdateMessage ) {
    if ( ! this.hasListeners( 'live-update' ) ) {
      this.debug( 'emitLiveUpdate/no-listeners' );
      return;
    }
    this.debug( 'emitLiveUpdate/has-listeners' );
    const res = await this.load( {} );
    _.assign( payload, {
      response  : res,
      version   : ( res instanceof Version ) ? res : res._version_instance,
    } );
    this.debug( 'emitLiveUpdate/payload', payload );
    await this.emit( 'live-update', payload );
  }

  getInstance( opts={} ) {
    return this.model.construct( {
      method  : 'Origin.getInstance',
      ...opts,
      origin  : this,
    } );
  }

  hydrate( opts={} ) {
    return this.getInstance( { method : 'Origin.hydrate', ...opts } );
  }

  declare last_transport_response?: TransportResponse;
  async processResponse( res ) {
    this.last_transport_response = res;
    this.debug( 'processResponse:', res );
    if ( this._version_instance ) {
      // If the version we have in memory is newer than the version that
      // is in the response, then we just ignore this response and
      // return the version we already have.
      if ( this._version_instance.version > res.version ) {
        this.debug( `have ${this.vtag} ignoring ${res.version}` );
        res._version_instance = this._version_instance;
        return res;
      }
    }
    // This is wrapped in an if because there are conditions where we
    // can get back a response that has a ref_url but no resource (if
    // we were retrieving and already had the latest version, or
    // attempting to update with no changes, for example).
    if ( res.resource ) {
      const version = new Version( res.type, res.id, res.version );
      version.setData( res.resource );
      res._version_instance = version;
      hideProps( this, { _version_instance : version } );
      this.version = version.version;
      this.emit( 'version', version );
      res._version_instance = this._version_instance;
      await version.saveCache();
    } else if ( res.version ) {
      await this.updateMeta( {
        highestSeenVersion : res.version,
        highestSeenTime    : Date.now(),
      } );
    }
    return res;
  }

  waitForResponse() {
    return new Promise( ( resolve, reject ) => {
      this.once( 'response', res => {
        if ( res.ok ) {
          resolve( this );
        } else {
          const err = new Error();
          _.assign( err, res.error );
          reject( err );
        }
      } );
    } );
  }

  get vtag() { return `v${this.version?.version || '0'}`; }
  toString() {
    return `Origin<${this.type}/${this.id}/${this.vtag}>`;
  }

  getMeta() {
    return {
      ..._.pick( this, [
        ...metaKeys, 'loaded', 'vtag',
      ] ),
      loading           : !! this.#loading,
      last_refresh      : this._last_refresh,
      version_instance  : !! this._version_instance,
      emitter           : super.getMeta(),
    };
  }
  getData() { return this._version_instance?.getData(); }

  #counts?: Counts;
  get has_counts() { return !! this.#counts; }
  get counts(): Counts {
    if ( this.#counts ) return this.#counts;
    if ( ! this.schema.hasBehavior( 'hasResourceCounts' ) ) return;
    return this.#counts = new Counts( this.type, this.id );
  }

}
Object.defineProperty( Origin, 'Version', {
  value         : Version,
  enumerable    : true,
  writable      : false,
  configurable  : false,
} );
