import { createBehavior } from '~/core/creators';

export type HasTimestampsOptions = Record<string, never>;
declare module './declarations' {
  export interface BehaviorConfigs {
    hasTimestamps?: HasTimestampsOptions | boolean;
  }
  export interface BehaviorOptions {
    hasTimestamps?: HasTimestampsOptions;
  }
}

createBehavior<HasTimestampsOptions>( {

  name  : 'hasTimestamps',
  build() {
    return [
      {
        fields      : {
          created_at  : {
            type        : 'date',
            immutable   : true,
            optional    : true,
            index       : true,
            metadata    : true,
            readonly    : true,
            faker       : 'date.past',
            sortable    : true,
          },
          created_by  : {
            type        : 'id',
            immutable   : true,
            optional    : true,
            index       : true,
            metadata    : true,
            readonly    : true,
            faker       : 'user.id',
            sortable    : true,
          },
          updated_at  : {
            type        : 'date',
            optional    : true,
            index       : true,
            metadata    : true,
            readonly    : true,
            faker       : 'date.recent',
            sortable    : true,
          },
          updated_by  : {
            type        : 'id',
            optional    : true,
            index       : true,
            metadata    : true,
            readonly    : true,
            faker       : 'user.id',
            sortable    : true,
          },
        },
      },
      BUILD.isServer && {
        events      : {
          beforeInsert() {
            this.merge( {
              created_at : new Date(),
              created_by : ctx.get( 'user._id', 'SYSTEM' ),
            } );
          },
          duringUpdate( ev ) {
            ev.updates.add( [
              {
                type  : 'set',
                field : 'updated_at',
                value : new Date(),
              },
              {
                type  : 'set',
                field : 'updated_by',
                value : ctx.get( 'user._id', 'SYSTEM' ),
              },
            ] );
          },
        },
      },
    ];
  },

} );
