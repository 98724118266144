import _ from 'lodash';
import { Module } from '~/core/lib/Module';
import { Face } from './Face';

Module.create( {
  name      : 'faces',
  services  : true,

  parse( data, origin ) {
    if ( _.isEmpty( data.faces ) ) return;
    const faces = _.omitBy( _.mapValues( data.faces, ( conf, name ) => {
      if ( _.isArray( conf ) ) return { name, sections : conf, origin };
      return { name, ...conf, origin };
    } ), _.isNil );
    if ( _.isEmpty( faces ) ) return;
    return { faces };
  },

  apply( data, config ) {
    if ( _.isEmpty( data.faces ) ) return;
    const faces = _.mapValues( data.faces, conf => {
      return new Face( { ...conf, component : config.id } );
    } );
    config.add( { faces } );
  },

} );
