import _ from 'lodash';
import { promise } from '@ssp/utils';

import { Model } from '~/core/lib/Model';
import { Schema } from '~/core/lib/Schema';

import type { SchemaId } from '~/types';
import type { FieldSelector } from '../fields';

export type FillModelOptions = {
  /**
   * Data to provide to the resource.  You can use this to inject
   * things like `project_id`, but note that if you are generating
   * more than one resource it will fail if there are `unique` fields
   * in `data`.
   */
  data?: Record<string, any>;
  /**
   * Provide an object mapping field names to fakers to override the
   * fakers normally defined for those fields.
   */
  fakers?: Record<string, string>;
  /**
   * Provide an object mapping field names to default values.  These
   * will be applied as defaults to the resource after the generate
   * step.  If any of the values are functions they will be run
   * (separately for each resource).
   */
  defaults?: Record<string, string>;
  /** Tags to be added to all the generated resources. */
  tags?: string[];
  /** Only generate values for fields matching these selectors. */
  fields?: FieldSelector<any>[];
};
export type GenerateModelOptions = FillModelOptions & {
  /** The type of Model to generate. */
  type?: SchemaId | typeof Model | Schema;
  /**
   * Whether or not to save the resource after generating. If false
   * the resources will just be created and returned and you can
   * manipulate them more as needed before saving them yourself.
   *
   * @default true
   */
  save?: boolean;
  /** Id of owner resource to generate related resources for. */
  owner?: string;
  count?: number;
};
export type GenerateModelsOptions = GenerateModelOptions & {
  /**
   * How many resources to generate.  If you provide a number then the
   * return result will be an array (even if the number is 1).  If
   * omitted then one resource will be generated and returned
   * directly.
   */
  count: number;
};

/** Generate multiple resources of a given type or schema. */
export async function generateModels<T extends Model>(
  options: GenerateModelsOptions,
): Promise<T[]> {
  const { count, ...opts } = options;
  if ( _.isNil( count ) ) return generateModel( opts );
  // If you specify a count you'll always get back an array, even if
  // the count was 1, if you don't then you only get back the one
  // generated model
  return promise.mapseries(
    _.times( count ),
    () => generateModel( opts ),
  );
}

/** Generate a single resource of a given type or schema. */
export async function generateModel<T extends Model>(
  options: GenerateModelOptions,
): Promise<T> {
  if ( _.isNumber( options.count ) ) return generateModels( options );
  const { type, owner, save = true, count, ...opts } = options;

  const model = Model.demand( type );
  const rsrc = model.create();

  if ( owner ) {
    if ( model.schema.is_user_resource ) {
      _.defaultsDeep( opts, { data : { user_id : owner } } );
    }
    if ( model.schema.is_project_resource ) {
      _.defaultsDeep( opts, { data : { project_id : owner } } );
    }
  }
  await fillModel( rsrc, opts );
  if ( save && typeof rsrc.save === 'function' ) await rsrc.save();
  return rsrc;
}

/** Use the faker methods to fill in a resource. */
export async function fillModel(
  model: Model,
  options: FillModelOptions = {},
) {
  const ev = model.eventbox( { options } );
  await ev.before( 'generate' );

  const data = await promise.props( _.mapValues( options.data, ( v, k ) => {
    return ( _.isFunction( v ) ) ? v.call( model, k ) : v;
  } ) );
  model.merge( data );
  const fields = getEmptyFields( model.schema, model, options.fields as any );
  const {
    generate,
  } = await import( /* webpackChunkName: "faker" */ './faker' );
  const more_data = await generate( {
    ..._.pick( options, 'fakers', 'defaults' ),
    fields, data,
  } );
  model.merge( more_data );

  const tags = _.flatMap( _.uniq( _.compact( _.flatten( [
    ( model as $TSFixMe ).tags, options.tags,
  ] ) ) ), tag => ( _.isFunction( tag ) ? tag( data ) : tag ) );
  model.merge( { tags } );

  if ( typeof model.updateDisplayName === 'function' ) {
    await model.updateDisplayName();
  }
  await ev.after( 'generate' );
  return model;
}

function getEmptyFields( schema, data, fields='@all' ) {
  const fs = schema.getFieldSet( fields );
  fs.remove( '@virtual', '@computed' );
  return fs.getFields().filter( ( { name } ) => {
    return _.isNil( data[ name ] )
      || ( data[ name ] === '' )
      || ( _.isObject( data[ name ] ) && _.isEmpty( data[ name ] ) );
  } );
}

export async function generate( ...args ) {
  const {
    generate,
  } = await import( /* webpackChunkName: "faker" */ './faker' );
  return generate( ...args );
}
export async function fake( ...args ) {
  const {
    fake,
  } = await import( /* webpackChunkName: "faker" */ './faker' );
  return fake( ...args );
}
