import { createResource } from '~/core/creators';

createResource( {
  id               : 'GitHub.Organization',
  name             : 'GitHub Organization',
  attachable       : [ 'SSP.Project' ],
  service_id_field : 'github_id',
  icon             : 'fab:github',
  featured         : true,
  fields           : {
    // The API calls this login, so I'm changing it to login.  If you
    // are reading this then this might have caused you pain, but
    // trust me when I say it's less pain than you would have
    // eventually encountered by leaving it the other way..
    login           : {
      type        : 'github-login',
      label       : 'Account Name',
      help        : [
        `The organization's name.`,
        `Only letters, numbers and hyphen are allowed.`,
      ].join( ' ' ),
      summary     : true,
      createonly  : true,
      index       : true,
      unique      : true,
      identifier  : true,
      quicksearch : true,
      faker       : 'gh-{{words.adjective}}-{{words.noun}}',
    },
    name            : {
      type        : 'string',
      label       : 'Display Name',
      quicksearch : true,
      optional    : true,
      minimal     : true,
      default( data={} ) { return data.login || this.login; },
    },
    description      : {
      type        : 'string',
      label       : 'Description',
      summary     : true,
      required    : false,
      quicksearch : true,
      optional    : true,
    },
    github_id         : {
      type        : 'github-id',
      label       : 'GitHub ID',
      unique      : true,
      summary     : true,
      index       : true,
      optional    : true,
      readonly    : true,
      immutable   : true,
    },
    avatar_url       : {
      type        : 'string',
      readonly    : true,
      label       : 'Avatar URL',
      optional    : true,
    },
    company          : {
      type        : 'string',
      label       : 'Company',
      readonly    : true,
      optional    : true,
    },
    location       : {
      type        : 'string',
      readonly    : true,
      label       : 'Location',
      optional    : true,
    },
    default_repository_permission : {
      type        : 'string',
      readonly    : true,
      label       : 'Default Repository Permissions',
      optional    : true,
    },
    members_can_create_repositories : {
      type        : 'boolean',
      readonly    : true,
      label       : 'Members can create repositories',
      optional    : true,
    },
    blog              : {
      type        : 'string',
      readonly    : true,
      label       : 'Blog',
      optional    : true,
    },
  },
  methods       : class {
    static get displayNameFields() { return [ 'name', 'login' ]; }
  },
  behaviors : {
    hasExternalLink : {},
    hasMembers      : {
      withCreator : { config : { role : 'Admin' } },
      userType    : 'GitHub.User',
      withRole    : {
        roles         : [ 'Admin', 'Member' ],
        defaultRole   : 'Member',
      },
      withService : true,
    },
  },
  faces         : {
    'card.summary' : [
      {
        layout : 'Badges',
        fields : [ '@badge' ],
      },
      {
        layout : 'LabelsAndValues',
        fields : [ 'login', 'members_count', 'external_link', 'description' ],
      },
    ],
    'list.summary' : [
      {
        layout : {
          type       : 'ItemsBetweenTitleAndIcons',
          iconize    : [ {
            fieldName : 'login',
            icon      : 'fad:id-badge',
          } ],
        },
        fields : [ '-@all' ],
      },
      {
        layout : 'ItemsAndBadges',
        fields  : [ 'description', '@badge' ],
      },
    ],
  },
} );
