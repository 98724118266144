import _ from 'lodash';
import { stringifyError } from '@ssp/utils';
import { Resource } from '~/core/resource/Resource';

import { JOB_PRIORITIES, isJobPriority } from '~/modules/jobs';

import type { TResource, SchemaId } from '~/types';
import type { List } from '~/modules';
import type { JobStep } from './steps';
import type { DateTime } from 'luxon';
import type { JobPriority, JobStatus, JobProgress } from '~/modules/jobs';

import './steps';

export class Job extends Resource {
  // TODO - Get rid of this one we have augmented type inference for fields
  declare job_name: string;
  declare name: string;
  declare owner: TResource<'SSP.User'>;
  declare owner_id: string;
  declare parent?: Job;
  declare parent_id?: string;
  declare user?: TResource<'SSP.User'>;
  declare user_id?: string;
  declare project?: TResource<'SSP.Project'>;
  declare project_id?: string;
  declare resource?: TResource<any> | typeof Resource;
  declare resource_id?: string;
  declare resource_type: SchemaId;
  declare steps: List<JobStep>;
  declare data: Record<string, any>;
  declare status: JobStatus;
  declare started_at?: DateTime;
  declare ended_at?: DateTime;
  declare error?: string;
  declare timeout: string;
  declare attempts: number;
  declare next_run_at: DateTime;
  declare status_reason?: string;
  declare running_on?: string;
  declare running_at?: DateTime;
  declare max_run_time?: string;
  declare max_attempts?: number;
  declare do_not_coalesce: boolean;
  declare priority: JobPriority;
  declare priority_number: number;
  declare sentry_error_id?: string;

  static cssColorMap = {
    queued      : 'grey',
    pending     : 'grey',
    waiting     : 'grey',
    interactive : 'grey',
    complete    : 'green',
    failed      : 'red',
    cancelled   : 'red',
    deferred    : '#FFC105', // orange matching Bootstrap warning
  };

  static cssClassMap = {
    queued      : 'secondary',
    pending     : 'secondary',
    waiting     : 'secondary',
    interactive : 'secondary',
    complete    : 'success',
    failed      : 'danger',
    cancelled   : 'danger',
    deferred    : 'warning',
  };

  static iconMap = {
    queued      : 'far:circle',
    pending     : 'far:circle',
    waiting     : 'far:circle',
    interactive : 'fas:people-arrows',
    complete    : 'fas:check-circle',
    failed      : 'fas:times-circle',
    cancelled   : 'fas:times-circle',
    deferred    : 'fas:pause-circle',
  };

  // These two lists (`isPendingStatuses` and `isEndedStatuses`)
  // should cover all possible statuses
  static isPendingStatuses() {
    return [
      'queued', // in the queue, waiting to run
      'waiting', // waiting for something (like a sub-job)
      'deferred', // waiting for a temporary error to clear
      'interactive', // waiting for interaction
    ];
  }
  static isEndedStatuses() {
    return [
      'complete', // finished successfully
      'failed', // finished unsuccessfully
      'cancelled', // aborted by user
    ];
  }

  static priorities = JOB_PRIORITIES;
  static getPriorityNumber( priority: JobPriority|number|string ) {
    if ( _.isNumber( priority ) ) return priority;
    return _.indexOf( this.priorities, priority );
  }
  static getPriorityName( priority: JobPriority|number|string ) {
    if ( isJobPriority( priority ) ) return priority;
    if ( _.isInteger( priority ) ) return this.priorities[ priority ];
  }

  // Breadcrumbs and baseurl ensure this.resource has id otherwise
  // it is referencing a component and the route structure needs to
  // adjust
  getBreadcrumbParent() {
    return this.resource?._id
      ? this.resource
      : this.parent;
  }

  async getBreadcrumbOwner() {
    if ( this.resource?._id ) return ( await this.resource.load() ).owner;
    return this.owner;
  }

  baseurl() {
    if ( this.resource?._id ) {
      return this.resource.route(
        'job',
        this._id,
      );
    } else if ( this.owner ) {
      return this.owner.route( 'job', this._id );
    } else {
      return `/job/${this._id}`;
    }
  }

  getFullName() {
    return `${this.resource_type} ${this.job_name} (${this._id})`;
  }

  get owner_type() { return 'SSP.User'; }

  getTitle() { return this.getFullName(); }

  getParent() { return this.parent; }

  /**
   * like `getParent` but continues traversing the inheritance tree
   * to the original job.
   *
   * @returns {Job} - the root job
   */
  async getRoot() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let iter = this;
    const seen = [];
    while ( iter.parent && !_.includes( seen, iter.parent._id ) ) {
      seen.push( iter.parent._id );
      iter = await iter.getParent().load();
    }
    return iter;
  }

  isRunnable() {
    return _.includes( Job.isPendingStatuses(), this.status );
  }

  isPending() {
    return _.includes( Job.isPendingStatuses(), this.status );
  }

  isEnded() {
    return _.includes( Job.isEndedStatuses(), this.status );
  }

  isWaiting() {
    return _.includes( [
      'waiting', // waiting for something (like a sub-job)
      'deferred', // waiting for a temporary error to clear
      'interactive', // waiting for user input
    ], this.status );
  }

  isSuccess() {
    return _.includes( [
      'complete', // finished successfully
    ], this.status );
  }

  isFailure() {
    return _.includes( [
      'failed', // finished unsuccessfully
      'cancelled', // aborted by user
    ], this.status );
  }

  isFinished() { return this.isSuccess() || this.isFailure(); }

  toString() { return `SYSTEM.Job[${this._id}] - ${this.name}`; }

  get remaining_steps() {
    const steps = [];
    for ( const step of this.steps ) {
      if ( step.is_failure ) {
        if ( step.ignore_errors ) continue; else return [];
      }
      if ( step.is_success ) continue;
      steps.push( step );
    }
    return steps;
  }

  get next_step() {
    for ( const step of this.steps ) {
      if ( step.is_failure ) {
        if ( step.ignore_errors ) continue; else return;
      }
      if ( step.is_success ) continue;
      return step;
    }
  }

  findNextInteractiveStep() {
    return _.find( this.remaining_steps, [ 'status', 'interactive' ] );
  }

  allStepsComplete() {
    return this.steps.every( step => step.isComplete() );
  }
  someStepsFailed() {
    return this.steps.some( step => step.isFailure() );
  }

  /**
   * @returns {StepProgress|null}
   */
  getStepProgress() {
    if ( ! this.steps ) return null;
    const complete = this.steps.filter( 'is_complete' ).length;
    return {
      stepsCompleted  : complete,
      totalSteps      : this.steps.size,
      completionPct   : Math.floor( ( complete / this.steps.size ) * 100 ),
    } as JobProgress;
  }

  canBeManagedBy( subject ) {
    return this._canBeManagedBy( subject, () => {
      // Can always manage jobs you created
      if ( this.owner_id && this.owner_id === subject._id ) {
        return [ true, 'subject is job creator' ];
      }
      // Support-type people that can manage any job
      if ( subject.hasCapability( 'manage-any-job' ) ) {
        return [ true, 'subject can manage any job' ];
      }
      if ( this.project ) {
        return this.project.canBeManagedBy( subject );
      } else if ( this.user ) {
        return this.user.canBeManagedBy( subject );
      }
    } );
  }

}

Job.initialize( {
  id      : 'SYSTEM.Job',
  name    : 'Job',
  icon    : 'far:briefcase',
  inherit : 'Resource',

  fields      : {
    job_name    : {
      type        : 'string',
      label       : 'Job Name',
      index       : true,
      unique      : false,
      summary     : true,
    },
    name        : {
      type        : 'string',
      label       : 'Name',
      index       : true,
      unique      : false,
      quicksearch : true,
      summary     : false,
      sortable    : true,
      optional    : true,
    },
    owner : {
      type        : 'link',
      label       : 'Owner',
      help        : 'The user that initiated this job',
      form        : false,
      summary     : true,
      model       : 'SSP.User',
      optional    : true,
    },
    owner_id   : {
      type      : 'string',
      computed  : { compute() { return this.owner?._id; } },
    },
    parent : {
      type        : 'link',
      label       : 'Parent Job',
      help        : 'The parent job that initiated this job',
      form        : false,
      summary     : true,
      model       : 'SYSTEM.Job',
      optional    : true,
      enumerable  : false,
    },
    parent_id   : {
      type      : 'string',
      computed  : { get() { return this.parent?._id; } },
    },
    user     : {
      type        : 'link',
      label       : 'User',
      help        : 'User context for this job.',
      form        : false,
      summary     : true,
      quicksearch : true,
      optional    : true,
      model       : 'SSP.User',
    },
    user_id     : {
      type      : 'string',
      computed  : { get() { return this.user?._id; } },
    },
    project  : {
      type        : 'link',
      label       : 'Project',
      help        : 'Project context for this job.',
      form        : false,
      summary     : true,
      quicksearch : true,
      optional    : true,
      model       : 'SSP.Project',
    },
    project_id   : {
      type      : 'string',
      computed  : { get() { return this.project?._id; } },
    },
    resource : {
      type            : 'link',
      label           : 'Resource',
      help            : 'The resource this job was invoked on.',
      description     : 'Linked resource ( or component if class job )',
      form            : false,
      summary         : true,
      quicksearch     : true,
      optional        : true,
      allow_type_only : true,
      allow_service   : true,
    },
    resource_id   : {
      type      : 'string',
      computed  : { get() { return this.resource?._id; } },
    },
    resource_type : {
      type      : 'string',
      computed  : { get() { return this.resource?.schema.id; } },
    },
    steps       : {
      type        : 'JobStep',
      label       : 'Job Steps',
      list        : { sort : 'toposort' },
      form        : false,
      optional    : true,
    },
    data        : {
      type        : 'object',
      optional    : true,
      readonly    : true,
      default() { return {}; },
    },
    /* Possible values for status:
     *  queued - waiting for the next step to run
     *  deferred - waiting for time to pass
     *  waiting - waiting for something external to happen
     *  complete - finished successfully
     *  failed - finished unsuccessfully
     *  cancelled - cancelled before completion
     *  interactive - waiting for interaction
     */
    status      : {
      type        : 'string',
      default     : 'queued',
      label       : 'Status',
      index       : true,
      summary     : false,
    },
    started_at   : {
      type        : 'date',
      label       : 'Time Started',
      optional    : true,
      readonly    : true,
      summary     : true,
      index       : true,
    },
    ended_at    : {
      type        : 'date',
      label       : 'Time Ended',
      optional    : true,
      readonly    : true,
      summary     : true,
      index       : true,
    },
    timeout     : {
      type        : 'string',
      label       : 'Timeout',
      default     : '10 minutes',
      optional    : true,
    },
    attempts    : {
      type        : 'number',
      label       : 'Attempts',
      default     : 0,
      summary     : true,
    },
    next_run_at : {
      type        : 'date',
      label       : 'Next Run Date',
      index       : true,
      summary     : true,
      default() { return new Date( Date.now() + 5000 ); },
      readonly    : true,
    },
    status_reason   : {
      type        : 'text',
      label       : 'Status Reason',
      optional    : true,
      readonly    : true,
      summary     : true,
      coerce      : stringifyError,
    },
    running_on  : {
      type        : 'string',
      optional    : true,
      readonly    : true,
      index       : true,
    },
    running_at  : {
      type        : 'date',
      optional    : true,
      readonly    : true,
      index       : true,
    },
    max_run_time    : {
      type        : 'string',
      label       : 'Maximum run time',
      default     : '72 hours',
      optional    : true,
    },
    max_attempts    : {
      type        : 'number',
      label       : 'Maximum Run Attempts',
      default     : 100,
      optional    : true,
    },
    do_not_coalesce : {
      type        : 'boolean',
      label       : 'Do not coalesce to this job',
      default     : false,
      index       : true,
    },
    priority    : {
      type        : 'string',
      label       : 'Priority',
      summary     : true,
      optional    : true,
      form        : {
        type    : 'Select',
        options : JOB_PRIORITIES,
        default : 'normal',
      },
      index       : true,
      default     : 'low',
    },
    priority_number : {
      type        : 'number',
      label       : 'Priority Number',
      readonly    : true,
      index       : true,
      metadata    : true,
    },
    sentry_error_id : {
      type        : 'string',
      label       : 'Sentry Error ID of the last error reported to Sentry',
      readonly    : true,
      metadata    : true,
      optional    : true,
    },
  },
  filters : {
    include_child_jobs  : {
      label     : 'Include Child Jobs',
      excluded  : { parent : { $not : { $type : 'object' } } },
    },
  },
  indexes : {
    // This index covers the fields used by the $match stage of the
    // runnable view
    running_on_next_run_at_status : {
      fields  : {
        running_on  : 1,
        next_run_at : 1,
        status      : 1,
      },
    },
    // This index covers the fields used by the $sort stage of the
    // runnable view
    priority_number_next_run_at_id : {
      fields  : {
        priority_number : 1,
        next_run_at     : 1,
        _id             : 1,
      },
    },
  },

  behaviors     : {
    hasTimestamps : {},
    hasErrorInfo  : {},
  },

  actions     : {
    process     : {
      label           : 'Process Now',
      help            : 'Enqueue this job for immediate processing',
      icon            : 'far:play-circle',
      access          : 'support',
      handler         : 'process',
    },
    requeue  : {
      label           : 'Re-queue',
      help            : [
        'Rerun this job right now.',
        'Sets status to "queued" and next_run to "now"',
      ],
      icon            : 'far:play-circle',
      access          : 'support',
      handler         : 'requeue',
    },
    retry     : {
      label           : 'Retry',
      help            : 'Create a new job with the same parameters',
      icon            : 'far:repeat',
      access          : 'support',
      handler         : 'retry',
    },
    cancel    : {
      label           : 'Cancel',
      help            : 'Stops further processing of this job',
      icon            : 'far:ban',
      access          : 'support',
      handler         : 'cancel',
      disabledMethod() {
        if ( this.isEnded() ) {
          return 'Cannot cancel a finished job.';
        }
      },
    },
    edit        : {
      label   : 'Edit',
      icon    : 'fad:pencil-alt',
      route   : 'edit',
      access  : 'none', // restricted to SYSTEM/Admins
    },
  },

  faces       : {
    'card.summary' : [
      {
        layout : 'LabelsAndValues',
        fields : [
          '_id', 'resource', 'owner', 'project', 'parent', 'started_at',
          'attempts', 'error', 'status_reason',
        ],
      },
    ],
    'list.summary' : [
      {
        layout : {
          type       : 'ItemsBetweenTitleAndIcons',
          iconize    : [ {
            fieldName : 'attempts',
            icon      : 'fas:sync',
          } ],
        },
        fields  : [ 'status' ],
      },
      {
        layout : 'ItemsAndBadges',
        fields  : [ 'resource', 'project', 'error', '@badge' ],
      },
    ],
    'panel.summary' : [
      {
        layout : 'PropTable',
        fields : [ '_id', '@summary', '-job_name' ],
      },
    ],
  },

  resultset : {
    sort  : '-updated_at',
  },

  queries : {
    runnable() {
      return {
        next_run_at : { $lte : new Date() },
        status      : { $in : Job.isPendingStatuses() },
      };
    },
    /**
     * Return a combination of the jobs returned by `my_user_jobs`,
     * `my_owned_jobs`, and `my_project_jobs`.
     *
     * @param {boolean} [mine=true] - If true only include
     * mine, if false exclude mine.
     */
    mine( mine=true ) {
      return [ {}, { flag : { mine } } ];
    },

    /**
     * Return a combination of the jobs returned by `my_user_jobs` and
     * `my_owned_jobs`.
     *
     * @param {boolean} [my_jobs=true] - If true only include
     * mine, if false exclude mine.
     */
    my_jobs( my_jobs=true ) {
      return [ {}, { flag : { my_jobs } } ];
    },

    /**
     * Returns jobs that are related to the current user (meaning they
     * are jobs running against user resources that belong to that
     * user).
     *
     * @param {boolean} [my_user_jobs=true] - If true only include
     * mine, if false exclude mine.
     */
    my_user_jobs( my_user_jobs=true ) {
      return [ {}, { flag : { my_user_jobs } } ];
    },

    /**
     * Returns jobs that are owned by the current user.  There may or
     * may not be overlap between this and `my_user_jobs`, this one is
     * all the jobs that this user started, regardless of the
     * are jobs running against user resources that belong to that
     * user).
     *
     * @param {boolean} [my_owned_jobs=true] - If true only include
     * mine, if false exclude mine.
     */
    my_owned_jobs( my_owned_jobs=true ) {
      return [ {}, { flag : { my_owned_jobs } } ];
    },

    /**
     * Returns jobs that are associated with the current users
     * projects.
     *
     * @param {boolean} [my_project_jobs=true] - If true only include
     * mine, if false exclude mine.
     */
    my_project_jobs( my_project_jobs=true ) {
      return [ {}, { flag : { my_project_jobs } } ];
    },
  },

} );
