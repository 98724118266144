import _ from 'lodash';

import { getOrigins } from '~/modules/origins';

import type { Resource } from '~/core/resource/Resource';

export type SubmitJobOptions = $TSFixMe; // TODO - {};

export async function submitJob(
  rsrc: Resource | typeof Resource,
  name: string,
  data: Record<string, any>,
  opts: SubmitJobOptions = {},
) {
  if ( BUILD.isServer ) {
    const schema = rsrc.schema;
    const jobdef = schema.getJob( name );
    if ( ! jobdef ) {
      throw new Error( `${schema.id} does not have a job named "${name}"` );
    }
    if ( typeof rsrc === 'function' ) { // class
      if ( ! jobdef.class_job ) {
        throw new Error( `${schema.id} job "${name}" is not a class job` );
      }
    } else { // instance
      if ( ! jobdef.instance_job ) {
        throw new Error( `${schema.id} job "${name}" is not an instance job` );
      }
    }
  }
  const res = await _submitJob( rsrc, name, data, opts );
  if ( _.isFunction( res.hydrate ) ) return res.hydrate();
  if ( _.isError( res ) ) throw res;
  log.warn( 'Unknown response from job method:', res );
  return res;
}
export async function _submitJob(
  rsrc: Resource | typeof Resource,
  name: string,
  data: Record<string, any>,
  opts: SubmitJobOptions = {},
) {
  if ( BUILD.isServer ) {
    const schema = rsrc.schema;
    const jobdef = schema.getJob( name );
    if ( ! jobdef ) {
      throw new Error( `${schema.id} does not have a job named "${name}"` );
    }
    if ( typeof rsrc === 'function' ) { // class
      if ( ! jobdef.class_job ) {
        throw new Error( `${schema.id} job "${name}" is not a class job` );
      }
    } else { // instance
      if ( ! jobdef.instance_job ) {
        throw new Error( `${schema.id} job "${name}" is not an instance job` );
      }
    }
  }
  return await getOrigins().job( rsrc, name, data, opts );
}
