// Automatically generated, DO NOT EDIT!
// To update run "yarn do-updates"
/* eslint-disable */
import { Model } from '~/core/lib/Model';
Model.demand( 'Test.Thing' ).extend( {
  "actions": {
    "delete": {
      "access": "admin",
      "help": "Deletes a Test Thing",
      "icon": "far:trash-alt",
      "id": "delete",
      "job_name": "delete",
      "label": "Delete",
      "name": "delete",
      "type": "instance"
    },
    "failing-job": {
      "access": "member",
      "icon": "far:thumbs-down",
      "id": "failing-job",
      "job_name": "failing-job",
      "label": "Run Failing Job",
      "name": "failing-job",
      "type": "instance"
    },
    "frobnicate": {
      "access": "anonymous",
      "icon": "far:birthday-cake",
      "id": "frobnicate",
      "job_name": "frobnicate",
      "label": "Frobnicate",
      "name": "frobnicate",
      "type": "instance"
    },
    "get-approval": {
      "access": "member",
      "icon": "far:hand-rock",
      "id": "get-approval",
      "job_name": "get-approval",
      "label": "This Requires Approval",
      "name": "get-approval",
      "type": "instance"
    },
    "retryable-job": {
      "access": "member",
      "icon": "far:thumbs-down",
      "id": "retryable-job",
      "job_name": "retryable-job",
      "label": "Run Retryable Job",
      "name": "retryable-job",
      "type": "instance"
    },
    "supertest": {
      "access": "member",
      "icon": "fab:superpowers",
      "id": "supertest",
      "job_name": "supertest",
      "label": "Run Master SuperTest",
      "name": "supertest",
      "type": "instance"
    },
    "supertest-approval": {
      "access": "member",
      "icon": "far:hand-rock",
      "id": "supertest-approval",
      "job_name": "supertest-approval",
      "label": "Run Approval SuperTest",
      "name": "supertest-approval",
      "type": "instance"
    },
    "supertest-lizard": {
      "access": "member",
      "icon": "far:hand-lizard",
      "id": "supertest-lizard",
      "job_name": "supertest-lizard",
      "label": "Run Lizard SuperTest",
      "name": "supertest-lizard",
      "type": "instance"
    },
    "supertest-paper": {
      "access": "member",
      "icon": "far:hand-paper",
      "id": "supertest-paper",
      "job_name": "supertest-paper",
      "label": "Run Paper SuperTest",
      "name": "supertest-paper",
      "type": "instance"
    },
    "supertest-rock": {
      "access": "member",
      "icon": "far:hand-rock",
      "id": "supertest-rock",
      "job_name": "supertest-rock",
      "label": "Run Rock SuperTest",
      "name": "supertest-rock",
      "type": "instance"
    },
    "supertest-scissors": {
      "access": "member",
      "icon": "far:hand-scissors",
      "id": "supertest-scissors",
      "job_name": "supertest-scissors",
      "label": "Run scissors SuperTest",
      "name": "supertest-scissors",
      "type": "instance"
    },
    "supertest-spock": {
      "access": "member",
      "icon": "far:hand-spock",
      "id": "supertest-spock",
      "job_name": "supertest-spock",
      "label": "Run Spock SuperTest",
      "name": "supertest-spock",
      "type": "instance"
    },
    "sync": {
      "access": "member",
      "icon": "fas:sync",
      "id": "sync",
      "job_name": "sync",
      "label": "Sync",
      "name": "sync",
      "type": "instance"
    },
    "tweak": {
      "access": "anonymous",
      "icon": "far:beer",
      "id": "tweak",
      "job_name": "tweak",
      "label": "Tweak",
      "name": "tweak",
      "params": {
        "howzit": {
          "label": "Howzit",
          "required": true,
          "type": "string"
        },
        "whatzit": {
          "label": "Whatzit",
          "required": true,
          "type": "string"
        },
        "wherezit": {
          "label": "Wherezit",
          "required": true,
          "type": "string"
        }
      },
      "type": "class"
    },
    "twiddle": {
      "access": "anonymous",
      "icon": "far:tty",
      "id": "twiddle",
      "job_name": "twiddle",
      "label": "Twiddle",
      "name": "twiddle",
      "type": "class"
    }
  }
} );
