import _ from 'lodash';
import { Model } from '../lib/Model';
import { mkdebug } from '@ssp/utils';
import { getIdentityFields } from '~/modules/fields/identity-utils';
import { getDataMap } from '~/modules/broker/data-utils';

import type { ModelOptions } from '../lib/Model';

const debug = mkdebug( 'ssp:database:core:subdocument' );

export class SubDocument extends Model {

  /**
   * Create a new subdoc.  This method is used to create a new record
   * for a model that does not yet exist in the database.  This
   * will just create it and return it, without sending anything to
   * the server.  You can then assign it to a resource field.
   *
   * @param data - Data to populate the subdocs fields.
   * @param opts - Constructor opts.
   */
  static create( data?: unknown, opts: Partial<ModelOptions> = {} ) {
    debug( 'Creating', this.schema.id, 'with', data, opts );
    const options = {
      method    : 'create',
      ...opts,
      defaults : true,
      creating : true,
      data     : _.assign( {}, opts.data, data ),
    };
    // SubDocuments don't get *create events built from here because
    // it isn't possible to dispatch them correctly when we don't yet
    // know what resource they will be attached to...
    return this.construct( options );
  }

  attachBroker( broker ) {
    getDataMap( this ).attachBroker( broker );
  }
  detachBroker() {
    getDataMap( this ).detachBroker();
  }

  equals( other ) {
    if ( ! _.get( other, 'schema.is_subdocument' ) ) return false;
    if ( this.schema.id !== other.schema.id ) return false;
    const fields = getIdentityFields( this.schema );
    return _.every( fields, field => {
      return field.equals( this[ field.name ], other[ field.name ] );
    } );
  }

  toString() { return `SubDocument<${this.schema.id}>`; }

  /**
   * Update the resource.
   *
   * @param {object} [data] - If provided, data will be merged into
   * the resource before updating.
   * @param {object} [options={}] - Options object.  Any options
   * specified here will be passed into the `Updates` instance.
   */
  async update( data, options={} ) {
    if ( data ) this.merge( data );
    await this.broker?.resource?.save( options );
    return this;
  }
  async save( data, options={} ) {
    if ( data ) this.merge( data );
    await this.broker?.resource?.save( options );
    return this;
  }

}
