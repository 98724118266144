import { createResource } from '~/core/creators';

createResource( {
  id         : 'BAH.Group',
  name       : 'BAH Group',
  icon       : 'far:sitemap',
  attachable : [ 'SSP.Project' ],
  hideZero   : true,
  fields     : {
    dn        : {
      type        : 'string',
      label       : 'Distinguished Name',
      quicksearch : true,
      index       : true,
      createonly  : true,
      validators  : { dn : true },
      form        : {
        help      : [
          `This is an advanced feature.`,
          `The format of the distinguished name is:`,
          `CN=My Group,OU=Distribution List,DC=resource,DC=ds,DC=bah,DC=com`,
          `If you don't know the DN of the group you would like to link to,`,
          `please open a support ticket to seek assistance.`,
        ],
      },
    },
    name      : {
      type        : 'string',
      label       : 'Name',
      quicksearch : true,
      readonly    : true,
    },
    subgroup_actions : {
      type        : 'string',
      label       : 'Options',
      optional    : true,
      form        : {
        type    : 'Select',
        options : [ 'ignore', 'expand' ],
        help    : [
          'Choosing \'expand\' will convert any subgroups or ',
          'distribution lists that are listed as members ',
          'into their individual members otherwise they will be ignored.',
        ],
      },
      default     : 'ignore',
    },
  },
  behaviors : {
    hasMembers      : {
      userType     : 'BAH.User',
      withService  : true,
      readonly     : true,
    },
  },
  faces         : {
    'list.summary' : [
      {
        layout : {
          type       : 'ItemsBetweenTitleAndIcons',
        },
        fields : [ '-@all' ],
      },
      {
        layout : 'ItemsAndBadges',
        fields  : [ 'dn', '@badge' ],
      },
    ],
  },
} );
