import { createSubDocument } from '~/core/creators';

createSubDocument( {
  id          : 'SSP.User.Settings.TourHistory',
  fields      : {
    id  : {
      type        : 'string',
      required    : true,
      identifier  : true,
    },
    version : {
      type     : 'number',
      label    : 'Tour Version',
      default  : 1,
    },
    status : {
      type  : 'string',
      label : 'Status',
    },
  },
} );
