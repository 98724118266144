import _ from 'lodash';
import {
  NotFound, isNotFoundError, resolveRoute, resolveURL, ReferenceURL,
  DatabaseModelError,
} from '@ssp/utils';

import { Model } from '../lib/Model';
import { Schema } from '../lib/Schema';
import { getModel, getSchema } from '../schemas';
import { Broker } from '~/modules/broker/Broker';
import { Updates } from '~/modules/updates/Updates';
import { hasMongoOperators } from '~/utils';
import { TransportResponse } from '~/lib/TransportResponse';
import { getOrigins, Origin, Version } from '~/modules/origins';
import { validateModel } from '~/modules/validation/utils';
import { generateModel, fillModel } from '~/modules/faker/utils';
import { performAction } from '~/modules/actions/utils';
import { submitJob, SubmitJobOptions } from '~/modules/jobs/utils';

import type {
  GenerateModelOptions, FillModelOptions, PerformActionOptions,
  ResultSetOptions, ValidationOptions, LiveUpdateMessage,
} from '~/modules';

import type { SchemaId, ModelData } from '~/types';
import type {
  ResourceFetchOptions, ResourceLoadOptions, ResourceUpdateOptions,
  ResourceRetrieveOptions,
} from './resource-types';
import type { CountsIds } from '~/behaviors';

export interface Resource {
  display_name?: string;
}

export abstract class Resource extends Model {

  declare _id?: string;
  declare _version?: number;

  get loaded() { return this.broker.loaded; }
  get loading() { return this.broker.loading; }
  get updating() { return this.broker.updating; }
  get stored() { return ! this.creating; }

  _preprocess_build_events( ev ) {
    const broker = new Broker( this );
    const { options } = ev;

    this.debug( '_preprocess_build_events:', ev );

    if ( options.partial ) {
      this.debug( 'Applying partials to broker:', options.partial );
      broker.partials( options.partial );
      ev.handled( 'partial' );
    }

    if ( options.response instanceof TransportResponse ) {
      const res = options.response;
      this.debug( 'Processing TransportResponse:', res );
      res.fatalize();
      if ( res._version_instance ) broker.updateFromResponse( res, options );
      ev.handled( 'response' );
    }
    if ( options.version instanceof Version ) {
      this.debug( 'preprocess - Setting broker Version:', options.version );
      broker.version = options.version;
      ev.handled( 'version' );
    } else if ( options.origin instanceof Origin ) {
      this.debug( 'preprocess - Setting broker Origin:', options.origin );
      broker.origin = options.origin;
      ev.handled( 'origin' );
    } else if ( options.id ) {
      this.debug( 'preprocess - Setting broker ID:', options.id );
      broker.id = options.id;
      ev.handled( 'id' );
    } else if ( options.ident ) {
      this.debug( 'preprocess - Setting broker Ident:', options.ident );
      broker.ident = options.ident;
      ev.handled( 'ident' );
    } else if ( options.query ) {
      this.debug( 'preprocess - Setting broker Query:', options.query );
      broker.query = options.query;
      ev.handled( 'query' );
    }
  }

  _postprocess_build_events( ev ) {
    const { options } = ev;
    if ( options.locked ) {
      this.broker.lock(
        options.locked === true ? '_postprocess_build_events' : options.locked,
      );
      ev.handled( 'locked' );
    } else {
      this.broker.unlock();
    }
    if ( options.immutable ) {
      this.broker.immutable = options.immutable;
      ev.handled( 'immutable' );
    } else {
      this.broker.immutable = false;
    }
    if ( _.isBoolean( options.live ) ) {
      this.broker.live = options.live;
      ev.handled( 'live' );
    } else if ( ! this.broker.immutable ) {
      this.broker.live = true;
    }

    this.defaults( { display_name : this.findDisplayName() } );
    this.broker.watch();
  }

  /**
   * Fetch a specific resource from the database.
   */
  static async fetch(
    query: string|Record<string, any>,
    options: ResourceFetchOptions = {},
  ) {
    if ( _.isNil( query ) ) {
      throw new DatabaseModelError( {
        message : `Resource.fetch requires a query`,
        tags    : { schema : this.schema.id, method : 'Resource.fetch' },
        data    : { query, options },
      } );
    }
    if ( this.schema.is_abstract ) {
      throw new DatabaseModelError( {
        message : `Cannot call fetch on abstract type ${this.schema.id}`,
        tags    : { schema : this.schema.id, method : 'Resource.fetch' },
        data    : { query, options },
      } );
    }
    const { safe, create, insert, ...opts } = options;

    let res = await getOrigins().fetch( this.schema.id, query, opts );
    if ( res instanceof Origin ) {
      return this.construct( { origin : res, method : 'fetch' } );
    } else if ( res instanceof TransportResponse ) {
      if ( res.ok ) {
        return this.construct( { response : res, method : 'fetch' } );
      } else {
        res = res.getError();
      }
    }
    if ( isNotFoundError( res ) ) {
      if ( insert || create ) {
        const data: Record<string, any> = {};
        if ( ! hasMongoOperators( query ) ) _.assign( data, query );
        if ( insert ) return this.insert( _.assign( data, insert ), opts );
        if ( create ) return this.create( _.assign( data, create ) );
      }
      if ( safe ) return;
    }
    if ( _.isError( res ) ) throw res;
    throw new NotFound( {
      tags : { method : 'Resource#fetch', schema : this.schema.id },
      data : { query, options },
    } );
  }

  /**
   * Retrieve a specific resource from the database.  The only real
   * difference between `retrieve` and `fetch` is that `retrieve`
   * takes a database ID rather than a query, so you can't search or
   * use `identifier` fields to find it.  This is primarily used by
   * things like `load()`.
   */
  static async retrieve( id: string, options: ResourceRetrieveOptions = {} ) {
    if ( ! _.isString( id ) ) {
      throw new DatabaseModelError( {
        message : `Resource.retrieve requires an id`,
        tags    : { schema : this.schema.id, method : 'Resource.retrieve' },
        data    : { id, options },
      } );
    }
    if ( this.schema.is_abstract ) {
      throw new DatabaseModelError( {
        message : `Cannot call retrieve on abstract model ${this.schema.id}`,
        tags    : { schema : this.schema.id, method : 'Resource.retrieve' },
        data    : { id, options },
      } );
    }
    const { safe, ...opts } = options;
    this.debug( 'Retrieving', this.schema.id, id );
    let res = await getOrigins().retrieve( this.schema.id, id, opts );
    if ( res instanceof Origin ) {
      return this.construct( { origin : res, method : 'retrieve' } );
    } else if ( res instanceof TransportResponse ) {
      if ( res.ok ) {
        return this.construct( { response : res, method : 'retrieve' } );
      } else {
        res = res.getError();
      }
    }
    if ( ( res instanceof NotFound ) && safe ) return;
    if ( res instanceof Error ) throw res;
    throw new NotFound( {
      tags : { method : 'Resource#retrieve', schema : this.schema.id },
      data : { id, options },
    } );
  }

  /**
   * Checks whether this resource can be managed by a user.
   * Management defaults to false while restrictions are further defined
   * by the respective resource model.
   *
   * @param {SSP.User} subject - UserResource
   */
  canBeManagedBy( subject ) {
    return this._canBeManagedBy( subject, () => { /* no-op */ } );
  }
  _canBeManagedBy( subject, custom_cb ) {
    if ( ! subject ) return [ false, 'no subject' ];
    if ( ! ( subject instanceof getModel( 'SSP.User' ) ) ) {
      return [ false, 'subject is not a user' ];
    }
    if ( ! subject.loaded ) return [ false, 'subject not loaded' ];
    if ( ! this.loaded && ! this.creating ) {
      return [ false, 'resource not loaded' ];
    }
    return custom_cb.call( this, subject )
      || [ false, 'resource cannot be managed by subject' ];
  }

  getRelatedResourceQuery( type: SchemaId ) {
    const schema = getSchema( type );
    const {
      is_user_schema, is_user_resource,
      is_project_schema, is_project_resource,
    } = this.schema;
    const ref_id = ( () => {
      if ( is_user_schema || is_project_schema ) return this._id;
      if ( is_user_resource && this.user_id ) return this.user_id;
      if ( is_project_resource && this.project_id ) return this.project_id;
      throw new DatabaseModelError( {
        message : 'Unable to determine relation id',
        tags    : {
          schema : this.schema.id, method : 'Resource#getRelatedResourceQuery',
        },
        data    : { target : schema.id },
      } );
    } )();

    const field = ( () => {
      if ( is_user_resource ) {
        if ( schema.hasActualField( 'user_id' ) ) return 'user_id';
        if ( schema.hasLinkField( 'user' ) ) return 'user.id';
      }
      if ( is_project_resource ) {
        if ( schema.hasActualField( 'project_id' ) ) return 'project_id';
        if ( schema.hasLinkField( 'project' ) ) return 'project.id';
      }
      throw new DatabaseModelError( {
        message : 'Unable to determine relation field',
        tags    : {
          schema : this.schema.id, method : 'Resource#getRelatedResourceQuery',
        },
        data    : { target : schema.id },
      } );
    } )();

    return { [field] : ref_id };
  }

  findResources( type: SchemaId, query: $TSFixMe = {}, opts: $TSFixMe = {} ) {
    return getModel( type ).find( query, opts )
      .find( this.getRelatedResourceQuery( type ) );
  }

  async getDisplayName() {
    const fields = Object.getPrototypeOf( this ).constructor.displayNameFields;
    for ( const field of fields ) {
      if ( this[ field ] ) return this[ field ];
    }
  }

  static async insert( data, opts ) {
    return this.create( data, opts ).insert( {}, opts );
  }

  async save( data?: ModelData<this>, opts={} ) {
    if ( data ) this.merge( data );
    const res = await this[ this.creating ? '_insert': '_update' ]( opts );
    res?.fatalize();
    return this;
  }

  async insert( data, options={} ) {
    if ( data ) this.merge( data );
    const res = await this._insert( options );
    res?.fatalize();
    return this;
  }

  async _insert( options={} ) {
    if ( this.broker.id && ! this._id ) this._id = this.broker.id;

    const ev = this.eventbox( {
      method : 'insert',
      options,
    } );

    await ev.before( [ 'save', 'insert' ] );
    await this.validate( options );

    ev.updates = new Updates( {
      method  : 'insert',
      schema  : this.schema.id,
      id      : this._id,
      options,
    } );
    await ev.before( [ 'computingSave', 'computingInsert' ] );
    ev.updates.computeInsert( this );
    await ev.after( [ 'computingInsert', 'computingSave' ] );
    this.debug( 'INSERT', ev.updates.updates );
    await ev.during( [ 'insert', 'save' ] );

    const res = await this.broker.insert( ev.updates, options );
    this.creating = false;
    await ev.after( [ 'insert', 'save' ] );
    return res;
  }

  /**
   * Update the resource.
   *
   * @param {object} [data] - If provided, data will be merged into
   * the resource before updating.
   * @param {object} [options={}] - Options object.  Any options
   * specified here will be passed into the `Updates` instance.
   */
  async update( data, options: ResourceUpdateOptions = {} ) {
    if ( data ) this.merge( data );
    const res = await this._update( options );
    res?.fatalize();
    return this;
  }
  async _update( options: ResourceUpdateOptions = {} ) {
    const origin = await this.broker.getInstance( {
      immutable : true,
      load      : true,
    } );
    const opts = { method : 'update', origin, options };
    _.assign( opts, _.pick( options, [
      'refreshing',
    ] ) );
    const ev = this.eventbox( opts );

    await ev.before( [ 'save', 'update' ] );
    await this.validate( options );

    ev.updates = new Updates( {
      method  : 'update',
      schema  : this.schema.id,
      id      : this._id,
      options,
    } );
    await ev.before( [ 'computingSave', 'computingUpdate' ] );
    ev.updates.computeUpdate( this, origin );
    if ( ev.updates.isEmpty() && ! options.force ) return;
    await ev.after( [ 'computingUpdate', 'computingSave' ] );

    await ev.during( [ 'update', 'save' ] );
    const res = await this.broker.update( ev.updates, options );
    this.creating = false;
    await ev.after( [ 'update', 'save' ] );
    return res;
  }

  /**
   * Refresh the resource.  Like `update`, but it indicates to events
   * that we're just updating the resource to reflect
   * reality, and that they shouldn't do things like spawn event based
   * update jobs in response to this.
   */
  async refresh( data, options: ResourceUpdateOptions = {} ) {
    return await this.update( data, { ...options, refreshing : true } );
  }

  async remove( opts={} ) {
    const res = await this._remove( opts );
    res?.fatalize();
    return this;
  }

  async _remove( opts={} ) {
    const ev = this.eventbox( { options : opts } );
    await ev.before( 'remove' );
    const res = await this.broker.remove( this._id, opts );
    await ev.after( 'remove' );
    return res;
  }

  /**
   * Load the resource data.
   *
   * @returns {Resource} - Returns itself (the resource that was
   * loaded) - unless you specify `safe: true`, then it will return an
   * `Error` instance if an error was encountered.
   */
  async load( options: ResourceLoadOptions = {} ) {
    // Can't load if this was a newly-created resource
    if ( this.creating ) return this;
    const res = await this.broker.load( { ...options, safe : true } );
    if ( _.isError( res ) ) {
      if ( options.safe ) return res;
      throw res;
    }
    return this;
  }

  async load_or_insert( opts={} ) {
    await this.broker.load( { ...opts, insert : true } );
    return this;
  }

  async reload( opts={} ) { return this.load( { ...opts, reload : true } ); }

  get ref_url() { return this.broker?.ref_url; }

  fqid() {
    return [ this.schema.id, this._id ].join( '/' );
  }

  findJobs( query?: any, opts?: $TSFixMe ) {
    return getModel( 'SYSTEM.Job' ).find( { 'resource.id' : this._id } )
      .find( query, opts );
  }

  toString() { return this.display_name || this._id; }

  baseurl() {
    if ( ! this._id ) return;
    return `/resource/${this.schema.id}/${this._id}`;
  }

  url( ...args ) {
    const base = this.baseurl();
    if ( ! base ) return;
    return resolveURL( base, ...args );
  }

  route( ...args ) {
    const base = this.baseurl();
    if ( ! base ) return;
    return resolveRoute( base, ...args );
  }

  static route( ...args ) {
    return [ '', this.schema.id, ...args ].join( '/' );
  }

  /**
   * Returns a route to the parent resource's create resource page.
   *
   * @param {object} parent - Resource data
   */
  static getCreateRoute( parent ) {
    return parent.route( 'add', this.schema.id );
  }

  static fromRef( ref_url ) {
    const ref = new ReferenceURL( null, { safe : false } );

    if ( this.schema.id === 'Resource' ) {
      // Can call this on Resource even though it's abstract
    } else if ( this.schema.is_abstract ) {
      throw new DatabaseModelError( {
        message : `Cannot call fromRef on abstract model ${this.schema.id}`,
        tags    : {
          schema : this.schema.id, method : 'Resource.fromRef',
        },
        data    : { target : schema.id },
      } );
    } else {
      ref.requiredType = this.schema.id;
    }
    ref.parse( ref_url );
    const opts: $TSFixMe = {
      partial   : ref.partials,
      method    : 'fromRef',
      defaults  : false,
    };
    if ( ref.id ) {
      opts.id = ref.id;
    } else if ( ref.ident ) {
      opts.ident = ref.ident;
    }
    return this.construct( opts );
  }

  /**
   * fromIdent can take the value of any identifier field,
   * but that means that in order to load it it has to be resolved.
   *
   * @param {string} [type] - The resource type to load (only needed
   * if calling this as `Resource.fromIdent`, you don't need to
   * specify the type if you are calling it on an instance of the
   * type).
   * @param {string|string[]} ident - the value of any field marked as
   * an `identifier` field, or an array of values.  If it's an array
   * tnen it will find any resource that has any of those values in
   * any of it's identifier fields, which can be useful if you have
   * several ident values and aren't sure which of them the resource
   * might already have.
   *
   * @example
   *  const user = DB.SSP.User.fromIdent( [ email1, email2 ] );
   */
  static fromIdent( type, ident ) {
    if ( ! ident ) {
      ident = type;
      type = this.schema.id;
    }
    if ( type === 'Resource' ) {
      throw new DatabaseModelError( {
        message : `Cannot call fromIdent without specifying type`,
        tags    : { schema : this.schema.id, method : 'Resource.fromIdent' },
        data    : { type, ident },
      } );
    }
    if ( type !== this.schema.id ) {
      return getModel( type ).fromIdent( ident );
    }
    if ( this.schema.is_abstract ) {
      throw new DatabaseModelError( {
        message : `Cannot call fromIdent on abstract model ${type}`,
        tags    : { schema : this.schema.id, method : 'Resource.fromIdent' },
        data    : { type, ident },
      } );
    }
    return this.construct( { type, ident, method : 'fromIdent' } );
  }

  /**
   * fromId constructs a very similar resource instance, to fromIdent,
   * but with the assertion that the value being used to construct it
   * is the _id, so the loading/resolving code can assume that it's
   * already resolved.
   *
   * @param {string} [type] - The resource type to load (only needed if
   * calling this as `Resource.fromId`, you don't need to specify
   * the type if you are calling it on a specify type).
   * @param {string} id - The primary database ID for the resource.
   *
   * @example
   *  const user = DB.SSP.User.fromId( '12345678abcdefgh' );
   */
  static fromId( type, id ) {
    if ( ! id ) {
      id = type;
      type = this.schema.id;
    }
    if ( type === 'Resource' ) {
      throw new DatabaseModelError( {
        message : `Cannot call fromId without specifying type`,
        tags    : { schema : this.schema.id, method : 'Resource.fromId' },
        data    : { type, id },
      } );
    }
    if ( type !== this.schema.id ) {
      return getModel( type ).fromId( id );
    }
    if ( this.schema.is_abstract ) {
      throw new DatabaseModelError( {
        message : `Cannot call fromId on abstract model ${type}`,
        tags    : { schema : this.schema.id, method : 'Resource.fromId' },
        data    : { type, id },
      } );
    }
    return this.construct( { type, id, method : 'fromId' } );
  }

  getMeta() {
    return {
      instance_id : this.instance_id,
      watchers    : this.hasWatchers(),
      broker      : this.broker.getMeta(),
    };
  }

  /**
   * Updates the resource display name used by SSP to populate
   * things like page header, resource card titles, and drop down lists.
   * Defaults to resource name while additional fallbacks are defined by the
   * the respective resource model.
   */
  async updateDisplayName() {
    return this.display_name = ( await this.getDisplayName() ) || this._id;
  }

  toLinkData() {
    return {
      type  : this.schema.id,
      id    : this._id,
      name  : this.findDisplayName(),
    };
  }
  static toLinkData() {
    return { type : this.schema.id, name : this.schema.name };
  }

  /** Enqueue a job for this resource. */
  async job(
    name: string, data: Record<string, any> = {}, opts: SubmitJobOptions = {},
  ) { return submitJob( this, name, data, opts ); }
  /** Enqueue a class job for this resource type. */
  static async job(
    name: string, data: Record<string, any> = {}, opts: SubmitJobOptions = {},
  ) { return submitJob( this, name, data, opts ); }

  /**
   * Create and return a new {@link ResultSet} object for this
   * class.
   *
   * @param options - {@link ResultSet#constructor} options.
   */
  static resultset( options: ResultSetOptions = {} ) {
    return new this.schema.ResultSet( { ...options, resource : this } );
  }

  /**
   * Create and return a new {@link ResultSet} object for this
   * resource.
   *
   * @param options - {@link ResultSet#constructor} options.
   */
  resultset( options={} ) {
    return new this.schema.ResultSet( { ...options, resource : this } );
  }

  static find( query={}, options={} ) {
    return this.resultset( options ).find( query );
  }

  /**
   * Run a named query.  This is a helper method that you can use to
   * run the same queries used by the `ResultSet` class.  Note that
   * when queries are used in building up a `ResultSet` they can
   * return options in addition to the query, but here the options are
   * ignored.  If you need to know what options would have been
   * included you can just run `model.resultset().runQuery( name,
   * ...args )` yourself.
   *
   * @param {string} name - The name of the query to run.
   * @param {any[]} [args] - The arguments to the query.
   */
  static query( name, ...args ) {
    return _.first( this.resultset().runQuery( name, ...args ) );
  }

  /** Generate some number of models. */
  static async generate( options: GenerateModelOptions = {} ) {
    return generateModel( { ...options, type : this } );
  }

  /** Generate related resources. */
  async generate(
    type: SchemaId, options?: GenerateModelOptions,
  ): Promise<$TSFixMe>;

  /** Fill empty fields in this resource with generated data. */
  async generate( options: FillModelOptions ): Promise<$TSFixMe>;

  async generate(
    arg1?: SchemaId | FillModelOptions,
    arg2: GenerateModelOptions = {},
  ) {
    if ( typeof arg1 === 'string' ) {
      return generateModel( { ...arg2, type : arg1, owner : this._id } );
    } else {
      return fillModel( this, arg1 );
    }
  }

  async action(
    name: string,
    data: Record<string, unknown> = {},
    opts: PerformActionOptions = {},
  ) {
    return performAction( this, name, data, opts );
  }
  static async action(
    name: string,
    data: Record<string, unknown> = {},
    opts: PerformActionOptions = {},
  ) {
    return performAction( this, name, data, opts );
  }

  async validate( options: ValidationOptions = {} ) {
    return validateModel( this, options );
  }

  fieldset( ...args ) { return this.schema.getFieldSet( ...args ); }

  async _watchable_start() {
    try {
      if ( this.broker ) this.broker.live = true;
      return await this._watchable_load();
    } catch ( err ) {
      return this.errored( err );
    }
  }

  async _watchable_stop() {
    if ( this.broker ) this.broker.live = false;
  }

  _watchable_load() { return this.load(); }

  async updateResourceIds(
    msg: LiveUpdateMessage, ids: CountsIds,
  ): Promise<Partial<CountsIds>|null> {
    void msg;
    return ids;
  }

  async computeResourceIds( ids: CountsIds = {} ): Promise<CountsIds> {
    const schemas = Schema.ids( s => s.canAttachTo( this ) );

    if ( ! ids[ 'SYSTEM.Job' ] ) {
      const jobs = await this.findJobs( {}, { filters : '*' } ).load();
      _.assign( ids, { 'SYSTEM.Job' : jobs.getAllIds() } );
    }
    _.remove( schemas, id => ids[ id ] );
    if ( schemas.length ) {
      await Promise.all( schemas.map( async schema => {
        const rs = await this.findResources( schema, {}, {
          filters : '*',
        } ).load();
        ids[ schema ] = rs.getAllIds();
      } ) );
    }
    return ids;
  }

}
