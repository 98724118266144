import _ from 'lodash';
import { hideProps, createNamedClass } from '@ssp/utils';

import { createBehavior } from '~/core/creators';
import { getModel, getSchema } from '~/core/schemas';

import * as withTraits from './traits';
import { MemberInfo as MemberInfoBase } from './MemberInfo';

import type { SchemaId } from '~/types';
import type { Trait, TraitName } from './types';

createBehavior( {

  name      : 'hasMembers',
  options   : {
    readonly            : false,
    canAddDisabledUsers : false,
    userType            : undefined,
    withCreator         : undefined,
    withRole            : undefined,
    withRoles           : undefined,
    withService         : true,
    withUpdateJob       : true,
    withTeamLinks       : true,
    withFormMessage     : true,
  },

  // eslint-disable-next-line max-lines-per-function
  build( config ) {
    const userType = config.schema.service.id + '.User';

    if ( this.options.readonly ) this.options.withUpdateJob = false;

    const traits: Trait[] = _.compact( _.map( this.options, ( opts, id ) => {
      if ( ! id.startsWith( 'with' ) ) return;
      if ( ! opts ) return;
      if ( opts === true ) opts = {};
      const trait = withTraits[ id ];
      if ( ! trait ) throw new Error( `Unknown hasMembers trait '${id}'` );
      _.defaults( opts, trait.defaults );
      const name = id.slice( 4 ).toLowerCase();
      return { ...trait, id, config, userType, name, options : opts };
    } ) );

    type TraitMap<T = unknown> = ( trait: Trait<any> ) => T;
    const traitMap = <T = unknown, >( mapper: TraitMap<T> ) => traits
      .flatMap( trait => mapper( trait ) ).filter( x => x );

    const getTraitNames = ( filter: TraitMap<boolean> ) => traits
      .filter( trait => filter( trait ) )
      .map( trait => trait.name );

    const accessTraits = getTraitNames( trait => !! trait.access_fields );
    const accessFields = traitMap( trait => trait.access_fields );

    const memberInfoId = [
      'MemberInfo', ...config.schema.id.split( '.' ),
    ].join( '_' );

    const traitify = ( method ) => traitMap( trait => {
      if ( typeof trait[ method ] !== 'function' ) return;
      return trait[ method ].call( this, trait );
    } );
    const traitNames = traitMap( trait => trait.name );

    _.assign( this.options, ...traitify( 'buildOptions' ) );

    const hasTrait = ( name: TraitName ) => {
      for ( const trait of traits ) {
        if ( trait.name === name ) return true;
        if ( trait.id === name ) return true;
      }
      return false;
    };

    const common_methods = class {

      static get MemberInfo() { return MemberInfo; }
      get MemberInfo() { return MemberInfo; }

      static getUserType() { return userType; }
      getUserType() { return userType; }

      static getUserModel() { return getModel( userType ); }
      getUserModel() { return getModel( userType ); }

      static getUserSchema() { return getSchema( userType ); }
      getUserSchema() { return getSchema( userType ); }
    };

    const MemberInfo = createNamedClass( memberInfoId, MemberInfoBase as any );
    MemberInfo.initialize( {
      id        : memberInfoId,
      inherit   : 'MemberInfo',
      methods   : [
        common_methods,
        class {
          static getResourceModel() { return config.schema.model; }
          getResourceModel() { return config.schema.model; }

          static getTraits() { return [ ...traitNames ]; }
          getTraits() { return [ ...traitNames ]; }
          static hasTrait( trait ) { return hasTrait( trait ); }
          hasTrait( trait ) { return hasTrait( trait ); }

          static getAccessTraits() { return [ ...accessTraits ]; }
          getAccessTraits() { return [ ...accessTraits ]; }
          static getAccessFields() { return [ ...accessFields ]; }
          getAccessFields() { return [ ...accessFields ]; }
        },
      ],
    }, ...traitify( 'buildMemberInfoConfig' ) );

    hideProps( config.schema, { MemberInfo } );
    hideProps( config.schema.model, { MemberInfo } );

    return [
      {
        fields      : {
          members     : {
            type      : memberInfoId,
            list      : true,
            label     : 'Members',
            summary   : false,
            minimal   : false,
            readonly  : this.options.readonly,
            optional  : true,
            form      : { face : 'members' },
            default   : () => ( [] ),
          },
          members_count : {
            type        : 'number',
            label       : 'Members',
            face        : { type: 'NumberBadge' },
            summary     : true,
            readonly    : true,
            computed    : { get() { return this.members.size; } },
          },
        },
        methods : [
          common_methods,
          class {
            getMembersTraits() { return [ ...traits ]; }

            static getMemberInfo() { return MemberInfo; }
            getMemberInfo() { return MemberInfo; }
          },
        ],
      },
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      ...require( './component-config' ).default,
      ...traitify( 'buildModelConfig' ),
    ];
  },
} );

declare module './types' {
  export interface TraitConfigs {
    userType: SchemaId & `${string}.User`;
  }
}

export * from './MemberInfo';
export * from './MemberInfoPendingChange';
export * from './jobs';
export * from './types';
export * from './membership-helper';
