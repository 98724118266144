import _ from 'lodash';
import { permissionsMap } from './permissions';
import { createResource } from '~/core/creators';

createResource( {
  id               : 'Confluence.Space',
  name             : 'Confluence Space',
  icon             : 'fab:confluence',
  attachable       : [ 'SSP.Project' ],
  service_id_field : 'confluence_id',
  featured         : true,
  fields           : {
    key           : {
      type        : 'string',
      label       : 'Confluence Key',
      summary     : true,
      index       : true,
      unique      : true,
      identifier  : true,
      quicksearch : true,
      validators  : {
        maxlength : 255,
        characters  : [ 'alphanumeric' ],
      },
      help        : [
        'This is the short ID of your space. Consider',
        'using the same key as your Jira project.',
      ].join( ' ' ),
    },
    name          : {
      type        : 'string',
      label       : 'Space Name',
      index       : true,
      quicksearch : true,
    },
    description   : {
      type        : 'string',
      label       : 'Description',
      summary     : true,
      quicksearch : true,
      optional    : true,
      minimal     : true,
    },
    confluence_id : {
      type        : 'id',
      readonly    : true,
      optional    : true,
      unique      : true,
      immutable   : true,
      identifier  : true,
    },
  },
  behaviors     : {
    hasExternalLink : {},
    hasMembers      : {
      userType    : 'Confluence.User',
      withCreator : {
        config : { role : 'Admin' },
      },
      withRole    : {
        roles         : _.map( permissionsMap, 'role' ),
        defaultRole   : 'Member',
      },
      withService : true,
    },
  },
  faces         : {
    'card.summary' : [
      {
        layout : 'Badges',
        fields : [ '@badge' ],
      },
      {
        layout : 'LabelsAndValues',
        fields : [ 'key', 'members_count', 'external_link', 'description' ],
      },
    ],
    'list.summary' : [
      {
        layout : {
          type       : 'ItemsBetweenTitleAndIcons',
          iconize    : [ {
            fieldName : 'key',
            icon      : 'fad:key',
          } ],
        },
        fields  : [ '-@all' ],
      },
      {
        layout : 'ItemsAndBadges',
        fields  : [ 'description', '@badge' ],
      },
    ],
  },
} );
