import _ from 'lodash';
import { hideProps } from '@ssp/utils';

import { Resource } from './Resource';
import { TeamLink } from './TeamLink';

import type { MemberInfo } from '../MemberInfo';
import type { MembershipChange } from './MembershipHelper';
import type { User } from './User';

const labels = {
  add     : 'Adding',
  remove  : 'Removing',
  update  : 'Updating',
} as const;

export type ChangeSummary = {
  resource: string;
  teamlink?: string;
  result: boolean;
  reason: string;
  result_from: 'resource' | 'teamlink';
  user_type: string;
  change_type: 'add' | 'remove' | 'update';
  config?: Record<string, unknown>;
};
/**
 * All the details about one specific change involving one resource,
 * one user, and possibly one team link.
 */
export class Change {

  resource: Resource;
  teamlink?: TeamLink;
  result: boolean;
  reason: string;
  result_from: 'teamlink' | 'resource';
  userType: string;
  memberinfo: MemberInfo;
  change: MembershipChange;
  user: User;

  constructor( options: Partial<Change>, user: User ) {
    _.assign( this, options );
    hideProps( this, { user } );
  }

  get change_type() { return this.change.change_type; }

  acceptedBy( acceptor: Resource | TeamLink ) {
    const [ result, reason ] = acceptor.acceptsChange( this.change );
    this.result = result;
    this.reason = reason;
    if ( acceptor instanceof TeamLink ) this.result_from = 'teamlink';
    if ( acceptor instanceof Resource ) this.result_from = 'resource';
    if ( ! result ) log.trace( `Not applying change:`, this );
    return result;
  }

  getSummaryInfo(): ChangeSummary {
    return {
      resource    : this.resource.resource.display_name,
      teamlink    : this.teamlink?.teamlink.display_name,
      result      : this.result,
      reason      : this.reason,
      result_from : this.result_from,
      user_type   : this.userType,
      change_type : this.change.change_type,
      config      : this.change.config,
    };
  }

  getMessage() {
    const msg = _.compact( [
      this.getMessageLabel(),
      this.getMessageDetails(),
    ] ).join( ' ' );
    return this.result ? msg : `~NOT ${msg}~`;
  }

  getMessageLabel() {
    const label = labels[ this.change.change_type ];
    return _.compact( [ label, this.user.name ] ).join( ' ' );
  }

  formatConfig() {
    const cfg = _.map( this.change.config, ( val, key ) => `${key} ${val}` );
    if ( cfg.length > 1 ) {
      const last = cfg.pop();
      cfg.push( `and ${last}` );
    }
    if ( cfg.length ) return cfg.join( ', ' );
  }

  formatDetails() {
    const details: string[] = [];
    if ( this.reason ) {
      details.push( `reason: ${this.reason}` );
    } else {
      details.push( `skipped by ${this.result_from}` );
    }
    if ( this.teamlink ) details.push( `via team link: ${this.teamlink.name}` );
    if ( details.length ) return details.join( ', ' );
  }

  getMessageDetails() {
    const msg: string[] = [];
    const cfg = this.formatConfig();
    if ( cfg ) msg.push( 'with', cfg );

    const details = this.formatDetails();
    if ( details ) msg.push( `(${details})` );
    return msg.join( ' ' );
  }

}
