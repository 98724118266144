import _ from 'lodash';
import { MembershipHelper } from './MembershipHelper';
import yaml from 'js-yaml';

import type { User } from './User';
import type { Resource } from './Resource';

const chargebackUrl = [
  'https://boozallen.sharepoint.com',
  '/teams/selfserviceportal/SitePages',
  '/information.aspx#chargeback',
].join( '' );
const supportUrl = 'https://support.boozallencsn.com';
const chargebackFAQ = `[Chargeback FAQ](${chargebackUrl})`;

export async function formatSummary(
  helper: MembershipHelper,
  detailed: boolean = false,
  by_user: boolean = false,
): Promise<string> {
  if ( by_user ) {
    return formatSummaryByUser( helper, detailed );
  } else {
    return formatSummaryByResource( helper, detailed );
  }
}

async function formatSummaryByResource(
  helper: MembershipHelper,
  detailed: boolean = false,
): Promise<string> {
  const rsrcs = helper.getResources();

  if ( ! rsrcs.length ) return;
  const res: string[] = [];

  res.push( `# Change Confirmation #` );

  const prov = getProvisionMessage( helper );
  if ( prov ) res.push( '', prov );

  for ( const rsrc of rsrcs ) {
    res.push( '', `## ${rsrc.name} (${rsrc.resource.schema.name}) ##`, '' );
    const users = _.sortBy( rsrc.users, 'name' );
    for ( const user of users ) {
      const data = formatUserForResource( user, rsrc, detailed );
      if ( data ) res.push( data );
    }
  }

  return res.concat( '' ).join( '\n' );
}

async function formatSummaryByUser(
  helper: MembershipHelper,
  detailed: boolean = false,
): Promise<string> {
  throw new Error( 'TODO' );
}

function formatUserForResource(
  user: User, rsrc: Resource, detailed: boolean = false,
): string | undefined {
  const res: string[] = [];
  const info = user.getFinalChangeInfoForResource( rsrc );
  if ( ! info ) return;

  return yaml.dump( _.omitBy( {
    ..._.omit( info, [
      // 'included_teamlinks', 'excluded_teamlinks',
      'contributing_changes',
    ] ),
    included_changes : _.map( info.included_changes, 'name' ),
    excluded_changes : _.map( info.excluded_changes, 'name' ),
  }, _.isEmpty ) );

  /*
  if ( change && changes.length && detailed ) {
    res.push(
      ' * ' + main,
      ...changes.map( chg => `   - ${chg.getMessage()}` ),
    );
  }
  */
}

function getProvisionMessage( helper: MembershipHelper ) {
  const counts = _.pickBy( helper.getProvisionCounts(), x => x > 0 );
  log.debug( 'COUNTS:', counts );
  delete counts[ 'SSP.User' ];
  if ( _.isEmpty( counts ) ) return;
  const res: string[] = [
    ':::caution New Accounts Being Provisioned',
    '',
    'Adding Members to this Resource will result in new accounts',
    'being provisioned:',
    '',
    ..._.map( counts, ( count, type ) => ` * ${count} new ${type} accounts` ),
    '',
    `Please be advised that provisioning new resources will`,
    `likely incur extra cost. Visit the ${chargebackFAQ} to`,
    `familiarize yourself with what to expect.`,
    '',
    `If you need to add large numbers of people to this resource,`,
    `please open a [support ticket](${supportUrl}).`,
    '',
    ':::',
  ];
  return res.join( '\n' );
}
