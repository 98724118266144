import _ from 'lodash';

import { Module } from '~/core/lib/Module';
import { EventHandler } from './EventHandler';

import type { ModelDefinitionEventHandler } from './types';

Module.create( {
  name      : 'events',
  services  : true,

  parse( data, origin ) {
    if ( _.isEmpty( data.events ) ) return;
    const events = _.flatMap( data.events, ( conf, type ) => {
      return this.subparse( type, conf, origin );
    } );
    if ( _.isEmpty( events ) ) return;
    return { events };
  },

  merge( ...data ) {
    const events = _.compact( _.flatMap( data, 'events' ) );
    if ( ! events.length ) return;
    return { events };
  },

  apply( data, config ) {
    if ( _.isEmpty( data.events ) ) return;
    const events = _.flatMap( data.events, conf => new EventHandler( conf ) );
    config.add( { events } );
  },

  subparse(
    type: string, config: ModelDefinitionEventHandler, origin: string,
  ) {
    if ( _.isArray( config ) ) {
      return _.map( config, conf => this.subparse( type, conf, origin ) );
    }
    if ( _.isFunction( config ) ) return { type, handler : config, origin };
    if ( _.isPlainObject( config ) ) return { type, ...config, origin };
    throw new TypeError( `Invalid EventHandler options '${config}'` );
  },

} );
