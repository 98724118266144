import _ from 'lodash';
import { JobStep } from './Step';

import type { JobOptions } from '~/modules/jobs/types';
import type { JobStepOptions } from './Step';

export type JobStepEnforceProvisionLimitOptions = {
  type: 'EnforceProvisionLimit';
} & JobStepOptions;

declare module '~/modules/jobs/types' {
  export interface JobStepTypes {
    EnforceProvisionLimit: JobStepEnforceProvisionLimitOptions;
  }
}

export class JobStepEnforceProvisionLimit extends JobStep {
  declare config: Partial<JobOptions>;
}

JobStepEnforceProvisionLimit.initialize( {
  id        : 'JobStep.EnforceProvisionLimit',
  inherit   : 'JobStep',
}, BUILD.isServer && {
  methods : class extends JobStepEnforceProvisionLimit {

    /**
     * Determine whether the items this job is attempting to provision
     * will exceed any of the provisioning limits.
     */
    async execute() {
      const { updates } = this.job.data;
      // Find the ids of all the users that are being added
      const add_ids = _.compact( _.map( updates, 'add.service_user_id' ) );
      if ( _.isEmpty( add_ids ) ) return;

      const UserModel = this.job.resource.getMemberInfo().getUserModel();
      const { service_id_field } = UserModel.schema;
      // Find the users that appear to need provisioning
      const users = await UserModel.find( { $and : [
        { _id : { $in : add_ids } },
        { $or : [
          { [ service_id_field ] : null },
          { [ service_id_field ] : '' },
          { is_disabled : true },
        ] },
      ] } ).all( { load : true } );

      const rootJob = await this.job.getRoot();

      return this.ensureProvisioningLimits( {
        project_id    : this.resource.project_id,
        requested_by  : rootJob.owner_id,
      }, users );
    }

  },
} );
