// Automatically generated, DO NOT EDIT!
// To update run "yarn do-updates"
/* eslint-disable */
import { Model } from '~/core/lib/Model';
Model.demand( 'GitHub.Team' ).extend( {
  "actions": {
    "import": {
      "access": "support",
      "icon": "far:file-import",
      "id": "import",
      "job_name": "import",
      "label": "Import",
      "name": "import",
      "params": {
        "owner": {
          "describe": "GitHub Org Name",
          "label": "GitHub Org Name",
          "required": true,
          "type": "string"
        },
        "team": {
          "describe": "GitHub Team Slug",
          "label": "GitHub Team Slug",
          "required": true,
          "type": "string"
        }
      },
      "type": "class"
    },
    "sync": {
      "access": "member",
      "icon": "fas:sync",
      "id": "sync",
      "job_name": "sync",
      "label": "Sync",
      "name": "sync",
      "type": "instance"
    }
  }
} );
