import _ from 'lodash';
import { faker } from '@faker-js/faker/locale/en';
import { mkdebug } from '@ssp/utils';
import { Field } from '../fields/Field';

import * as fakers from './fakers';

const debug = mkdebug( 'ssp:database:faker' );

// TODO - There are cases where this won't work:
//    - generated database records will have ids generated by shortid
//    - mongo's $sample aggregate
if ( BUILD.isServer ) {
  const { config } = require( '@ssp/config' );
  config.register( 'database/faker', {
    seed    : {
      type      : 'integer',
      default   : faker.datatype.number,
      env       : 'FAKER_SEED',
    },
  } );
  config.watch( 'database/faker/seed', seed => {
    if ( _.isString( seed ) ) seed = parseInt( seed );
    if ( ! _.isFinite( seed ) ) return;
    faker.seed( seed );
    debug( `FAKER_SEED = ${seed}` );
  } );
} else {
  const seed = faker.datatype.number();
  faker.seed( seed );
  debug( `FAKER_SEED = ${seed}` );
}

_.merge( faker, { fake, generate }, fakers );

export function fake( str, data={}, opts={} ) {
  if ( _.isNil( str ) ) return;

  if ( _.isPlainObject( str ) ) {
    return _.mapValues( str, val => fake( val, data, opts ) );
  }
  if ( _.isArray( str ) ) {
    return _.map( str, val => fake( val, data, opts ) );
  }
  if ( ! _.isString( str ) ) return;
  str = str.trim();
  if ( str.length === 0 ) return;

  if ( str === 'undefined' ) return undefined;
  if ( str === 'null' ) return null;

  // Raw booleans
  if ( str === 'true' ) return true;
  if ( str === 'false' ) return false;

  // Raw numbers
  if ( /^\d+$/u.test( str ) ) return parseInt( str );
  if ( /^\d+\.\d+$/u.test( str ) ) return parseFloat( str );

  // Quoted strings
  const quoted = /^(['"])(.*)\1$/u.exec( str );
  if ( quoted ) return quoted[ 2 ];

  // Mustache templates
  if ( /\{\{/u.test( str ) ) {
    // Replace mustache-style templates
    return str.replace( /\{\{(.*?)\}\}/ug, ( x, p1 ) => fake( p1 ) );
  }

  // JSON-encoded properties
  const is_json = ( str.startsWith( '{' ) && str.endsWith( '}' ) )
    || ( str.startsWith( '[' ) && str.endsWith( ']' ) );
  if ( is_json ) return JSON.parse( str );

  // Method calls, in either of these formats:
  // category.faker.name( "json", "encoded", "arguments" )
  // category.faker.name string arguments
  const [ method, ...args ] = extract( str );
  if ( method ) {
    const func = _.get( faker, method );
    if ( _.isFunction( func ) ) return func( ...args );
  }

  log.warn( `Unable to parse faker specification "${str}"` );

  return str;
}

class FakerField {
  /**
   * Field name
   *
   * @type {string}
   */
  name;

  /**
   * Faker to use for this field.
   *
   * @type {string|Function}
   */
  faker;

  /**
   * Default value to use for this field.
   *
   * @type {any|Function}
   */
  default;

  /**
   * Current value of the field.
   *
   * @type {any}
   */
  value;

  /**
   * `Field` instance for DB fields.
   *
   * @type {Field}
   */
  field;

  constructor( config, data, options={} ) {
    _.assign( this, config, { data, options : { ...options, field : this } } );
    if ( this.field ) {
      _.defaults( this, _.pick( this.field, [ 'name', 'faker', 'default' ] ) );
    }
  }
  empty( value ) { return _.isNil( value ) || value === ''; }
  get needs_value() {
    return this.empty( this.value ) || _.isFunction( this.value );
  }
  get has_value() { return ! this.needs_value; }

  async process() {
    if ( this.has_value ) return;
    this.value = await this.getValue();
  }

  run( func ) {
    return func.call( this.context, this.data, this.options );
  }
  maybeRun( value ) {
    if ( _.isFunction( value ) ) return this.run( value );
    return value;
  }

  result( prop ) {
    const value = _.get( this, prop );
    if ( _.isFunction( value ) ) return this.run( value );
    if ( ! this.empty( value ) ) return value;
  }

  async getValue() {
    const source = this.options.fakers?.[ this.name ]
      ?? this.faker
      ?? this.field?.faker
      ?? this.field?.type?.faker;

    if ( source === false ) return;

    if ( _.isString( source ) ) {
      const res = fake( source, this.data, this.options );
      if ( ! this.empty( res ) ) return res;
    }
    if ( _.isFunction( source ) ) {
      const res = this.run( source );
      if ( ! this.empty( res ) ) return res;
    }
    const val = await this.result( 'default' );
    if ( ! this.empty( val ) ) return val;
  }

}

/**
 * Generate some data from a bunch of field definitions.
 *
 * @param {object} options - Options object.
 * @param {object<string,FakerField>|FakerField[]} options.fields
 * - Field definitions. Can be an object where the keys are field
 * names and the values are either faker definitions or objects with
 * a `faker` property (like DB field configurations), or an array of
 * objects with `name` and `faker` properties.
 * @param {object} [options.fakers] - Override fakers.  This is mostly
 * useful when you are passing `fields` directly from some DB
 * configuration.  This can be an object where the keys are field
 * names and the values are either a faker config or a function.  Any
 * fakers defined here override the ones defined on the fields with
 * those names.
 * @param {object<string,any|Function>} [options.data]
 * - Initialization data.  Everything in this object will be used to
 * initialize the returned data object, so values passed in here
 * won't get faked values.  If the values are functions they will be
 * run to determine the data.
 * @param {object} [options.defaults] - Default values.  Any
 * properties in this object will be added to the result object just
 * before it's returned, unless the results already have a value for
 * that property.  The defaults can also be a function that returns
 * the default value.
 * @param {any} [options.context] - Context.  If provided, when any
 * `faker` or `default` functions are run this value will be provided
 * as the `this` context.
 * @returns {object} Returns an object where the keys are the field
 * names and the values are the values for each field, either from the
 * `data` object, or generated by appropriate fakers, or from the
 * `defaults` object.
 */
export async function generate( options={} ) {
  const fields = getFields( options );
  const data = {};
  for ( const field of fields ) {
    if ( ! field.needs_value ) continue;
    await field.process( data, options, field );
    if ( ! field.needs_value ) data[ field.name ] = field.value;
  }
  _.defaults( data, options.defaults );
  return data;
}

function getFields( options ) {
  const data = {};
  return _.map( options.fields, ( conf, key ) => {
    const field = {};
    if ( conf instanceof Field ) {
      field.field = conf;
    } else if ( _.isPlainObject( conf ) ) {
      _.assign( field, conf );
    } else {
      field.faker = conf;
    }
    if ( _.isString( key ) ) field.name = key;
    return new FakerField( field, data, options );
  } );
}

function extract( str ) {
  const x = /^([\w.]+)\((.*)\)$/u.exec( str );
  if ( x ) {
    const res = [ x[ 1 ] ];
    if ( x[ 2 ] ) {
      try {
        const args = JSON.parse( `[${x[ 2 ]}]` );
        res.push( ...args );
      } catch ( err ) {
        throw new Error(
          `Unable to parse arguments from "${str}": ${err.message}`,
        );
      }
    }
    return res;
  }
  if ( /^[\w.]+(\s|$)/u.test( str ) ) return str.split( ' ' );
  return [];
}

export { faker };
