import _ from 'lodash';
import { DataMap } from './DataMap';
import { List } from '../fields/List';

import type { Model } from '~/core/lib/Model';

const dataSymbol = Symbol( 'DataMap' );

/**
 * Get the data map for a given resource.  This is not intended for
 * general purpose use, unless you have a *really* good reason why you
 * need to use this, you should just stick with the normal field
 * accessors.
 *
 * @param doc - The resource document to get the data map
 * for.
 */
export function getDataMap( doc: any ) {
  const map = doc[ dataSymbol ];
  if ( map instanceof DataMap ) return map;
  if ( ! doc.schema ) {
    throw new Error( `Can't create DataMap for resource without schema` );
  }
  Object.defineProperty( doc, dataSymbol, {
    enumerable    : false,
    configurable  : false,
    writable      : false,
    value         : new DataMap( {
      schema  : doc.schema,
      is_list : doc instanceof List,
    } ),
  } );
  return doc[ dataSymbol ];
}

export type UpdateQuietlyOptions = {
  /**
   * Allow partial updates.
   * This exists only to support the Broker's `updateFromPartials`
   * method, and allows for setting what data is available when you
   * don't have the complete resource data.  If you set this to `true`
   * then any fields that are not included in `data` will just be
   * ignored.  By default it assumes that fields missing from `data`
   * had their values deleted, and deletes them locally too.
   */
  partial?: boolean;
  /**
   * Keep transient values even if the update provides no data for them.
   */
  transient?: boolean;
};
/**
 * Update a resource "quietly", meaning without triggering the normal
 * notifications about a document being modified.  This has a very
 * narrow use scope, it's called in order to update a resource
 * instance when it's being constructed, or when an unmodified local
 * copy of a resource has been updated on the server and the live copy
 * in the browser should be updated to match. This is used primarily
 * by the `Broker` instance, and for some special requirements by
 * `MongoTransport`, but aside from those uses you should probably
 * just pretend this doesn't exist and definitely don't import it
 * anywhere else.
 *
 * @param rsrc - The resource to update.
 * @param data - The new values to update the resource with.
 * @param opts - Options object.
 */
export function updateQuietly(
  rsrc: Model,
  data: Record<string, $TSFixMe>,
  opts: UpdateQuietlyOptions = {},
): boolean {
  _.defaults( opts, {
    partial   : false,
    transient : true,
  } );
  const datamap = getDataMap( rsrc );
  // Iterate over all of the non-computed fields....
  const changes = rsrc.schema.getFields( '@all', '-@computed' ).map( field => {
    const key = field.name;
    if ( _.has( data, key ) ) {
      // If we do have a value for the field, then we update the field with it.
      return field.updateQuietly( rsrc, data[ key ] );
    } else {
      // If the data we were given doesn't have a value for the field...
      // If we're operating in partial mode then that's ok, we can ignore it.
      if ( opts.partial ) return false;
      // If it's a transient field and we're accepting transient
      // fields, then we can also ignore it.
      if ( opts.transient && field.transient ) return false;
      // Otherwise we assume this means it's value was deleted on the server,
      // so we delete it locally also
      datamap._delete( key );
      // And return true so that the caller is notified that there
      // were some data changes.
      return true;
    }
  } );
  return Boolean( _.some( changes ) );
}
