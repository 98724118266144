import _ from 'lodash';
import { DatabaseResultSetError } from '@ssp/utils';

const validators = {
  string  : _.isString,
  boolean : _.isBoolean,
  integer : _.isInteger,
};

export type OptionHelper<T=unknown> = {
  /** Validator, for simple types */
  type: keyof typeof validators;
  // Set to true if this helper takes an object as an argument
  // (which means that if it's transform method produces an object
  // it's the actual value, rather than a combination of options).
  object?: boolean;
  // If true then this option is one that gets passed directly
  // through to MongoDB.
  direct?: boolean;
  // Validator method that takes a value and throws an error if
  // it's not valid.
  validate?: ( value: any ) => value is T;
  // Transform method that takes arguments provided by the user
  // (for example by calling `rs.limit( 20 )` and transformes those
  // arguments into the value that gets added to the options.
  // transform?: ( value: any ) => any | { [key: keyof helpers]: any };
  default?: T;
};

// Please keep the exports in here sorted alphabetically, it's kind of
// a big file to keep track of...

export const batch: OptionHelper<number> = {
  type    : 'integer',
  default : 50,
};

export const children: OptionHelper<boolean> = {
  type    : 'boolean',
  default : true,
};

export const collation: OptionHelper = {
  validate( value ) {
    if ( _.isObject( value ) ) return value; // TODO - validate structure
    throw new DatabaseResultSetError( {
      message : `"collation" option must be an object`,
      tags    : { schema : this.schema.id, method : 'ResultSet#collation' },
      data    : { value },
    } );
  },
  object    : true,
  direct    : true,
};

export const comment: OptionHelper<string> = {
  type   : 'string',
  direct : true,
};
export const exact: OptionHelper<boolean> = {
  type    : 'boolean',
  default : false,
};

/** Specify data fields to add to the return ref_urls. */
export const fields: OptionHelper<string[]> = {
  validate( value ) {
    const chk = val => _.isString( val ) && /^\w+$/u.test( val );
    if ( _.isArray( value ) && _.every( value, chk ) ) {
      return value;
    } else {
      throw new DatabaseResultSetError( {
        message : `Option "fields" must be an array of strings`,
        tags    : { schema : this.schema.id, method : 'ResultSet#fields' },
        data    : { value },
      } );
    }
  },
  transform( ...args ) { return _.compact( _.flattenDeep( args ) ); },
};

/**
 * Set an arbitrary flag that can be processed on the backend.
 */
export const flag: OptionHelper = {
  transform( name, value=true ) {
    const was = _.get( this, 'options.flag', {} );
    if ( _.isPlainObject( name ) ) {
      return { ...was, ...name };
    } else {
      return { ...was, [ name ] : value };
    }
  },
  object  : true,
};

export const hint: OptionHelper<string> = {
  type   : 'string',
  direct : true,
};
export const limit: OptionHelper<number> = {
  type   : 'integer',
  direct : true,
};
export const maxTimeMS: OptionHelper<number> = {
  type   : 'integer',
  direct : true,
};
export const matched: OptionHelper<boolean> = {
  type : 'boolean',
};

const DEFAULT_MINLENGTH = 3;
export const minlength: OptionHelper<boolean | number> = {
  type : [ 'boolean', 'integer' ],
  preload_client( minlen ) {
    let want = minlen ?? this.options.minlength ?? DEFAULT_MINLENGTH;
    if ( want === true ) want = DEFAULT_MINLENGTH;
    if ( this.options.regexp || ! want ) return;
    const have = this.options.search?.length || 0;
    const need = want - have;
    if ( need > 0 ) return `Enter ${need} more characters`;
  },
  validate( val ) {
    if ( _.isBoolean( val ) || _.isFinite( val ) ) return;
    throw new DatabaseResultSetError( {
      message : `Option "minlength" must be boolean or number`,
      tags    : { schema : this.schema.id, method : 'ResultSet#minlength' },
      data    : { value : val },
    } );
  },
  transform( val ) { return val; },
};

export const mode: OptionHelper<'sync'|'async'> = {
  validate( value ) {
    const options = [ 'sync', 'async' ];
    if ( _.isString( value ) && _.includes( options, value ) ) return value;
    throw new DatabaseResultSetError( {
      message : `"mode" option must be "sync" or "async"`,
      tags    : { schema : this.schema.id, method : 'ResultSet#mode' },
      data    : { value },
    } );
  },
};

export const pageSize: OptionHelper<number> = {
  type  : 'integer',
  validate( value ) {
    if ( _.isInteger( value ) || value === Infinity ) return value;
    throw new DatabaseResultSetError( {
      message : `"pageSize" option must be an integer`,
      tags    : { schema : this.schema.id, method : 'ResultSet#pageSize' },
      data    : { value },
    } );
  },
  direct  : true,
};

export const quicksearch: OptionHelper<boolean> = {
  type  : 'boolean',
  transform( value ) {
    if ( _.isBoolean( value ) ) return value;
    if ( _.isString( value ) ) return { search : value, quicksearch : true };
  },
};

// Set to true if the search option should be treated as a RegExp.
export const regexp: OptionHelper<boolean> = {
  type  : 'boolean',
  transform( value ) {
    if ( _.isBoolean( value ) ) return value;
    if ( _.isString( value ) ) return { regexp : true, search : value };
    if ( _.isRegExp( value ) ) {
      return {
        regexp    : true,
        search    : value.pattern,
        sensitive : ! value.ignoreCase,
      };
    }
  },
};

export const readConcern: OptionHelper<
  'local'|'available'|'majority'|'linearizable'
> = {
  validate( value ) {
    /*
     * Possible read concerns:
     *  'local' (default)
     *  'available'
     *  'majority'
     *  'linearizable'
     */
    const options = [ 'local', 'available', 'majority', 'linearizable' ];
    if ( _.isString( value ) && _.includes( options, value ) ) {
      return value;
    }
    throw new DatabaseResultSetError( {
      message : `"readConcern" option is not valid`,
      tags    : { schema : this.schema.id, method : 'ResultSet#readConcern' },
      data    : { value, options },
    } );
  },
  direct  : true,
};

// TODO - is this actually used for anything?
export const restrictions: OptionHelper = {
  validate( value ) {
    if ( _.isArray( value ) ) return value;
    if ( _.isObject( value ) ) return value;
    throw new DatabaseResultSetError( {
      message : '"restrictions" option must be an array or object',
      tags    : { schema : this.schema.id, method : 'ResultSet#restrictions' },
      data    : { value },
    } );
  },
  object  : true,
};

export const search: OptionHelper<string> = { type : 'string' };
export const sensitive: OptionHelper<boolean> = { type : 'boolean' };
export const skip: OptionHelper<number> = { type : 'integer', direct : true };

export const filters: OptionHelper<string|string[]> = {
  validate( value ) {
    const chk = val => _.isString( val ) && /^-?(\w+|\*)$/u.test( val );
    if ( _.isArray( value ) && _.every( value, chk ) ) {
      return value;
    } else {
      throw new DatabaseResultSetError( {
        message : `Option "filters" must be an array of strings`,
        tags    : { schema : this.schema.id, method : 'ResultSet#filters' },
        data    : { value },
      } );
    }
  },
  transform( ...args ) {
    let was = _.get( this, 'options.filters', [] );
    for ( const arg of _.filter( _.flattenDeep( args ), _.isString ) ) {
      if ( arg.startsWith( '-' ) ) {
        was = _.without( was, arg.slice( 1 ) );
      } else if ( arg.startsWith( '!' ) ) {
        if ( was.includes( arg.slice( 1 ) ) ) {
          was = _.without( was, arg.slice( 1 ) );
        } else {
          was = was.concat( arg.slice( 1 ) );
        }
      } else if ( arg.startsWith( '+' ) ) {
        was = was.concat( arg.slice( 1 ) );
      } else {
        was = was.concat( arg );
      }
    }
    return _.uniq( was );
  },
};

/**
 * Specify how to sort the results.  Takes an array of field names
 * optionally prefixed with `+` or `-` to indicate ascending or
 * descending sort order (with `+` being the default if you don't
 * specify).
 */
export const sort: OptionHelper<string | string[]> = {
  transform( ...args ) {
    if ( args.length === 1 && args[0] === false ) return false;
    args = _.compact( _.map( args, arg => {
      const x = /^([+-]?)(\w+)$/u.exec( arg );
      if ( ! x ) return;
      if ( x[2] === 'undefined' ) return;
      return ( x[ 1 ] || '+' ) + x[ 2 ];
    } ) );
    // Only keep the first instance of each field
    args = _.uniqBy( args, arg => arg.substring( 1 ) );
    return ( args.length === 1 ) ? args[ 0 ] : [ ...args ];
  },
  validate( value ) {
    if ( _.isArray( value ) && _.size( value ) === 1 && value[0] === false ) {
      return false;
    }
    const chk = val => _.isString( val ) && /^[+-]?\w+$/u.test( val );
    const valid = chk( value )
      || ( _.isArray( value ) && _.every( value, chk ) );
    if ( ! valid ) {
      throw new DatabaseResultSetError( {
        message : `"sort" option must be a string, array of strings, or false`,
        tags    : { schema : this.schema.id, method : 'ResultSet#sort' },
        data    : { value },
      } );
    }
    return value;
  },
};

export function validate( name, value, types ) {
  types = _.castArray( types );
  for ( const type of types ) {
    const validator = validators[ type ];
    if ( _.isFunction( validator ) ) {
      if ( validator( value ) ) return;
    } else {
      throw new DatabaseResultSetError( {
        message : `Unknown option-helper type "${type}"`,
        tags    : { schema : this.schema.id, method : `ResultSet#${type}` },
        data    : { name, value, types },
      } );
    }
  }
  let msg = `"${name}" option must be `;
  if ( types.length === 1 ) {
    msg += types[0];
  } else {
    msg += [ ..._.initial( types ), `or ${_.last( types )}` ].join( ', ' );
  }
  throw new DatabaseResultSetError( {
    message : msg,
    tags    : { schema : this.schema.id, method : `ResultSet#${name}` },
    data    : { name, value, types },
  } );
}
