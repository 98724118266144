import { hideProps } from '@ssp/utils';

import type { TResource } from '~/types';

import type { MembershipHelper, MembershipChange } from './MembershipHelper';
import type { Resource } from './Resource';

export class TeamLink {

  readonly helper: MembershipHelper;

  /** The resource that this team link applies changes to. */
  resource: Resource;

  get id(): string { return this.teamlink._id; }
  get name(): string { return this.teamlink.display_name; }

  constructor(
    public teamlink: TResource<'SSP.TeamLink'>,
    helper: MembershipHelper,
  ) {
    hideProps( this, { helper } );
  }

  async prepare() {
    await this.teamlink.load();
    this.resource = await this.helper.resource( this.teamlink.to );
  }

  async processChange( change: MembershipChange ) {
    return this.resource.processChange( {
      ...change,
      config  : this.teamlink.config || {},
    }, this );
  }

  acceptsChange( change: MembershipChange ): [ boolean, string ] {
    if ( ! change ) return [ false, 'No change' ];
    const { teamlink } = this;
    const { is_discovered, change_type } = change;
    if ( is_discovered && ! teamlink.include_discovered_changes ) {
      return [ false, 'Discovered changes are ignored by config' ];
    }

    if ( change_type === 'add' ) {
      if ( teamlink.auto_add ) {
        return [ true, 'Link is configured to process additions' ];
      } else {
        return [ false, 'Link is not configured to process additions' ];
      }
    }

    if ( change_type === 'remove' ) {
      if ( teamlink.auto_remove ) {
        return [ true, 'Link is configured to process removals' ];
      } else {
        return [ false, 'Link is not configured to process removals' ];
      }
    }

    if ( change_type === 'update' ) {
      return [ false, 'Links do not process updates' ];
    }

    return [ false, `Unknown change_type "${change_type}"` ];
  }

}
