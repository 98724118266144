import DB from '~';
import { createResource } from '~/core/creators';

createResource( {
  id               : 'GitHub.Team',
  name             : 'GitHub Team',
  icon             : 'fab:github',
  attachable       : [ 'SSP.Project' ],
  service_id_field : 'github_id',
  hideZero         : true,
  fields           : {
    organization : {
      type        : 'link',
      label       : 'Organization',
      summary     : true,
      createonly  : true,
      help        : 'The organization that will manage this team.',
      model       : 'GitHub.Organization',
      resultset( resource, field ) {
        return DB.GitHub.Organization.find(
          { project_id : resource.project_id },
          { minlength : false },
        );
      },
    },
    name          : {
      type          : 'string',
      label         : 'Team Name',
      index         : true,
      quicksearch   : true,
    },
    description   : {
      type          : 'string',
      label         : 'Description',
      summary       : true,
      quicksearch   : true,
      optional      : true,
      minimal       : true,
    },
    slug          : {
      type          : 'string',
      label         : 'Slug',
      summary       : true,
      optional      : true,
      readonly      : true,
    },
    privacy       : {
      type          : 'string',
      label         : 'Privacy',
      summary       : true,
      default       : 'closed',
      help          : [
        'For non-nested teams: ',
        'Visible = visible to all members of this organization',
        'Secret = only visible to organization owners and team members',
      ],
      form          : {
        type        : 'Select',
        options     : [
          { label : 'Visible', value : 'closed' },
          { label : 'Secret', value : 'secret' },
        ],
      },
    },
    github_id     : {
      type          : 'github-id',
      label         : 'GitHub Id',
      index         : true,
      unique        : true,
      summary       : true,
      optional      : true,
      readonly      : true,
      immutable     : true,
    },
  },
  actions : {
    // TODO[members,forms] - Make this warning appear on the manage-members page
    /*
    removeMember  : {
      warning         : [
        `Removing members from this team will also delete`,
        `their forks of any private, team-owned repositories`,
      ].join( ' ' ),
    },
    */
  },
  behaviors : {
    hasExternalLink : {},
    hasMembers    : {
      withCreator : {
        default : false,
        config  : { roles : [ 'Member' ] },
      },
      userType    : 'GitHub.User',
      withRole    : {
        roles         : [ 'Maintainer', 'Member' ],
        defaultRole   : 'Member',
      },
      withService : true,
    },
  },
  indexes : {
    organization_id_name_idx : {
      fields  : { 'organization.id' : 1, 'name' : 1 },
      partialFilterExpression : { 'organization.id' : { $exists : true } },
      unique  : true,
    },
  },
  methods : class {
    getParent() { return this.organization; }
  },

  faces         : {
    'card.summary' : [
      {
        layout : 'Badges',
        fields : [ '@badge' ],
      },
      {
        layout : 'LabelsAndValues',
        fields : [
          'slug', 'organization', 'members_count', 'privacy', 'external_link',
          'description',
        ],
      },
    ],
    'list.summary' : [
      {
        layout : {
          type : 'ItemsBetweenTitleAndIcons',
        },
        fields : [ '-@all' ],
      },
      {
        layout : 'ItemsAndBadges',
        fields : [ 'description', 'organization', '@badge' ],
      },
    ],
  },
} );
