import DB from '~';
import { Forbidden } from '@ssp/utils';
import { createResource } from '~/core/creators';
import { getModel } from '~/core/schemas';

createResource( {
  id              : 'SSP.User.Settings',
  inherit         : 'UserResource',
  name            : 'SSP User Settings',
  attachable      : [ 'SSP.User' ],
  doNotAssimilate : true,

  fields  : {
    user_id       : {
      type          : 'id',
      label         : 'User ID',
      index         : true,
      unique        : true,
      readonly      : true,
      required      : true,
      metadata      : true,
    },
    owner         : {
      type      : 'SSP.User',
      label     : 'Owner',
      face      : { type : 'Owner' },
      computed  : {
        cached  : true,
        get() { return DB.SSP.User.fromId( this.user_id ); },
      },
      metadata  : true,
      readonly  : true,
    },
    start_page  : {
      /*
       * if set, this should be set to the route you want to
       * start on (some suggestions):
       *  - /projects           Projects Page
       *  - /project/<some-id>  Specific Project Page
       *  - /about              About Page
       *  - /user               Your user profile page
       *
       * If it is not set, then a start page will be determine for
       * you automatically:
       * - If you are a member of multiple projects, then go to /projects
       * - If you are a member of only one project, then go there
       * - if you aren't a member of any projects then go to /about
       */
      type        : 'string',
      label       : 'Start Page',
      hidden      : true,
      optional    : true,
    },
    quick_projects  : {
      type        : 'string',
      array       : true,
      ordered     : true,
      label       : 'Quick Projects',
      hidden      : true,
      form        : false,
      optional    : true,
      default     : [],
    },
    progress_style : {
      type     : 'string',
      label    : 'Progress Indicator Style',
      help     : 'Select the type of progress indicators to use.',
      optional : true,
      default  : 'circular',
      form     : { type : 'RadioGroup', options : [ 'circular', 'numeric' ] },
    },
    show_users_page         : {
      type     : 'boolean',
      label    : 'Show Users Page',
      help     : 'Show the more advanced "Users" search page.',
      optional : true,
      default  : false,
    },
    show_absolute_timestamps  : {
      type     : 'boolean',
      label    : 'Absolute dates?',
      help     : 'Show actual date and time rather than relative to now',
      optional : true,
      default  : false,
    },
    datetime_format       : {
      type    : 'string',
      label   : 'Date/Time Display Format',
      help    : [
        'https://moment.github.io/luxon',
        '#/formatting?id=table-of-tokens',
      ].join( '/' ),
      optional  : true,
      default   : 'FFF',
    },
    auto_launch_tours : {
      type     : 'string',
      label    : 'Automatically Launch Tours',
      help     : 'Which tours do you want to automatically launch?',
      optional : true,
      default  : 'New and Updated',
      form     : {
        type : 'RadioGroup',
        options : [ 'New and Updated', 'Only New', 'Never' ],
        default : 'New and Updated',
        addons : [
          {
            type      : 'Button',
            position  : 'append',
            label     : 'Reset all tours',
            className : 'p-2',
            async onClick( { config } ) {
              const settings = config.resource.broker.origin.getInstance();
              await settings.load();
              settings.tour_history.clear();
              await settings.save();
            },
          },
        ],
      },
    },
    tour_history : {
      type : 'SSP.User.Settings.TourHistory',
      optional : true,
      list : { sort : false },
    },
  },

  methods : class {
    route() { return `/user/${this.user_id}/settings`; }

    async load( opts={} ) { return this.load_or_insert( opts ); }

    getStartPage() {
      if ( ! this.loaded ) return;
      if ( this.start_page && this.start_page.startsWith( '/' ) ) {
        return this.start_page;
      }
      if ( this.quick_projects ) {
        if ( this.quick_projects.length === 1 ) {
          return `/project/${this.quick_projects[ 0 ]}`;
        }
        if ( this.quick_projects.length > 1 ) return '/projects';
      }
      // Change this to change what the default page is for people who
      // haven't specified a start page and don't have any quick_projects.
      return '/about';
    }

    async updateTourHistory( data ) {
      if ( this.tour_history.has( data ) ) {
        // if a user has previously finished a tour,
        // rewatching and skipping it should retain its finished status,
        // which comes in handy when users want only new tours and not updated
        // ones to automatically launch
        if ( this.tour_history.get( data ).status === 'finished' ) {
          delete data.status;
        }
        this.tour_history.get( data ).merge( data );
      } else {
        this.tour_history.add( data );
      }
      await this.save();
    }
  },

  events  : {
    async beforeQuery( ev ) {
      const { req } = ev;
      if ( ! req ) return;
      req.restrict( { user_id : req.subject._id } );
    },
    beforeInsert( ev ) {
      if ( this._id && ! this.user_id ) this.user_id = this._id;
      if ( this.user_id && ! this._id ) this._id = this.user_id;
    },
    duringBuild( ev ) {
      if ( this._id && ! this.user_id ) {
        this.merge( { user_id : this._id }, true );
      }
      if ( this.user_id && ! this._id ) {
        this.merge( { _id : this.user_id }, true );
      }
    },
  },

} );

if ( BUILD.isServer ) {
  getModel( 'SSP.User.Settings' ).extend( {
    methods : class {
      async getDisplayName() {
        const user = await this.owner.load();
        const name = await user.getDisplayName();
        return `User Settings - ${name}`;
      }
    },
    events  : {
      beforeRequest( ev ) {
        const req = ev.request;
        // Can insert from the browser only your own settings record
        // (it must have the same _id as your user).
        if ( req.method === 'insert' ) {
          const [ field ] = req.updates.getField( '_id' );
          const id = field?.value;
          if ( id !== req.subject._id ) {
            throw new Forbidden( 'Can only insert your own settings' );
          }
        }
      },
    },
  } );
}
