import _ from 'lodash';
import { idgen } from '@ssp/utils';

import { noun, adjective } from './words';
import { faker } from '../faker';

export async function id() { return idgen(); }
export async function ids( type, count=3 ) { return _.times( count, id ); }

export async function jiraKey() {
  return [
    adjective().substring( 0, 5 ),
    noun().substring( 0, 5 ),
    faker.datatype.number( 99999 ),
  ].join( '' ).toUpperCase();
}
