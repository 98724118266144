import _ from 'lodash';
import { verboseCheckAccess } from '../actions/utils';
import { Field } from '../fields/Field';

import type { Resource } from '~/core/resource/Resource';

/**
 * Determine if a given user (the current user from the global
 * context by default) is allowed to modify the value of a given
 * field.
 */
export function canModifyField(
  resource: Resource,
  field: string | Field,
  subject = ctx.user,
) {
  const [ result, _reason ] = verboseCanModifyField( resource, field, subject );
  return result;
}

/**
 * Determine if a given user (the current user from the global
 * context by default) is allowed to modify the value of a given
 * field.
 */
export function verboseCanModifyField(
  resource: Resource,
  field: string | Field,
  subject = ctx.user,
) {
  if ( ! subject ) return [ false, 'no subject provided' ];
  if ( ! resource ) return [ false, 'no resource provided' ];
  if ( ! field ) return [ false, 'no field provided' ];
  const schema = resource.schema;
  if ( _.isString( field ) ) {
    if ( ! schema.hasField( field ) ) {
      return [ false, `"${field}" is not a valid field on ${schema.id}` ];
    }
    field = schema.getField( field );
  }
  if ( ! ( field instanceof Field ) ) {
    return [ false, 'invalid field provided' ];
  }
  // Metadata fields default to no access, assuming they will only get
  // updated on the server.  You can specify an access property
  // though, as there are exceptions to this (like `project_id` and
  // `user_id` which can be updated to change ownership).
  if ( field.metadata && ! field.access ) {
    return [ false, 'metadata field with no access specifier' ];
  }
  // The default access level for non-metadata fields is 'admin'
  const access = field.access || 'admin';
  if ( access === 'none' ) return [ false, 'access is none' ];
  // @ts-ignore
  if ( access === 'anonymous' ) {
    return [ false, 'anonymous not supported for fields' ];
  }
  return verboseCheckAccess( subject, resource, access );
}
