import _ from 'lodash';
import { createResource } from '~/core/creators';
import { Schema } from '~/core/lib/Schema';
import { getModel } from '~/core/schemas';

function membersComps() {
  return Schema.ids( s => s.hasBehavior( 'hasMembers' ) );
}

const linkOptions = {
  summary     : true,
  index       : true,
  createonly  : true,
  model() {
    const possibles = membersComps();
    const { counts } = this.form.resourceContext?.broker?.origin?.counts;
    if ( ! counts ) return possibles;
    return possibles.filter( model => counts[ model ] );
  },
  query() {
    return { project_id : this.form.resourceContext.project_id };
  },
  options     : { minlength : false },
};

createResource( {
  id         : 'SSP.TeamLink',
  name       : 'Team Link',
  icon       : 'far:link',
  attachable : [ 'SSP.Project' ],

  fields     : {
    name            : {
      type        : 'string',
      label       : 'Link Title',
      description : 'Title of the resources linked',
      summary     : true,
      quicksearch : true,
      readonly    : true,
      optional    : true,
    },
    from  : {
      type        : 'link',
      label       : 'Link From',
      description : 'The link\'s originating resource',
      ...linkOptions,
    },
    to    : {
      type        : 'link',
      label       : 'Link To',
      description : 'The link\'s target resource',
      ...linkOptions,
    },
    type  : {
      type        : 'string',
      label       : 'Link Type',
      optional    : true,
      summary     : true,
      readonly    : true,
    },
    auto_add        : {
      type        : 'boolean',
      label       : 'Auto Add',
      default     : true,
      summary     : true,
      optional    : true,
      minimal     : true,
      hideFalse   : true,
      badge       : { label : 'Auto Add' },
      help        : [
        'When members are added to the "from" resource,',
        'automatically add them to the "to" resource',
      ],
    },
    auto_remove        : {
      type        : 'boolean',
      label       : 'Auto Remove',
      default     : false,
      summary     : true,
      optional    : true,
      minimal     : true,
      hideFalse   : true,
      badge       : { label : 'Auto Remove' },
      help        : [
        'When members are removed from the "from" resource,',
        'automatically remove them from the "to" resource',
      ],
    },
    include_discovered_changes : {
      type        : 'boolean',
      label       : 'Include discovered changes',
      help        : [
        'When this is true then changes that are made directly in the',
        'service (rather than through SSP) will be applied to',
        'relevant team links also. When false (the default) those',
        'discovered changes do not propagate.',
      ],
      default     : false,
      summary     : false,
      optional    : true,
    },
    add_all : {
      type        : 'boolean',
      label       : 'Add All Members',
      default     : true,
      optional    : true,
      minimal     : true,
      help        : 'Add all members on creation',
      transient   : true,
      createonly  : true,
    },
    config : {
      type      : 'object',
      label     : 'Configuration',
      help      : 'Configuration options that will be applied to members',
      summary   : true,
      optional  : true,
      subdocObj : true,
      form : {
        fields : [ '-user_id', '-service_user_id' ],
        create : {
          conditional : {
            fieldName : 'to', // field for condition to meet
            subdocObj : true,
            condition( field, fieldstate ) {
              const { input } = fieldstate;
              if ( ! input.value ) return false;
              _.assign( field, { schema : input.value.members.schema } );
              return true;
            },
          },
        },
      },
    },
  },
  forms : {
    message : [
      'Note: Changing this configuration will only affect people',
      'who are added to or removed from the target resource through',
      'this link. This will not change the permissions of existing',
      'members. Permissions of existing members can be changed on the',
      '"Manage Members" page.',
    ],
  },
  indexes : {
    from_id_to_id_idx : {
      fields  : { 'from.id' : 1, 'to.id' : 1 },
      options : { unique : true },
    },
  },
  actions     : {
    changeOwner   : false,
    addAllMembers : {
      label           : 'Add All Members',
      help            : [
        `Ensures all members of the "from" resource are also`,
        `members of the "to" resource.`,
      ],
      icon            : 'far:user-check',
      access          : 'admin',
      handler         : 'addAllMembers',
    },
    delete      : {
      label   : 'Delete',
      help    : 'Delete this Team Link from SSP',
      icon    : 'far:trash-alt',
      access  : 'admin',
      route   : 'delete',
      context : 'self',
    },
  },
  methods     : class {

    getMemberInfo() {
      if ( ! this.to ) return;
      return getModel( this.to.schema.id ).MemberInfo;
    }
    getSubformSchema() { return this.getMemberInfo()?.schema; }
    // TeamLinks are accessed from within a resource context
    // but need to be created at the project level.
    static getCreateRoute( parent ) {
      return parent.getOwnerRoute( 'add', this.schema.id );
    }

    async getBreadcrumbInfo() {
      return {
        resource    : this,
        to          : await this.to.load(),
        from        : await this.from.load(),
      };
    }

    getOptions() { return membersComps(); }

    async getDisplayName() {
      await this.from.load( { safe : true } );
      await this.to.load( { safe : true } );
      return [
        this.from?.name || this.from?.id || 'UNKNOWN',
        this.to?.name || this.to?.id || 'UNKNOWN',
      ].join( ' to ' );
    }

    /**
     * Make sure all members of the from resource are also members of
     * the to resource. This doesn't do anything for people who are
     * already members of the to resource.
     * Returns the MemberInfo entries for the members that were added.
     */
    async addAllMembers() {
      const fr = await this.from.load();
      const to = await this.to.load();
      const missing = _.reject( fr.members.toArray(), m => to.hasMember( m ) );
      const todo = _.map( missing, 'user_id' );

      return to.addMembers( todo, this.config );
    }
  },
  queries     : {
    forResource( rsrc ) {
      const id = _.isString( rsrc ) ? rsrc : rsrc._id;
      return { $or : [ { 'from.id' : id }, { 'to.id' : id } ] };
    },
  },
  faces         : {
    'list.summary' : [
      {
        layout : {
          type       : 'ItemsBetweenTitleAndIcons',
          iconize    : [
            {
              fieldName : 'from',
            },
            {
              fieldName : 'to',
            },
          ],
        },
        fields : [ '-@all' ],
      },
      {
        layout : 'ItemsAndBadges',
        fields  : [
          '@badge',
        ],
      },
    ],
  },
} );
