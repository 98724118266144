import { isFunction } from '@ssp/utils';

import type { Broker } from './Broker';

export interface Brokerable {
  attachBroker( broker: Broker ): void;
  detachBroker( broker: Broker ): void;
}

export function isBrokerable( value: any ): value is Brokerable {
  return value && ( typeof value === 'object' )
    && isFunction( value.attachBroker )
    && isFunction( value.detachBroker );
}
