import { createResource } from '~/core/creators';

createResource( {
  id          : 'Test.Thing',
  name        : 'Test Thing',
  attachable  : [ { type : 'SSP.Project', is_test_project : true } ],
  icon        : 'far:bug',
  hideZero    : true,
  fields      : {
    name            : {
      type        : 'string',
      unique      : true,
      faker       : 'project.name',
      quicksearch : true,
    },
    test_id    : {
      type      : 'id',
      optional  : true,
    },
    test_tag    : {
      type        : 'string',
      optional    : true,
      identifier  : true,
      faker       : 'hacker.ingverb',
      quicksearch : true,
    },
    creation_reason : {
      type        : 'string',
      label       : 'Creation Reason',
      createonly  : true,
      faker       : 'company.bs',
    },
    subthing  : {
      type        : 'Test.Thing.SubThing',
      optional    : true,
    },
    subthings : {
      type        : 'Test.Thing.SubThing',
      list        : { sort : false },
      optional    : true,
    },
    string    : {
      type      : 'string',
      default   : '',
      optional  : true,
    },
    test_field_1  : {
      type        : 'string',
      validators  : { length : { min : 1, max : 16 } },
      default     : 'Test This!',
      optional    : true,
      faker       : 'company.catchPhrase',
    },
    test_ensured  : {
      type        : 'string',
      optional    : true,
      faker       : 'none',
    },
    /*
    quidditch_team  : {
      type        : 'QuidditchTeam',
      label       : 'Quidditch Team',
      default     : [],
    },
    */
  },
  actions   : {
    test_danger_message : {
      label   : 'Test Danger Message',
      icon    : 'fad:comments-alt',
      access  : 'anonymous',
      danger  : [
        'This is a danger message!  It can tell you how scary the thing you',
        'are about to do is.',
      ],
      route   : '/',
    },
    test_warning_message : {
      label   : 'Test Warning Message',
      icon    : 'fad:comments-alt',
      access  : 'anonymous',
      warning : [
        `This is a warning message!  It's a little bit less scary than the`,
        `danger message is.`,
      ],
      route   : '/',
    },
    test_message_message : {
      label   : 'Test Regular Message',
      icon    : 'fad:comments-alt',
      access  : 'anonymous',
      message : [
        `This is a message!  It's not scary at all!`,
        `When you click "Submit" I'll take you somewhere educational.`,
      ],
      href    : 'https://google.com/',
    },
    test_action_form : {
      label   : 'Test ActionForm',
      icon    : 'fad:comments-alt',
      message : 'This is a message with a form!',
      access  : 'anonymous',
      params  : {
        first   : {
          type    : 'string',
          label   : `Baby's First Param`,
        },
      },
      type    : 'instance',
      method  : 'console_log',
    },
    test_messages : {
      label   : 'Test All Messages',
      icon    : 'fad:comments-alt',
      access  : 'admin',
      danger  : 'This is a danger message!',
      warning : 'This is a warning message!',
      message : 'This is a message message!',
      route   : '/',
    },
  },
  methods : class {
    console_log( data ) {
      // eslint-disable-next-line no-console
      console.log( 'TEST ACTION DATA', data );
    }
  },
  behaviors : {
    hasMembers  : {
      withCreator : {
        default : true,
        config  : { role : 'King' },
      },
      userType            : 'Test.User',
      withRole            : {
        roles         : [ 'King', 'Queen', 'Prince', 'Princess', 'Knave' ],
        defaultRole   : 'Knave',
      },
      withService         : true,
    },
    hasArchived : {},
  },
  queries   : {
    testOne( a, b, c ) { return { a, b, c }; },
    testTwo( d, e ) { return [ { d }, { hint : e } ]; },
    testThree() { return []; },
    testFour( f ) { throw new Error( f ); },
    testFive() { return undefined; },
    testSix() { return 42; },
  },
  faces         : {
    'list.summary' : [
      {
        layout : {
          type       : 'ItemsBetweenTitleAndIcons',
        },
        fields  : [ '-@all' ],
      },
      {
        layout : 'ItemsAndBadges',
        fields  : [ '@badge' ],
      },
    ],
  },
} );
