// Automatically generated, DO NOT EDIT!
// To update run "yarn do-updates"
/* eslint-disable */
import { Model } from '~/core/lib/Model';
Model.demand( 'SYSTEM' ).extend( {
  "actions": {
    "process-periodic-class-jobs": {
      "access": "support",
      "help": [
        "Processes a batch of periodic jobs",
        "(those having a defined interval)"
      ],
      "icon": "fas:sync",
      "id": "process-periodic-class-jobs",
      "job_name": "process-periodic-class-jobs",
      "label": "Process Periodic Class Jobs",
      "name": "process-periodic-class-jobs",
      "params": {
        "interval": {
          "default": "2 days",
          "label": "Minimum run interval",
          "type": "string"
        },
        "job_data": {
          "label": "Job Data",
          "required": false,
          "type": "object"
        },
        "job_name": {
          "label": "Job Name",
          "required": true,
          "type": "string"
        },
        "job_options": {
          "label": "Job Options",
          "required": false,
          "type": "object"
        },
        "resource_type": {
          "label": "Resource Type",
          "required": true,
          "type": "string"
        }
      },
      "type": "class"
    },
    "process-periodic-instance-jobs": {
      "access": "support",
      "help": [
        "Processes a batch of periodic jobs",
        "(those having a defined interval)"
      ],
      "icon": "fas:sync",
      "id": "process-periodic-instance-jobs",
      "job_name": "process-periodic-instance-jobs",
      "label": "Process Periodic Instance Jobs",
      "name": "process-periodic-instance-jobs",
      "params": {
        "interval": {
          "default": "2 days",
          "label": "Minimum run interval",
          "type": "string"
        },
        "job_data": {
          "label": "Job Data",
          "required": false,
          "type": "object"
        },
        "job_name": {
          "label": "Job Name",
          "required": true,
          "type": "string"
        },
        "job_options": {
          "label": "Job Options",
          "required": false,
          "type": "object"
        },
        "limit": {
          "default": 500,
          "label": "Max jobs enqueued by this run",
          "type": "number"
        },
        "query": {
          "label": "Query to limit instances considered",
          "required": false,
          "type": "object"
        },
        "resource_type": {
          "label": "Resource Type",
          "required": true,
          "type": "string"
        }
      },
      "type": "class"
    }
  }
} );
