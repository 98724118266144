import { JobStep } from './Step';

import type { TResource } from '~/types';
import type { JobStepOptions } from './Step';

export type JobStepInteractiveFulfillmentInterface = {
  /** The React component to render. */
  component: string;
  /** Whatever information that component requires. */
  [key: string]: any;
};

declare module '~/modules/jobs/types' {
  export interface JobStepTypes {
    Interactive: JobStepInteractiveOptions;
  }
}

export type JobStepInteractiveOptions = {
  type: 'Interactive';
  getFulfillmentInterface: (
    subject: TResource<'SSP.User'>,
  ) => Promise<JobStepInteractiveFulfillmentInterface>;
  processFulfillment: (
    data: Record<string, unknown>,
  ) => Promise<$TSFixMe>;
} & JobStepOptions;

export class JobStepInteractive extends JobStep {

  /**
   * @returns information indicating what to render in the UI for this
   * step
   */
  async getFulfillmentInterface(
    _subject: TResource<'SSP.User'>,
  ): Promise<JobStepInteractiveFulfillmentInterface> {
    throw new Error( [
      'getFulfillmentInterface is an abstract method',
      'that hasn\'t been implemented',
    ].join( ' ' ) );
  }

  /**
   * @param data - The data
   */
  async processFulfillment(
    _data: Record<string, $TSFixMe>,
  ): Promise<$TSFixMe> {
    throw new Error( [
      'processFulfillment is an abstract method',
      'that hasn\'t been implemented',
    ].join( ' ' ) );
  }

}

JobStepInteractive.initialize( {
  id        : 'JobStep.Interactive',
  inherit   : 'JobStep',
  fields    : {
    completed_by  : {
      type          : 'id',
      label         : 'Completed By',
      index         : true,
      readonly      : true,
      // metadata      : true,
      // Optional because it will get filled in automatically when
      // inserted or updated.
      optional      : true,
      summary   : true,
    },
  },
} );
