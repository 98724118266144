import _ from 'lodash';
import { DB } from '~';
import { isProject } from '~/utils/types';
import { createResource } from '~/core/creators';
import { UserResource } from '~/core/resource/UserResource';
import { getModel } from '~/core/schemas';
import { Schema } from '~/core/lib/Schema';
import { getOrigins } from '~/modules/origins';
import { queries } from './queries';
import { CAPABILITIES_MAP, SYSTEM_TEAMS_MAP } from './data-maps';

createResource( {
  id   : 'SSP.User',
  name : 'SSP User',
  icon : 'far:user',

  fields      : {
    user_id       : {
      type          : 'id',
      label         : 'User ID',
      index         : true,
      unique        : true,
      readonly      : true,
      metadata      : true,
      // Optional because it will get filled in automatically when
      // inserted or updated.
      optional      : true,
    },
    name            : {
      type        : 'string',
      label       : 'Name',
      optional    : true,
      quicksearch : true,
      faker       : 'user.name',
      sortable    : true,
      index       : true,
    },
    avatar          : {
      type        : 'string',
      optional    : true,
      faker       : 'image.avatar',
    },
    employee_id     : {
      type        : 'employee-id',
      label       : 'Employee ID',
      index       : true,
      unique      : true,
      optional    : true,
      summary     : true,
      quicksearch : true,
      faker       : 'none',
    },
    title    : {
      type        : 'string',
      label       : 'Title',
      optional    : true,
      summary     : true,
      faker       : 'name.jobTitle',
    },
    level    : {
      type        : 'string',
      label       : 'Level',
      optional    : true,
      summary     : true,
    },
    department    : {
      type        : 'string',
      label       : 'Department',
      optional    : true,
      summary     : true,
      faker       : 'name.jobDescriptor',
      // jobDescriptor jobArea jobType
    },
    division    : {
      type        : 'string',
      label       : 'Division',
      optional    : true,
      summary     : true,
    },
    organization_id : {
      type        : 'string',
      label       : 'Responsibility Center',
      optional    : true,
      summary     : true,
    },
    country    : {
      type        : 'string',
      label       : 'Country',
      optional    : true,
      summary     : true,
      faker       : 'address.country',
    },
    profile         : {
      type        : 'markdown',
      label       : 'Profile',
      optional    : true,
    },
    team_ids        : {
      type        : 'id',
      array       : true,
      index       : true,
      readonly    : true,
      metadata    : true,
      optional    : true,
    },
    system_teams    : {
      type        : 'string',
      array       : true,
      computed  : {
        cached  : true,
        compute() { return this.computeSystemTeams(); },
      },
    },
    acl_teams       : {
      type        : 'string',
      array       : true,
      index       : true,
      readonly    : true,
      metadata    : true,
      optional    : true,
    },
    capabilities    : {
      type        : 'string',
      description : 'System-wide capabilities',
      array       : true,
      computed  : {
        cached  : true,
        compute() {
          if ( ! this.system_teams ) return null;
          return _.uniq( _.compact( _.flatMap(
            this.system_teams, team => CAPABILITIES_MAP[ team ] || [],
          ) ) );
        },
      },
    },
    project_ids     : {
      type        : 'id',
      array       : true,
      index       : true,
      readonly    : true,
      metadata    : true,
      optional    : true,
    },
    admin_project_ids : {
      type        : 'id',
      array       : true,
      index       : true,
      readonly    : true,
      metadata    : true,
      optional    : true,
    },
    account_types     : {
      type      : 'string',
      array     : true,
      faker     : 'none',
      optional  : true,
      default() { return []; },
    },
    channels          : {
      type        : 'string',
      description : 'Notification channels the user belongs to.',
      array       : true,
      computed    : {
        cached  : true,
        compute() {
          if ( ! this._id ) return null;
          return _.uniq( _.compact( _.flattenDeep( [
            `users/${this._id}`,
            _.map( this.project_ids, id => `projects/${id}` ),
            _.map( this.admin_project_ids, id => `admins/${id}` ),
            _.map( this.acl_teams, id => `teams/${id}` ),
            _.invokeMap( this.system_teams, 'toLowerCase' ),
          ] ) ) );
        },
      },
    },
    settings          : {
      type        : 'SSP.User.Settings',
      enumerable  : false,
      computed    : {
        cached    : true,
        compute() {
          if ( ! this._id ) return null;
          return DB.SSP.User.Settings.fromId( this._id );
        },
      },
    },
  },

  actions   : {
    changeOwner : false,
    edit        : {
      label       : 'Edit',
      icon        : 'fad:pencil-alt',
      route       : 'edit',
      access      : 'admin',
      development : true,
    },
    impersonate : {
      label       : 'Impersonate',
      help        : [
        'Logs you into SSP with this user\'s account, enabling a view of',
        'SSP from their perspective, with their access control',
        'rules and associated resources in place of your own',
      ],
      icon        : 'far:hockey-mask',
      capability  : 'impersonate-users',
      method      : 'impersonate',
    },
  },

  behaviors : {
    hasEmail    : {
      uniqueEmails        : true,
      useMultipleEmails   : true,
      lowerEmailFieldName : 'username',
      lowerEmailOptions   : {
        label       : 'Username',
        index       : true,
        unique      : true,
        summary     : true,
        quicksearch : true,
        identifier  : true,
        readonly    : true,
      },
    },
    hasDisabled       : { canAutoDisable : true },
    hasResourceCounts : {},
    hasServiceAccounts : {},
  },

  events    : {
    async beforeGenerate( ev ) {
      const { fake } = await import( '~/modules/faker/faker' );
      const name = await fake(
        '{{name.lastName}}, {{name.firstName}}',
      );
      _.assign( ev.data, {
        name          : `${name} [USA]`,
        email         : `${name.replace( /\W+/gu, '_' )}@fake.bah.com`,
        country       : 'USA',
      } );
    },
  },

  methods   : class extends UserResource {

    toString( include_id=false ) {
      const name = this.display_name || this.email || this._id;
      if ( ! include_id ) return name;
      if ( name === this._id ) return name;
      return `${name} (${this._id})`;
    }

    impersonate() { return SSP.auth.impersonate( this._id ); }

    isAdmin() {
      return _.includes( this.system_teams, 'Administrators' );
    }

    isDeveloper() {
      return _.includes( this.system_teams, 'Developers' );
    }

    isDisabled() {
      if ( this.is_disabled ) {
        return 'Cannot Enable Accounts of a Disabled SSP User';
      }
    }

    isEmployee() {
      return _.includes( this.system_teams, 'Employees' );
    }

    isSupport() {
      return _.includes( this.system_teams, 'Support' );
    }

    hasCapability( cap ) {
      return _.includes( this.capabilities, cap );
    }

    findRelatedResources( resource_type, query={}, opts={} ) {
      return getModel( resource_type ).find( query, opts ).user( this._id );
    }

    findTeams( query, opts ) {
      return DB.SSP.Team.find( query, opts ).user( this._id );
    }

    findProjects( query={}, opts={} ) {
      return DB.SSP.Project.find( query, opts ).user( this._id );
    }

    isAdminOfProject( project_id ) {
      if ( isProject( project_id ) ) project_id = project_id._id;
      return _.includes( this.admin_project_ids, project_id );
    }

    isMemberOfProject( project_id ) {
      if ( isProject( project_id ) ) project_id = project_id._id;
      return _.includes( this.project_ids, project_id );
    }

    baseurl() {
      if ( ! this._id ) return;
      return `/user/${this._id}`;
    }

    async getRelated( component, opts={} ) {
      const schema = Schema.demand( component );
      const query = { user_id : this._id };
      if ( schema.hasBehavior( 'hasPrimaryAccount' ) ) {
        query.is_primary_account = true;
      }
      return schema.model.fetch( query, opts );
    }

    async findOrCreateRelated( component, save=true ) {
      const Comp = getModel( component );
      if ( ! Comp ) {
        throw new Error(
          `Invalid component "${component}" to findOrCreateRelated`,
        );
      }

      const rsrc = await this.getRelated( component, { safe : true } );
      if ( rsrc ) return rsrc;
      if ( _.isFunction( Comp.createFrom ) ) {
        await this.load();
        return Comp.createFrom( this, save );
      } else if ( save ) {
        return Comp.insert( { user_id : this._id } );
      } else {
        return Comp.create( { user_id : this._id } );
      }
    }

    _enable_user_accounts_options() {
      return Schema.filter(
        'is_user_account', 'service_id_field',
      ).map( schema => {
        if ( this.account_types.includes( schema.id ) ) {
          if ( ! schema.hasAction( 'enable' ) ) return undefined;
          return {
            value : schema.id,
            label : `${schema.name} (Enable)`,
          };
        } else {
          return {
            value : schema.id,
            label : `${schema.name} (Provision)`,
          };
        }
      } );
    }

    reload_on_related_changes() {
      const reloader = _.debounce( () => {
        log.debug( `${this} changed, reloading` );
        return this.reload();
      }, 5000, {
        maxWait   : 10000,
        leading   : false,
        trailing  : true,
      } );
      return getOrigins().watchRelatedUpdates( 'SSP.User', this._id, msg => {
        // If we see an update for an SSP.Team that was somehow
        // related to this user, then we reload the user (we don't
        // bother to check and see if the change to the team was
        // *actually* about this user, because that would require that
        // we retrieve the team from the server, and since we're
        // making a roundtrip to the server, we might as well just
        // update our record anyway.
        if ( msg.type === 'SSP.Team' ) reloader();
      } );
    }

    findJobs( query, opts ) {
      return DB.SYSTEM.Job
        .find( { 'user.id' : this._id } )
        .find( query, opts );
    }

    async computeResourceIds( ids = {} ) {
      const teams = await this.findTeams( {}, { filters : '*' } ).all( {
        load : true,
      } );
      _.assign( ids, {
        'SSP.Team'    : _.uniq( _.map( teams, '_id' ) ),
        'SSP.Project' : _.uniq( _.map( teams, 'project_id' ) ),
      } );
      return await super.computeResourceIds( ids );
    }

    computeSystemTeams() {
      const sys_teams = new Set();
      sys_teams.add( 'Everyone' );
      for ( const acl_team of this.acl_teams || [] ) {
        for ( const team of SYSTEM_TEAMS_MAP[ acl_team ] || [] ) {
          sys_teams.add( team );
        }
      }
      if ( this.service_account_type ) {
        sys_teams.add( 'ServiceAccount' );
      } else if ( this.employee_id && this.username.endsWith( '@bah.com' ) ) {
        sys_teams.add( 'Employees' );
      } else if ( this.employee_id ) {
        sys_teams.add( 'NonEmployees' );
      } else {
        sys_teams.add( 'Guests' );
      }
      return Array.from( sys_teams );
    }

  },

  queries       : { ...queries },

  faces         : {
    'card.summary' : [
      {
        layout : 'Badges',
        fields : [ '@badge' ],
      },
      {
        layout : 'LabelsAndValues',
        fields : [
          '@summary', '-@badge', '-username', '-organization_id',
        ],
      },
    ],
    'list.summary' : [
      {
        layout : {
          type       : 'ItemsBetweenTitleAndIcons',
          iconize    : [ {
            fieldName : 'employee_id',
            icon      : 'fad:id-badge',
          } ],
        },
        fields  : [ 'title' ],
      },
      {
        layout : 'ItemsAndBadges',
        fields  : [ 'email', '@badge' ],
      },
    ],
  },
} );

export * from './data-maps';
