import { createToggleAction } from '~/modules/actions/utils';
import { createBehavior } from '~/core/creators';

export type HasDisabledOptions = {
  canAutoDisable?: boolean;
};
declare module './declarations' {
  export interface BehaviorConfigs {
    hasDisabled?: HasDisabledOptions | boolean;
  }
  export interface BehaviorOptions {
    hasDisabled?: Required<HasDisabledOptions>;
  }
}

createBehavior<HasDisabledOptions>( {

  name      : 'hasDisabled',
  options   : {
    canAutoDisable  : false,
  },

  build() {
    return [
      {
        fields    : {
          is_disabled     : {
            type        : 'boolean',
            label       : 'Account Disabled',
            default     : false,
            summary     : true,
            readonly    : true,
            sortable    : true,
            optional    : true,
            badge       : { label : 'Account Disabled' },
          },
          last_enabled_at : {
            type        : 'date',
            label       : 'Last Enabled',
            optional    : true,
            metadata    : true,
          },
          last_disabled_at  : {
            type        : 'date',
            label       : 'Last Disabled',
            optional    : true,
            metadata    : true,
          },
        },
        filters : {
          hide_disabled : {
            label     : 'Hide Disabled Resources',
            included  : { is_disabled : { $ne : true } },
          },
        },
      },

      this.options.canAutoDisable && [
        {
          fields  : {
            do_not_auto_disable : {
              type        : 'boolean',
              label       : 'Do Not Auto Disable',
              default     : false,
              optional    : true,
              summary     : true,
              index       : true,
              readonly    : true,
              badge       : { label : 'Do not auto disable' },
            },
          },
          actions : createToggleAction( {
            field   : 'do_not_auto_disable',
            access  : 'support',
            enable  : {
              label : 'Allow Auto-Disable',
              help  : 'Allows auto-disable to act on this resource',
            },
            disable : {
              label : 'Prevent Auto-Disable',
              help  : 'Prevents auto-disable from acting on this resource',
            },
          } ),
        }, BUILD.isServer && {
          events  : {
            beforeUpdate( ev ) {
              if ( this.is_disabled && ! ev.origin.is_disabled ) {
                this.last_disabled_at = new Date();
              }
              if ( ev.origin.is_disabled && ! this.is_disabled ) {
                this.last_enabled_at = new Date();
              }
            },
          },
        },
      ],
    ];
  },

} );
