import _ from 'lodash';
import {
  invariant, hideProps, isConstructor, createNamedClass, pluralize,
} from '@ssp/utils';
import { Type } from '@ssp/types';

import type { LodashIteratee } from '@ssp/ts';

import { DB } from '../registry';
import { Behavior } from './Behavior';
import { SchemaConfig } from './SchemaConfig';
import { Face } from '~/modules/faces/Face';
import { Model } from './Model';
import { Field } from '~/modules/fields/Field';
import { buildIdentQuery, buildUniqueQuery } from '~/modules/indexes/utils';
import { ResultSet } from '~/modules/resultset/ResultSet';

import type { SchemaId } from '~/types/schema';
import type { FieldSelector } from '~/modules/fields/FieldSet';
import type { SchemaIdSource, Definitions } from './types';
import type { Resource } from '~/core/resource/Resource';
import type {
  BehaviorConfigs, BehaviorOptions,
} from '~/behaviors/declarations';

export interface Schema {}

export const SCHEMAS: Record<SchemaId, Schema> = {};

export interface SchemaOptions {
  id?: string;
  inherit?: string;
  type_field?: string;
  is_abstract?: boolean;
  is_subdocument?: boolean;
  is_resource?: boolean;
  is_service?: boolean;
  is_user_resource?: boolean;
  is_project_resource?: boolean;
  is_external_service?: boolean;

  name?: string;
  plural_name?: string;
  icon?: string;
  featured?: boolean;
  hideZero?: boolean;
  hideOnAbout?: boolean;
  doNotAssimilate?: boolean;
  service_id_field?: string;

  /**
   * This provides an array of objects that indicate the types of
   * resources that a resource of this kind can be attached to.
   * For the common case where the restriction is only by resource
   * type you can include plain strings in the array.  For example,
   * `[ 'SSP.Project' ]` means exactly the same as `[ { type
   * : 'SSP.Project' } ]`,  For more advanced requirements you can
   * match against properties of the resource you are attempting to
   * attach to.  If the array contains multiple matchers, only one of
   * them has to match in order for the attachment to be possible.
   *
   * @example
   *
   * // can only attach to regular projects
   * attachable : [ 'SSP.Project' ],
   *
   * // This resource can only attach to regular projects or user
   * projects.
   * attachable : [ 'SSP.Project', 'SSP.Project.UserProject' ],
   *
   * // This resource can attach to regular projects or user projects
   * where the `is_beta_project` flag is `true`
   * attachable : [
   *   { type : 'SSP.Project', is_beta_project : true },
   *   { type : 'SSP.Project.UserProject', is_beta_project : true },
   * ],
   *
   * // This resource can attach to beta or test projects:
   * attachable : [
   *   { type : 'SSP.Project', is_beta_project : true },
   *   { type : 'SSP.Project', is_test_project : true },
   * ],
   */
  attachable?: ( string | Record<string, any> )[];
  /**
   * A flag you can set when creating resources for testing. This
   * keeps them from getting attached to their parents (so that
   * extending the parent doesn't affect the temporary child, unless
   * the parent is also temporary), and also provides them with
   * a `nuke` method that destroys them to clean up.
   */
  is_temporary?: boolean;

  origin?: string;
}

const schema_options_keys = [
  'id',
  'inherit',
  'type_field',
  'is_abstract',
  'is_subdocument',
  'is_resource',
  'is_service',
  'is_user_resource',
  'is_project_resource',
  'is_external_service',
  'origin',
  'name',
  'plural_name',
  'icon',
  'featured',
  'hideZero',
  'hideOnAbout',
  'doNotAssimilate',
  'service_id_field',
  'attachable',
  'is_temporary',
] as const;

export type SchemaOptionsKey = typeof schema_options_keys[number];
export function isSchemaOptionsKey( value: any ): value is SchemaOptionsKey {
  return schema_options_keys.includes( value );
}

export type SchemaFilterOptions = {
  /** Whether to include child schemas or not. */
  children?: boolean;
  /** Filter schemas by ID. */
  schema?: string | string[];
  schemas?: string | string[];
  id?: string | string[];
  ids?: string | string[];

  is_abstract?: boolean;
  is_resource?: boolean;
  is_subdocument?: boolean;
  is_service?: boolean;
  is_top_schema?: boolean;
  is_project_resource?: boolean;
  is_user_resource?: boolean;
  is_child?: boolean;

  is_user_schema?: boolean;
  is_project_schema?: boolean;

  service?: string;
  service_id?: string;
} & Omit<SchemaOptions, 'id'>;

export type SchemaFilterable =
  | SchemaFilterOptions
  | ( ( schema: Schema ) => any )
  | ( keyof Schema & string )
  | ( keyof BehaviorConfigs & string )
  | `!${keyof Schema & string}`
  | `model.${( keyof Model & string )}`
  | `!model.${( keyof Model & string )}`
  | 'is_any'
  | string;

const type_bases = {
  is_subdocument      : 'SubDocument',
  is_service          : 'ServiceResource',
  is_resource         : 'Resource',
  is_user_resource    : 'UserResource',
  is_project_resource : 'ProjectResource',
} as const;

/**
 * This Schema class contains all the configuration information for
 * a given database model.  This is the fully normalized, fully
 * computed version so that we don't need to recompute any of this
 * information on the fly later on.
 */
export class Schema {

  static options_keys = schema_options_keys;

  static get( id: SchemaIdSource ) {
    if ( _.isNil( id ) ) return;
    if ( id instanceof Schema ) return id;
    if ( typeof id === 'function' && id.schema instanceof Schema ) {
      return id.schema;
    }
    if ( typeof id === 'object' ) {
      const obj = id as any;
      if ( obj.schema instanceof Schema ) return obj.schema;
      for ( const x of [ 'schema', 'type', 'resource_type' ] ) {
        if ( _.isString( obj[ x ] ) && SCHEMAS[ obj[ x ] ] ) {
          return SCHEMAS[ obj[ x ] ];
        }
      }
    }
    if ( _.isString( id ) ) {
      if ( SCHEMAS[ id ] ) return SCHEMAS[ id ];
      for ( const schema of Object.values( SCHEMAS ) ) {
        if ( ! schema.isTopSchema() ) continue;
        if ( schema.collection === id ) return schema;
      }
      return;
    }
  }

  static demand( id: SchemaIdSource ) {
    const schema = Schema.get( id );
    if ( schema ) return schema;
    throw new Error( `Unknown schema '${String( id )}'` );
  }

  /** Retrieve multiple schemas. */
  static filter( ...filters: SchemaFilterable[] ): Schema[] {
    filters = _.compact( _.flattenDeep( filters ) );
    if ( filters.includes( 'is_any' ) ) {
      _.pull( filters, 'is_any' );
    } else {
      filters.push( ...[
        'is_abstract', 'is_child', 'is_subdocument', 'is_service',
      ].filter( x => ! filters.includes( x ) ).map( x => '!' + x ) );
    }

    const results = filters.reduce( ( matches, opt ) => {
      if ( _.isNil( opt ) ) return matches;

      if ( typeof opt === 'string' ) {
        opt = opt.trim();
        let filter = _.filter;
        let discriminant: any = opt;
        if ( opt.startsWith( '!' ) ) {
          discriminant = opt = opt.slice( 1 ).trim();
          filter = _.reject;
        }
        if ( Behavior.has( opt ) ) discriminant = s => s.hasBehavior( opt );
        if ( SCHEMAS[ opt ] ) discriminant = { id : opt };
        return filter( matches, discriminant );
      }

      if ( typeof opt === 'function' ) return _.filter( matches, opt );

      if ( ! _.isPlainObject( opt ) ) {
        log.debug( 'INVALID FILTER:', opt );
        throw new Error( `Invalid argument to filter` );
      }
      const {
        children,
        id : id1, ids : id2, schema : id3, schemas : id4,
        service : sv1, service_id : sv2,
        ...opts
      } = opt;

      if ( ! children ) _.remove( matches, { is_top_schema : false } );
      const ids = _.compact( _.uniq( _.flattenDeep( [
        id1, id2, id3, id4,
      ] ) ) );
      if ( ids.length ) {
        _.remove( matches, s => ( ! ids.includes( s.id ) ) );
      }
      const svs = _.compact( _.uniq( _.flattenDeep( [ sv1, sv2 ] ) ) );
      if ( svs.length ) {
        _.remove( matches, s => ( ! svs.includes( s.service_id ) ) );
      }
      return _.isEmpty( opts ) ? matches : _.filter( matches, opts );
    }, _.values( SCHEMAS ) );
    return _.sortBy( results, 'id' ) as Schema[];
  }

  /** Get the IDs of matching schemas. */
  static ids( ...filters: SchemaFilterable[] ): SchemaId[] {
    return this.filter( ...filters ).map( s => s.id );
  }
  static models( ...filters: SchemaFilterable[] ): ( typeof Model )[] {
    return this.filter( ...filters ).map( s => s.model );
  }

  /** Get the schema ID of a SchemaIdSource. */
  static id( item: SchemaIdSource ): SchemaId | undefined {
    return this.get( item )?.id;
  }
  /** Get the model of a SchemaIdSource. */
  static model( item: SchemaIdSource ): typeof Model | undefined {
    return this.get( item )?.model;
  }

  /**
   * The id of this Schema.  This is the dotted-name (like
   * `GitHub.Team`).
   */
  readonly id: string;

  /**
   * The name of this Schema.  This is the human-readable name (like
   * `GitHub Team`).
   */
  readonly name: string;
  readonly plural_name: string;

  /**
   * The model that the model created by this Schema will inherit
   * from.
   */
  readonly inherit?: string;

  /** The children of this Schema. */
  readonly children: Schema[] = [];

  get is_child() {
    return Boolean( this.type_field && ! this.is_top_schema );
  }
  get has_children() {
    return Boolean( this.type_field && this.is_top_schema );
  }
  readonly type_field?: string;

  /**
   * Set to true for definitions created with `abstract: true`.
   * Abstract types must be subclassed, they cannot be instantiated
   * directly.
   */
  readonly is_abstract?: boolean;

  /** Returns true if this definition was created with `createSubDocument`. */
  readonly is_subdocument?: boolean;
  get is_subdoc() { return this.is_subdocument; }

  /** Returns true if this definition was created with `createService`. */
  readonly is_service?: boolean;

  /** Returns true if this definition was created with `createResource`. */
  readonly is_resource?: boolean;

  readonly is_user_resource?: boolean;
  readonly is_project_resource?: boolean;

  get is_user_schema() { return this.getTopSchema().id === 'SSP.User'; }
  get is_project_schema() { return this.getTopSchema().id === 'SSP.Project'; }

  /** Returns true if this is a schema for a user account type. */
  readonly is_user_account?: boolean;

  readonly service_id_field?: string;

  /**
   * Set to true if this schema is a SSP representation of an
   * artifact from a service that integrates with SSP through an
   * API. This comes handy in scenarios such as deleting projects,
   * where it's important to identify external service resources
   * and re-home them as to not leave them orphaned without a project
   */
  readonly is_external_service: boolean;

  readonly service_id: SchemaId;
  readonly is_top_schema: boolean = true;
  get is_top() { return this.is_top_schema; }

  readonly origin: string;

  readonly is_temporary = false;

  declare config: SchemaConfig;
  declare ResultSet: typeof ResultSet;

  constructor( options: SchemaOptions, model?: typeof Model ) {
    if ( ! _.isString( options?.id ) ) {
      throw new TypeError( `Schema requires an id` );
    }
    hideProps( this, { config : new SchemaConfig( this ) } );

    const { attachable, ...opts } = options;
    this.attachable = _.compact( _.map( _.castArray( attachable ), can => {
      if ( _.isNil( can ) ) return;
      if ( _.isString( can ) ) can = { type : can };
      if ( ! _.isPlainObject( can ) ) {
        throw new TypeError( `Invalid value for attachable: "${can}"` );
      }
      return can;
    } ) );

    _.assign( this, opts );

    const { id, origin } = this;
    if ( origin ) this.origin = origin;

    invariant( ! SCHEMAS[ id ], `Duplicate schema '${id}'` );
    const defs: Definitions[] = [];

    if ( [ 'Resource',  'SubDocument', 'ServiceResource' ].includes( id ) ) {
      Object.defineProperty( model, 'toString', {
        value : () => `class<${id}> extends Model {}`,
      } );
    } else {
      if ( ! this.inherit ) {
        for ( const [ key, base ] of Object.entries( type_bases ) ) {
          if ( this[ key ] ) {
            this.inherit = base;
            break;
          }
        }
      }
      if ( this.inherit ) {
        const parent = Schema.demand( this.inherit );
        if ( this.is_temporary && ! parent.is_temporary ) {
          // no-op
        } else {
          parent.children.push( this );
        }
        _.defaults( this, _.pick( parent, [
          'type_field', 'is_temporary',
          'is_subdocument', 'is_service', 'is_resource',
          'is_user_resource', 'is_project_resource',
          'icon',
        ] ), {
          is_external_service : parent?.is_external_service
            || this.service?.is_external_service,
          service_id_field : parent?.service_id_field
            || this.service?.service_id_field,
        } );
        defs.push( parent.config.definition );
        if ( ! model ) {
          model = createNamedClass(
            id, parent.model,
            `class<${id}> extends Parent<${parent.id}> {}`,
          );
        }
      } else {
        log.debug( 'NO INHERIT:', this );
        log.warn( `SCHEMA NOT INHERITING ANYTHING: ${id}` );
      }
    }
    SCHEMAS[ id ] = this;

    setDefaults( this );

    Object.defineProperty( model, 'name', { value : this.id } );
    hideProps( this, { model } );
    hideProps( model, { schema : this } );
    createModelType( this );
    verifyInheritance( this );

    defs.push( this.config.attachTypeField() );
    this.config.extend( defs, origin );

    if ( ( this.is_resource || this.is_subdocument ) && ! this.is_abstract ) {
      _.set( DB, this.id, model );
    }
  }

  get flags() {
    return _.filter(
      _.keys( this ),
      key => ( this[ key ] && key.startsWith( 'is_' ) ),
    ).sort();
  }

  get parent() { if ( this.inherit ) return Schema.demand( this.inherit ); }
  get service() { return Schema.get( this.service_id ); }

  /**
   * Get all the schemas that are children of the model represented by
   * this Schema.
   *
   * @param depth - The depth to which to look for children, or "true"
   * to get all children.
   * @returns an array of schemas
   */
  getChildren( depth: number | boolean = 1 ): Schema[] {
    if ( _.isNumber( depth ) ) depth--;
    if ( ! depth ) return this.children;
    return _.compact( this.children.concat(
      ..._.invokeMap( this.children, 'getChildren', depth ),
    ) );
  }

  get [ Symbol.toStringTag ]() { return this.id; }

  get collection() {
    const id = this.getTopSchema().id;
    return id.split( '.' ).map( x => x.toLowerCase() ).join( '_' );
  }

  /**
   * This returns the schema for the resource at the "top" of an
   * inheritance tree.  When you define a type that includes
   * a `type_field` the resources of that type all live in one
   * collection.  This is the Schema for that "top" collection.
   */
  getTopSchema() {
    if ( ! this.type_field ) return this;
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let iter: any = this;
    while ( iter ) {
      const parent = iter.parent;
      if ( parent && parent.type_field === this.type_field ) {
        iter = parent;
      } else {
        return iter;
      }
    }
    return;
  }

  isTopSchema() {
    return this === this.getTopSchema();
  }

  getModelForOptions( options, first=true ) {
    // If the options specify a type, but it isn't our type then we
    // relay it to the appropriate specified type's schema, but only
    // if we weren't already called recursively this way
    if ( first && options.type && options.type !== this.id ) {
      return Schema.demand( options.type ).getModelForOptions( options, false );
    }
    const type_field = this.type_field;
    if ( ! type_field ) return this.model;

    const type = _.get( options.data, type_field )
      || _.get( options.defaults, type_field );
    if ( ! type ) return this.model;

    return Schema.get( type )?.model || this.model;
  }

  declare model: $TSFixMe;

  // ----- ACTIONS ----- //
  get actions() { return this.config.actions; }
  getAction( name, include_not_available = false ) {
    const action = this.config.actions[ name ];
    if ( ! action ) return;
    if ( ! ( action.is_available || include_not_available ) ) return;
    return action;
  }
  hasAction( name, include_not_available = false ) {
    return !! this.getAction( name, include_not_available );
  }
  getActions( filter, include_not_available = false ) {
    let actions = _.values( this.config.actions );
    if ( ! include_not_available ) {
      actions = _.filter( actions, 'is_available' );
    }
    if ( filter ) actions = _.filter( actions, filter );
    return actions;
  }

  // ----- BEHAVIORS ----- //
  get behaviors() { return this.config.behaviors; }
  getBehavior( name ) { return this.config.behaviors[ name ]; }
  getBehaviors() { return this.config.behaviors; }
  getBehaviorOptions<B extends keyof BehaviorOptions>(
    name: B,
  ): BehaviorOptions[B] {
    return this.getBehavior( name )?.options;
  }
  hasBehavior( name ) { return Boolean( this.config.behaviors[ name ] ); }

  // ----- EVENTS ----- //
  get events() { return this.config.events; }
  getEvents( type ) {
    if ( ! type ) return this.config.events;
    return _.filter( this.config.events, { type : type.toLowerCase() } );
  }
  hasEvent( type ) {
    type = type.toLowerCase();
    for ( const ev of this.config.events ) {
      if ( ev.type === type || ev.name === type ) return true;
    }
    return false;
  }

  // ----- FACES / VIEWS ----- //
  get faces() { return this.config.faces; }
  getViews() {
    return _.compact( _.uniq( _.map( this.getFaces(), 'type' ) ) );
  }
  getFaces( filter?: LodashIteratee ) {
    // If the filter is a string we assume you want all the faces for
    // that view, since that's the most common use case.
    if ( _.isString( filter ) ) filter = { type : filter };
    if ( filter ) return _.filter( this.config.faces, filter );
    return _.values( this.config.faces );
  }
  getFace( view, name ) {
    if ( view instanceof Face ) return view;
    if ( ! name ) [ view, name ] = view.split( /[.:]/u );
    if ( ! name ) {
      const faces = this.getFaces( { name : view } );
      if ( faces.length === 1 ) return faces[ 0 ];
    }
    return this.config.faces[ [ view, name ].join( '.' ) ];
  }
  hasFace( view, name ) {
    const face = this.getFace( view, name );
    if ( ! face ) return false;
    return face.fullname;
  }
  findFace( views, names, fallback=false ) {
    views = _.uniq( _.filter( _.castArray( views ), _.isString ) );
    names = _.uniq( _.filter( _.castArray( names ), _.isString ) );
    if ( fallback ) {
      views = _.uniq( [ ...views, ...this.getViews() ] );
      names = _.uniq( [ ...names, 'summary' ] );
    }

    for ( const view of views ) {
      for ( const name of names ) {
        const face = this.getFace( view, name );
        if ( face ) return face;
      }
    }
    throw new Error( `Unable to find face from ${views} / ${names}` );
  }

  // ----- FIELDS ----- //
  get fields() { return this.config.fields.fields; }
  getField( name: string ) { return this.config.fields.getField( name ); }
  hasField( name: string ) { return this.config.fields.hasField( name ); }
  hasActualField( name: string ) {
    return this.config.fields.hasActualField( name );
  }
  hasLinkField( name: string ) {
    return this.config.fields.hasLinkField( name );
  }
  getFieldSet( ...args: FieldSelector<this>[] ) {
    return this.config.fields.getFieldSet( ...args );
  }
  getAllFields( filter?: LodashIteratee ): Field[] {
    return this.config.fields.getAllFields( filter );
  }
  getFields( ...selectors: FieldSelector<this>[] ): Field[] {
    return this.config.fields.getFields( ...selectors );
  }
  getFieldNames( ...selectors: FieldSelector<this>[] ): string[] {
    return this.config.fields.getFieldNames( ...selectors );
  }

  // ----- INDEXES ----- //
  get indexes() { return this.config.indexes; }
  getIndex( indexName: string ) { return this.config.indexes[ indexName ]; }
  getIndexes() { return _.values( this.config.indexes ); }
  buildIdentQuery( ident ) { return buildIdentQuery( this, ident ); }
  buildUniqueQuery( query ) { return buildUniqueQuery( this, query ); }

  // ----- JOBS ----- //
  get jobs() { return this.config.jobs; }
  hasJob( name: string ) { return Boolean( this.config.jobs?.[ name ] ); }
  getJob( name: string ) {
    if ( this.config.jobs?.[ name ] ) return this.config.jobs[ name ];
    if ( this.parent ) return this.parent.getJob( name );
  }

  // ----- MARKDOWN ----- //
  markdown: Record<string, string>;
  hasMarkdown( name: string ) { return Boolean( this.markdown?.[ name ] ); }
  getMarkdown( name: string ): string | undefined {
    if ( this.markdown?.[ name ] ) return this.markdown[ name ];
    if ( this.parent ) return this.parent.getMarkdown( name );
  }
  setMarkdown( name: string, value: string ) {
    if ( ! this.markdown ) hideProps( this, { markdown : {} } );
    if ( this.markdown[ name ] ) {
      throw new Error(
        `Schema ${this.id} already has markdown named '${name}'`,
      );
    }
    this.markdown[ name ] = value;
    return value;
  }
  getMarkdownSummary( name: string ) {
    const md = this.getMarkdown( name );
    if ( ! md ) return;
    const chunks = md.split( '\n\n' )
      .filter( x => x.length > 0 && ! x.startsWith( '#' ) );
    return _.first( chunks );
  }

  // ----- TRIGGERS ---- //
  get triggers() { return this.config.triggers; }

  // ----- VALIDATORS ----- //
  get validators() { return this.config.validators; }

  // ----- ATTACHABILITY ----- //
  attachable?: Record<string, any>[];

  /**
   * Returns a boolean indicating whether resources of this type can
   * be attached to the resource provided.
   *
   * @param rsrc - The resource we want to possibly attach it to.
   */
  canAttachTo( rsrc: Resource ): boolean {
    if ( ! this.attachable ) return false;
    if ( ! rsrc ) return false;
    const type = _.get( rsrc, 'schema.id' ) || _.get( rsrc, 'type' );
    if ( ! type ) return false;
    return _.some( this.attachable, check => {
      return _.every( check, ( val, key ) => {
        if ( key === 'type' ) return type === val;
        return rsrc[ key ] === val;
      } );
    } );
  }

  // ----- OTHER ----- //

  simplify() { return _.pick( this, Schema.options_keys ); }
  getAllSchemas() { return [ this, ...this.getChildren() ]; }

}

function createModelType( schema: Schema ) {
  // Register a new class type
  const isRecordOf = ( arg: any ): arg is Model => _.isObject( arg )
    && ! isConstructor( arg )
    && isConstructor( arg.constructor )
    && Model.prototype.isPrototypeOf( ( arg as any ).prototype );
  Type.create( {
    name      : schema.id,
    validator : { type : 'dbmodel', config : schema.id },
    coerce( data ) { return schema.model.coerce( data ); },
    parse( data ) {
      return schema.model.construct( { method : 'Type parse', data } );
    },
    format( value ) { return value?.toJSON(); },
    equals( one, two ) {
      return isRecordOf( one ) && isRecordOf( two ) && one.equals( two );
    },
  } );
}

function verifyInheritance( schema ) {
  for ( const [ flag, base ] of Object.entries( type_bases ) ) {
    if ( ! schema[ flag ] ) continue;
    if ( base === schema.id ) return;
    const BaseModel = Schema.get( base )?.model;
    if ( ! BaseModel ) continue;
    if ( BaseModel.isPrototypeOf( schema.model ) ) continue;
    log.debug( 'SCHEMA:', schema );
    throw new Error(
      `Model ${schema.id} flagged with ${flag} must inherit from ${base}`,
    );
  }
}

function setDefaults( schema ) {
  if ( schema.is_abstract || schema.is_subdocument ) return;
  if ( schema.is_child ) return;
  _.defaults( schema, {
    service_id          : schema.id.split( '.' )[0],
    is_external_service : Boolean( schema.service_id_field ),
    is_user_account     : Boolean(
      schema.is_user_resource
      && schema.id.endsWith( '.User' )
      && schema.service_id_field,
    ),
  } );
  if ( schema.is_user_resource || schema.is_project_resource ) {
    _.defaults( schema, { is_resource : true } );
  }
  _.defaults( schema, { name : schema.id.split( '.' ).join( ' ' ) } );
  _.defaults( schema, { plural_name : pluralize( schema.name ) } );
}
