import _ from 'lodash';
import { invariant, isThenable, DatabaseFieldError } from '@ssp/utils';

import { Field } from './Field';
import { getDataMap } from '../broker/data-utils';

import type { FieldOptions } from './Field';

export type VirtualFieldOptions = FieldOptions & {
  virtual?: boolean;
  computed?: ( () => any ) | {
    compute?: ( () => any );
    get?: ( () => any );
    set?: ( ( val: any ) => void );
    clearable?: boolean;
    cached?: boolean;
  };
};

export interface VirtualField {
  constructor: typeof VirtualField;

  computed?: {
    get: ( () => any );
    set?: ( ( val: any ) => void );
    clearable?: boolean;
    cached?: boolean;
  };
}
export class VirtualField extends Field {

  get simple() { return false; }
  get virtual() { return true; }

  // @ts-ignore
  get sortable() { return false; }
  set sortable( _x ) { /* no-op */ }
  // @ts-ignore
  get quicksearch() { return false; }
  set quicksearch( _x ) { /* no-op */ }
  get minimal() { return false; }
  set minimal( _x ) { /* no-op */ }
  get createonly() { return false; }
  set createonly( _x ) { /* no-op */ }
  // @ts-ignore
  get transforms() { return []; }
  set transforms( _x ) { /* no-op */ }
  get optional() { return true; }
  set optional( _x ) { /* no-op */ }
  get required() { return false; }
  set required( _x ) { /* no-op */ }

  readonly = true;

  constructor( options: VirtualFieldOptions ) {
    if ( options.computed ) {
      if ( _.isFunction( options.computed ) ) {
        options.computed = { get : options.computed };
      }
      if ( _.isFunction( options.computed.compute ) ) {
        options.computed.get = options.computed.compute;
        delete options.computed.compute;
      }
    }
    const { computed, ...opts } = options;
    super( opts );
    if ( computed ) {
      if ( ! _.isPlainObject( computed ) ) {
        throw new DatabaseFieldError( {
          message : `Invalid configuration for computed field`,
          tags    : {
            schema : this.schema.id,
            method : 'VirtualField.constructor',
            field  : this.name,
          },
          data    : { config : computed },
        } );
      }
      invariant( typeof computed === 'object' );
      if ( ! _.isFunction( computed.get ) ) {
        throw new DatabaseFieldError( {
          message : `Missing getter for computed field`,
          tags    : {
            schema : this.schema.id,
            method : 'VirtualField.constructor',
            field  : this.name,
          },
          data    : { config : computed },
        } );
      }
      const { get } = computed;
      invariant( typeof get === 'function' );
      this.computed = {
        clearable : false,
        ...computed,
        get,
      };
      this.readonly = ! _.isFunction( this.computed.set );
    }
  }

  makeAccessors() {
    const { name, computed } = this;
    const accs = _.pick( this.computed, 'get', 'set' );
    if ( computed ) {
      if ( computed.cached ) _.assign( accs, this.makeCachedComputed() );
    } else {
      _.defaults( accs, {
        get() { return getDataMap( this ).get( name ); },
        set() {
          throw new DatabaseFieldError( {
            message : `Cannot set computed field ${name}`,
            tags    : {
              schema : this.schema.id,
              method : 'VirtualField.constructor',
              field  : this.name,
            },
            data    : { config : computed },
          } );
        },
      } );
    }
    const { get, set } = accs;
    invariant( typeof get === 'function' );
    return {
      get, set,
      configurable  : false,
      enumerable    : this.enumerable,
    };
  }

  makeCachedComputed() {
    const { name } = this;
    const { clearable, get : compute } = this.computed;
    if ( ! _.isFunction( compute ) ) {
      throw new DatabaseFieldError( {
        message : `Invalid cached computed "${name}" (no compute method)`,
        tags    : {
          schema : this.schema.id,
          method : 'VirtualField.constructor',
          field  : this.name,
        },
      } );
    }
    return {
      get() {
        const map = getDataMap( this );
        if ( map.has( name ) ) return map.get( name );
        const result = compute.call( this );
        if ( _.isNull( result ) ) return;
        if ( isThenable( result ) ) {
          throw new DatabaseFieldError( {
            message : `Cached computed field ${name} returned thenable`,
            tags    : {
              schema : this.schema.id,
              method : 'VirtualField.constructor',
              field  : this.name,
            },
          } );
        }
        map._set( name, result, true );
        return result;
      },
      set( value ) {
        if ( clearable && _.isNull( value ) ) {
          getDataMap( this ).delete( name );
        } else {
          throw new DatabaseFieldError( {
            message : `Cannot set computed field ${name}`,
            tags    : {
              schema : this.schema.id,
              method : 'VirtualField.constructor',
              field  : this.name,
            },
          } );
        }
      },
    };
  }

}
