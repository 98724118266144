import { createResource } from '~/core/creators';
import { getModel } from '~/core/schemas';

createResource( {
  id                : 'Test.User',
  name              : 'Test User',
  attachable        : [ 'SSP.User' ],
  icon              : 'far:bug',
  service_id_field  : 'test_id',
  fields      : {
    username        : {
      type        : 'string',
      label       : 'Username',
      index       : true,
      unique      : true,
      summary     : true,
      quicksearch : true,
      identifier  : true,
    },
    name            : {
      type        : 'string',
      label       : 'Name',
      optional    : true,
      summary     : true,
      quicksearch : true,
    },
    test_id    : {
      type      : 'id',
      optional  : true,
    },
  },
  behaviors : {
    hasEmail          : {},
    hasDisabled       : {},
    hasPrimaryAccount : {},
  },
} );

if ( BUILD.isServer ) {
  getModel( 'Test.User' ).extend( {
    methods  : class {

      static createFrom( ssp_user ) {
        return this.insert( {
          user_id       : ssp_user._id,
          username      : ssp_user._id,
          name          : ssp_user.name,
          email         : ssp_user.email,
        } );
      }

    },
  } );
}
