import _ from 'lodash';

declare module './types' {
  export interface TraitConfigs {
    withRole: {
      roles: string[];
      defaultRole?: string;
      label?: string;
    };
  }
  export interface TraitOptions {
    roles: string[];
    defaultRole: string;
  }
}

export function buildOptions( { options } ) {
  const { roles } = options || {};
  if ( ! ( _.isArray( roles ) && _.every( roles, _.isString ) ) ) {
    throw new TypeError( `withRole requires "roles"` );
  }

  _.defaults( options, {
    label         : 'Role',
    defaultRole   : options.roles[0],
  } );

  return _.pick( options, 'roles', 'defaultRole' );
}
export function buildMemberInfoConfig( { options } ) {

  const { label, roles, defaultRole } = options;

  return {
    fields          : {
      role  : {
        type        : 'string',
        label,
        default     : defaultRole,
        sortable    : true,
        form        : {
          type          : 'Select',
          options       : roles,
          defaultValue  : defaultRole,
        },
      },
    },
    methods         : class {
      resolveRole( ...values ) {
        let resolved_role: string;
        let resolved_rank: number = Infinity;
        for ( const value of values ) {
          const rank = roles.indexOf( value );
          if ( rank === -1 ) continue;
          if ( rank < resolved_rank || ! resolved_role ) {
            resolved_role = value;
            resolved_rank = rank;
          }
        }
        log.trace( 'RESOLVED ROLE:', resolved_role, resolved_rank );
        return resolved_role;
      }
    },
  };
}

export const access_fields = [ 'role' ];
