import { z } from 'zod';

export const updatesConfigSchema = z.object( {
  /**
   * When mode is 'update' (the default) you get the normal process,
   * where changed fields in the document will be updated on the
   * server. When mode is 'replace' then if the subdoc has been
   * modified it will be replaced in it's entirety with the new
   * version.
   */
  mode    : z.enum( [ 'update', 'replace' ] ).optional(),
} );

export type UpdatesConfigSchema = z.infer<typeof updatesConfigSchema>;

declare module '../declarations' {
  export interface ModelDefinition {
    updates?: UpdatesConfigSchema;
  }
  export interface SchemaConfigOptions {
    updates: UpdatesConfigSchema;
  }
}
