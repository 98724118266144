import { createBehavior } from '~/core/creators';
import { Forbidden } from '@ssp/utils';

export type HasArchivedOptions = Record<string, never>;

declare module './declarations' {
  export interface BehaviorConfigs {
    hasArchived?: HasArchivedOptions | boolean;
  }
  export interface BehaviorOptions {
    hasArchived?: HasArchivedOptions;
  }
}
createBehavior<HasArchivedOptions>( {

  name      : 'hasArchived',
  build() {
    return {
      fields    : {
        is_archived     : {
          type        : 'boolean',
          label       : 'Archived',
          default     : false,
          summary     : true,
          readonly    : true,
          optional    : true,
          badge       : { label : 'Archived' },
        },
      },
      filters : {
        show_archived : {
          label : 'Include Archived Resources',
          // included provides a query that will be added to the
          // ResultSet query if this filter *was* selected.  excluded
          // provides a query that will be added if this filter is
          // *not* selected.
          // included  : {},
          excluded  : { is_archived : { $ne : true } },
        },
      },
      events    : {
        duringUpdate( ev ) {
          const { updates, schema } = ev;
          ctx.only( 'user', () => {
            if ( ev.origin.is_archived && ev.target.is_archived ) {
              const archived = schema.getFieldNames( [
                '@all', '-@readonly', '-@metadata',
              ] );
              const intersect = archived.filter( f => updates.hasField( f ) );
              if ( intersect.length > 0 ) {
                throw new Forbidden( 'Cannot update archived resources' );
              }
            }
          } );
        },
      },
    };
  },
} );
