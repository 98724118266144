
declare module './types' {
  export interface TraitConfigs {
    withUpdateJob: boolean;
  }
}

export function buildModelConfig() {

  if ( ! BUILD.isServer ) return {};

  return {
    events  : {
      async afterSave( ev ) {
         if ( ev.refreshing || ! ev.updates.hasField( 'members' ) ) return;
         if ( ev.method === 'insert' && this.members.isEmpty() ) return;
         await this.job( 'update-members' );
     },
    },
  };

}
