import { stringifyError, idgen } from '@ssp/utils';
import { SubDocument } from '~/core/resource';

import { isJobStepStatus } from '~/modules/jobs';
import type { JobStepStatus } from '~/modules/jobs';
import type { Job_Server } from '../SYSTEM.Job.server';

export type JobStepOptions = {
  id?: string;
  // type?: JobStepType;
  name?: string;
  label?: string;
  before?: string | string[];
  after?: string | string[];
  retry?: number;
  attempts?: number;
  ignore_errors?: boolean;
  ignore_status?: boolean;
  timeout?: string;
  def?: string;
};
export class JobStep extends SubDocument {

  declare id: string;
  declare status: JobStepStatus;
  declare name: string;
  declare job: Job_Server;

  static isValidStatus = isJobStepStatus;

  isValidStatus() { return isJobStepStatus( this.status ); }

  get is_runnable() { return this.isRunnable(); }
  isRunnable() {
    return [
      'pending', // A step waiting to be run
      'waiting', // waiting for an external event
      'deferred', // waiting for a retry
    ].includes( this.status );
  }

  get is_waiting() { return this.isWaiting(); }
  isWaiting() {
    return [
      'waiting', // waiting for an external event
      'interactive', // waiting for human input
    ].includes( this.status );
  }
  get is_interactive() { return this.isInteractive(); }
  isInteractive() {
    return [
      'interactive', // waiting for human input
    ].includes( this.status );
  }

  get is_success() { return this.isSuccess(); }
  isSuccess() {
    return [
      'complete', // finished successfully
    ].includes( this.status );
  }

  get is_failure() { return this.isFailure(); }
  isFailure() {
    return [
      'failed', // finished unsuccessfully
      'cancelled', // cancelled externally
    ].includes( this.status );
  }

  get is_pending() { return this.isPending(); }
  isPending() {
    return [
      'pending', // pending being run by worker
    ].includes( this.status );
  }

  get is_complete() { return this.isComplete(); }
  isComplete() { return this.isSuccess() || this.isFailure(); }

  toString() { return `${this.schema.id}<${this.name}>`; }

}

JobStep.initialize( {
  id             : 'JobStep',
  is_subdocument : true,
  fields         : {
    id      : {
      type        : 'string',
      label       : 'Step ID',
      optional    : false,
      identifier  : true,
      summary     : true,
      readonly    : true,
      default() { return idgen(); },
    },
    type  : {
      type      : 'string',
      index     : true,
      metadata  : true,
      summary   : true,
    },
    label             : {
      type     : 'string',
      label    : 'Label',
      readonly : true,
      summary  : true,
    },
    status            : {
      type        : 'string',
      label       : 'Status',
      default     : 'pending',
      readonly    : true,
      index       : true,
    },
    status_reason   : {
      type        : 'text',
      label       : 'Status Reason',
      optional    : true,
      readonly    : true,
      coerce      : stringifyError,
    },
    before  : {
      type        : 'string',
      description : 'Run before these steps',
      metadata    : true,
      array       : true,
      default     : [],
    },
    after   : {
      type        : 'string',
      description : 'Run after these steps',
      metadata    : true,
      array       : true,
      default     : [],
    },
    retry : {
      type     : 'number',
      label    : 'Max Retry Limit',
      default  : 0,
      readonly : true,
    },
    attempts : {
      type     : 'number',
      label    : '# of Attempts',
      default  : 0,
      readonly : true,
    },
    name              : {
      type        : 'string',
      label       : 'Name',
      readonly    : true,
      optional    : true,
    },
    started_at        : {
      type        : 'date',
      label       : 'Started At',
      readonly    : true,
      optional    : true,
      summary     : true,
    },
    ended_at          : {
      type        : 'date',
      label       : 'Ended At',
      readonly    : true,
      optional    : true,
      summary     : true,
    },
    ignore_errors     : {
      type        : 'boolean',
      label       : 'Ignore Errors',
      help        : 'The failure of this step will not cause the job to fail',
      default     : false,
      readonly    : true,
    },
    ignore_status     : {
      type        : 'boolean',
      label       : 'Ignore Status',
      help        : 'The status of this step does not affect the job status',
      default     : false,
      readonly    : true,
    },
    timeout     : {
      type        : 'string',
      label       : 'Timeout',
      optional    : true,
      summary     : true,
      readonly    : true,
    },
  },
  behaviors    : {
    hasErrorInfo    : {},
  },
  type_field   : 'type',
} );
