import type { Producable } from '@ssp/ts';

import type { Action } from './Action';
import type { Capability } from '~/components/SSP.User/data-maps';
import type { TResource, TModel } from '~/types';

export type ActionAccess =
  | 'admin' | 'support' | 'member' | 'none' | 'anonymous';
export type ActionContext = | 'self' | 'user' | 'project';

export type ActionOptions = {
  name: string;
  id?: string;
  type?: 'instance' | 'class';
  group?: string;
  context?: ActionContext;
  development?: boolean;
  label?: string;
  icon?: string;
  visibilityMethod?: string;
  disabledMethod?: string;
  access?: ActionAccess;
  capability?: Capability;
  params?: Producable<$TSFixMe>;
  help?: Producable<string | string[]>;
  data?: Producable<Record<string, any>>;
  message?: Producable<string | string[]>;
  warning?: Producable<string | string[]>;
  danger?: Producable<string | string[]>;
  disabled?: Producable<string>;
  not_available?: string;

  href?: Producable<string>;
  route?: Producable<string>;
  method?: Producable<string>;
  handler?: Producable<string>;
  modal?: Producable<string>;
  job_name?: Producable<string>;
};

export const ACTION_HANDLER_TYPES = [
  'href',
  'route',
  'job_name',
  'method',
  'handler',
  'modal',
] as const;
export type ActionHandlerType = typeof ACTION_HANDLER_TYPES[number];
export function isActionHandlerType( value: any ): value is ActionHandlerType {
  return ACTION_HANDLER_TYPES.includes( value );
}

export type GetRenderedContextOptions = {
  resource: TResource<any> | TModel<any>;
  context?: TResource<any>;
};
export type RenderedContext = {
  id: string;
  name: string;
  action: Action;
  description?: string;
  help?: string;
  group?: string;
  label?: string;
  icon?: string;
  hidden?: string;
  disabled?: string;

  params?: $TSFixMe;
  data?: Record<string, any>;
  message?: string | string[];
  warning?: string | string[];
  danger?: string | string[];
} & GetRenderedContextOptions;

export type ModelDefinitionAction =
  | false
  | Omit<ActionOptions, 'name'>;
export type ModelDefinitionActions = Record<string, ModelDefinitionAction>;
export type SchemaConfigActions = Record<string, Action>;

declare module '../declarations' {
  export interface ModelDefinition {
    actions?: ModelDefinitionActions;
  }
  export interface SchemaConfigOptions {
    actions: SchemaConfigActions;
  }
}
