import _ from 'lodash';
import { mkdebug } from '@ssp/utils';

const debug = mkdebug( 'ssp:database:utils:mongo-query' );

export function mongoSimplify( query ) {
  debug( 'mongoSimplify <in<', query );
  const res = _simplify( query ) || {};
  debug( 'mongoSimplify >out>', query );
  return res;
}
function _simplify( query ) {
  if ( ! _.isPlainObject( query ) ) return query;
  if ( isEmpty( query ) ) return;
  debug( 'SIMPLIFY:', query );
  const op = is_op( query );
  if ( op ) {
    debug( 'SIMPLIFY OP:', op );
    if ( op === '$and' ) {
      const value = _.reject( _.map( query[ op ], _simplify ), isEmpty );
      debug( 'SIMPLIFY $AND VALUE:', value );
      if ( value.length === 0 ) return;
      if ( value.length === 1 ) return value[0];
      const merged_value = mongoMerge( value );
      if ( _.isPlainObject( merged_value ) ) return merged_value;
      debug( 'MERGED VALUE:', merged_value );
      return { $and : merged_value };
    }
    if ( op === '$or' ) {
      const value = _.reject( _.map( query[ op ], _simplify ), isEmpty );
      debug( 'SIMPLIFY $OR VALUE:', value );
      if ( value.length === 0 ) return;
      if ( value.length === 1 ) return value[0];
      return { $or : squish( value ) };
    }
    // const res = _.omitBy( _.mapValues( query[op], _simplify ), isEmpty );
    // debug( 'SIMPLIFY OP RES:', op, res );
    // if ( isEmpty( res ) ) return;
    // return { [op] : res };
    return query;
  }
  // const res = _.omitBy( _.mapValues( query, _simplify ), isEmpty );
  // debug( 'SIMPLIFY RES:', res );
  // if ( isEmpty( res ) ) return;
  // return res;
  return query;
}

export function mongoMerge( ...data ) {
  data = _.flattenDeep( data );
  const merged = {};
  const unmerged = [];
  const proc = ( obj ) => {
    if ( _.isNil( obj ) ) return;
    if ( _.isArray( obj ) ) return _.map( obj, proc );
    if ( ! _.isPlainObject( obj ) ) {
      throw new Error( `merge.proc didn't expect "${obj}"` );
    }
    if ( is_op( obj ) === '$and' && _.isArray( obj.$and ) ) {
      return _.map( obj.$and, proc );
    }
    debug( 'ATTEMPTING TO COMBINE:', obj, merged );
    for ( const [ k, v ] of Object.entries( obj ) ) {
      if ( _.has( merged, k ) && ! _.isEqual( merged[k], v ) ) {
        unmerged.push( obj );
        return;
      }
    }
    _.assign( merged, obj );
  };
  _.each( data, obj => {
    debug( 'CHECKING:', obj );
    const op = is_op( obj );
    if ( op === '$and' ) return proc( obj.$and );
    if ( _.isNil( op ) ) return proc( obj );
    return unmerged.push( obj );
  } );

  debug( 'BEFORE SQUISHING:', merged, unmerged );
  data = _.uniqWith( squish( merged, unmerged ), _.isEqual );
  debug( 'AFTER SQUISHING:', data );
  if ( data.length === 0 ) return {};
  if ( data.length === 1 ) return data[0];
  return { $and : data };
}

function is_op( val ) {
  if ( ! _.isPlainObject( val ) ) return; // not an object
  const [ key, ...more ] = _.keys( val );
  if ( ! key ) return; // no keys
  if ( more.length ) return; // more than one key
  if ( key.startsWith( '$' ) ) return key;
  return;
}

export function hasMongoOperators( query ) {
  if ( ! _.isPlainObject( query ) ) return false;
  return _.some( query, ( val, key ) => {
    if ( key.startsWith( '$' ) ) return true;
    if ( _.isObject( val ) || _.isArray( val ) ) {
      return hasMongoOperators( val );
    }
  } );
}

function squish( ...args ) {
  return _.reject( _.flattenDeep( args ), isEmpty );
}
function isEmpty( arg ) {
  if ( _.isNil( arg ) ) return true;
  const op = is_op( arg );
  if ( [ '$and', '$or' ].includes( op ) ) {
    return squish( arg[op] ).length === 0;
  }
  return _.isEmpty( arg );
}

