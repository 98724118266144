import _ from 'lodash';
import { uuid, SSPError, isSSPError, isAnyError } from '@ssp/utils';
import { SubDocument } from '~/core/resource';

import type {
  ErrorData, ErrorTags, ErrorLevel, AnyError,
} from '@ssp/utils';

export class ErrorInfo extends SubDocument {

  declare id: string;
  declare name: string;
  declare message: string;
  declare code?: number;
  declare status?: string;
  declare index?: string|number;
  declare cause?: ErrorInfo;
  declare stack: any;

  // SSP errors will have some or all of the additional properties
  // listed below this point.  Non-SSP errors will only have the
  // properties above here (because they are harder to safely
  // persist).
  declare data?: ErrorData;
  declare tags?: ErrorTags;
  declare level?: ErrorLevel;
  declare severity?: number;
  declare flags?: string[];
  declare label: string;

  declare wrap_stack?: any[];
  declare reproduction?: string;
  declare errors?: ErrorInfo[];

  declare warnings: string[];

  static fromError( error: AnyError ) {
    const opts: Partial<ErrorInfo> = _.pick( error, [
      'name', 'message', 'code', 'status', 'stack',
    ] );
    if ( error.index ) opts.index = String( error.index );

    if ( isSSPError( error ) ) {
      _.assign( opts, _.pick( error, [
        'data', 'tags', 'level', 'flags', 'label', 'reproduction',
        'warnings', 'wrap_stack',
      ] ) );
    }
    if ( isAnyError( error.cause ) ) {
      opts.cause = ErrorInfo.fromError( error.cause );
    }
    if ( Array.isArray( error.errors ) ) {
      opts.errors = error.errors.map( err => ErrorInfo.fromError( err ) );
    }
    return ErrorInfo.create( opts );
  }

  toString() { return `Error<${this.message}>`; }

  updateSeverity() { this.severity = SSPError.severity( this.level ); }

}

ErrorInfo.initialize( {
  id             : 'ErrorInfo',
  is_subdocument : true,
  fields         : _.mapValues( {
    id      : {
      type        : 'string',
      label       : 'ErrorInfo ID',
      optional    : false,
      identifier  : true,
      summary     : true,
      default() { return uuid(); },
    },
    name    : {
      type      : 'string',
      index     : true,
      optional  : false,
    },
    message : {
      type      : 'string',
      index     : true,
      optional  : false,
    },
    code    : {
      type      : 'number',
      index     : true,
    },
    status  : {
      type      : 'string',
      index     : true,
    },
    index   : {
      type      : 'string',
      index     : true,
    },
    data    : {
      type      : 'any',
    },
    tags    : {
      type      : 'string',
      array     : true,
    },
    level   : {
      type      : 'string',
    },
    severity  : {
      type      : 'integer',
    },
    flags     : {
      type      : 'string',
      array     : true,
    },
    label     : {
      type      : 'string',
    },

    cause     : {
      type      : 'ErrorInfo',
      index     : false,
    },
    errors    : {
      type      : 'ErrorInfo',
      array     : true,
      index     : false,
    },

    stack       : {
      type      : 'any',
    },
    wrap_stack  : {
      type      : 'any',
    },
    reproduction  : {
      type      : 'string',
      access    : 'support' as const,
    },
    warnings      : {
      type      : 'string',
      array     : true,
    },
  }, val => _.defaults( val, {
    readonly  : true,
    optional  : true,
  } ) ),
  updates      : { mode : 'replace' },
  events       : {
    afterBuild() { this.updateSeverity(); },
    beforeSave() { this.updateSeverity(); },
  },
} );
