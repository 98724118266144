import _ from 'lodash';
import { sample } from './sample';
import { id as rsrc_id } from './resource';
import { adjectives, noun } from './words';

export function name( words=3 ) {
  return _.startCase( [ adjectives( words - 1 ), noun() ] );
}

export function id() { return rsrc_id( 'SSP.Project' ); }

export function type() {
  return sample( [
    ..._.times( 50, () => 'SSP.Project' ),
    // TODO - UserProject/ProjectGroup at some point
  ] );
}
