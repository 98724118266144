import _ from 'lodash';
import { Event } from './Event';
import { dispatchEvents } from './dispatchEvent';
import { mkdebug } from '@ssp/utils';

import type { Model } from '~/core/lib/Model';
import type { EventOptions, When } from './Event';

const debug = mkdebug( 'ssp:database:events:eventbox' );

const { whenOrder } = Event;

export class EventBox {

  target: Model | typeof Model;
  invokeTarget?: Model;

  was_handled?: Record<string, boolean>;
  lastWhenIdx: number;

  [key: string]: any;

  constructor( target: Model | typeof Model, options: EventOptions ) {
    this.target = target;
    _.defaults( this, {
      cancelable  : true,
      propagates  : true,
      mode        : 'async',
    } );
    // eslint-disable-next-line prefer-const
    let { handled, ...opts } = options;
    if ( handled ) {
      if ( _.isArray( handled ) ) handled = _.zipObject( handled, [] );
      if ( _.isPlainObject( handled ) ) {
        this.was_handled = _.mapValues( handled, () => false );
      }
    }
    _.assign( this, opts );
    this.lastWhenIdx = -1;
  }

  handled = ( ...names ) => {
    if ( ! this.was_handled ) this.was_handled = {};
    names.forEach( name => {
      if ( _.isArray( name ) ) return _.map( name, n => this.handled( n ) );
      if ( ! _.isString( name ) ) {
        throw new Error( `Invalid argument to handled: "${name}"` );
      }
      this.was_handled[ name ] = true;
    } );
  };

  get unhandled() {
    return _.keys( _.omitBy( this.was_handled || {} ) );
  }

  event( name ): Event {
    return new Event( this.target, name, {
      ..._.omit( this, 'target', 'lastWhenIdx' ),
      ..._.pick( this, 'handled' ),
    } );
  }

  events( ...names: ( string | string[] )[] ): Event[] {
    return _.map( _.flattenDeep( names ), name => this.event( name ) );
  }

  eventnames( whens: When | When[], names: string | string[] ): string[] {
    return _.flatMap( _.castArray( whens ), when => {
      return _.flatMap( _.castArray( names ), name => {
        return when + name.toLowerCase();
      } );
    } );
  }

  dispatch(
    whens: When | When[],
    eventids: string | string[],
    opts?: Record<string, any>,
  ) {
    if ( opts ) _.assign( this, opts );
    const eventnames = this.eventnames( whens, eventids );
    debug( 'DISPATCHING EVENTS:', eventnames );
    const events = this.events( eventnames );
    // Throw an error if we try to dispatch events in the wrong order
    // or skip stages..
    let last = null;
    const check = ( curr, prev ) => {
      const name = ( idx ) => whenOrder[ idx ];
      if ( curr.whenidx < prev.whenidx ) {
        throw new Error( [
          `Events dispatched in wrong order, attempting to dispatch a`,
          `${curr.when} event, but the last event from this box was a`,
          `${name( prev.whenidx )} event.`,
        ].join( ' ' ) );
      }
      const skipped = whenOrder.slice( prev.whenidx, curr.whenidx );
      if ( skipped.length ) {
        throw new Error( [
          `Event dispatching attepted to skip steps.  The next event`,
          `requested is a ${event.when} event, but that would skip over:`,
          skipped.join( ', ' ),
        ].join( ' ' ) );
      }
    };
    events.forEach( event => {
      check( event, last || { whenidx : this.lastWhenIdx } );
      last = event;
    } );
    if ( debug.enabled ) {
      events.forEach( event => debug( 'DISPATCHING EVENT:', event.identity ) );
    }
    return dispatchEvents( events );
  }

  before( name: string | string[], opts?: Record<string, any> ) {
    return this.dispatch( 'before', name, opts );
  }
  during( name: string | string[], opts?: Record<string, any> ) {
    return this.dispatch( 'during', name, opts );
  }
  after( name: string | string[], opts?: Record<string, any> ) {
    return this.dispatch( 'after', name, opts );
  }

}
