// Automatically generated, DO NOT EDIT!
// To update run "yarn do-updates"
/* eslint-disable */
import { Schema } from '~/core/lib/Schema';
import raw from 'raw.macro';
export * from './SSP.Project';
export * from './children';
import './markdown';
import './action-jobs';
Schema.demand( 'SSP.Project' ).logo = raw( './logo.svg' );
