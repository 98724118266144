import _ from 'lodash';

export const Transforms = {
  lowercase       : _.toLower,
  uppercase       : _.toUpper,
  lowercasewords  : _.lowerCase,
  uppercasewords  : _.upperCase,
  capitalize      : _.startCase,
  startcase       : _.startCase,
  kebabcase       : _.kebabCase,
  snakecase       : _.snakeCase,
  camelcase       : _.camelCase,
};

export function getTransforms( transform ) {
  return _.flow( [
    x => _.flattenDeep( [ x ] ),
    x => _.flatMap( x, y => ( _.isString( y ) ? y.split( /[\s,]+/u ) : y ) ),
    _.compact,
    _.uniq,
    y => ( _.isFunction( y ) ? y : Transforms[ y ] ),
    _.compact,
  ] )( transform );
}

export function runTransforms( transform, value, ...args ) {
  if ( ! ( _.isArray( transform ) && transform.length ) ) return value;
  const transforms = getTransforms( transform );
  return _.reduce( transforms, ( val, fn ) => fn( val, ...args ), value );
}
