/* eslint-disable import/first, import/imports-first */
/* eslint-disable import/newline-after-import */
import './bluebird';
import '@ssp/logger';

import { Module } from '~/core/lib/Module';
import { Schema } from '~/core/lib/Schema';
import { SchemaConfig } from '~/core/lib/SchemaConfig';
import { Behavior } from '~/core/lib/Behavior';

import { DB } from './core/registry';

export { DB };
export default DB;

import './modules/modules';
import './core/resource/Resource.initialize';
import './core/resource/SubDocument.initialize';
import './behaviors';
import './core/resource/ProjectResource.initialize';
import './core/resource/UserResource.initialize';
import './core/resource/ServiceResource.initialize';

export { Module, Schema, SchemaConfig, Behavior };

export * from './types';
export * from './lib';
export * from './core/lib';
export * from './modules/index';
export * from './modules/declarations';

export * from './core/resource/resource-types';
export * from './core/resource/Resource';
export * from './core/resource/SubDocument';

export * from './behaviors';
export * from './core';

export * from './components';


export * from './utils';

SchemaConfig.finish();

globalThis.DB = DB;
