import _ from 'lodash';
import { DB } from '~';
import { promise } from '@ssp/utils';
import { createToggleActions } from '~/modules/actions/utils';
import { createResource } from '~/core/creators';
import { Schema } from '~/core/lib/Schema';
import { ProjectResource } from '~/core/resource/ProjectResource';

createResource( {
  id    : 'SSP.Project',
  name  : 'SSP Project',
  icon  : 'far:project-diagram',

  fields      : {
    project_id    : {
      type          : 'id',
      label         : 'Project ID',
      index         : true,
      unique        : true,
      readonly      : true,
      metadata      : true,
      // Optional, because it will get filled in automatically when
      // inserted or updated..
      optional      : true,
      faker         : 'none',
    },
    type        : {
      type        : 'string',
      index       : true,
      summary     : false,
      label       : 'Project Type',
      default     : 'SSP.Project',
      access      : 'support',
      metadata    : true,
      faker       : 'project.type',
      sortable    : true,
    },
    name        : {
      type        : 'string',
      index       : true,
      unique      : true,
      quicksearch : true,
      help        : 'Fully spelled out project name.',
      label       : 'Full Name',
      faker       : 'project.name',
    },
    description : {
      type        : 'text',
      optional    : true,
      quicksearch : true,
      summary     : true,
      help        : 'Brief description of the project.',
      label       : 'Description',
      minimal     : true,
      faker       : 'hacker.phrase',
    },
    slug        : {
      type        : 'slug',
      index       : true,
      unique      : true,
      optional    : true,
      quicksearch : true,
      summary     : true,
      identifier  : true,
      help        : [
        'Used for the friendly URL to your project. Cannot contain spaces.',
      ],
      label       : 'Short Title',
      sortable    : true,
      // default( data={} ) {
      //   return slugify( this.name || data.name );
      // },
    },
    is_sensitive  : {
      type        : 'boolean',
      default     : false,
      summary     : false,
      access      : 'support',
      label       : 'Sensitive',
      metadata    : true,
      badge       : { color : 'danger' },
    },
    is_hidden     : {
      type        : 'boolean',
      label       : 'Hidden',
      default     : false,
      access      : 'support',
      metadata    : true,
      badge       : { color : 'danger' },
    },
    active      : {
      type        : 'boolean',
      label       : 'Active',
      default     : true,
      summary     : true,
      access      : 'support',
      metadata    : true,
      badge       : { color : 'warning', value : false, label : 'Inactive' },
    },
    is_beta_project : {
      type        : 'boolean',
      label       : 'Beta Project?',
      default     : false,
      optional    : true,
      summary     : true,
      access      : 'support',
      metadata    : true,
      badge       : { label : 'Beta' },
    },
    is_test_project : {
      type        : 'boolean',
      label       : 'Test Project?',
      default     : false,
      optional    : true,
      summary     : true,
      access      : 'support',
      metadata    : true,
      badge       : { label : 'Test' },
    },
  },
  type_field   : 'type',

  actions     : {
    create    : {
      label         : 'Create Project',
      help          : 'Create a new SSP Project',
      route         : '/projects/create',
      icon          : 'far:plus-square',
      capability    : 'create-projects',
      type          : 'class',
    },
    delete    : {
      label         : 'Delete Project',
      help          : 'Delete this SSP Project',
      route         : 'delete',
      access        : 'admin',
      icon          : 'far:trash-alt',
      context       : 'self',
    },
    ...createToggleActions(
      {
        field   : 'is_test_project',
        label   : 'Test Mode',
        help    : 'Toggle this project\'s "test" features',
        icon    : 'far:vials',
        access  : 'support',
      },
      {
        field   : 'is_beta_project',
        label   : 'Beta Mode',
        help    : 'Toggle this project\'s "beta" features',
        icon    : 'far:vials',
        access  : 'support',
      },
    ),
    changeOwner     : false,
  },
  methods     : class extends ProjectResource {

    baseurl() {
      if ( ! this._id ) return;
      return `/project/${this._id}`;
    }

    findJobs( query, opts ) {
      return DB.SYSTEM.Job
        .find( { 'project.id' : this._id } )
        .find( query, opts );
    }

    findTeams( query, opts ) {
      return this.findResources( 'SSP.Team', query, opts );
    }

    async getTeams( query, opts ) {
      return this.findTeams( query, opts ).load();
    }

    async getTeam( name ) {
      return this.findTeams( { name } ).single();
    }

    async getMemberIds( query={}, opts={} ) {
      const teams = await this.findTeams( query, opts ).all();
      return _.uniq( _.flatMap( teams, team => {
        return team.members.map( 'user_id' );
      } ) );
    }

    /**
     * For this project get its associated resources
     *
     * @typedef ResourceOptions
     * @property {boolean} external - get all its resources that have
     * an association with an external service. One place this comes in handy
     * is for finding resources that must be archived or moved to a different
     * project before this project can be deleted
     * @property {boolean} nonExternal - get all resources that do not have
     * an association with an external service. One place this comes in handy
     * is when cleaning up remaining resources that were not subject to the
     * requirement of being moved or archived prior to deleting this project
     */

    /**
     * @param {ResourceOptions} options
     * @returns {Resource[]}
    */
    async getResources( ...filters: Parameters<typeof Schema['filter']> ) {
      const schemas = Schema.filter( ...filters );
      const resources = await promise.mapseries( schemas, async schema => {
        const rs = schema.model.find(
          { project_id : this._id },
          { minlength : false },
        );
        await rs.load();
        return rs.all();
      } );
      return _.compact( _.flatten( resources ) );
    }

    async getPrimaryAdminTeam() {
      const team = await DB.SSP.Team.fetch( {
        project_id        : this._id,
        is_primary_admin  : true,
      }, { safe : true } );
      if ( team ) return team;
      const team2 = await DB.SSP.Team.fetch( {
        project_id        : this._id,
        name              : 'Administrators',
      }, { safe : true } );
      if ( team2 ) return team2.makePrimaryAdmin();
      return DB.SSP.Team.insert( {
        project_id        : this._id,
        is_primary_admin  : true,
        name              : 'Administrators',
        description       : `${this.name} Administrators`,
      } );
    }

    async computeResourceIds( ids = {} ) {
      const teams = await this.findTeams( {}, { filters : '*' } ).all();
      const users = teams.flatMap( team => team.members.toArray() );
      _.assign( ids, {
        'SSP.Team' : _.uniq( _.map( teams, '_id' ) ),
        'SSP.User' : _.uniq( _.map( users, 'user_id' ) ),
      } );
      return await super.computeResourceIds( ids );
    }

  },

  faces       : {
    'card.summary' : [
      {
        layout  : 'Badges',
        fields  : [ '@badge' ],
      },
      {
        layout : 'Values',
        fields : [ 'description', '-@badge' ],
      },
      {
        layout : 'LabelsAndValues',
        fields : [ '@summary', '-description', '-@badge' ],
      },
    ],
    'list.summary' : [
      {
        layout : {
          type       : 'ItemsBetweenTitleAndIcons',
        },
        fields  : [ '-@all' ],
      },
      {
        layout : 'ItemsAndBadges',
        fields  : [ 'description', '@badge' ],
      },
    ],
    'panel.summary' : [
      {
        layout  : 'Badges',
        fields  : [ '@badge' ],
      },
      {
        layout : 'Values',
        fields : [ 'description', '-@badge' ],
      },
      {
        layout : 'PropTable',
        fields : [ '@summary', '-description', '-@badge' ],
      },
    ],
  },
  behaviors : {
    hasResourceCounts : {},
  },
} );
