import { Module } from '~/core/lib/Module';

Module.create( {
  name     : 'forms',
  services : false,

  parse( data ) {
    return { forms : data.forms || {} };
  },

  apply( data, config ) {
    config.add( { forms : data.forms } );
  },

} );
