import _ from 'lodash';

// MongoDB Update Aggregation Operator Helpers

type Doc = Record<string, any>;

export function set<T extends Doc = Doc>( updates: T );
export function set<T=any>( field: string, value: T );
export function set( field: string | Doc, value?: any ) {
  if ( typeof field === 'string' ) {
    if ( value === null ) return { $unset : field };
    return { $set : { [field] : settable( value ) } };
  } else if ( _.isPlainObject( field ) ) {
    return { $set : _.mapValues( value, settable ) };
  } else {
    throw new TypeError( `Invalid argument for $set: "${value}"` );
  }
}
export function _set( field: string, value?: any ) {
  if ( value === null ) {
    return { $unset : field };
  } else {
    return { $set : { [field] : value } };
  }
}

/**
 * Ensure that the value provided can be passed to `$set` without issue.
 */
export function settable( value: any ) {
  // https://jira.mongodb.org/browse/SERVER-54046
  if ( _.isPlainObject( value ) ) {
    if ( _.isEmpty( value ) ) return { $literal : {} };
    return _.mapValues( value, settable );
  }
  if ( Array.isArray( value ) ) {
    if ( value.length ) return value.map( settable );
    return { $literal : [] };
  }
  return value;
}

export function unset( field: string ) {
  return { $unset : field };
}

export function inc( field: string ) {
  return { $set : { [field] : {
    $cond : {
      if    : { $eq : [ { $type : `$${field}` }, 'null' ] },
      then  : 1,
      else  : { $add : [ `$${field}`, 1 ] },
    },
  } } };
}

export function ensureArray( field: string ) {
  return { $set : { [field] : {
    $cond : {
      if    : { $eq : [ { $type : `$${field}` }, 'array' ] },
      then  : `$${field}`,
      else  : [],
    },
  } } };
}

export function concat<T=unknown>( field: string, values: T[] ) {
  values = settable( _.compact( _.castArray( values ) ) );
  return { $set : { [field] : { $concatArrays : [ `$${field}`, values ] } } };
}

export function filter( field: string, cond: any, varname: string = 'x' ) {
  return { $set : { [field] : {
    $filter : {
      input : `$${field}`,
      as    : varname,
      cond,
    },
  } } };
}

export function reject( field: string, cond: any, varname: string = 'x' ) {
  return { $set : { [field] : {
    $filter : {
      input : `$${field}`,
      as    : varname,
      cond  : { $not : cond },
    },
  } } };
}

export function rejectArrayElements( field: string, values: string[] ) {
  return { $set : { [field] : { $setDifference : [ `$${field}`, values ] } } };
}

export function condForSubdoc(
  subdoc: Record<string, any>, varname: string = 'x',
) {
  // If they are objects it's a little more complex
  const $and = _.map( subdoc, ( v, k ) => ( {
    $eq : [ `$$${varname}.${k}`, v ],
  } ) );
  if ( ! $and.length ) return;
  if ( $and.length === 1 ) return $and[ 0 ];
  return { $and };
}
export function condForSubdocs( subdocs: any[], varname: string = 'x' ) {
  // If they are objects it's a little more complex
  const $or = _.compact( _.map( subdocs, subdoc => {
    return condForSubdoc( subdoc, varname );
  } ) );
  if ( ! $or.length ) return;
  if ( $or.length === 1 ) return $or[ 0 ];
  return { $or };
}

export function rejectSubdocs(
  field: string, subdocs: any[], varname: string = 'x',
) {
  const cond = condForSubdocs( subdocs, varname );
  if ( ! cond ) return;
  return reject( field, cond, varname );
}
export function updateListSubdoc(
  field: string,
  match: Doc,
  update: Doc,
  varname: string = 'x',
) {
  const x = varname;
  const $$x = `$$${varname}`;
  return { $set : { [field] : {
    $map : {
      input : `$${field}`,
      as    : x,
      in    : { $cond: {
        if    : condForSubdoc( match ),
        then  : { $mergeObjects: [ $$x, update ] },
        else  : $$x,
      } },
    },
  } } };
}

/* TODO - Process all the list updates at once with a switch that
 * branches over the conditions? This would make the computed mongo
 * update docs a bit more complex, but would mean only iterating over
 * the members list once for an update. Not sure whether that would
 * improve performance enough to be worthwhile...
  const branches = list_updates.map( ( { match, update } ) => ( {
    case : condForSubdoc( match ),
    then : { $mergeObjects : [ $$x, update ] },
  } ) );

  return { $set : { [field] : { $map : {
    input : '$members',
    as    : x,
    in    : { $switch : { branches, default : $$x } },
  } } } };
*/
