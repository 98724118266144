/* eslint-disable import/group-exports */
/**
 * @description
 * This module exports a bunch of type-checking methods all in one
 * place, so you don't have to remember where each of these needs to be
 * imported from, and so you have this handy list of all of them in one
 * place.
 */

import _ from 'lodash';

import { Schema } from '~/core/lib/Schema';
import { Model } from '~/core/lib/Model';

import { isConstructor, isFunction, isId } from '@ssp/utils';

/**
 * Check whether the argument is a Service or not.  Returns true if
 * the passed argument is a model constructor that inherits from
 * `ServiceResource`.
 *
 * @param {any} value - value to test.
 * @returns Boolean.
 */
export function isService( value ) {
  return isModelOf( 'ServiceResource', value );
}

/**
 * Check whether the argument is a project or not.  Returns true if
 * the passed argument is an instance of the SSP.Project Component.
 *
 * @param {any} value - value to test.
 * @returns Boolean.
 */
export function isProject( value ) {
  return isInstanceOfModel( 'SSP.Project', value );
}

/**
 * Check whether the argument is a user object.  Returns true if the
 * passed argument is an instance of the SSP.User Component.
 *
 * @param {any} value - value to test.
 * @returns Boolean.
 */
export function isUser( value ) {
  return isInstanceOfModel( 'SSP.User', value );
}

/**
 * Check whether the argument is an object of the given type.  Returns
 * true if the passed argument is an instance of the Component given
 * in the type parameter.
 *
 * @param {string} type - Type to check for.
 * @param {any} value - value to test.
 * @returns Boolean.
 */
export function isResourceType( type, value ) {
  return value && type && value.schema && value.schema.id === type;
}

/**
 * Check whether the argument is a string which is the name of
 * a resource type.
 *
 * @param {string} value - string to test.
 * @returns Boolean.
 */
export function isComponentName( value ) {
  return _.isString( value ) && Schema.get( value )?.is_resource;
}
// Back-compat alias
export function isResourceTypeName( value ) {
  return isComponentName( value );
}
export function isServiceName( value ) {
  return _.isString( value ) && Schema.get( value )?.is_service;
}

export function isInstanceOfModel( parent, value ) {
  if ( ! parent ) throw new Error( `isInstanceOfModel expects parent` );
  if ( isFunction( value ) ) return false; // constructor
  return value instanceof Model.demand( parent );
  // return Model.demand( parent ).isPrototypeOf( value );
}

/**
 * Check whether the argument is a component (a constructor of a class
 * that descends from Resource).
 *
 * @param {any} value - value to test.
 * @returns Boolean.
 */
export function isComponent( value ) {
  return isModelOf( 'Resource', value );
}

/**
 * Check whether the argument is a resource object.  Returns true if
 * the passed argument is an instance of a class that descends from
 * SSP.Resource.
 *
 * @param {any} value - value to test.
 * @returns Boolean.
 */
export function isResource( value ) {
  return isInstanceOfModel( 'Resource', value );
}

export function isSubDocument( value ) {
  return isInstanceOfModel( 'SubDocument', value );
}
export function isSubDoc( value ) { return isSubDocument( value ); }
export function isSubdoc( value ) { return isSubDocument( value ); }

export function isResourceOrComponent( value ) {
  return isResource( value ) || isComponent( value );
}

/**
 * Check whether the argument is a database model.  Returns true if
 * the passed argument has the Model as an ancestor and is
 * a constructor rather than an instance of a model.
 *
 * @param {any} value - value to test.
 * @returns Boolean.
 */
export function isModel( value ) {
  return isConstructor( value )
    && Model.isPrototypeOf( value );
}

/**
 * Check whether the argument is a database schema.
 *
 * @param {any} value - value to test.
 * @returns Boolean.
 */
export const isSchema = value => value instanceof Schema;

/**
 * Check whether the argument is a database record.  Returns true if
 * the passed argument has the Model as an ancestor and is an
 * instance rather than a constructor.
 *
 * @param {any} value - value to test.
 * @returns Boolean.
 */
export function isDBRecord( value ) {
  return _.isObject( value )
    && ! isConstructor( value )
    && isModel( value.constructor );
}

/**
 * Check whether an object is a valid "owner" -- meaning either an
 * SSP.Project or SSP.User, the two resource types that can "own"
 * other resources.
 *
 * @param {any} value - value to test.
 */
export function isOwner( value ) {
  return isProject( value ) || isUser( value );
}

/**
 * Return true if the value passed in is a valid `LinkData` object.
 *
 * @param {*} value - The value to test.
 * @returns {value is LinkData}
 */
export function isLinkData( value ) {
  return _.isPlainObject( value )
    && ( isComponentName( value.type ) || isServiceName( value.type ) )
    && ( _.isNil( value.id ) || isId( value.id ) )
    && ( _.isNil( value.name ) || _.isString( value.name ) );
}

/** Check if `value` is a model that inherits from `model`. */
export function isModelOf( model: any, value: any ) {
  return isConstructor( value )
    &&  Model.demand( model ).isPrototypeOf( value );
}
