// Automatically generated, DO NOT EDIT!
// To update run "yarn do-updates"
/* eslint-disable */
import { Model } from '~/core/lib/Model';
Model.demand( 'SSP.Project' ).extend( {
  "actions": {
    "sync": {
      "access": "member",
      "help": [
        "For all of this project's resources, perform a reconciliation",
        "between SSP's data record of a resource and its remote",
        "counterpart"
      ],
      "icon": "fas:sync",
      "id": "sync",
      "job_name": "sync",
      "label": "Sync",
      "name": "sync",
      "type": "instance"
    }
  }
} );
