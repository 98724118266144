// Automatically generated, DO NOT EDIT!
// To update run "yarn do-updates"
/* eslint-disable */
import { Model } from '~/core/lib/Model';
Model.demand( 'CSNAD.User' ).extend( {
  "actions": {
    "discover": {
      "access": "support",
      "icon": "far:search-plus",
      "id": "discover",
      "job_name": "discover",
      "label": "Discover",
      "name": "discover",
      "type": "class"
    },
    "import": {
      "access": "support",
      "icon": "far:file-import",
      "id": "import",
      "job_name": "import",
      "label": "Import",
      "name": "import",
      "params": {
        "__changed": {
          "hidden": true,
          "restricted": true,
          "type": "string"
        },
        "__created": {
          "hidden": true,
          "restricted": true,
          "type": "string"
        },
        "__data": {
          "hidden": true,
          "restricted": true,
          "type": "object"
        },
        "dn": {
          "describe": "DN for user to import",
          "required": true,
          "type": "string"
        }
      },
      "type": "class"
    },
    "refresh-batch": {
      "access": "support",
      "icon": "far:sync",
      "id": "refresh-batch",
      "job_name": "refresh-batch",
      "label": "Refresh Recently Changed CSN AD Users",
      "name": "refresh-batch",
      "params": {
        "since": {
          "default": "3h",
          "describe": "Timeframe to look for changes",
          "required": false,
          "type": "string"
        }
      },
      "type": "class"
    },
    "sync": {
      "access": "admin",
      "icon": "fas:sync",
      "id": "sync",
      "job_name": "sync",
      "label": "Sync",
      "name": "sync",
      "type": "instance"
    }
  }
} );
