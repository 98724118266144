import _ from 'lodash';

declare module './types' {
  export interface TraitConfigs {
    withRoles: {
      roles: string[];
      defaultRoles?: string[];
      label?: string;
    };
  }
  export interface TraitOptions {
    roles: string[];
    defaultRoles: string[];
  }
}

export function buildOptions( { options } ) {
  const { roles } = options || {};
  if ( ! ( _.isArray( roles ) && _.every( roles, _.isString ) ) ) {
    throw new TypeError( `withRoles requires "roles"` );
  }

  _.defaults( options, {
    label         : 'Roles',
    defaultRoles  : options.roles.slice( 0, 1 ),
  } );

  return _.pick( options, 'roles', 'defaultRole' );
}
export function buildMemberInfoConfig( { options } ) {

  const { label, roles, defaultRoles } = options;

  return {
    fields      : {
      roles   : {
        type        : 'string',
        array       : true,
        label,
        default     : defaultRoles,
        form        : {
          type    : 'SwitchGroup',
          options : roles,
          default : defaultRoles,
        },
      },
    },
    methods     : class {
      resolveRoles( ...values ) {
        const resolved = _.compact( _.uniq( _.flattenDeep( values ) ) ).sort();
        log.trace( 'RESOLVED ROLES:', resolved );
        return resolved;
      }
    },
  };
}

export const access_fields = [ 'roles' ];
export const defaults = { label : 'Roles' };
