import { createSubDocument } from '~/core/creators';

createSubDocument( {
  id      : 'QuidditchPlayer',
  fields  : {
    name    : {
      type        : 'string',
      label       : 'Name',
      identifier  : true,
    },
    broom   : {
      type  : 'string',
      label : 'Broom Model',
    },
  },
} );
createSubDocument( {
  id     : 'QuidditchTeam',
  fields : {
    team    : {
      type      : 'string',
      label     : 'Team Name',
    },
    house   : {
      type      : 'string',
      label     : 'House',
    },
    players : {
      type      : 'QuidditchPlayer',
      list      : true,
    },
    chaser1 : {
      type      : 'QuidditchPlayer',
    },
    chaser2 : {
      type      : 'QuidditchPlayer',
    },
    chaser3 : {
      type      : 'QuidditchPlayer',
    },
    beater1 : {
      type      : 'QuidditchPlayer',
    },
    beater2 : {
      type      : 'QuidditchPlayer',
    },
    keeper  : {
      type      : 'QuidditchPlayer',
    },
    Seeker  : {
      type      : 'QuidditchPlayer',
    },
  },
} );
