import _ from 'lodash';
import { Watchable } from '~/core/lib/Watchable';
import { getIdentity } from './identity-utils';
import { mkdebug, toposort, hideProps } from '@ssp/utils';
import { getDataMap } from '../broker/data-utils';

import type { ListField } from './ListField';

const debug = mkdebug( 'ssp:database:fields:list' );

let counter = 0;

export interface ListOptions {
  /**
   * Field name to sort by, or function to use for sorting.  You can
   * also use `toposort` to get topographical sorting, `false` to
   * disable sorting, or `true` to sort by identity.
   */
  sort?: string | boolean | 'toposort' | ( ( a: string, b: string ) => number );
}

export class List<T=unknown> extends Watchable {

  list_id: string = `list-${counter++}`;

  declare field: ListField;

  get id() { return getDataMap( this ).id; }
  get broker() { return getDataMap( this ).broker; }
  set broker( broker ) { getDataMap( this ).broker = broker; }
  get size() { return getDataMap( this ).size; }
  get schema() { return this.field.subdocSchema; }
  get readonly() { return this.field.readonly; }

  constructor( field: ListField, options ) {
    super();
    /*
    if ( ! ( field instanceof ListField ) ) {
      throw new TypeError( `List requires ListField instance` );
    }
    */
    debug( 'BUILDING LIST:', options );
    this.setSort( options.sort ?? true );
    hideProps( this, { field } );
    getDataMap( this );
  }

  declare _sort?: string | boolean | 'toposort'
    | ( ( a: any, b: any ) => number );
  declare _sorted_keys?: string[];

  getSort() { return this._sort; }
  setSort( val ) { this._sort = val; this.reset(); }

  /**
   * Reset stored state, called automatically for things like changing
   * sort order.
   */
  reset() {
    delete this._sorted_keys;
    this.changed();
  }

  get unsorted_keys(): string[] {
    return Array.from( getDataMap( this ).keys() );
  }
  get unsorted_values() {
    return Array.from( getDataMap( this ).values() );
  }
  get unsorted_entries() {
    return Array.from( getDataMap( this ).entries() );
  }

  getSortedKeys( sort = this.getSort() ) {
    if ( this._sorted_keys ) return this._sorted_keys;
    if ( sort === true ) return this._sorted_keys = this.unsorted_keys.sort();
    if ( sort === false ) return this._sorted_keys = this.unsorted_keys;
    if ( sort === 'toposort' ) {
      return this._sorted_keys = toposort(
        this.unsorted_values,
        { result : 'ids' },
      );
    }
    if ( _.isString( sort ) ) {
      return this._sorted_keys = this.unsorted_keys.sort( ( a, b ) => {
        const A: string = _.result( this.getKey( a ), sort );
        const B: string = _.result( this.getKey( b ), sort );
        return A.localeCompare( B );
      } );
    }
    if ( _.isFunction( sort ) ) {
      return this._sorted_keys = this.unsorted_keys.sort( ( a, b ) => {
        return sort( this.getKey( a ), this.getKey( b ) );
      } );
    }
    return this._sorted_keys = this.unsorted_keys;
  }

  replace( values, quietly=false ) {
    this.clear( true );
    this.add( values, quietly );
  }

  coerceValue( value ) {
    return this.field.coerceValue( value );
  }
  identity( value ) {
    const res = getIdentity( value, value?.schema || this.schema );
    if ( res === '[Object object]' ) {
      log.error( `Got invalid identity from`, value );
      throw new Error( `Invalid identity for "${value}"` );
    }
    return res;
  }

  add( value, quietly=false ) {
    if ( _.isNil( value ) ) return;
    if ( value instanceof List ) value = value.toArray();
    if ( Array.isArray( value ) ) {
      return _.map( value, v => this.add( v, quietly ) );
    }
    value = this.coerceValue( value );
    const id = this.identity( value );
    if ( ! id ) {
      log.error( `Cannot get identity of`, value );
      throw new Error( `Cannot get identity of "${value}"` );
    }
    getDataMap( this ).set( id, value, quietly );
    this.reset();
    return value;
  }

  update( data: T ) {
    if ( this.has( data ) ) return this.get( data ).merge( data );
    return this.add( data );
  }

  new( data = {} ): T {
    return this.coerceValue( data );
  }

  has( value ): boolean {
    const id = this.identity( value );
    if ( getDataMap( this ).has( id ) ) return true;
    return false;
  }
  get( value ): T|undefined {
    const id = this.identity( value );
    const val = getDataMap( this ).get( id );
    if ( val ) return val;
  }
  delete( value, quietly=false ) {
    if ( Array.isArray( value ) ) {
      return _.map( value, v => this.delete( v, quietly ) );
    }
    getDataMap( this ).delete( this.identity( value ), quietly );
    this.reset();
  }
  clear( quietly=false ) {
    getDataMap( this ).clear( quietly );
    this.reset();
  }

  hasKey( key ) { return getDataMap( this ).has( key ); }
  getKey( key ) { return getDataMap( this ).get( key ); }

  // These need to be sort-aware
  toArray() { return Array.from( this.values() ); }
  toObject() { return Object.fromEntries( this.entries() ); }
  * keys() {
    for ( const key of this.getSortedKeys() ) yield key;
  }
  * values() {
    for ( const key of this.keys() ) yield this.getKey( key );
  }
  * entries() {
    for ( const key of this.keys() ) yield [ key, this.getKey( key ) ];
  }
  [ Symbol.iterator ]() { return this.values(); }

  deleteBy( iteratee ) {
    iteratee = _.iteratee( iteratee );
    const removed = [];
    for ( const item of this ) {
      if ( iteratee( item ) ) {
        removed.push( item );
        this.delete( item );
      }
    }
    return removed;
  }

  getKeyAtIndex( idx ) {
    const key = Array.from( this.keys() )[ idx ];
    if ( _.isString( key ) ) return key;
  }
  getItemAtIndex( idx ) {
    const key = this.getKeyAtIndex( idx );
    if ( key ) return this.getKey( key );
  }

  getIndexOfKey( key ) {
    return Array.from( this.keys() ).indexOf( key );
  }
  getIndexOfItem( item ) {
    return Array.from( this.values() ).indexOf( item );
  }

  indexOf( item ) {
    return _.isString( item )
      ? this.getIndexOfKey( item )
      : this.getIndexOfItem( item );
  }

  getItemBefore( item ) {
    const idx = this.indexOf( item );
    if ( idx >= 1 ) return this.getItemAtIndex( idx - 1 );
  }
  getItemAfter( item ) {
    const idx = this.indexOf( item );
    if ( idx >= 0 ) return this.getItemAtIndex( idx + 1 );
  }

  forEach( cb ) {
    cb = _.iteratee( cb );
    for ( const [ id, value ] of this.entries() ) {
      cb( value, id, this );
    }
  }
  each( cb ) { return this.forEach( cb ); }
  map( cb ) {
    cb = _.iteratee( cb );
    const res = [];
    for ( const [ id, obj ] of this.entries() ) {
      res.push( cb( obj, id, this ) );
    }
    return res;
  }
  mapValues( cb ) {
    cb = _.iteratee( cb );
    const res = {};
    for ( const [ id, obj ] of this.entries() ) {
      res[ id ] = cb( obj, id, this );
    }
    return res;
  }
  filter( cb ) {
    cb = _.iteratee( cb );
    const res = [];
    for ( const [ id, obj ] of this.entries() ) {
      if ( cb( obj, id, this ) ) res.push( obj );
    }
    return res;
  }
  reject( cb ) {
    cb = _.iteratee( cb );
    const res = [];
    for ( const [ id, obj ] of this.entries() ) {
      if ( ! cb( obj, id, this ) ) res.push( obj );
    }
    return res;
  }
  find( cb ) {
    cb = _.iteratee( cb );
    for ( const [ id, obj ] of this.entries() ) {
      if ( cb( obj, id, this ) ) return obj;
    }
  }
  findIndex( cb ) {
    const obj = this.find( cb );
    if ( obj ) return this.indexOf( obj );
  }
  every( cb ) {
    cb = _.iteratee( cb );
    for ( const [ id, obj ] of this.entries() ) {
      if ( ! cb( obj, id, this ) ) return false;
    }
    return true;
  }
  some( cb ) {
    cb = _.iteratee( cb );
    for ( const [ id, obj ] of this.entries() ) {
      if ( cb( obj, id, this ) ) return true;
    }
    return false;
  }
  none( cb ) {
    cb = _.iteratee( cb );
    for ( const [ id, obj ] of this.entries() ) {
      if ( cb( obj, id, this ) ) return false;
    }
    return true;
  }

  /**
   * Apply a transform function to every element of the list.  This
   * works like `map`, but instead of returning an array of the
   * results, the values in the list are replaced with the results.
   *
   * @param {Function} func - Transform function.
   * @param {any[]} [args] - Arguments to pass to function.
   */
  transform( func, ...args ) {
    this.replace( this.map( item => func( item, ...args ) ) );
  }

  isEmpty() { return getDataMap( this ).isEmpty(); }

  resultset( options={} ) {
    const { ResultSet } = this.schema;
    const opts = { ...options, list : this };
    return new ResultSet( opts );
  }

  toString() { return this.list_id; }

  attachBroker( broker ) { getDataMap( this ).attachBroker( broker ); }
  detachBroker() { getDataMap( this ).detachBroker(); }

  async load( opts ) { return Promise.all( this.map( i => i.load( opts ) ) ); }

}
