import _ from 'lodash';
import { When } from './Event';
import { Schema } from '~/core/lib/Schema';
import { isThenable } from '@ssp/utils';

export type EventHandlerFunction = ( ev: Event ) => any|Promise<any>;
export interface EventHandlerOptions {
  /** ID */
  id?: string;
  /** Handler type */
  type: string;

  /** The handler function. */
  handler: EventHandlerFunction;
}

export class EventHandler {

  /** Event Handler ID. */
  id: string;

  /**
   * The type of event that this handler is attached to.  This is the
   * complete type, for example `beforesave` or `aftergenerate`.  It
   * will always be converted to lowercase.
   */
  type: string;

  /**
   * When the event is positioned.  This will be `before`, `during`,
   * or `after`, unless this is for a custom event that didn't start
   * with `before`, `during` or `after`, in that case this will be
   * undefined.
   */
  when?: When;

  /**
   * The name of the event, without it's `before`, `during`, or
   * `after` prefix.  If this is a custom event that didn't have
   * a prefix then `name` will be the same as `type`.
   */
  name: string;

  /** The handler function. */
  handler: EventHandlerFunction;

  /** The origin of this event handler (where it was defined). */
  origin: string;

  constructor( opts: EventHandlerOptions & { origin?: string; } ) {
    const { id, type, handler, origin } = opts;
    this.origin = origin;
    if ( _.isString( type ) ) {
      this.type = type.toLowerCase();
      if ( this.type.startsWith( 'before' ) ) {
        this.when = 'before';
      } else if ( this.type.startsWith( 'during' ) ) {
        this.when = 'during';
      } else if ( this.type.startsWith( 'after' ) ) {
        this.when = 'after';
      }
      if ( this.when ) {
        this.name = this.type.slice( this.when.length );
      } else {
        this.name = this.type;
      }
    } else {
      throw new TypeError( `EventHandler requires type` );
    }
    if ( typeof handler === 'function' ) {
      this.handler = handler;
    } else {
      throw new TypeError( `EventHandler requires handler function` );
    }
    if ( typeof id === 'string' ) {
      this.id = id;
    } else {
      this.id = this.type + ' // ' + origin;
    }
  }

  execute( event ) {
    const executor = () => this.handler.call( event.target, event );
    const res = _.isFunction( event.wrap_execute )
      ? event.wrap_execute( executor )
      : executor();
    if ( event.mode === 'sync' && isThenable( res ) ) {
      event.throw( 'event returned a thenable for a sync event handler', {
        class     : 'EventHandler',
        method    : 'execute',
        parameter : 'event',
        value     : event,
        handler   : this,
      } );
    }
    return res;
  }

  equals( that ) {
    return EventHandler.equals( this, that );
  }

  get code() {
    return this.handler.toString();
  }

  static equals( a, b ) {
    return ( a instanceof EventHandler ) && ( b instanceof EventHandler )
      && ( a.type === b.type ) && ( a.code === b.code );
  }

  toString() { return `EventHandler[${this.type}/${this.origin}]`; }

  validate( schema: Schema ) {
    if ( schema.is_subdocument ) {
      if ( this.type === 'create' ) {
        throw new Error( [
          `Invalid subdocument model "${schema.id}"`,
          `subdoc cannot define create hooks`,
        ].join( ' - ' ) );
      }
    }
  }
}
