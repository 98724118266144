import { createBehavior } from '~/core/creators';

export type HasExternalLinkOptions = Record<string, never>;
declare module './declarations' {
  export interface BehaviorConfigs {
    hasExternalLink?: HasExternalLinkOptions | boolean;
  }
  export interface BehaviorOptions {
    hasExternalLink?: HasExternalLinkOptions;
  }
}

createBehavior<HasExternalLinkOptions>( {

  name  : 'hasExternalLink',
  build() {
    return {
      fields : {
        external_link : {
          type      : 'string',
          label     : 'External Link',
          readonly  : true,
          summary   : true,
          optional  : true,
          sortable  : true,
          face      : { type : 'Url' },
        },
      },
    };
  },

} );
