import { JobStep } from './Step';

import type { JobStepOptions } from './Step';

export type JobStepActionOptions = {
  type: 'Action';
  action: ( this: JobStepAction ) => any;
} & JobStepOptions;

declare module '~/modules/jobs/types' {
  export interface JobStepTypes {
    Action: JobStepActionOptions;
  }
}

export class JobStepAction extends JobStep {
}

JobStepAction.initialize( {
  id        : 'JobStep.Action',
  inherit   : 'JobStep',
}, BUILD.isServer && {
  methods : class extends JobStepAction {

    async execute() {
      return await this.action();
    }

  },
} );
