import { JobStep } from './Step';

import type { Job } from '../SYSTEM.Job';
import type { JobStepOptions } from './Step';

export type JobStepJobOptions = {
  type: 'Job';
  action: ( this: JobStepJob ) => any;
} & JobStepOptions;

declare module '~/modules/jobs/types' {
  export interface JobStepTypes {
    Job: JobStepJobOptions;
  }
}

export class JobStepJob extends JobStep {
  declare waiting_for_job: Job;
}

JobStepJob.initialize( {
  id        : 'JobStep.Job',
  inherit   : 'JobStep',
  fields    : {
    waiting_for_job : {
      type      : 'link',
      label     : 'Waiting for Job',
      help      : 'The job that this step represents',
      form      : false,
      summary   : true,
      model     : 'SYSTEM.Job',
      optional  : false,
    },
  },
}, BUILD.isServer && {
  methods : class extends JobStepJob {

    async execute() {
      const job = await this.waiting_for_job.reload();

      if ( job.isSuccess() ) return;
      if ( job.isFailure() ) {
        if ( this.ignore_errors ) return;
        this.fail( `Subjob ${job._id} failed` );
      }
      await job.bumpPriorityIfLower( this.job.priority );
      this.wait( `Waiting for job ${job._id}` );
    }

  },
  events  : {
    async beforeSave() {
      if ( ! this.label ) {
        this.label = `Wait for subjob ${this.waiting_for_job.getFullName()}`;
      }
    },
  },
} );
