import _ from 'lodash';
import { mkdebug } from '@ssp/utils';

import type { ResourceLoadOptions } from '../resource/resource-types';

const debug = mkdebug( 'ssp:database:core:watchable' );

const watchersMap = new WeakMap();
const TARGET = Symbol( 'Watchable#target' );

function isProxy( obj ) { return !! obj[ TARGET ]; }
function unwrap( obj ) { return _.get( obj, TARGET, obj ); }

function watchers( instance ) {
  instance = unwrap( instance );
  if ( ! watchersMap.has( instance ) ) watchersMap.set( instance, new Set() );
  return watchersMap.get( instance );
}

export const watchableIsProxy = isProxy;
export const watchableUnwrap = unwrap;

export interface WatchOptions extends ResourceLoadOptions {
  /** A label for the watcher, mostly for debug purposes. */
  label?: string;
  /**
   * If loading the resource produces an error, just attach it to the
   * resource rather than returning it.
   */
  safe?: boolean;
  /**
   * Child watchables, for when you need to be notified about changes
   * to multiple things.
   */
  children?: Watchable[];
  /**
   * If true then we register a subscription with the origins manager to
   * ensure that we get notified of updates to this resource, even if we
   * normally wouldn't.
   */
  subscribe?: boolean;
}

export interface WatchableSubscription {
  label?: string;
  callback: ( item: Watchable ) => void;
  safe: boolean;
  children: Watchable[];
  subscribe: boolean;
}

export class Watchable {

  static isProxy = isProxy;
  static unwrap = unwrap;

  /**
   * Watch for changes to the underlying watchable item.
   *
   * @param callback - Callback to call with updated items.
   */
  watch(
    callback: ( item: Watchable ) => void,
    options: WatchOptions = {},
  ) {
    if ( typeof callback !== 'function' ) {
      throw new Error( 'watch: Listener must be a function' );
    }
    const { label, children = [], safe = false, subscribe = false } = options;
    _.remove( children, child => ! ( child instanceof Watchable ) );
    const cb = () => this.changed();
    const sub: WatchableSubscription = {
      label, callback, safe, subscribe, children,
    };
    const unregister_children = _.invokeMap( children, 'watch', cb );
    const subs = watchers( this );
    debug( 'Adding watcher' + ( sub.label ? ` "${sub.label}"` : '' ) );
    if ( ! subs.size ) this._watchable_start();
    subs.add( sub );
    this._watchable_emit( this._watchable_wrap(), sub );
    return () => {
      debug( 'Removing watcher' + ( sub.label ? ` "${sub.label}"` : '' ) );
      _.each( unregister_children, x => x() );
      subs.delete( sub );
      if ( ! subs.size ) this._watchable_stop();
    };
  }

  hasWatchers() { return watchers( this ).size; }

  _watchable_start() {
    if ( typeof ( this as any ).load === 'function' ) ( this as any ).load();
  }
  _watchable_stop() {
    // no-op - abstract
  }

  _watchable_wrap( rsrc: Watchable = this ) {
    const realResource = unwrap( rsrc );

    if ( isProxy( realResource ) ) throw new Error( `realResource isProxy` );

    return new Proxy( realResource, {
      get( target, property, receiver ) {
        if ( property === TARGET ) return realResource;
        return Reflect.get( target, property, receiver );
      },
    } );
  }

  async changed( rsrc: Watchable = this ) {
    debug( `${this}: emitting watchable changed` );
    return this._watchable_emit( this._watchable_wrap( rsrc ) );
  }

  async errored( error ) {
    debug( `${this}: emitting watchable errored` );
    return this._watchable_emit( error );
  }

  async _watchable_emit( value: this, watcher?: WatchableSubscription ) {
    if ( ! value ) return;
    const set = watchers( this );
    const list = [ ...( watcher ? [ watcher ] : set ) ];
    debug( `${this}: _watchable_emit:`, value, `(${list.length} watchers)` );

    const promises = [];
    if ( _.isError( value ) ) {
      const safe = _.remove( list, 'safe' );
      promises.push( ..._.map( safe, async sub => (
        set.has( sub ) && sub.callback( value )
      ) ) );
    }
    promises.push( ..._.map( list, async sub => (
      set.has( sub ) && sub.callback( value )
    ) ) );
    return Promise.all( promises );
  }

  replaced( replacedWith ) {
    return this.changed( replacedWith );
  }

  // clearWatchers() { watchers( this, name ).clear(); }
  // watcherCount() { return watchers( this ).size; }

}
