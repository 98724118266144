import _ from 'lodash';
import { List } from '../fields/List';
import { getIdentityObject } from '../fields/identity-utils';
import { mkdebug } from '@ssp/utils';

import type { Schema, Model } from '~/core/lib';
import type { Field } from '~/modules/fields/Field';

const debug = mkdebug( 'ssp:database:updates:computations' );

interface Options {
  /** Field name prefix */
  prefix?: string;
}

function prefix( name: string, opts: Options = {} ) {
  return opts.prefix ? `${opts.prefix}.${name}` : name;
}

/** Map over all of the updateable fields from a schema. */
function mapFields( schema: Schema, iteratee ) {
  const fields = schema.getFields( '@all', '-@virtual', '-@computed' );
  return _.compact( _.flatMap( fields, f => iteratee( f, f.name ) ) );
}

/** Compute insert for a schema. */
export function computeSchemaInsert( doc: Model, opts: Options ) {
  const res = mapFields( doc.schema, ( field, name ) => {
    return computeFieldInsert( field, doc[ name ], opts );
  } );
  debug( 'computeSchemaInsert', { doc, opts, res } );
  return res;
}
/** Compute updates for a schema. */
export function computeSchemaUpdate(
  doc: Model, origin: Model, opts: Options,
) {
  return mapFields( doc.schema, ( field, name ) => {
    return computeFieldUpdate( field, doc[ name ], origin[ name ], opts );
  } );
}

/** Compute insert for a field. */
export function computeFieldInsert(
  field: Field, value: any, opts: Options,
) {
  if ( field.virtual || field.computed ) return;
  if ( _.isNil( value ) ) return;
  if ( field.list ) {
    return {
      type   : 'list_add',
      field  : prefix( field.name, opts ),
      values : value.map( a => field.formatValue( a ) ),
    };
  }
  return {
    type  : 'set',
    field : field.name,
    value : field.formatValue( value ),
  };
}

/** Compute update for a field. */
export function computeFieldUpdate(
  field: Field, value: any, origin: any, opts: Options,
) {
  // virtual and computed fields can't be updated
  if ( field.virtual || field.computed ) return;
  // Don't do anything if the field doesn't have a value
  if ( _.isNil( value ) && _.isNil( origin ) ) return;
  // Immutable fields can't be changed if they already have a value
  if ( field.immutable && ! _.isNil( origin ) ) return;
  // If we have a current value, but it's nil, then we're deleting
  if ( _.isNil( value ) ) {
    return {
      type  : 'unset',
      field : prefix( field.name, opts ),
    };
  }
  if ( field.list ) {
    return computeListUpdate( field, value, origin, opts );
  } else if ( field.array ) {
    return computeArrayUpdate( field, value, origin, opts );
  } else if ( field.subdoc ) {
    return computeSubdocUpdate( field, value, origin, opts );
  } else {
    return computeSimpleUpdate( field, value, origin, opts );
  }
}

function set_field( field: Field, value: any, opts: Options ) {
  const name = prefix( field.name, opts );
  value = field.formatValue( value );
  // If the value is null then clear it instead of putting a null in the DB
  if ( _.isNull( value ) ) return { type : 'unset', field : name };
  return { type : 'set', field : name, value };
}

function computeSimpleUpdate(
  field: Field, value: any, origin: any, opts: Options,
) {
  // if they are equal there is nothing to do
  if ( field.equals( value, origin ) ) return;
  return set_field( field, value, opts );
}

function computeSubdocUpdate(
  field: Field, value: any, origin: any, opts: Options,
) {
  // if they are equal there is nothing to do
  if ( origin && value && field.equals( value, origin ) ) return;

  // If we had a value and now we have `null` then we're explicitly
  // clearing the subdoc
  if ( value === null ) {
    return { type : 'unset', field : prefix( field.name, opts ) };
  }

  // If the field is a link field, or didn't previously have a value,
  // or was configured to update only by replacing the entire value,
  // then we force it to use 'set' instead of computing subdoc updates
  const replace = field.link
    || ( value && ! origin )
    || field.schema.config.updates.mode === 'replace';
  if ( replace ) return set_field( field, value, opts );

  const res = computeSchemaUpdate( value, origin, {
    ...opts,
    prefix : prefix( field.name, opts ),
  } );
  debug( 'computeSubdocUpdate', { field, value, origin, opts, res } );
  return res;
}

function computeListUpdate(
  field: Field, value: any, origin: any, opts: Options,
) {
  if ( ! ( value instanceof List ) ) {
    throw new TypeError( `Cannot computeListUpdate with non-list value` );
  }
  if ( ! ( origin instanceof List ) ) {
    throw new TypeError( `Cannot computeListUpdate with non-list origin` );
  }

  const origin_keys = Array.from( origin.keys() );
  const value_keys = Array.from( value.keys() );
  const added_keys = value_keys
    .filter( key => ! origin.hasKey( key ) );
  const removed_keys = origin_keys
    .filter( key => ! value.hasKey( key ) );
  const other_keys = _.uniq( [
    ...value_keys.filter( key => origin.hasKey( key ) ),
    ...origin_keys.filter( key => value.hasKey( key ) ),
  ] );

  // For lists we use the same 'mode === "replace"' logic that we do
  // for subdocs, but it applies to the list entries rather than to
  // the list itself
  const replace = field.link
    || field.schema.config.updates.mode === 'replace';
  if ( replace ) {
    // If we're replacing then we just copy the "modified" list to the
    // "add" and "remove" lists so that we remove the old version then
    // add the new version.
    removed_keys.push( ...other_keys );
    added_keys.push( ...other_keys );
    other_keys.length = 0;
  }

  const res = [];
  if ( removed_keys.length ) {
    res.push( {
      type   : 'list_remove',
      field  : prefix( field.name, opts ),
      values : removed_keys.map( x => getIdentityObject( origin.getKey( x ) ) ),
    } );
  }
  if ( added_keys.length ) {
    res.push( {
      type   : 'list_add',
      field  : prefix( field.name, opts ),
      values : added_keys.map( x => field.formatValue( value.getKey( x ) ) ),
    } );
  }
  if ( other_keys.length ) {
    other_keys.forEach( key => {
      const was = origin.getKey( key );
      const now = value.getKey( key );
      const changes = computeDifference( was, now );
      if ( _.isEmpty( changes ) ) return;
      res.push( {
        type   : 'list_modify',
        field  : prefix( field.name, opts ),
        match  : getIdentityObject( was ),
        changes,
      } );
    } );
  }
  debug( 'computeListUpdate', { field, value, origin, opts, res } );
  return res;
}

// Ideally we would just be using `computeSchemaUpdate` here, but
// Mongo doesn't support the regular set of update operations for
// array elements, just merging in update objects is the best we can
// do right now.
function computeDifference( was, now ) {
  if ( was && now ) {
    if ( was.schema.top_schema !== now.schema.top_schema ) {
      throw new TypeError( [
        'Cannot computeDifference with incompatible schemas:',
        `(${was.schema?.id} != ${now.schema?.id})`,
      ].join( ' ' ) );
    }
  }
  const schema = now.schema || was.schema;
  // const config = schema.config.updates;
  const fields = schema.getFields( '@all', '-@virtual', '-@computed' );

  const diffs = {};
  fields.forEach( f => {
    const origin = was[ f.name ];
    const value = now[ f.name ];
    // if they are equal there is nothing to do
    if ( origin && value && f.equals( origin, value ) ) return;
    if ( value === null && origin ) {
      // If we had a value and now we have `null` then we're
      // explicitly clearing the field
      diffs[ f.name ] = null;
    }
    diffs[ f.name ] = now[ f.name ];
  } );
  return diffs;
}

function computeArrayUpdate(
  field: Field, value: any[], origin: any[], opts: Options,
) {
  const haves = new Set( origin );
  const wants = new Set( value );
  const adds = [];
  const dels = [];
  _.each( _.uniq( [ ...wants, ...haves ] ), val => {
    if ( wants.has( val ) && haves.has( val ) ) return;
    if ( haves.has( val ) ) dels.push( val );
    if ( wants.has( val ) ) adds.push( val );
  } );
  const res = [];
  if ( dels.length ) {
    res.push( {
      type   : 'array_remove',
      field  : prefix( field.name, opts ),
      values : _.map( dels, val => field.formatValue( val ) ),
    } );
  }
  if ( adds.length ) {
    res.push( {
      type   : 'array_add',
      field  : prefix( field.name, opts ),
      values : _.map( adds, val => field.formatValue( val ) ),
    } );
  }
  debug( 'computeArrayUpdate', { field, value, origin, opts, res } );
  return res;
}
