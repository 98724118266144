import _ from 'lodash';
import { isFunction, TopoSort, invariant, squish } from '@ssp/utils';

import type { SchemaConfig } from './SchemaConfig';
import type { ModelDefinition } from '~/modules/declarations';
import type { Definitions } from './types';

const registry = new TopoSort<Module>( { getId : item => item.name } );

export type ModuleDefinition = {
  name: string;

  parse: ( data: ModelDefinition, origin: string ) => ModelDefinition;
  merge?: ( ...data: ModelDefinition[] ) => ModelDefinition;
  apply: ( data: ModelDefinition, config: SchemaConfig ) => void;
  finish?: ( config: SchemaConfig ) => void;

  before?: string | string[];
  after?: string | string[];
  /** Set to true if this module should apply to ServiceResource also. */
  services?: boolean;

  [key: string]: any;
};

export class Module {

  name: string;
  props?: string[];
  config?: ModelDefinition;
  services: boolean = false;

  static finished = false;

  constructor( opts: ModuleDefinition ) {
    _.assign( this, opts );
  }

  static create( config: ModuleDefinition ) {
    const mod = new Module( config );
    registry.add( mod );
    /*
    registry.dot( {
      file : 'module-order.gv', label : 'module_order',
      implied : true,
    } );
    */
    return mod;
  }

  static map( iteratee ) {
    return registry.items.map( mod => iteratee( mod ) );
  }
  static collect<T=unknown>( iteratee: ( mod: Module ) => T ) {
    const res = {};
    for ( const mod of registry.items ) {
      res[ mod.name ] = iteratee( mod );
    }
    return res;
  }

  hasDefaultProps() {
    return this.props.length === 1 && this.props[ 0 ] === this.name;
  }

  declare parse: ( data: ModelDefinition, origin: string ) => ModelDefinition;

  merge( ...data ) {
    const values = _.compact( _.flatMap( data, this.name ) );
    if ( ! values.length ) return;
    const res = _.merge( {}, ...values );
    if ( _.isEmpty( res ) ) return;
    return { [this.name] : res };
  }

  apply( data, config ) {
    const val = data[ this.name ];
    if ( _.isEmpty( val ) ) return;
    config.add( { [this.name] : val } );
  }

  declare finish?: ( config: SchemaConfig ) => void;

  static parse(
    defs: Definitions,
    origin: string,
  ): ModelDefinition | undefined {
    return this.merge( ...squish( [ defs ] ).flatMap( def => {
      invariant( _.isPlainObject( def ) );
      return registry.items.map( mod => mod.parse( def, origin ) );
    } ) );
  }

  static merge( ...defs: ModelDefinition[] ): ModelDefinition {
    const data: ModelDefinition = {};
    for ( const mod of registry.items ) {
      if ( mod.name === 'events' && mod.merge === this.merge ) {
        throw new Error( 'EVENT NO MERGY' );
      }
      _.assign( data, mod.merge( ...defs ) );
    }
    return data;
    // return _.omitBy( data, _.isEmpty );
  }

  static apply( data: ModelDefinition, config: SchemaConfig ) {
    this.collect( mod => {
      if ( config.schema.is_service && ! mod.services ) return;
      if ( mod.can( 'apply' ) ) return mod.apply( data, config );
      throw new Error( `Module ${mod.name} has no apply method!` );
    } );
  }

  static finish( config: SchemaConfig ) {
    this.collect( mod => {
      if ( ! mod.can( 'finish' ) ) return;
      return mod.finish( config );
    } );
  }

  can( method ) { return isFunction( this[ method ] ); }

}
