import { Module } from '~/core/lib/Module';

import { updatesConfigSchema } from './types';

Module.create( {
  name     : 'updates',
  services : false,

  parse( data ) {
    return { updates : updatesConfigSchema.parse( data.updates || {} ) };
  },

  apply( data, config ) {
    config.add( { updates : data.updates } );
  },

} );
