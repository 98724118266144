import _ from 'lodash';
import { invariant } from '@ssp/utils';

import { Module } from '~/core/lib/Module';
import { Behavior } from '~/core/lib/Behavior';

Module.create( {
  name     : 'behaviors',
  services : false,

  parse( data, origin ) {
    if ( _.isEmpty( data.behaviors ) ) return;
    const behaviors = _.mapValues( data.behaviors, ( config, name ) => ( {
      ...( config === true ? {} : config ),
      name, origin,
    } ) );
    if ( _.isEmpty( behaviors ) ) return;
    return { behaviors };
  },

  apply( data, config ) {
    if ( _.isEmpty( data.behaviors ) ) return;
    const behaviors = _.mapValues( data.behaviors, conf => {
      invariant( typeof conf === 'object' );
      const Class = Behavior.get( ( conf as $TSFixMe ).name );
      return new Class( conf );
    } );
    config.add( { behaviors } );
  },

  /*
  finish( config ) {
    log.debug( `FINISH BEHAVIOR:`, config.behaviors );
    _.each( config.behaviors, behavior => {
      const conf = behavior.build( config );
      config.extend( conf, `${behavior.name} (from ${behavior.origin})` );
    } );
  },
  */

} );
