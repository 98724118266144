import _ from 'lodash';
import { createNamedClass, hideProps } from '@ssp/utils';
import { Module } from '~/core/lib/Module';
import { ResultSet } from './ResultSet';
import { ListResultSet } from './ListResultSet';

Module.create( {
  name     : 'resultset',
  services : false,

  parse( data, origin ) {
    const res: any = {};
    if ( ! _.isEmpty( data.resultset ) ) res.resultset = data.resultset;
    if ( ! _.isEmpty( data.queries ) ) {
      const queries = _.omitBy( _.mapValues( data.queries, ( opts, name ) => {
        if ( _.isNil( opts ) ) return;
        if ( _.isFunction( opts ) ) return { name, handler : opts, origin };
        if ( typeof opts === 'object' ) {
          return { name, ...opts, origin };
        }
      } ), _.isNil );
      if ( ! _.isEmpty( queries ) ) res.queries = queries;
    }
    if ( ! _.isEmpty( data.filters ) ) {
      const filters = _.mapValues( data.filters, ( conf, id ) => ( {
        id, ...conf, origin,
      } ) );
      if ( ! _.isEmpty( filters ) ) res.filters = filters;
    }
    if ( _.isEmpty( res ) ) return;
    return res;
  },

  merge( ...data ) {
    const res = _.omitBy( {
      resultset : _.assign( {}, ..._.flatMap( data, 'resultset' ) ),
      queries : _.assign( {}, ..._.flatMap( data, 'queries' ) ),
      filters : _.assign( {}, ..._.flatMap( data, 'filters' ) ),
    }, _.isEmpty );
    if ( _.isEmpty( res ) ) return;
    return res;
  },

  apply( data, config ) {
    const { resultset, queries, filters } = data;
    if ( ! _.isEmpty( resultset ) ) config.add( { resultset } );
    if ( ! _.isEmpty( queries ) ) config.add( { queries } );
    if ( ! _.isEmpty( filters ) ) config.add( { filters } );
  },

  finish( config ) {
    hideProps( config.schema, { ResultSet : getResultSet( config ) } );
  },

} );

function getResultSet( config ) {
  const { id, resultset, schema, queries } = config;
  const RS = createNamedClass(
    id.split( '.' ).join( '_' ),
    config.schema.is_subdocument ? ListResultSet : ResultSet,
  );
  hideProps( RS, { config : Object.freeze( resultset ), schema } );

  const descs: PropertyDescriptorMap = _.mapValues( queries, ( _x, name ) => {
    // eslint-disable-next-line func-names
    const func = function( ...args ) {
      return this.extendWithQuery( name, ...args );
    };
    return {
      value         : func,
      enumerable    : true,
      writable      : false,
      configurable  : true,
    };
  } );
  Object.defineProperties( RS.prototype, descs );
  return RS;
}
