import { createResource } from '~/core/creators';

/* ex. group from memberOf info
 * name: CN=BLUE_CAC_Engineering
 * groups: OU=CSN Groups
 */
createResource( {
  id          : 'CSNAD.Group',
  name        : 'CSNAD Group',
  icon        : 'far:sitemap',
  attachable  : [ 'SSP.Project' ],
  hideZero    : true,
  fields      : {
    dn              : {
      type        : 'string',
      label       : 'Distinguished Name',
      quicksearch : true,
      index       : true,
      createonly  : true,
      validators  : { dn : true },
      form        : {
        help      : [
          `This is an advanced feature.`,
          `The format of the distinguished name is:`,
          `CN=My Group,OU=CSN Groups,DC=csn,DC=internal.`,
          `If you don't know the DN of the group you would like to link to,`,
          `please open a support ticket to seek assistance.`,
        ].join( ' ' ),
      },
    },
    name            : {
      type        : 'string',
      label       : 'Name',
      quicksearch : true,
      readonly    : true,
    },
    subgroup_actions : {
      type        : 'string',
      label       : 'Options',
      optional    : true,
      form        : {
        type    : 'Select',
        options : [ 'ignore', 'expand' ],
        help    : [
          'Choosing \'expand\' will convert any subgroups or ',
          'distribution lists that are listed as members ',
          'into their individual members otherwise they will be ignored.',
        ],
      },
      default     : 'ignore',
    },
  },
  actions : {
    edit         : false,
  },
  behaviors : {
    hasMembers      : {
      userType      : 'CSNAD.User',
      withService   : true,
      readonly      : true,
    },
  },
  faces         : {
    'list.summary' : [
      {
        layout : {
          type       : 'ItemsBetweenTitleAndIcons',
        },
        fields : [ '-@all' ],
      },
      {
        layout : 'ItemsAndBadges',
        fields  : [ 'dn', '@badge' ],
      },
    ],
  },
} );
