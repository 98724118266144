import _ from 'lodash';
import { Definitions, Schema } from '~/core';
import type { ModelDefinition } from '~/modules/declarations';

import type { SchemaConfig } from './SchemaConfig';
import type { BehaviorConfigs } from '~/behaviors/declarations';

export interface Behavior {
  build( config: SchemaConfig ): ModelDefinition;
}
export class Behavior {

  static registry = {};

  static get( name: string ) {
    if ( this.registry[ name ] ) return this.registry[ name ];
    throw new Error( `Unknown Behavior ${name}` );
  }

  static has( name: string ) { return !! this.registry[ name ]; }

  declare name: string;
  declare options: Record<string, any>;

  get components() { return this.components; }
  get models() {
    return Schema.models( s => s.hasBehavior( this.name ) );
  }

  name: string;
  origin: string;

  constructor( options: Record<string, any> ) {
    const { name, origin, ...opts } = options;
    this.name = name;
    this.origin = origin;
    this.options = _.cloneDeep( this.options );
    _.assign( this.options, opts );
  }

}

export type BehaviorDefinition<
  TOptions extends Record<string, any> = Record<string, unknown>,
> = {
  name: string;
  options?: TOptions;
  build?: ( this: Behavior, config: SchemaConfig ) => Definitions;
  model?: Definitions;
  [key: string]: any;
};

declare module '~/modules/declarations' {
  export interface ModelDefinition {
    behaviors?: { [K in keyof BehaviorConfigs]: BehaviorConfigs[K]; };
  }
  export interface SchemaConfig {
    behaviors: Record<string, Behavior>;
  }
}
