import { Resource } from './Resource';

export class ServiceResource extends Resource {

  static toLinkData() {
    return { type : this.schema.id, name : this.schema.name };
  }

  constructor() {
    super( null, null );
    throw new Error( `Cannot create instance of ServiceResource` );
  }
}
