import _ from 'lodash';
import { idgen } from '@ssp/utils';
import { getModel } from '~/core/schemas';

export class FaceSection {
  // For easy use as a react key
  id = idgen();
  // layout;
  // header;
  // footer;
  // fields;
  // title;
  // subtitle;
  constructor( opts={}, face ) {
    _.assign( this, opts, { face } );
    _.defaults( this, { layout : 'PropTable' } );
    if ( _.isString( this.layout ) ) this.layout = { type : this.layout };
    [ 'layout', 'face', 'fields' ].forEach( x => {
      if ( ! this[ x ] ) throw new Error( `FaceSection requires ${x}` );
    } );
  }

  get model() { return this.face.model; }
  get schema() { return this.face.schema; }

  buildFieldSet() {
    return this.schema.getFieldSet( this.fields );
  }

  get fieldset() {
    return this._fieldset || ( this._fieldset = this.buildFieldSet() );
  }

  buildItems() {
    const keys = [ 'name', 'label', 'help', 'badge' ];
    return this.fieldset.getFields().map( field => _.defaults(
      {},
      field.face,
      _.pick( field, keys ),
      field.type.face,
      _.pick( field.type, keys ),
      { type : 'Unknown' },
    ) );
  }

  get items() {
    return this._items || ( this._items = this.buildItems() );
  }

  get names() { return this.fieldset.getNames(); }

}

export class Face {

  // component;
  // type;
  // name;

  // label;
  // sections;

  constructor( opts={} ) {
    const {
      // These are metadata that all faces must have
      component, type, name, origin,
      // These are some optional properties that might be on the face
      label,
      // More complex faces may define multiple sections
      sections,
      // Simpler faces only have one section
      ...section
    } = opts;

    _.assign( this, { component, type, name, label } );
    [ 'component', 'name', 'type' ].forEach( x => {
      if ( ! this[ x ] ) throw new Error( `Face must have a ${x}` );
    } );

    this.sections = [];
    this.addSection( section );
    _.each( sections, sec => this.addSection( sec ) );
  }

  addSection( section ) {
    if ( _.isEmpty( section ) ) return;
    if ( _.isString( section ) ) section = { fields : [ section ] };
    if ( _.isArray( section ) ) section = { fields : section };
    this.sections.push( new FaceSection( section, this ) );
  }

  get name() { return this._name; }
  set name( val ) {
    if ( _.isString( val ) && val.includes( '.' ) ) {
      const x = val.split( '.' );
      this._type = x[ 0 ];
      this._name = x[ 1 ];
    } else {
      this._name = val;
    }
  }
  get type() { return this._type; }
  set type( val ) {
    if ( _.isString( val ) && val.includes( '.' ) ) {
      const x = val.split( '.' );
      this._type = x[ 0 ];
      this._name = x[ 1 ];
    } else {
      this._type = val;
    }
  }

  get fullname() { return [ this.type, this.name ].join( '.' ); }
  get fqname() { return [ this.component, this.fullname ].join( '.' ); }

  get model() { return getModel( this.component ); }
  get schema() { return this.model.schema; }

  get section() {
    if ( this.sections.length !== 1 ) return;
    return this.sections[ 0 ];
  }

  find( filter ) { return _.find( this.sections, filter ); }
  filter( filter ) { return _.filter( this.sections, filter ); }
  reject( filter ) { return _.reject( this.sections, filter ); }

  toJSON() {
    return _.mapKeys( this, ( val, key ) => key.replace( /^_/u, '' ) );
  }

}

Face.Section = FaceSection;
