import { isUser } from '~/utils/types';
import { createResource } from '~/core/creators';

createResource( {
  id               : 'Jira.User',
  name             : 'Jira User',
  attachable       : [ 'SSP.User' ],
  icon             : 'fab:jira',
  service_id_field : 'jira_id',
  fields      : {
    // jira_id is the 'key' field in Jira.
    // Prior to Jira v8.5 the 'key' field was the 'username'
    // it then changed to an actual id following 'JIRAUSER10100' format.
    jira_id : {
      type        : 'id',
      label       : 'Jira ID',
      summary     : true,
      index       : true,
      unique      : true,
      readonly    : true,
      optional    : true,
      immutable   : true,
    },
    username : {
      type        : 'string',
      label       : 'Username',
      readonly    : true,
      summary     : true,
      index       : true,
      unique      : true,
      quicksearch : true,
      identifier  : true,
    },
    name : {
      type        : 'string',
      label       : 'Display Name',
      summary     : true,
      index       : true,
      unique      : false,
      optional    : true,
      quicksearch : true,
    },
  },
  behaviors : {
    hasEmail            : {},
    hasDisabled         : { canAutoDisable : true },
    hasPrimaryAccount   : {},
    hasExternalLink     : {},
  },
  methods : class {

    static createFrom( ssp_user ) {
      if ( ! isUser( ssp_user ) ) {
        throw new Error( `createFrom must be called with an SSP.User` );
      }
      return this.insert( {
        user_id       : ssp_user._id,
        name          : ssp_user.name,
        username      : ssp_user.email.toLowerCase(),
        email         : ssp_user.email,
      } );
    }

  },
  faces         : {
    'card.summary' : [
      {
        layout : 'Badges',
        fields : [ '@badge' ],
      },
      {
        layout : 'LabelsAndValues',
        fields : [ 'email', 'username', 'jira_id' ],
      },
    ],
    'list.summary' : [
      {
        layout : {
          type       : 'ItemsBetweenTitleAndIcons',
          iconize    : [ {
            fieldName : 'jira_id',
            icon      : 'fad:id-badge',
          } ],
        },
        fields : [ '-@all' ],
      },
      {
        layout : 'ItemsAndBadges',
        fields  : [ 'email', '@badge' ],
      },
    ],
  },
} );
