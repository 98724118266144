import { isUser } from '~/utils/types';
import { createResource } from '~/core/creators';

createResource( {
  id               : 'Confluence.User',
  name             : 'Confluence User',
  icon             : 'fab:confluence',
  attachable       : [ 'SSP.User' ],
  service_id_field : 'confluence_id',

  fields      : {
    confluence_id   : {
      type        : 'id',
      label       : 'Confluence User Key',
      summary     : true,
      index       : true,
      unique      : true,
      readonly    : true,
      optional    : true,
      quicksearch : true,
      immutable   : true,
    },
    name          : {
      type       : 'string',
      label      : 'Name',
      summary    : true,
      index      : true,
      optional   : true,
      quicksearch : true,
    },
    username  : {
      type        : 'string',
      label       : 'Username',
      summary     : true,
      index       : true,
      unique      : true,
      optional    : true,
      quicksearch : true,
      identifier  : true,
    },
  },

  behaviors : {
    hasExternalLink     : {},
    hasEmail            : {
      useMultipleEmails   : false,
    },
    hasDisabled         : {},
    hasPrimaryAccount   : {},
  },

  methods : class {

    static createFrom( ssp_user ) {
      if ( ! isUser( ssp_user ) ) {
        throw new Error( `createFrom must be called with an SSP.User` );
      }
      return this.insert( {
        user_id       : ssp_user._id,
        name          : ssp_user.name,
        username      : ssp_user.email.toLowerCase(),
        email         : ssp_user.email,
      } );
    }

  },

  faces         : {
    'card.summary' : [
      {
        layout : 'Badges',
        fields : [ '@badge' ],
      },
      {
        layout : 'LabelsAndValues',
        fields : [ 'email', 'username', 'confluence_id' ],
      },
    ],
  },
} );
