import _ from 'lodash';
import {
  hideProps, mkdebug, NotFound, createRefUrl, DatabaseBrokerError,
} from '@ssp/utils';

import { TransportResponse } from '~/lib/TransportResponse';
import { getOrigins, Origin, Version } from '~/modules/origins';
import { Updates } from '~/modules/updates/Updates';

import { getDataMap, updateQuietly } from './data-utils';
import { isResource, hasMongoOperators } from '~/utils';

import type { Resource } from '~/core/resource/Resource';
import type { Schema } from '~/core/lib/Schema';

const debug = mkdebug( 'ssp:database:broker:broker' );

let counter = 0;

export type BrokerLoadOptions = {
  /**
   * Put the broker into live mode, so you get live updates for
   * changes after loading.
   */
  live?: boolean;
  /** Load even if this resource is already loaded. */
  reload?: boolean;
  /**
   * Allow returning a version from the cache and refreshing it in
   * the background.
   */
  cache?: boolean;
  /**
   * If resource is not found then insert a new one. If this is an
   * object then it's contents will be merged into the resource prior
   * to inserting.
   */
  insert?: boolean | Record<string, any>;
  /** Return exceptions, rather than throwing them. */
  safe?: boolean;
  /** Also load resources in link fields? */
  links?: boolean;
  /** A comment for Mongo debugging */
  comment?: string;
  /** Unlock the resource for loading? */
  unlock?: boolean;
};

/**
 * This class mediates the storage of data for a single resource
 * instance, ensuring that stores are synchronized as needed and
 * keeping track of changes for computing change documents.
 */
export class Broker {

  broker_id = `broker-${counter++}`;

  debug( ...args ) { debug( this.toString(), ...args ); }

  /** The resource this broker is managing data for. */
  resource: Resource;
  schema: Schema;

  /** The Origin instance for this resource. */
  _origin?: Origin;
  /**
   * The Version instance for the version of the resource that this
   * broker holds.
   */
  _version?: Version;
  /**
   * If the broker receives a live update while it's locked and can't
   * be updated, then the new Version instance will be stored here.
   * This is used to drive things like the "Outdated" sidebar
   * notification.
   */
  outdated?: Version;

  get version() { return this._version; }
  set version( version ) {
    this.debug( 'Setting version', version );
    if ( this._version ) {
      this.debug(
        'Replacing version', this._version.version, 'with', version.version,
      );
    }
    this._version = version;
    const data = version.getData();
    if ( data ) {
      this.debug( 'updating with data', data );
      const updated = this.updateQuietly( data );
      this.debug( '  -> updated?', updated );
      this.unlock();
      if ( updated || this.outdated ) {
        this.debug( 'Triggering change notification' );
        delete this.outdated;
        this.changed();
      }
    } else {
      this.partials( {
        _id           : version.id,
        display_name  : version.origin.name,
      } );
    }
  }

  get origin() { return this._origin; }
  set origin( origin ) {
    if ( this._origin ) {
      if ( this._origin === origin ) return;
      throw new DatabaseBrokerError( {
        message : `Can't change broker origin`,
        tags    : { schema : this.schema.id, property : 'Broker#origin' },
        data    : { origin },
      } );
    }
    hideProps( this, { _origin : origin } );
    this.partials( {
      _id           : origin.id,
      display_name  : origin.name,
    } );
    this.watch();
  }

  /** The primary id for the associated resource. */
  get id() { return this.origin?.id || this.resource?._id; }
  set id( id ) {
    this.origin = getOrigins().get( this.type, id );
    this.partials( { _id : id } );
  }

  _ident?: string;
  get ident() {
    if ( this.id ) return;
    if ( this._ident ) return this._ident;
    for ( const field of this.schema.getFieldNames( '@identifier' ) ) {
      if ( this.resource[ field ] ) return this.resource[ field ];
    }
  }
  set ident( ident ) {
    if ( _.isNil( ident ) ) return;
    const valid = _.isString( ident )
      || ( _.isArray( ident ) && _.every( ident, _.isString ) );
    if ( ! valid ) {
      throw new DatabaseBrokerError( {
        message : `ident must be a string or array of strings`,
        tags    : { schema : this.schema.id, property : 'Broker#ident' },
        data    : { ident },
      } );
    }
    this._ident = ident;
  }

  _query?: any;
  get query() { return this._query; }
  set query( query ) {
    this._query = query;
    if ( _.isPlainObject( query ) && ! this.origin ) {
      if ( ! hasMongoOperators( query ) ) this.partials( query );
    }
  }

  get type() { return _.get( this.schema, 'id' ); }
  get model() { return _.get( this.schema, 'model' ); }

  /** The reference url for this resource. */
  get ref_url() {
    if ( this.origin ) return this.origin.ref_url;
    if ( this.ident ) {
      return createRefUrl( {
        type  : this.type,
        ident : this.ident,
        name  : this.resource.findDisplayName(),
      } );
    }
    return;
  }

  /**
   * Set to true if this resource has been resolved and has a valid
   * type and id now.
   */
  get resolved() { return !! this.origin?.id; }

  _loading?: Promise<any>;
  /**
   * This gets set to true while the resource is being loaded from the
   * backend.
   */
  get loading() { return !! this._loading; }

  /**
   * This gets set to true once the resource has been loaded from the
   * backend.
   */
  loaded = false;

  /**
   * This gets set to true while there is an in-flight update in
   * progress.
   */
  updating = false;

  error = null;

  /**
   * This gets set to `true` when the resource is modified externally.
   * This "locks" the record and prevents updates received from the
   * server from being applied.  This way a resource being updated
   * won't get it's changes overwritten if it changes on the server.
   * Can be set to a boolean, or a string indicating the reason it was
   * locked.
   */
  #locked?: string;
  get locked() { return this.#locked; }

  _immutable: boolean = false;
  get immutable() { return this._immutable || false; }
  set immutable( immutable ) {
    if ( immutable && ! this.version ) {
      throw new DatabaseBrokerError( {
        message : `Cannot make a Broker immutable without a Version`,
        tags    : { schema : this.schema.id, property : 'Broker#immutable' },
      } );
    }
    this._immutable = immutable;
    if ( immutable ) {
      this.live = false;
      this.lock( 'Immutable' );
    }
  }

  _live?: boolean;
  get live() { return this._live; }
  set live( live ) {
    if ( live ) {
      if ( this.immutable ) {
        throw new DatabaseBrokerError( {
          message : `Cannot make an immutable Broker live`,
          tags    : { schema : this.schema.id, property : 'Broker#live' },
        } );
      }
      this._live = true;
      this.watch();
    } else {
      this._live = false;
      this.unwatch();
    }
  }

  #watcher?: () => void;
  get watching() { return !! this.#watcher; }
  watch() {
    if ( this.watching ) {
      this.debug( 'Not starting watch: already watching' );
      return;
    }
    if ( ! this.live ) {
      this.debug( 'Not starting watch: not marked live' );
      return;
    }
    if ( ! this.origin ) {
      this.debug( 'Not starting watch: no origin' );
      return;
    }
    this.debug( 'Starting watch' );

    // A live-update event is emitted when the origin processes
    // a live-update that refers to this resource.
    this.#watcher = this.origin.watch( this.processLiveUpdate.bind( this ) );
  }
  unwatch() {
    if ( ! this.#watcher ) return;
    this.debug( 'Stopping watch' );
    this.#watcher();
  }

  rewatch() { this.unwatch(); this.watch(); }

  constructor( resource ) {
    if ( ! isResource( resource ) ) {
      throw new DatabaseBrokerError( {
        message : 'Broker requires resource',
        tags    : { schema : this.schema.id, method : 'Broker.constructor' },
        data    : { resource },
      } );
    }
    hideProps( this, { resource, schema : resource.schema } );
    getDataMap( resource ).attachBroker( this );
  }

  partials( data ) {
    if ( this.loaded || ! _.isPlainObject( data ) ) return;
    data = _.omitBy( data, val => {
      if ( _.isBoolean( val ) ) return;
      return _.isEmpty( val );
    } );
    this.debug( 'Applying partials', data );
    if ( this.updateQuietly( data, { partial : true } ) ) this.changed();
  }

  lock( reason: string = 'unknown' ) {
    if ( this.#locked && this.#locked !== 'unknown' ) {
      this.#locked += ';' + reason;
    } else {
      this.#locked = reason;
    }
    this.changed();
  }
  unlock() {
    if ( this.immutable ) {
      throw new DatabaseBrokerError( {
        message : `Cannot unlock an immutable broker`,
        tags    : { schema : this.schema.id, method : 'Broker#unlock' },
      } );
    }
    this.#locked = undefined;
  }

  changed() {
    if ( this.resource._id && ! this.origin ) this.id = this.resource._id;
    return this.resource.changed();
  }

  /**
   * Update resource data without trigger change notifications.
   *
   * @param data - The data to update.
   * @param [options] - Options object.
   * @param [options.partial=true] - Allow partial updates.
   * If true then only fields specified in the data will be updated,
   * if false then any fields not specified in the data will be
   * cleared.
   */
  updateQuietly( data, options={} ) {
    const { partial = true } = options;
    const res = updateQuietly( this.resource, data, { partial } );
    if ( res && this.resource._id && ! this.origin ) {
      this.id = this.resource._id;
    }
    return res;
  }

  async updateFromResponse(
    res: TransportResponse, opts: BrokerLoadOptions = {},
  ) {
    if ( ! ( res instanceof TransportResponse ) ) {
      throw new DatabaseBrokerError( {
        message : `Invalid argument for updateFromResponse: ${res}`,
        tags    : {
          schema : this.schema.id, method : 'Broker#updateFromResponse',
        },
        data    : { response : res },
      } );
    }
    res.fatalize();
    let changed = true;
    if ( res._version_instance ) {
      this.updateFromVersion( res._version_instance, { unlock : true } );
    } else if ( res.ref ) {
      const { id, version } = res.ref;
      if ( id === this.id && version === this.version?.version ) {
        changed = false;
      } else {
        const origin = await getOrigins().get( res.ref.type, res.ref.id );
        const vers = await origin.getCachedVersion();
        this.updateFromVersion( vers, { unlock : true } );
      }
    }
    this.loaded = true;
    delete this._loading;
    if ( opts.links ) await this.loadLinks( opts );
    if ( changed ) this.changed();
    return res;
  }

  async loadLinks( opts = {} ) {
    const rsrcs = this.resource.schema
      .getFieldNames( '@link' )
      .map( f => this.resource[f] )
      .filter( isResource );
    if ( ! rsrcs.length ) return;
    return Promise.all( _.invokeMap(
      rsrcs,
      'load',
      { ...opts, links : false },
    ) );
  }

  updateFromVersion( version, opts: BrokerLoadOptions = {} ) {
    this.debug( 'updateFromVersion', version, opts );
    if ( ! ( version instanceof Version ) ) {
      throw new DatabaseBrokerError( {
        message : `Broker.version must be instance of Version`,
        tags    : {
          schema : this.schema.id, method : 'Broker#updateFromVersion',
        },
        data    : { version, options : opts },
      } );
    }
    if ( version.origin && ! this.origin ) this.origin = version.origin;
    if ( this.locked ) {
      if ( opts.unlock ) {
        this.debug( 'Forced update, unlocking broker' );
        this.unlock();
      } else {
        this.debug( 'Broker is locked, setting outdated and skipping', {
          locked : this.locked,
        } );
        hideProps( this, { outdated : version } );
        this.changed();
        return;
      }
    }
    this.version = version;
  }

  processLiveUpdate( { version, ...payload } ) {
    this.debug( 'processLiveUpdate', version, payload );
    if ( version instanceof Version ) {
      this.updateFromVersion( version );
    }
  }

  /** Load the resource data from the server. */
  load( options: BrokerLoadOptions = {} ) {
    if ( this.resource.creating ) {
      throw new DatabaseBrokerError( {
        message : `Cannot load resource that is marked creating`,
        tags    : { schema : this.schema.id, method : 'Broker#load' },
        data    : { options },
      } );
    }
    this.debug( 'Broker.load:', options );
    const { live, ...opts } = options;
    _.defaults( opts, {
      safe      : false,
      reload    : false,
      cache     : false,
      insert    : false,
      links     : false,
    } );
    if ( _.isBoolean( live ) ) this.live = live;
    if ( this.loaded && ! ( opts.reload || this.outdated ) ) return this;
    if ( this._loading ) return this._loading;
    const promise = this._load( opts );
    this._loading = promise;
    promise.then( ( res ) => { delete this._loading; return res; } );
    return promise;
  }

  async _load( options: BrokerLoadOptions = {} ) {
    this.debug( 'Broker._load', options );
    const { safe, insert, ...opts } = options;
    try {
      try {
        this.debug( 'Attempting to load for', _.pick( this, [
          'type', 'id', 'ident', 'query', 'version',
        ] ) );
        if ( this.origin ) {
          const method = options.reload ? 'reload' : 'load';
          this.debug( `${method.toUpperCase()}ING FROM ORIGIN` );
          const res = await this.origin[ method ]( opts );
          this.debug( '  RESPONSE:', res );
          return await this.updateFromResponse( res, options );
        } else if ( this.ident || this.query ) {
          this.debug( 'FETCHING FROM ORIGINS:', this.ident, this.query );
          const query = this.ident || this.query;
          const res = await getOrigins().fetch( this.type, query, opts );
          this.debug( '  RESPONSE:', res );
          return await this.updateFromResponse( res, options );
        } else {
          throw new NotFound( {
            message : 'Could not load resource (no origin, ident, or query)',
            tags    : {
              schema : this.schema.id,
              method : 'Broker#load',
            },
            data    : {
              type  : this.schema.id,
              ident : this.ident,
              query : this.query,
            },
          } );
        }
      } catch ( error ) {
        if ( insert && error.status === 'not-found' ) {
          this.debug( 'NOT FOUND, INSERTING:' );
          if ( _.isPlainObject( insert ) ) this.resource.merge( insert );
          const res = await this.resource._insert();
          this.debug( '  RESPONSE:', res );
          return await this.updateFromResponse( res, options );
        }
        throw error;
      } finally {
        if ( opts.live === true ) this.live = true;
      }
    } catch ( load_error ) {
      // this.error = load_error;
      if ( safe ) return load_error;
      throw load_error;
    }
  }

  async getInstance( options={} ) {
    const { load, ...opts } = options;
    if ( this.version ) return this.version.getInstance( opts );
    if ( load ) {
      const res = await this._load();
      if ( res.status === 'not-found' ) return;
      return this.version.getInstance( opts );
    }
    if ( this.origin ) return this.origin.getInstance();
    throw new DatabaseBrokerError( {
      message : [
        'The broker for this resource does not have a "version" available.',
        'The most likely cause for this is that you called update on a',
        'resource that has not been stored.  In that case you should use',
        'the save method instead, which will figure out whether to update',
        'or insert automatically.',
      ].join( ' ' ),
      tags    : { schema : this.schema.id, method : 'Broker#getInstance' },
      data    : { options },
    } );
  }

  /**
   * Insert a new resource and return the TransportResponse.
   *
   * @param updates - Updates instance.
   * @returns {TransportResponse}
   */
  async insert( updates: Updates, options: BrokerLoadOptions = {} ) {
    if ( ! ( updates instanceof Updates ) ) {
      throw new DatabaseBrokerError( {
        message : `insert expected Updates instance`,
        tags    : { schema : this.schema.id, method : 'Broker#insert' },
        data    : { updates },
      } );
    }
    try {
      this.updating = true;
      const res = await getOrigins().insert( updates );
      return await this.updateFromResponse( res, options );
    } finally {
      this.updating = false;
    }
  }

  /**
   * Update a resource and return the Version instance for the updated
   * version.
   *
   * @param updates - Updates instance.
   * @returns {Version}
   */
  async update( updates: Updates, options: BrokerLoadOptions = {} ) {
    if ( ! ( updates instanceof Updates ) ) {
      throw new DatabaseBrokerError( {
        message : `update expected Updates instance`,
        tags    : { schema : this.schema.id, method : 'Broker#update' },
        data    : { updates },
      } );
    }
    try {
      this.updating = true;
      const res = await getOrigins().update( updates );
      return await this.updateFromResponse( res, options );
    } finally {
      this.updating = false;
    }
  }

  async remove( options: BrokerLoadOptions = {} ) {
    try {
      this.updating = true;
      const res = await getOrigins().remove( this.schema, this.id );
      return await this.updateFromResponse( res, options );
    } finally {
      this.updating = false;
    }
  }

  get vtag() { return `v${this.version?.version || 0}`; }
  toString() {
    return `${this.broker_id} <${this.type}/${this.id}/${this.vtag}>`;
  }

  getMeta() {
    return {
      broker_id   : this.broker_id,
      has_version : !! this.version,
      is_outdated : !! this.outdated,
      ..._.pick( this, [
        'type', 'id', 'ident', 'query',
        'loading', 'loaded', 'locked', 'immutable', 'resolved', 'updating',
        'live', 'watching',
      ] ),
      origin      : this.origin?.getMeta(),
    };
  }

  getVersionInstance() { return this._version?.getInstance(); }
  getOutdatedInstance() { return this.outdated?.getInstance(); }

}
