import _ from 'lodash';
import { createBehavior } from '~/core/creators';
import { throwError } from '~/core/errors';

import type { Definitions } from '~';
import type { FieldOptions } from '~/modules/fields';

export type HasEmailOptions = {
  useSingleEmail?: boolean;
  useMultipleEmails?: boolean;
  uniqueEmails?: boolean;
  emailFieldName?: string;
  emailsFieldName?: string;
  lowerEmailFieldName?: string;
  lowerEmailsFieldName?: string;
  // Field options for email/emails field.
  emailOptions?: Partial<FieldOptions>;
  emailsOptions?: Partial<FieldOptions>;
  lowerEmailOptions?: Partial<FieldOptions>;
  lowerEmailsOptions?: Partial<FieldOptions>;
};

declare module './declarations' {
  export interface BehaviorConfigs {
    hasEmail?: HasEmailOptions | boolean;
  }
  export interface BehaviorOptions {
    hasEmail: Required<HasEmailOptions>;
  }
}

createBehavior<HasEmailOptions>( {

  name      : 'hasEmail',
  options   : {
    useSingleEmail          : true,
    useMultipleEmails       : false,
    uniqueEmails            : false,
    emailFieldName          : 'email',
    emailsFieldName         : 'emails',
    lowerEmailFieldName     : 'lower_email',
    lowerEmailsFieldName    : 'lower_emails',
    // Field options for email/emails field.
    emailOptions            : {},
    emailsOptions           : {},
    lowerEmailOptions       : {},
    lowerEmailsOptions      : {},
  },

  build() {
    const {
      useSingleEmail, useMultipleEmails, emailFieldName, emailsFieldName,
      lowerEmailFieldName, lowerEmailsFieldName, uniqueEmails,
    } = this.options;

    const configs: Definitions = [ { fields : this.buildFields() } ];

    if ( BUILD.isServer ) {
      configs.push( {
        events : {
          beforeSave() {
            this.updateEmailFields();
          },
        },
        methods : class {
          updateEmailFields() {
            const emails = _.compact( _.uniq( _.flattenDeep( [
              this[ emailFieldName ], this[ emailsFieldName ],
            ] ) ) );
            if ( useSingleEmail ) {
              if ( ! this[ emailFieldName ] ) {
                this[ emailFieldName ] = emails[ 0 ];
              }
              this[ lowerEmailFieldName ] = _.toLower( this[ emailFieldName ] );
            }
            if ( useMultipleEmails ) {
              this[ emailsFieldName ] = emails;
              this[ lowerEmailsFieldName ] = emails.map( _.toLower );
            }
          }
        },
      } );
    }

    if ( uniqueEmails ) {
      configs.push( {
        methods : class {
          static async getByEmail( email, opts={} ) {
            if ( _.isArray( email ) ) {
              return this.fetch( { emails : email }, opts );
            }
            return this.fetch( { email }, opts );
          }
          static async findOrCreateByEmail( email, opts={} ) {
            _.defaultsDeep( opts, {
              insert : { [ _.isArray( email ) ? 'emails' : 'email' ] : email },
            } );
            return this.getByEmail( email, opts );
          }
        },
      } );
    }

    return configs;
  },

  // eslint-disable-next-line max-lines-per-function
  buildFields() {
    const {
      useSingleEmail, useMultipleEmails, emailFieldName, emailsFieldName,
      lowerEmailFieldName, lowerEmailsFieldName, uniqueEmails,
      emailOptions, emailsOptions, lowerEmailOptions, lowerEmailsOptions,
    } = this.options;
    const transformFetchQuery = ( value, _values ) => {
      const query = this.lowercaseQuery( value );
      return _.compact( _.flatten( [
        useSingleEmail && lowerEmailFieldName,
        useMultipleEmails && lowerEmailsFieldName,
      ] ) ).map( name => ( { [ name ] : query } ) );
    };

    const fields = {};
    if ( useSingleEmail ) {
      fields[ emailFieldName ] = _.assign( {
        type        : 'email',
        label       : 'Email',
        summary     : true,
        index       : true,
        quicksearch : true,
        optional    : false,
        unique      : uniqueEmails,
        readonly    : true,
        sortable    : true,
        faker       : 'internet.email',
        identifier  : uniqueEmails,
        transformFetchQuery,
      }, emailOptions );
      const lowerOpts = _.assign( {
        type        : 'email',
        label       : 'Lowercased Email Address',
        summary     : true,
        index       : true,
        quicksearch : false,
        optional    : false,
        unique      : uniqueEmails,
        metadata    : true,
        readonly    : true,
        identifier  : uniqueEmails,
        default() {
          if ( _.isString( this[ emailFieldName ] ) ) {
            return this[ emailFieldName ].toLowerCase();
          }
          return undefined;
        },
        transformFetchQuery,
      }, lowerEmailOptions );
      fields[ lowerEmailFieldName ] = lowerOpts;
    }

    if ( useMultipleEmails ) {
      fields[ emailsFieldName ] = _.assign( {
        type        : 'email',
        array       : true,
        label       : 'Additional Email Addresses',
        summary     : false,
        index       : true,
        quicksearch : false,
        optional    : false,
        unique      : uniqueEmails,
        readonly    : true,
        identifier  : uniqueEmails,
        default() {
          if ( _.isString( this[ emailFieldName ] ) ) {
            return [ this[ emailFieldName ] ];
          }
          return [];
        },
        transformFetchQuery,
      }, emailsOptions );
      const lowerOpts = _.assign( {
        type        : 'email',
        array       : true,
        label       : 'Lowercased Additional Email Addresses',
        summary     : false,
        index       : true,
        quicksearch : false,
        unique      : uniqueEmails,
        metadata    : true,
        readonly    : true,
        identifier  : uniqueEmails,
        default() {
          if ( _.isArray( this[ emailsFieldName ] ) ) {
            return this[ emailsFieldName ].map( e => {
              if ( ! _.isString( e ) ) {
                throw new Error( `hasEmail: ${e} is not a string` );
              }
              return e.toLowerCase();
            } );
          }
          return [];
        },
        transformFetchQuery,
      }, lowerEmailsOptions );
      fields[ lowerEmailsFieldName ] = lowerOpts;
    }

    return fields;
  },

  lowercaseQuery( query ) {
    if ( _.isString( query ) ) return query.toLowerCase();
    if ( _.isArray( query ) ) {
      return { $in : _.invokeMap( query, 'toLowerCase' ) };
    }
    if ( _.isObject( query ) && _.isArray( query.$in ) ) {
      return { $in : _.invokeMap( query.$in, 'toLowerCase' ) };
    }
    throwError( `Cannot lowercase query`, {
      method    : 'transformFetchQuery',
      behavior  : 'hasEmail',
      query,
    } );
  },

} );
