import _ from 'lodash';
import { Forbidden, NotAcceptable } from '@ssp/utils';
import { JobStepInteractive } from './Interactive';

import type { TResource } from '~/types';
import type { JobStepOptions } from './Step';

export type JobStepApprovalOptions = {
  type: 'Approval';
  action: ( this: JobStepApproval ) => any;
} & JobStepOptions;
export type JobStepApprovalMessageApprover = {
  /** The React component to render. */
  component: string;
  /** The job record */
  resource: TResource<'SYSTEM.Job'>;
  /** A message for the approver. */
  message: string;
};
export type JobStepApprovalMessageNonApprover = {
  /** The React component to render. */
  component: string;
  /** The content within the component. */
  content: string;
};


declare module '~/modules/jobs/types' {
  export interface JobStepTypes {
    Approval: JobStepApprovalOptions;
  }
}

export class JobStepApproval extends JobStepInteractive {

  declare approver_message: string;
  declare approver_teams: string[];
  /**
   * This returns an approval UI appropriate for the subject in question
   *
   * @param {SSP.User} subject - the person viewing this record
   * @returns {ApproverMessage|NonApproverMessage} - a UI config
   */
  getFulfillmentInterface( subject ) {
    if ( this.subjectCanFulfill( subject ) ) { // return an ApproverMessage
      return {
        component : 'SubDocForm',
        resource  : this,
        message   : this.approver_message,
      } as JobStepApprovalMessageApprover;
    } else { // return a NonApproverMessage
      return {
        component : 'Markdown',
        content   : [
          'You do not have permission to perform this action.',
          'This step requires approval by a member of one of these teams:',
          ..._.map( this.approver_teams, team => `* ${team}` ),
        ].join( '\n' ),
      };
    }
  }
  /**
   * Returns true if the current subject in the context is allowed
   * to approve or deny this step.
   */
  subjectCanFulfill( subject ) {
    const user = subject || ctx.get( 'user' );
    if ( ! user ) return false;
    return _.some(
      user.acl_teams,
      team => _.includes( this.approver_teams, team ),
    );
  }
}

JobStepApproval.initialize( {
  id        : 'JobStep.Approval',
  inherit   : 'JobStep.Interactive',
  fields    : {
    approver_teams  : {
      type        : 'string',
      description : 'List of ACL Teams that may approve',
      array       : true,
      required    : true,
      readonly    : true,
      label       : 'Approver Teams',
      summary     : true,
    },
    approver_message  : {
      type     : 'markdown',
      label    : 'Approver Message',
      index    : true,
      // Optional because it will get filled in automatically when
      // inserted or updated.
      readonly : true,
      optional : true,
    },
    approval_state : {
      type     : 'string',
      label    : 'Override Provision Limit',
      summary  : true,
      form     : { type : 'RadioGroup', options : [ 'Approve', 'Deny' ] },
    },
  },
}, BUILD.isServer && {
  methods   : class extends JobStepApproval {

    async execute() {
      const approvers = this.approver_teams.join( ', ' );
      this.interact( `Requires approval from: ${approvers}` );
    }

    /**
     * @param approvalState - describes
     * the approver's chosen approval value
     */
    setApprovalResult( approvalState: 'Approve' | 'Deny' ) {
      const user = ctx.get<TResource<'SSP.User'>>( 'user' );
      this.merge( {
        approval_state  : approvalState,
        completed_by    : user._id,
        ended_at        : new Date(),
        status          : 'complete',
      } );
      if ( approvalState === 'Approve' ) {
        const reason = `Approved by ${user.toString( true )}`;
        this.merge( {
          status        : 'complete',
          status_reason : reason,
        } );
        this.job.markQueued( reason );
      } else if ( approvalState === 'Deny' ) {
        const reason = `Denied by ${user.toString( true )}`;
        this.merge( {
          status        : 'cancelled',
          status_reason : reason,
        } );
        this.job.markCancelled( reason );
      } else {
        throw new NotAcceptable(
          'Unrecognized Approval State value:',
          { value : approvalState },
        );
      }
    }

    assertSubjectCanFulfill() {
      if ( this.subjectCanFulfill() ) return;
      const user = ctx.user.toString( true );
      throw new Forbidden(
        `${user} is not authorized to perform this action`,
      );
    }

  },
  events : {
    /**
     * If the subject is authorized to apply a change to the
     * approval status of this Approval step, then proceed to set
     * additional details.
     * If the subject is unauthorized to apply the attempted change,
     * then throw an error.
     *
     * @param {Event} ev - the event object
     */
    async beforeSave( ev ) {
      const state = this.approval_state;
      if ( ! state ) return;
      if ( ! ctx.is( 'user' ) ) return;
      if ( state === ev.origin.approval_state ) return;
      this.assertSubjectCanFulfill();
      this.setApprovalResult( state );
    },
  },
} );
