import { createResource } from '~/core/creators';

createResource( {
  id               : 'Jira.Project',
  name             : 'Jira Project',
  icon             : 'fab:jira',
  attachable       : [ 'SSP.Project' ],
  service_id_field : 'jira_id',
  featured         : true,
  fields           : {
    key        : {
      type        : 'string',
      label       : 'Project Key',
      summary     : true,
      unique      : true,
      identifier  : true,
      createonly  : true,
      quicksearch : true,
      validators  : {
        minlength : 2,
        maxlength : 15,
        characters : { head : 'upper', body : [ 'upper', 'numbers' ] },
      },
      transform   : 'uppercase',
      optional    : false,
      help        : [
        'Prefix for each issue in Jira. Try to keep this as short',
        'as possible while remaining unique.',
        'Must be upper case alphanumeric.',
        'Max Length = 15 characters',
      ],
      faker : 'resource.jiraKey',
    },
    name        : {
      type        : 'string',
      label       : 'Name',
      faker       : 'project.name',
      optional    : false,
      quicksearch : true,
      unique      : true,
      validators  : {
        minlength : 2,
        maxlength : 80,
      },
    },
    description : {
      type        : 'text',
      label       : 'Description',
      summary     : true,
      optional    : true,
      quicksearch : true,
      minimal     : true,
      form        : {
        type      : 'Textarea',
      },
    },
    jira_id     : {
      type        : 'id',
      readonly    : true,
      optional    : true,
      unique      : true,
      identifier  : true,
      immutable   : true,
    },
    jira_project_lead : {
      type        : 'link',
      model       : 'Jira.User',
      label       : 'Jira Project Lead',
      optional    : true,
      readonly    : true,
      summary     : true,
    },
    project_template_key : {
      type        : 'string',
      label       : 'Jira Template',
      summary     : true,
      optional    : true,
      readonly    : true,
      default     : 'Software',
    },
    jira_notification_scheme : {
      type        : 'string',
      label       : 'Jira Notification Scheme',
      summary     : true,
      optional    : true,
      readonly    : true,
      default     : 'Default Notification Scheme',
    },
  },
  behaviors : {
    hasExternalLink : {},
    hasMembers         : {
      userType    : 'Jira.User',
      withCreator : {
        config : { roles : [ 'Administrators' ] },
      },
      withRoles   : {
        defaultRoles  : [ 'Users' ],
        roles         : [
          'Administrators',
          'Engineers',
          'Users',
          'Developers',
          'Read Only',
        ],
      },
      withService : true,
    },
    hasArchived   : {},
  },
  faces         : {
    'card.summary' : [
      {
        layout : 'Badges',
        fields : [ '@badge' ],
      },
      {
        layout : 'LabelsAndValues',
        fields : [ 'key', 'members_count', 'external_link', 'description' ],
      },
    ],
    'list.summary' : [
      {
        layout : {
          type       : 'ItemsBetweenTitleAndIcons',
          iconize    : [ {
            fieldName : 'key',
            icon      : 'fad:key',
          } ],
        },
        fields : [ '-@all' ],
      },
      {
        layout : 'ItemsAndBadges',
        fields  : [ 'description', '@badge' ],
      },
    ],
  },
} );
