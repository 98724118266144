// Automatically generated, DO NOT EDIT!
// To update run "yarn do-updates"
/* eslint-disable */
import { Model } from '~/core/lib/Model';
Model.demand( 'SSP.User' ).extend( {
  "actions": {
    "disable-user-accounts": {
      "access": "support",
      "icon": "fad:user-lock",
      "id": "disable-user-accounts",
      "job_name": "disable-user-accounts",
      "label": "Disable All Accounts",
      "name": "disable-user-accounts",
      "type": "instance"
    },
    "disable-user-batch": {
      "access": "support",
      "help": "Ensures the resources for all disabled SSP Users are disabled",
      "icon": "fad:user-lock",
      "id": "disable-user-batch",
      "job_name": "disable-user-batch",
      "label": "Ensure Disables",
      "name": "disable-user-batch",
      "params": {
        "interval": {
          "default": "1 week",
          "help": "Timeframe to check for when users were last disabled",
          "label": "Interval",
          "type": "string"
        }
      },
      "type": "class"
    },
    "enable-user-accounts": {
      "access": "support",
      "disabledMethod": "isDisabled",
      "icon": "fad:user-unlock",
      "id": "enable-user-accounts",
      "job_name": "enable-user-accounts",
      "label": "Enable Accounts",
      "name": "enable-user-accounts",
      "params": {
        "account_types": {
          "array": true,
          "form": {
            "options": "_enable_user_accounts_options",
            "type": "CheckboxGroup"
          },
          "label": "Account Types to Enable",
          "type": "string"
        }
      },
      "type": "instance"
    },
    "merge-duplicate-accounts": {
      "access": "support",
      "help": "Merges duplicate SSP user records into this record; duplicate records are removed.",
      "icon": "far:compress-arrows-alt",
      "id": "merge-duplicate-accounts",
      "job_name": "merge-duplicate-accounts",
      "label": "Merge Duplicate Accounts",
      "name": "merge-duplicate-accounts",
      "type": "instance"
    },
    "sync": {
      "access": "admin",
      "icon": "fas:sync",
      "id": "sync",
      "job_name": "sync",
      "label": "Sync",
      "name": "sync",
      "params": {
        "force": {
          "default": false,
          "describe": "Force fresh sync jobs (do not coalesce ended jobs)",
          "help": "Force fresh sync jobs (do not coalesce ended jobs)",
          "required": false,
          "type": "boolean"
        },
        "sync_accounts": {
          "default": false,
          "describe": "Sync LDAP resources",
          "help": "Sync LDAP accounts",
          "required": false,
          "type": "boolean"
        },
        "sync_resources": {
          "default": true,
          "describe": "Sync non-LDAP resources",
          "help": "Sync non-LDAP resources",
          "required": false,
          "type": "boolean"
        }
      },
      "type": "instance"
    },
    "sync-batch": {
      "access": "support",
      "icon": "fas:sync",
      "id": "sync-batch",
      "job_name": "sync-batch",
      "label": "Sync Batch",
      "name": "sync-batch",
      "params": {
        "disabled": {
          "default": false,
          "label": "Process disabled instead of active users",
          "type": "boolean"
        },
        "interval": {
          "default": "2 days",
          "label": "Minimum run interval",
          "type": "string"
        },
        "limit": {
          "default": 1000,
          "label": "Max jobs enqueued by this run",
          "type": "number"
        }
      },
      "type": "class"
    }
  }
} );
