import _ from 'lodash';
import metrics from '@ssp/metrics';

import { getTransport, TransportResponse } from '~/lib';
import { Schema } from '~/core/lib/Schema';

export type Batch = {
  dispatched : boolean;
  versions: Record<string, number>;
  callbacks: Record<string, {
    resolve: ( value: any ) => void;
    reject: ( error: Error ) => void;
  }>;
  promises: Record<string, Promise<ResType>>;
}

type ResType = TransportResponse<'batch', unknown>;

export class BatchLoaders {
  loaders: Record<string, BatchLoader> = {};

  get( schema: string|Schema ) {
    if ( schema instanceof Schema ) schema = schema.id;
    return this.loaders[ schema ]
      || ( this.loaders[ schema ] = new BatchLoader( schema ) );
  }
}

const batch_size_hist = metrics.histogram( {
  name    : 'ssp_database_batchloader_batch_size',
  help    : 'Number of requests collected into a batch',
  labels  : [ 'schema', 'locus' ],
  buckets : [ 1, 2, 5, 10, 20, 50, 100 ],
} );

/** A `Batch` is an API for requesting data in batches. */
export class BatchLoader {
  private batch: Batch|null = null;

  constructor( public readonly schema: string ) {}

  /** Request a resource, returning a promise for it's data. */
  load( id: string, version: number = 0 ): Promise<ResType> {
    if ( _.isNil( id ) ) throw new TypeError( 'BatchLoader.load requires id' );

    const batch = this.getCurrentBatch();

    const cached = batch.promises[ id ];
    if ( cached ) return cached;

    batch.versions[ id ] = _.max( [ batch.versions[ id ] || 0, version || 0 ] );
    const promise = new Promise<ResType>( ( resolve, reject ) => {
      batch.callbacks[ id ] = { resolve, reject };
    } );
    batch.promises[ id ] = promise;
    return promise;
  }

  private getCurrentBatch(): Batch {
    if ( this.batch && ! this.batch.dispatched  ) return this.batch;

    const batch = this.batch = {
      dispatched : false,
      versions   : {},
      callbacks  : {},
      promises   : {},
    };
    this.scheduleBatch( this.loadBatch.bind( this, batch ) );

    return batch;
  }

  private scheduleBatch = getScheduler();

  private async loadBatch( batch: Batch ) {
    batch.dispatched  = true;
    const schema = Schema.get( this.schema );
    const size = _.size( batch.versions );
    log.debug( 'Submitting batch of', size, this.schema );
    batch_size_hist.observe( size, {
      schema : this.schema,
      locus  : BUILD.isServer ? 'server' : 'client',
    } );
    try {
      const response = await getTransport().batch( schema, batch.versions, {} );
      response.fatalize();
      for ( const [ id, rsrc ] of Object.entries( response.resources ) ) {
        const res = new TransportResponse( {
          schema  : this.schema,
          method  : 'retrieve',
          batch   : response,
        } );
        if ( rsrc instanceof Error ) {
          res.error = rsrc;
        } else if ( _.isString( rsrc ) ) {
          res.ref_url = rsrc;
        } else {
          res.resource = rsrc;
        }
        batch.callbacks[ id ].resolve( res );
      }
    } catch ( error ) {
      for ( const { reject } of Object.values( batch.callbacks ) ) {
        reject( error );
      }
    }
  }
}

function getScheduler() {

  if ( BUILD.isServer ) {
    // ES6 specifies a JobQueue that schedules work for after the
    // current execution context is done:
    // http://www.ecma-international.org/ecma-262/6.0/#sec-jobs-and-job-queues
    //
    // The Node implementation of this is `process.nextTick`, which
    // maintains a global FIFO JobQueue for all jobs, which Node flushes
    // after the current call stack ends.
    //
    // When you call `promise.then`, it enqueues a Job to the
    // 'PromiseJobs' queue, which later gets flushed in one shot on the
    // global JobQueue.
    //
    // What we're trying to do with the 'Batch' is to queue up all the
    // loads that are requested in a single frame of execution and send
    // them to the backend all at once. However, we also want to include
    // in the batch any loads which occur during the flushing of that
    // 'PromiseJobs' queue immediately after that same execution frame.
    //
    // To do that, we enqueue a promise chained off of an already
    // resolved promise, so that we enqueue a global job that will be
    // run after the PromiseJobs queue is flushed.
    const resolvedPromise = Promise.resolve();

    return function scheduler( fn ) {
      resolvedPromise.then( () => process.nextTick( fn ) );
    };
  }

  // Browsers don't have anything equivalent to `nextTick`, so in the
  // browser we just use a macrotask via `setImmediate` or
  // `setTimeout` which has a performance penalty compared to
  // `nextTick`, but that's all that is available.
  if ( typeof setImmediate === 'function' ) {
    return function schedule( fn ) { setImmediate( fn ); };
  }
  return function schedule( fn ) { setTimeout( fn, 0 ); };
}

const client_batches = BUILD.isClient && new BatchLoaders();
export function getBatchLoaders(): BatchLoaders {
  if ( client_batches ) return client_batches;
  if ( ! ctx.get( 'batches' ) ) ctx.set( 'batches', new BatchLoaders() );
  return ctx.get( 'batches' );
}
export function getBatchLoader( schema: string ): BatchLoader {
  return getBatchLoaders().get( schema );
}
export function getWithBatchLoader(
  schema: string,
  id: string,
  version?: number,
): Promise<ResType> {
  return getBatchLoader( schema ).load( id, version );
}
