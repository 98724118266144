import _ from 'lodash';
import type { Resource } from '~/core/resource/Resource';
import * as helpers from './helpers';
import { List } from '../fields/List';

export type UpdateOperationType =
  | 'set' | 'unset'
  | 'array_add' | 'array_remove'
  | 'list_add' | 'list_remove' | 'list_modify';

export abstract class UpdateOperation {

  abstract get type(): UpdateOperationType;
  declare field: string;

  constructor( { type, ...opts } ) {
    if ( type !== ( this as any ).type ) {
      throw new TypeError(
        `Type mismatch: ${type} !== ${( this as any ).type}`,
      );
    }
    if ( ! _.isString( opts.field ) ) {
      throw new TypeError( `UpdateOperation field must be string` );
    }
    _.assign( this, opts );
    this.build();
  }

  build() { return; }
  toMongoInsert() { return; }

  abstract toJSON(): Record<string, any>;
  abstract applyTo( rsrc: Resource ): void;
}
Object.defineProperty( UpdateOperation.prototype, 'type', {
  enumerable : true,
} );

export class SimpleSet extends UpdateOperation {

  declare value: any;

  get type(): 'set' { return 'set'; }
  toMongoUpdate() { return helpers.set( this.field, this.value ); }
  toMongoInsert() {
    if ( this.value === null ) return;
    return { [ this.field ] : this.value };
  }
  applyTo( rsrc ) { _.set( rsrc, this.field, this.value ); }
  toJSON() {
    return {
      type      : this.type,
      field     : this.field,
      value     : this.value,
    };
  }
}
export class SimpleUnset extends UpdateOperation {
  get type(): 'unset' { return 'unset'; }
  toMongoUpdate() { return helpers.unset( this.field ); }
  applyTo( rsrc ) { _.unset( rsrc, this.field ); }
  toJSON() { return { type : this.type, field : this.field }; }
}

export class ArrayAdd extends UpdateOperation {

  get type(): 'array_add' { return 'array_add'; }
  declare values: any[];

  build() { this.values = _.compact( _.castArray( this.values ) ); }
  toMongoUpdate() {
    if ( _.isEmpty( this.values ) ) return;
    return helpers.concat( this.field, this.values );
  }
  toMongoInsert() { return { [ this.field ] : this.values }; }
  applyTo( rsrc ) { getArray( rsrc, this.field ).push( ...this.values ); }

  toJSON() {
    return {
      type      : this.type,
      field     : this.field,
      values    : this.values,
    };
  }
}
export class ArrayRemove extends UpdateOperation {

  get type(): 'array_remove' { return 'array_remove'; }
  declare values: any[];
  varname: string = 'x';

  build() { this.values = _.compact( _.castArray( this.values ) ); }
  toMongoUpdate() {
    if ( _.isEmpty( this.values ) ) return;
    return helpers.rejectArrayElements( this.field, this.values );
  }
  applyTo( rsrc ) { _.pullAll( getArray( rsrc, this.field ), this.values ); }

  toJSON() {
    return {
      type      : this.type,
      field     : this.field,
      values    : this.values,
    };
  }
}

export class ListAdd extends UpdateOperation {

  get type(): 'list_add' { return 'list_add'; }
  declare values: any[];

  build() { this.values = _.compact( _.castArray( this.values ) ); }
  toMongoUpdate() {
    if ( _.isEmpty( this.values ) ) return;
    return helpers.concat( this.field, this.values );
  }
  toMongoInsert() { return { [ this.field ] : this.values }; }
  applyTo( rsrc ) { getList( rsrc, this.field ).add( this.values ); }

  toJSON() {
    return {
      type      : this.type,
      field     : this.field,
      values    : this.values,
    };
  }
}
export class ListRemove extends UpdateOperation {

  get type(): 'list_remove' { return 'list_remove'; }
  declare values: any[];
  varname: string = 'x';

  build() { this.values = _.compact( _.castArray( this.values ) ); }
  toMongoUpdate() {
    return helpers.rejectSubdocs( this.field, this.values, this.varname );
  }
  applyTo( rsrc ) { getList( rsrc, this.field ).delete( this.values ); }

  toJSON() {
    return {
      type      : this.type,
      field     : this.field,
      values    : this.values,
    };
  }

}
export class ListModify extends UpdateOperation {
  get type(): 'list_modify' { return 'list_modify'; }
  varname: string = 'x';
  declare match: any;
  declare changes: any;
  toMongoUpdate() {
    return helpers.updateListSubdoc(
      this.field, this.match, this.changes, this.varname,
    );
  }

  applyTo( rsrc ) {
    const subdoc = getList( rsrc, this.field ).get( this.match );
    if ( ! subdoc ) {
      throw new Error( `Could not find matching subdoc in ${this.field}` );
    }
    subdoc.merge( this.changes );
  }

  toJSON() {
    return {
      type      : this.type,
      field     : this.field,
      match     : this.match,
      changes   : this.changes,
    };
  }
}

function getArray( rsrc, field ) {
  const array = _.get( rsrc, field );
  if ( Array.isArray( array ) ) return array;
  throw new TypeError( `Field ${field} contained non-array ${array}` );
}

function getList( rsrc, field ) {
  const list = _.get( rsrc, field );
  // @ts-ignore
  if ( list instanceof List ) return list;
  throw new TypeError( `Field ${field} contained non-list ${list}` );
}

export type Operation =
  | SimpleSet | SimpleUnset
  | ArrayAdd | ArrayRemove
  | ListAdd | ListRemove | ListModify;

export type UpdateOperationOptions =
  | { type: 'set'; field: string; value: any; }
  | { type: 'unset'; field: string; }
  | { type: 'array_add'; field: string; values: any[]; }
  | { type: 'array_remove'; field: string; values: any[]; }
  | { type: 'list_add'; field: string; values: any[]; }
  | { type: 'list_remove'; field: string; values: any[]; }
  | {
    type: 'list_modify';
    field: string;
    varname?: string;
    match: any;
    changes: any;
  };

export type UpdatesData = {
  method: 'insert' | 'update';
  options: Record<string, any>;
  schema: string;
  version?: number;
  id?: string;
  updates: Operation[];
};

export type UpdatesOptions = {
  method?: 'insert' | 'update';
  options?: Record<string, any>;
  schema?: string;
  version?: number;
  id?: string;
  updates?: ( Operation | UpdateOperationOptions )[];
};
