import _ from 'lodash';
import { mkdebug } from '@ssp/utils';

import { computeSchemaInsert, computeSchemaUpdate } from './computations';
import { isResource } from '~/utils/types';
import { getSchema } from '~/core/schemas';
import { verboseCanModifyField } from './utils';

import type { TResource } from '~/types';

import {
  UpdateOperation, UpdatesData, UpdatesOptions,
  SimpleSet, SimpleUnset, ArrayAdd, ArrayRemove,
  ListAdd, ListRemove, ListModify,
} from './updates-types';
import * as helpers from './helpers';

const debug = mkdebug( 'ssp:database:updates' );

const types = {
  set           : SimpleSet,
  unset         : SimpleUnset,
  array_add     : ArrayAdd,
  array_remove  : ArrayRemove,
  list_add      : ListAdd,
  list_remove   : ListRemove,
  list_modify   : ListModify,
} as const;

/**
 * This class helps to build and apply our intermediate update
 * document format.
 */
export class Updates {

  updates: UpdateOperation[] = [];
  options: Record<string, any> = {};
  method?: 'insert' | 'update';
  version?: number;
  schema?: string;
  id?: string;

  constructor( options_in?: UpdatesOptions ) {
    const {
      method, version, updates, schema, id, options, ...opts
    } = options_in;
    this.options = _.assign( {}, opts, options );
    if ( method ) this.method = method;
    if ( version ) this.version = version;
    if ( schema ) this.schema = schema;
    if ( id ) this.id = id;
    if ( updates ) this.add( updates );
  }

  filter( iteratee ) { return _.filter( this.updates, iteratee ); }
  reject( iteratee ) { return _.reject( this.updates, iteratee ); }
  map( iteratee ) { return _.map( this.updates, iteratee ); }
  flatMap( iteratee ) { return _.flatMap( this.updates, iteratee ); }

  isEmpty() { return this.updates.length === 0; }

  add( item ) {
    if ( _.isNil( item ) ) return;
    if ( Array.isArray( item ) ) return _.map( item, i => this.add( i ) );
    if ( item instanceof UpdateOperation ) return this.updates.push( item );
    if ( ! _.isPlainObject( item ) ) {
      throw new TypeError( `Invalid argument to Updates.add: ${item}` );
    }
    if ( ! item.type ) {
      throw new TypeError( `Invalid argument to Updates.add: ${item}` );
    }
    const Class = types[ item.type ];
    if ( ! Class ) throw new TypeError( `Invalid update type ${item.type}` );
    const obj = new Class( item );
    this.updates.push( obj );
    return obj;
  }

  getFieldNames() { return _.uniq( this.flatMap( 'field' ) ); }
  hasField( field ) { return _.some( this.updates, { field } ); }

  discardField( field ) { _.remove( this.updates, { field } ); }

  /**
   * Compute insert values for a resource.
   *
   * @param doc - The resource document to compute for.
   */
  computeInsert( doc: TResource<any> ) {
    if ( ! isResource( doc ) ) {
      throw new TypeError( `computeInsert: resource must be Resource` );
    }
    this.method = 'insert';
    this.version = 0;
    this.schema = doc.schema.id;
    const data = computeSchemaInsert( doc, {} );
    this.add( data );
  }

  /**
   * Compute update values for a resource.
   *
   * @param doc - The resource document to compute for.
   * @param origin - The resource origin document to
   * compare to.
   */
  computeUpdate( doc: TResource<any>, origin: TResource<any> ) {
    const err = ( ...msg ): never => {
      throw new Error( `computeUpdate: ${msg.join( ' ' )}` );
    };
    if ( ! isResource( origin ) ) err( `origin must be Resource` );
    if ( ! isResource( doc ) ) err( `doc must be Resource` );

    for ( const key of [ 'schema.id', '_id', '_version' ] ) {
      const now = _.get( doc, key );
      const was = _.get( origin, key );
      if ( now === was ) continue;
      err( `Resources must have the same ${key} (${now} != ${was})` );
    }

    this.method = 'update';
    this.version = doc._version;
    this.schema = doc.schema.id;
    this.id = doc._id;
    this.add( computeSchemaUpdate( doc, origin, {} ) );
  }

  getSchema() { return getSchema( this.schema ); }

  collect( method, ...args ) {
    return _.compact( _.flatMap( this.updates, update => {
      return update[ method ]( ...args );
    } ) );
  }

  applyTo( rsrc ) {
    if ( this.version ) {
      const have = rsrc._version;
      const want = this.version;
      if ( have < want ) {
        log.warn( `Applying changes from outdated version!`, { want, have } );
      }
      if ( want > have ) {
        log.warn( `Applying changes to outdated version!`, { want, have } );
      }
    }
    const user = ctx.get( 'user' );
    if ( ! user ) {
      for ( const update of this.updates ) update.applyTo( rsrc );
      return;
    }
    for ( const update of this.updates ) {
      const [ result, reason ] = verboseCanModifyField(
        rsrc, update.field, user,
      );
      if ( result ) {
        update.applyTo( rsrc );
      } else {
        log.warn( `Skipping update to field "${update.field}": ${reason}` );
      }
    }
  }

  toMongoUpdate() {
    const arrays = this.getArrayFields().map( helpers.ensureArray );
    const updates = this.collect( 'toMongoUpdate' );
    const update = arrays.concat( updates ).concat( helpers.inc( '_version' ) );
    debug( 'MONGO UPDATE:', update );
    return update;
  }

  toMongoInsert() {
    const insert = _.assign(
      {}, ...this.collect( 'toMongoInsert' ), { _version : 1 },
    );
    debug( 'MONGO INSERT:', insert );
    return insert;
  }

  getField( field: string ) {
    if ( ! field ) throw new TypeError( `getField requires field` );
    return this.updates.filter( upd => {
      return upd.field === field || upd.field.startsWith( field + '.' );
    } );
  }
  getArrayFields() {
    const array_types = this.filter( f => /^(array|list)_/u.test( f.type ) );
    return _.uniq( _.map( array_types, 'field' ) );
  }

  getFieldJSON( field ) {
    if ( ! field ) throw new TypeError( `getFieldJSON requires field` );
    return _.invokeMap( this.filter( { field } ), 'toJSON' );
  }

  toJSON(): UpdatesData {
    return {
      options : this.options,
      method  : this.method,
      schema  : this.schema,
      id      : this.id,
      version : this.version,
      updates : _.invokeMap( this.updates, 'toJSON' ),
    };
  }
}
