import { JobStepApproval } from './Approval';

import type { JobStepApprovalOptions } from './Approval';

export type JobStepManualInterventionOptions = {
  type: 'ManualIntervention';
  action: ( this: JobStepManualIntervention ) => any;
} & JobStepApprovalOptions;

declare module '~/modules/jobs/types' {
  export interface JobStepTypes {
    ManualIntervention: JobStepManualInterventionOptions;
  }
}

export class JobStepManualIntervention extends JobStepApproval {
}

JobStepManualIntervention.initialize( {
  id        : 'JobStep.ManualIntervention',
  inherit   : 'JobStep.Approval',
  fields    : {
    intervention_complete  : {
      type      : 'boolean',
      label     : 'Task Complete',
      summary   : true,
      required  : true,
      form          : {
        type        : 'Checkbox',
        message     : 'Manual intervention task is complete',
      },
    },
  },
}, BUILD.isServer && {

  events : {
    /**
     * If the subject is authorized to complete the intervention
     * of this step, then proceed to set additional details.
     * If the subject is unauthorized to apply the attempted change,
     * then throw an error.
     *
     * @param {Event} ev - the event object
     */
    async beforeUpdate( ev ) {
      if ( ! this.intervention_complete ) return;
      if ( ! ctx.is( 'user' ) ) return;
      if ( this.intervention_complete === ev.origin.intervention_complete ) {
        return;
      }
      this.assertSubjectCanFulfill();
      const reason = `Completed by ${ctx.user.toString( true )}`;
      this.merge( {
        status        : 'complete',
        status_reason : reason,
        completed_by  : ctx.user._id,
        ended_at      : new Date(),
      } );
      this.job.markQueued( reason );
    },
  },

} );
