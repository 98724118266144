import _ from 'lodash';
import { Module } from '~/core/lib/Module';
import { extractMethodDescriptors } from './utils';

Module.create( {
  name     : 'methods',
  services : true,

  parse( data, origin ) {
    const methods = extractMethodDescriptors( data.methods as any, origin );
    if ( _.isEmpty( methods ) ) return;
    return { methods };
  },

  apply( data, config ) {
    const descs = data.methods;
    if ( _.isEmpty( descs ) ) return;
    config.add( { methods : descs } );
  },

} );

