import _ from 'lodash';
import { isSubDocument, isResource, isSchema } from '~/utils';
import { getSchema } from '~/core/schemas';
import type { Schema, Model } from '~/core/lib';
import type { SchemaId } from '~/types';

/**
 * If the argument is a string or a number it just gets returned as
 * a string.  If it's a resource then it's `_id` is returned, and if
 * it's a subdocument then an identity is determined for it based on
 * it's `identifier` fields.  If the value is a plain object then an
 * identity will be computed assuming that the object contains
 * subdocument data for the corresponding schema.
 *
 * @param val - The value to extract the identity from.
 * @param schema - The schema to use in computing subdocument identity.
 */
export function getIdentity( val: Model ): string;
export function getIdentity( val: Record<string, any>, schema: Schema ): string;
export function getIdentity( val: string|number ): string;
export function getIdentity(
  val: Model | Record<string, any> | string | number,
  schema?: Schema,
) {
  if ( _.isNil( val ) ) {
    throw new TypeError( `Can't get identity of undefined value` );
  }
  if ( _.isString( val ) ) return val;
  if ( _.isNumber( val ) ) return val.toString();
  if ( isSubDocument( val ) ) return getRecordIdentity( val, schema );
  if ( isResource( val ) ) return val.broker.id;
  if ( _.isPlainObject( val ) && schema ) {
    if ( _.isString( schema ) ) schema = getSchema( schema );
    return getRecordIdentity( val, schema );
  }
  throw new TypeError( `Can't get identity of "${val}"` );
}

function getRecordIdentity( value, schema ) {
  const pairs = getIdentityPairs( value, schema );
  const values = _.map( pairs, 1 );
  return values.join( ';' );
}

export function getIdentityFields( schema ) {
  const fields = _.sortBy( schema.getFields( '@identifier' ), 'name' );
  if ( ! fields.length ) {
    log.debug( 'NO IDENTIFIER FIELDS FOR SCHEMA:', schema );
    throw new Error( `${schema.id} has no identifier fields` );
  }
  return fields;
}
function getIdentityPairs( value, schema ) {
  const schema_in = schema;
  if ( _.isString( schema ) ) schema = getSchema( schema );
  if ( ! schema ) schema = value.schema;
  if ( ! isSchema( schema ) ) {
    log.debug( 'NO SCHEMA', schema_in, value );
    throw new TypeError( `Can't get identity pairs without schema` );
  }
  const { id } = schema;
  const fields = getIdentityFields( schema );
  return _.map( fields.map( field => {
    const { name } = field;
    const val = value[ name ];
    if ( _.isNil( val ) ) {
      throw new Error( `Can't get identity of ${id} with no ${name} value` );
    }
    if ( _.isString( val ) || _.isNumber( val ) ) return [ name, val ];
    throw new Error( `Can't get identity of ${id} with non-string ${name}` );
  } ) );
}

export function getIdentityObject( val: Model ): Record<string, string|number>;
export function getIdentityObject(
  val: Record<string, any>,
  schema: Schema | SchemaId,
): Record<string, string|number>;
export function getIdentityObject(
  val: Model | Record<string, any>,
  schema?: Schema | SchemaId,
) { return _.fromPairs( getIdentityPairs( val, schema ) ); }
