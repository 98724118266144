import _ from 'lodash';
import { Emitter, mkdebug } from '@ssp/utils';

import { getTransport } from '~/lib';
import { getOrigins } from '~/modules/origins';
import { Schema } from '~/core/lib/Schema';

import type { SchemaId } from '~/types';
import type { LiveUpdateMessage } from './types';

type CallbackArg<T extends 'ids'|'counts'>
  = T extends 'ids' ? string[] : T extends 'counts' ? number : never;

export type Watcher<T extends 'ids'|'counts'>
  = ( ids: Record<SchemaId, CallbackArg<T>> ) => void;
const debug = mkdebug( 'ssp:database:modules:origins:counts' );
export class Counts extends Emitter {
  active: boolean = false;

  declare ids?: Record<SchemaId, string[]>;
  declare counts?: Record<SchemaId, number>;

  constructor( public readonly type: SchemaId, public readonly id: string ) {
    super();
  }

  get schema() { return Schema.get( this.type ); }
  get origin() { return getOrigins().get( this.type, this.id ); }

  async emit() {
    this.ids = _.mapValues( this.ids, v => v.slice() );
    this.counts = _.mapValues( this.ids, 'length' );
    super.emit( 'ids', this.ids );
    super.emit( 'counts', this.counts );
  }

  private replace( ids: Record<SchemaId, string[]> ): boolean {
    if ( _.isEqual( this.ids, ids ) ) return false;
    this.ids = ids;
    this.emit();
    return true;
  }
  private update( ids: Record<SchemaId, string[]> ): boolean {
    this.ids = { ...this.ids, ...ids };
    this.emit();
    return true;
  }
  private insert( type: SchemaId, id: string ): boolean {
    const ids = this.ids[ type ] || ( this.ids[ type ] = [] );
    if ( ids.includes( id ) ) return false;
    ids.push( id );
    this.emit();
    return true;
  }
  private remove( type: SchemaId, id: string ): boolean {
    const ids = this.ids[ type ];
    if ( ! ids ) return false;
    if ( ! ids.includes( id ) ) return false;
    _.pull( ids, id );
    this.emit();
    return true;
  }
  #related_updates_cleanup?: () => void;
  private start() {
    this.ids = {};
    this.active = true;
    this.#related_updates_cleanup = getOrigins().watchRelatedUpdates(
      this.type, this.id, msg => {
        debug( 'RELATED UPDATES:', msg );
        const { method, type, id } = msg;
        if ( ! ( method && type && id ) ) return;
        if ( method === 'insert' ) this.insert( type, id );
        if ( method === 'remove' ) this.remove( type, id );
      },
    );
    this.resync();
  }

  private stop() {
    this.ids = null;
    this.#related_updates_cleanup();
    this.active = false;
  }

  async getIds() {
    if ( ! this.active ) await this.resync();
    return this.ids;
  }

  async resync() {
    if ( BUILD.isClient ) {
      return getTransport().perform( {
        schema      : this.type,
        id          : this.id,
        method      : 'action',
        action_name : '_getResourceIds',
      } ).then( res => {
        if ( res.ok ) {
          this.replace( res.result );
        } else {
          log.error( `getResourceIds error:`, res.getError() );
        }
      }, ( err ) => log.error( 'getResourceIds error:', err ) );
    } else {
      try {
        const rsrc = await this.origin.getInstance().load();
        this.replace( await rsrc.computeResourceIds() );
      } catch ( err ) {
        log.error( `getResourceIds error:`, err );
        throw err;
      }
    }
  }

  async processLiveUpdate( msg: LiveUpdateMessage ) {
    const rsrc = msg.version.getInstance();
    const res = await rsrc.updateResourceIds( msg, this.ids );
    if ( _.isPlainObject( res ) ) return this.update( res );
    if ( _.isNull( res ) ) return this.resync();
  }

  watch<T extends 'ids'|'counts'>( type: T, callback: Watcher<T> ): () => void;
  watch( callback: Watcher<'counts'> ): () => void;
  watch(
    type: 'ids' | 'counts' | Watcher<'ids'|'counts'>,
    watcher?: Watcher<'ids'|'counts'>,
  ): () => void {
    if ( typeof type === 'function' ) return this.watch( 'counts', type );
    if ( typeof watcher !== 'function' ) {
      throw new TypeError( `Counts.watch requires callback function` );
    }
    const cleanup = this.on( type, watcher );
    if ( this.active ) {
      if ( type === 'ids' ) watcher( this.ids );
      if ( type === 'counts' ) watcher( this.counts );
    } else {
      this.start();
    }
    return () => { cleanup(); this.cleanup(); };
  }

  cleanup() {
    if ( this.hasListeners( 'ids' ) || this.hasListeners( 'counts' ) ) return;
    this.stop();
  }
}
