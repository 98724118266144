import _ from 'lodash';
import { hideProps, squish, invariant, DatabaseSchemaError } from '@ssp/utils';

import type { Squishy } from '@ssp/utils';

import { Schema, isSchemaOptionsKey, SCHEMAS } from './Schema';
import { Module } from './Module';
import { Model } from './Model';
import { Behavior } from './Behavior';
import { SchemaFields } from './SchemaFields';
import { applyMethodDescriptors } from '~/modules/methods/utils';

import type { Definitions } from './types';
import type { SchemaOptions } from './Schema';
import type {
  ModelDefinition,
  SchemaConfigOptions,
} from '~/modules/declarations';


export class SchemaConfig implements SchemaConfigOptions {

  declare schema: Schema;

  get id() { return this.schema.id; }

  static finished = false;
  finished = false;
  invalidated = false;

  fields;

  actions: SchemaConfigOptions['actions'] = {};
  behaviors: SchemaConfigOptions['behaviors'] = {};
  faces: SchemaConfigOptions['faces'] = {};
  filters: SchemaConfigOptions['filters'] = {};
  indexes: SchemaConfigOptions['indexes'] = {};
  jobs: SchemaConfigOptions['jobs'] = {};
  methods: SchemaConfigOptions['methods'] = {};
  queries: SchemaConfigOptions['queries'] = {};
  resultset: SchemaConfigOptions['resultset'] = {};
  triggers: SchemaConfigOptions['triggers'] = {};
  updates: SchemaConfigOptions['updates'] = {};
  forms: SchemaConfigOptions['forms'] = {};
  views: SchemaConfigOptions['views'] = {};

  events: SchemaConfigOptions['events'] = [];
  validators: SchemaConfigOptions['validators'] = [];

  constructor( schema: Schema ) {
    hideProps( this, { schema } );
    this.fields = new SchemaFields( this );
  }

  declare accessors: PropertyDescriptorMap;

  getAccessors() {
    return this.accessors ? this.accessors : this.fields.makeAccessors();
  }

  definition: ModelDefinition = {};

  invalidate( _keys: ( keyof SchemaConfigOptions )[] ) {
    this.invalidated = true;
  }

  add( items: Partial<SchemaConfig> ) {
    const changed: string[] = [];
    for ( const [ key, data ] of Object.entries( items ) ) {
      if ( _.isNil( data ) ) continue;
      const methodName = `add${_.upperFirst( key )}`;
      if ( typeof this[ methodName ] === 'function' ) {
        if ( this[ methodName ]( data ) ) changed.push( key );
      } else {
        const store = this[ key ];
        if ( Array.isArray( store ) && Array.isArray( data ) ) {
          store.push( ...data );
          changed.push( key );
        } else if ( _.isPlainObject( store ) ) {
          _.assign( store, data );
          changed.push( key );
        } else {
          throw new DatabaseSchemaError( {
            message : `Invalid value for "${key}": ${data}`,
            tags    : { schema : this.id, method : 'SchemaConfig#add' },
            data    : { key, data },
          } );
        }
      }
    }
    if ( changed.length ) {
      this.invalidate( changed as ( keyof SchemaConfigOptions )[] );
    }
  }

  protected addBehaviors( behaviors: Record<string, Behavior> ) {
    let changes = false;
    for ( const behavior of Object.values( behaviors ) ) {
      if ( this.behaviors[ behavior.name ] === behavior ) return false;
      this.behaviors[ behavior.name ] = behavior;
      const built = behavior.build( this );
      changes = true;
      this.extend( built, `${behavior.name} (from ${behavior.origin})` );
    }
    return changes;
  }

  protected addEvents( events: SchemaConfigOptions['events'] ) {
    events = squish( [ this.events, events ] );
    events.forEach( x => x.validate( this.schema ) );
    this.events = _.uniqWith( events, ( a, b ) => a.equals( b ) );
    return true;
  }

  protected addFields( fields: Parameters<SchemaFields['addFields']>[0] ) {
    return this.fields.addFields( fields );
  }
  protected addMethods( methods: SchemaConfigOptions['methods'] ) {
    _.assign( this.methods, methods );
    applyMethodDescriptors( this.schema.model, methods );
    return true;
  }

  extend( definitions: Squishy<Definitions>, origin: string ) {
    return this._extend( Module.parse( definitions, origin ) );
  }
  _extend( definition: ModelDefinition ) {
    Module.apply( definition, this );
    this.definition = Module.merge( this.definition, definition );

    if ( this.finished || SchemaConfig.finished ) this.finish();

    const children = this.schema.getChildren();
    if ( children.length ) {
      _.each( children, child => child.config._extend( definition ) );
    }
    return this;
  }

  attachTypeField() {
    const schema = this.schema;

    _.assign( schema, { is_top_schema : true } );
    if ( ! schema.type_field ) return;
    if ( typeof schema.type_field !== 'string' ) {
      throw new DatabaseSchemaError( {
        message : `Schema type_field value msut be a string`,
        tags    : { schema : this.id, method : 'SchemaConfig#attachTypeField' },
        data    : { type_field : schema.type_field },
      } );
    }
    if ( schema.parent.type_field ) {
      if ( schema.type_field === schema.parent.type_field ) {
        _.assign( schema, { is_top_schema : false } );
        return;
      }
      throw new DatabaseSchemaError( {
        message : [
          `Schema ${schema.id} already has inherited type_field`,
          `"${schema.parent.type_field}" from ${schema.parent.id},`,
          `cannot change it to "${schema.type_field}".`,
        ].join( ' ' ),
        tags    : { schema : this.id, method : 'SchemaConfig#attachTypeField' },
        data    : { type_field : schema.type_field },
      } );
    }
    return {
      fields : {
        [ schema.type_field ] : {
          type      : 'string',
          index     : true,
          metadata  : true,
          default   : obj => obj?.[ schema.type_field ],
        },
      },
    };
  }

  finish() {
    this.finished = true;
    this.invalidated = false;
    Module.finish( this );

    /*
    // Disabled for now because @ssp/logger doesn't properly log the
    // fields when their getters are on the prototype, even if they are
    // enumerable, so we set the accessors on the instance instead (in
    // the Model constructor).
    const descs = schema_config.accessors;
    Object.defineProperties( model.prototype, descs );
    */
    hideProps( this, { accessors : this.fields.makeAccessors() } );

  }

  static finish() {
    this.finished = true;
    for ( const schema of Object.values( SCHEMAS ) ) {
      schema.config.finish();
    }
  }

  nuke() {
    log.debug( 'NUKING:', this.id );
    if ( ! this.schema.is_temporary ) {
      log.debug( `Not nuking ${this.schema.id} - not marked is_temporary` );
      return;
    }
    _.invokeMap( this.schema.getChildren( Infinity ), 'nuke' );
    delete SCHEMAS[ this.id ];
  }

}

export function defineModel(
  configs: Squishy<( SchemaOptions & ModelDefinition )>,
) {
  return defineSchema( configs ).model;
}
export function defineSchema(
  configs: Squishy<( SchemaOptions & ModelDefinition )>,
) {
  const [ opts, defs ] = extractSchemaOpts( configs );
  const schema = new Schema( opts as SchemaOptions );
  if ( defs.length ) schema.config.extend( defs, opts.origin );
  return schema;
}
export function initializeSchema(
  configs: Squishy<( SchemaOptions & ModelDefinition )>,
  model: typeof Model | undefined,
) {
  const [ opts, defs ] = extractSchemaOpts( configs );
  const schema = new Schema( opts as SchemaOptions, model );
  if ( defs.length ) schema.config.extend( defs, opts.origin );
  return schema;
}
function extractSchemaOpts(
  configs: Squishy<( SchemaOptions & ModelDefinition )>,
): [ SchemaOptions, ModelDefinition[] ] {
  const opts: any = {};
  const defs: ModelDefinition[] = [];
  for ( const def of squish( configs ) ) {
    for ( const key of Schema.options_keys ) {
      invariant( isSchemaOptionsKey( key ) );
      if ( _.has( def, key ) ) {
        opts[ key ] = def[ key ] as SchemaOptions[typeof key];
        delete def[ key ];
      }
    }
    if ( ! _.isEmpty( def ) ) defs.push( def as ModelDefinition );
  }
  return [ opts, defs ];
}
