import _ from 'lodash';
import { DatabaseModelError } from '@ssp/utils';

import DB from '~';
import { isProject, isResource } from '~/utils/types';

export const queries = BUILD.isServer ? {
  async coworkers( onlyMyTeams=false ) {
    if ( onlyMyTeams ) {
      return { team_ids : { $in : ctx.user.team_ids } };
    } else {
      return { project_ids : { $in : ctx.user.project_ids } };
    }
  },
  async project( project_id ) {
    if ( isProject( project_id ) && project_id._id ) {
      project_id = project_id._id;
    }
    if ( isResource( project_id ) && project_id.project_id ) {
      project_id = project_id.project_id;
    }
    if ( _.isString( project_id ) ) {
      const project = await DB.SSP.Project.fetch( project_id );
      const ids = await project.getMemberIds();
      return { _id : { $in : ids } };
    }
    throw new DatabaseModelError( {
      message : `Invalid argument for query project(): ${project_id}`,
      tags    : { schema : this.schema.id, query : 'project' },
      data    : { project_id },
    } );
  },
} : {
  coworkers( onlyMyTeams=false ) {
    return [ {}, { flag : { coworkers : onlyMyTeams } } ];
  },
  project( project_id ) {
    if ( isProject( project_id ) && project_id._id ) {
      project_id = project_id._id;
    }
    if ( isResource( project_id ) && project_id.project_id ) {
      project_id = project_id.project_id;
    }
    if ( _.isString( project_id ) ) {
      return [ {}, { flag : { project : project_id } } ];
    }
    throw new DatabaseModelError( {
      message : `Invalid argument for query project(): ${project_id}`,
      tags    : { schema : this.schema.id, query : 'project' },
      data    : { project_id },
    } );
  },
};
