import _ from 'lodash';
import { JobStep } from './Step';

import type { JobOptions } from '~/modules/jobs/types';

import type { JobStepOptions } from './Step';

export type JobStepConfigureJobOptions = {
  type: 'ConfigureJob';
  config: Partial<JobOptions>;
} & JobStepOptions;

declare module '~/modules/jobs/types' {
  export interface JobStepTypes {
    ConfigureJob: JobStepConfigureJobOptions;
  }
}

export class JobStepConfigureJob extends JobStep {
  declare config: Partial<JobOptions>;
}

JobStepConfigureJob.initialize( {
  id        : 'JobStep.ConfigureJob',
  inherit   : 'JobStep',
  fields    : {
    config    : {
      type      : 'object',
      describe  : 'Configuration options to apply to job',
      default() { return {}; },
    },
  },
}, BUILD.isServer && {
  methods : class extends JobStepConfigureJob {

    async execute() {
      const config = this.config;
      if ( ! _.isPlainObject( config ) ) {
        log.debug( 'ConfigureJob:', this );
        throw new Error( `JobDefinition step ${this.name} has invalid config` );
      }
      await this.job.update( config );
    }

  },
} );
