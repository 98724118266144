import _ from 'lodash';
import {
  mkdebug, isConstructor,
  DatabaseModelError,
  DatabaseSchemaError,
} from '@ssp/utils';
import { inspect } from '@ssp/logger';

import type { Squishy } from '@ssp/utils';

import { Watchable } from './Watchable';
import { getDataMap, updateQuietly } from '~/modules/broker/data-utils';
import { Updates } from '~/modules/updates/Updates';
import { Schema } from './Schema';
import { initializeSchema } from './SchemaConfig';
import { EventBox } from '~/modules/events/EventBox';

import type { ModelData, SchemaId, TSchema, TResource } from '~/types';
import type { TransportResponse } from '~/lib/TransportResponse';
import type { SchemaOptions } from './Schema';
import type { ModelDefinition } from '~/modules/declarations';
import type { FieldSelector, Origin, Version } from '~/modules';
import type { SchemaIdSource, Definitions } from './types';

const debug = mkdebug( 'ssp:database:core:model' );

const callCheckSymbol = Symbol( 'callCheckSymbol' );

const counters = {};

export type ModelDefaults<T extends SchemaId = SchemaId> =
  | boolean // true = all fields that have a default value, false = none
  | FieldSelector<TSchema<T>>[] // FieldSet field selectors
  | Partial<ModelData<T>>;  // regular default values

export type ModelOptions<T extends SchemaId = SchemaId> = {
  type?: SchemaId;
  method: string;
  creating?: boolean;
  data?: ModelData<T>;
  defaults?: ModelDefaults<T>;
  partial?: Partial<ModelData<T>>;
  response?: TransportResponse<TResource<T>>;
  origin?: Origin;
  version?: Version;
  id?: string;
  ident?: string | string[];
  query?: Record<string, any>;
  locked?: boolean;
  immutable?: boolean;
  live?: boolean;
};

export interface Model {}

export class Model extends Watchable {

  static get<T extends typeof Model>(
    this: T, id: SchemaIdSource,
  ): T | undefined {
    if ( this.is( id ) ) return id;
    const model = Schema.get( id )?.model;
    if ( this.is( model ) ) return model;
  }

  static demand<T extends typeof Model>( this: T, id: SchemaIdSource ): T {
    const model = this.get( id );
    if ( model ) return model;
    throw new DatabaseSchemaError( `Unknown model '${String( id )}'`, {
      tags : { schema : String( id ) },
    } );
  }

  /**
   * Check whether the argument is a database model.  Returns true if
   * the passed argument has the DB Model as an ancestor and is
   * a constructor rather than an instance of a model.
   */
  static is<T extends typeof Model>( this: T, value: any ): value is T {
    return isConstructor( value ) && this.isPrototypeOf( value );
  }

  static initialize(
    config: ModelDefinition & SchemaOptions,
    ...definitions: Squishy<Definitions>[]
  ) {
    initializeSchema( [ {
      ...config,
      origin  : config.id + '.initialize',
    }, definitions ], this );
    return this;
  }

  static extend( ...definitions: Squishy<ModelDefinition>[] ) {
    this.schema.config.extend( definitions, this.schema.id + '.extend' );
    return this;
  }

  static counter: number = 0;

  static schema: Schema;
  get schema() { return Object.getPrototypeOf( this ).constructor.schema; }

  static get model() { return this; }
  get model() { return Object.getPrototypeOf( this ).constructor; }

  declare instance_id: string;
  creating: boolean = false;

  constructor( { method, creating, ...options }: ModelOptions, callChecker ) {
    super();
    const { schema } = this;
    const type = schema.id;
    if ( ! counters[ type ] ) counters[ type ] = 0;
    Model.counter++;
    const counter = counters[ type ]++;
    this.instance_id = `model-${type}-${counter}`;
    debug( 'Constructing', this.instance_id );
    if ( creating ) this.creating = true;

    if ( callChecker !== callCheckSymbol ) {
      throw new DatabaseModelError( {
        message : [
          `Do not call ${type} constructor directly: use`,
          `${type}.create() instead of new ${type}()`,
        ].join( ' ' ),
        tags    : { schema : this.schema.id, method : 'Model#constructor' },
      } );
    }
    if ( arguments.length > 2 ) {
      throw new DatabaseModelError( {
        message : `Too many arguments to ${type} constructor`,
        tags    : { schema : this.schema.id, method : 'Model#constructor' },
        data    : { arguments }, // eslint-disable-line prefer-rest-params
      } );
    }
    if ( schema.is_abstract ) {
      throw new DatabaseModelError( {
        message : `Cannot instantiate abstract model "${type}"`,
        tags    : { schema : this.schema.id, method : 'Model#constructor' },
      } );
    }
    if ( ! _.isString( method ) ) {
      throw new DatabaseModelError( {
        message : `Invalid method name "${method}" provided to constructor`,
        tags    : { schema : this.schema.id, method : 'Model#constructor' },
        data    : { method, options },
      } );
    }

    options = _.omitBy( options, _.isNil );
    Object.defineProperties( this, schema.config.getAccessors() );

    const ev = this.eventbox( {
      options, handled : _.keys( options ), mode : 'sync', method,
    } );

    debug( 'Constructing', type, 'with', options );

    if ( options.type ) {
      if ( this.schema.id !== options.type ) {
        throw new DatabaseModelError( {
          message : `Expected "${options.type}", found "${this.schema.id}"`,
          tags    : { schema : this.schema.id, method : 'Model#constructor' },
          data    : { options },
        } );
      }
      ev.handled( 'type' );
    }

    ev.dispatch( 'before', 'build' );

    this._preprocess_build_events( ev );

    ev.dispatch( 'during', 'build' );

    if ( options.data ) {
      this.merge( options.data );
      ev.handled( 'data' );
    }
    if ( ! _.isNil( options.defaults ) ) {
      this.defaults( options.defaults );
      ev.handled( 'defaults' );
    }

    this._postprocess_build_events( ev );

    ev.dispatch( 'after', 'build' );

    if ( ev.unhandled.length ) {
      throw new DatabaseModelError( {
        message : `Unknown options to Model constructor: ${ev.unhandled}`,
        tags    : { schema : this.schema.id, method : 'Model#constructor' },
        data    : {
          options, unhandled : ev.unhandled.join( ', ' ),
          values : _.pick( options, ev.unhandled ),
        },
      } );
    }

  }

  _preprocess_build_events( ev ) {
    const { options } = ev;
    if ( options.partial ) {
      updateQuietly( this, options.partial, { partial : true } );
      ev.handled( 'partial' );
    }
  }
  _postprocess_build_events( _ev ) { /* no-op */ }

  static get displayNameFields() { return [ 'name' ]; }
  static construct( opts ) {
    const ModelClass = this.schema.getModelForOptions( opts );
    return new ModelClass( opts, callCheckSymbol );
  }

  findDisplayName() {
    return Object.getPrototypeOf( this ).constructor.findDisplayNameFor( this );
  }
  static findDisplayNameFor( data ) {
    for ( const field of this.displayNameFields ) {
      if ( data[ field ] ) return data[ field ];
    }
    return;
  }
  debug( ...args ) {
    if ( ! debug.enabled ) return;
    debug( String( this ), ...args );
  }
  static debug( ...args ) {
    if ( ! debug.enabled ) return;
    debug( `Model<${this.schema.id}>`, ...args );
  }

  /**
   * Returns true if the model has a value for the indicated field.
   *
   * @param key - Field name.
   * @returns Whether the field has a value or not.
   */
  has( key: string ): boolean {
    if ( ! _.isString( key ) ) return false;
    const field = this.schema.getField( key );
    if ( ! field ) return false;
    return getDataMap( this ).has( key );
  }

  default( key: string, value: any ) {
    if ( this.has( key ) ) return;
    this[ key ] = value;
  }

  merge( data: Updates ): void;
  merge( data: Record<string, any>, quietly?: boolean ): void;
  merge( data, quietly: boolean = false ) {
    if ( data instanceof Updates ) {
      if ( quietly ) {
        throw new DatabaseModelError( {
          message : `Cannot apply updates quietly from an Updates instance`,
          tags    : { schema : this.schema.id, method : 'Model#merge' },
          data    : { data },
        } );
      } else {
        data.applyTo( this );
      }
    } else if ( _.isPlainObject( data ) ) {
      const changed = updateQuietly( this, data, { partial : true } );
      if ( changed && ! quietly ) this.changed();
      return changed;
    } else {
      throw new DatabaseModelError( {
        message : `Cannot merge "${data}" with type ${typeof data}`,
        tags    : { schema : this.schema.id, method : 'Model#merge' },
        data    : { data },
      } );
    }
  }

  matches( data ) {
    for ( const [ key, raw_value ] of Object.entries( data ) ) {
      const field = this.schema.getField( key );
      if ( ! field ) continue;
      const value = field.transform( field.coerce( raw_value, this ), this );
      if ( ! field.equals( this[ key ], value ) ) return false;
    }
    return true;
  }

  defaults( data ) {
    if ( ! data ) return;
    if ( data === true ) data = [ '@has:default' ];
    if ( _.isArray( data ) ) {
      const fields = this.schema.getFields( data, '-@virtual', '-@computed' );
      data = _.transform( fields, ( res, field ) => {
        res[ field.name ] = field.getDefault( this );
      }, {} );
    }
    if ( ! _.isPlainObject( data ) ) {
      throw new DatabaseModelError( {
        message : `Invalid defaults argument to ${this.schema.id}`,
        tags    : { schema : this.schema.id, method : 'Model#defaults' },
        data    : { data },
      } );
    }
    if ( this.schema.type_field ) {
      _.defaults( data, { [ this.schema.type_field ] : this.schema.id } );
    }
    data = _.omitBy( data, ( val, key ) => (
      this.has( key ) || this[ key ] === val
    ) );
    // if ( _.isEmpty( data ) ) return;
    const ev = this.eventbox( { defaults : data, mode : 'sync' } );
    ev.before( 'defaults' );
    this.merge( data );
    ev.after( 'defaults' );
  }

  eventbox( opts={} ) { return new EventBox( this, opts ); }
  static eventbox( opts={} ) { return new EventBox( this, opts ); }

  toString() { return `Model<${this.schema.id}>`; }

  /**
   * Create a new Model document.  This method is used to create a new
   * record for a model that does not yet exist in the database.  This
   * will just create it and return it, without sending anything to
   * the server.  You can use this to construct an empty resource that
   * you then modify before sending to the server (with `save()`), or
   * to construct a subdoc that gets added to a resource later.
   *
   * @param {object} [data] - Data to populate the subdocs fields.
   * @param {Model~Options} [opts] - Constructor opts.
   * @returns {Model} The created model instance.
   */
  static create(
    data: Record<string, unknown> = {},
    opts: Partial<ModelOptions> = {},
  ) {
    debug( 'Creating', this.schema.id, 'with', data, opts );
    const options = {
      method    : 'create',
      ...opts,
      defaults : true,
      creating : true,
      data     : _.assign( {}, opts.data, data ),
    };
    const ev = this.eventbox( { options, mode : 'sync', method : 'create' } );
    ev.before( 'create' );
    const resource = this.construct( options );
    ev.after( 'create', { resource } );
    return resource;
  }

  static coerce( data: unknown, opts: Partial<ModelOptions> = {} ) {
    debug( 'Coercing', this.schema.id, 'with', data, opts );
    if ( data instanceof this ) return data;
    // Don't coerce undefined or null values
    if ( _.isNil( data ) ) return data;
    return this.construct( {
      method    : 'coerce',
      ...opts,
      defaults  : true,
      creating  : true,
      data      : _.assign( {}, opts.data, data ),
    } );
  }

  /**
   * Return the resource data as a regular JSON object.  This will
   * include all fields except for computed and virtual ones.
   */
  toJSON() {
    const fields = this.schema.getFields( '@all', '-@virtual', '-@computed' );
    return _.fromPairs( _.map( fields, field => {
      const formatted = field.format( this[ field.name ] );
      if ( formatted instanceof Model ) {
        throw new DatabaseModelError( {
          message : `${this.schema.id} field ${field.name} formatted a Model?`,
          tags    : { schema : this.schema.id, method : 'Model#toJSON' },
        } );
      }
      return [ field.name, formatted ];
    } ) );
  }

  keys() { return getDataMap( this ).keys(); }
  values() { return getDataMap( this ).values(); }
  entries() { return getDataMap( this ).entries(); }

  // Chai uses this to format values in AssertionError
  inspect() { return inspect( this ); }

  /**
   * Return true if this object represents the same thing as the other
   * object.  The same thing means:
   *  - If they are not of the same type then return false.
   *  - If this schema has unique fields then check to see if they
   *    represent the same object.
   *  - If they don't have unique fields then check to see if all of
   *  their field values are unique.
   *
   * @param {Model} other - The object to compare against.
   */
  equals( other ) {
    // If it's not a model then don't bother checking anything else
    if ( ! ( other instanceof Model ) ) return false;
    // If it isn't the same type then it can't match
    if ( this.schema.id !== other.schema.id ) return false;
    const matches = ( fields ) => {
      return _.every( fields, field => {
        if ( _.isNil( this[ field ] ) ) return false;
        if ( _.isNil( other[ field ] ) ) return false;
        return this[ field ] === other[ field ];
      } );
    };
    for ( const index of this.schema.getIndexes() ) {
      if ( ! index.unique ) return; // skip non-unique indexes
      if ( matches( _.keys( index.fields ) ) ) return true;
    }
    return false;
  }

  get broker() { return getDataMap( this ).broker; }

  get( key ) { return this[ key ]; }
  set( key, value ) { return this[ key ] = value; }

}
