import _ from 'lodash';
import { Module } from '~/core/lib/Module';
import { Validator } from '@ssp/types';

Module.create( {
  name     : 'validation',
  services : false,

  parse( data ) {
    if ( _.isEmpty( data.validator ) && _.isEmpty( data.validators ) ) return;
    const validators = Validator.parse( [ data.validators, data.validator ] );
    if ( _.isEmpty( validators ) ) return;
    return { validators };
  },

  merge( ...configs ) {
    const inputs = _.compact( _.map( configs, 'validators' ) );
    if ( ! inputs.length ) return;
    return { validators : Validator.merge( inputs ) };
  },

  apply( data, config ) {
    config.add( { validators : data.validators } );
  },

} );
