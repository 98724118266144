// Automatically generated, DO NOT EDIT!
// To update run "yarn do-updates"
/* eslint-disable */
import { Model } from '~/core/lib/Model';
Model.demand( 'CSNAD.Group' ).extend( {
  "actions": {
    "import": {
      "access": "support",
      "icon": "far:file-import",
      "id": "import",
      "job_name": "import",
      "label": "Import",
      "name": "import",
      "params": {
        "dn": {
          "describe": "Distinguished Name",
          "required": true,
          "type": "string"
        },
        "subgroup_actions": {
          "default": "ignore",
          "form": {
            "help": [
              "Choosing 'expand' will convert any subgroups or ",
              "distribution lists that are listed as members ",
              "into their individual members otherwise they will be ignored."
            ],
            "options": [
              "ignore",
              "expand"
            ],
            "type": "Select"
          },
          "label": "Options",
          "required": false,
          "type": "string"
        }
      },
      "type": "class"
    },
    "sync": {
      "access": "member",
      "icon": "fas:sync",
      "id": "sync",
      "job_name": "sync",
      "label": "Sync",
      "name": "sync",
      "type": "instance"
    }
  }
} );
