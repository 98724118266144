import { isUser } from '~/utils/types';
import { createResource } from '~/core/creators';

createResource( {
  id                : 'Mural.User',
  name              : 'Mural User',
  attachable        : [ 'SSP.User' ],
  icon              : 'far:sticky-note',
  service_id_field  : 'mural_id',
  hideZero          : true, // TODO: remove once create job exists
  fields            : {
    mural_id    : {
      type        : 'id',
      label       : 'Mural User ID',
      summary     : true,
      index       : true,
      readonly    : true,
      unique      : true,
      optional    : true,
      immutable   : true,
    },
    name    : {
      type      : 'string',
      label     : 'Full Name',
      summary   : true,
      index     : true,
      unique    : false,
      optional  : true,
    },
    givenName : {
      type : 'string',
      label : 'First Name',
      summary : true,
      unique  : false,
      optional : false,
    },
    familyName : {
      type : 'string',
      label : 'Last Name',
      summary : true,
      unique  : false,
      optional : false,
    },
  },
  behaviors : {
    hasEmail            : {},
    hasDisabled         : { canAutoDisable : true },
    hasPrimaryAccount   : {},
  },
  actions : {
    edit : { not_available : 'true' },
  },
  methods : class {

    static createFrom( ssp_user ) {
      if ( ! isUser( ssp_user ) ) {
        throw new Error( `createFrom must be called with an SSP.User` );
      }
      const names = ssp_user.name.split( ',' );
      return this.insert( {
        user_id       : ssp_user._id,
        name          : ssp_user.name,
        familyName    : names[0],
        givenName     : names[1],
        email         : ssp_user.email,
      } );
    }

  },
  faces         : {
    'card.summary' : [
      {
        layout : 'Badges',
        fields : [ '@badge' ],
      },
      {
        layout : 'LabelsAndValues',
        fields : [ 'email', 'mural_id' ],
      },
    ],
    'list.summary' : [
      {
        layout : {
          type       : 'ItemsBetweenTitleAndIcons',
          iconize    : [ {
            fieldName : 'mural_id',
            icon      : 'fad:id-badge',
          } ],
        },
        fields : [ '-@all' ],
      },
      {
        layout : 'ItemsAndBadges',
        fields  : [ 'email', '@badge' ],
      },
    ],
  },
} );
