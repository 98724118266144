import { DatabaseError } from '@ssp/utils';

export {
  DatabaseError,
  DatabaseMissingDataError,
  DatabaseResultSetError,
  DatabaseSchemaError,
  DatabaseModelError,
  DatabaseTransportError,
} from '@ssp/utils';

export interface ErrorArgs {
  message: string[];
  details: Record<string, any>;
  options: Record<string, any>;
}

export function throwError( ...args ): never {
  throw new DatabaseError( ...args, { thrower : throwError } );
}
